import React = require("react");
import { K2RuleWithVCXProps } from "./k2StyleRenderer";
import { UpdateControl, UFUpdateControl, UpdateDynamicContent } from "../common/communication.base";
import { K2ComponentState, WithContextPlacementProps, withContext, StyleHelper, AcquireControl } from "./k2hoc";
import { NclGroupBox, NclDynamicContent } from "../common/components.ncl";
import { GenerateControl, GenerateChildrenOfControl } from "./K2GenerateControl";
import { K2TruncateText } from "./K2TruncateText";

class _DynamicContent extends React.PureComponent<WithContextPlacementProps, K2ComponentState<UpdateDynamicContent>> {
  static displayName = `K2DynamicContent`;
  private control: NclDynamicContent;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclDynamicContent;
    }) as NclDynamicContent;
    this.state = { data: this.control.init(this) as UpdateDynamicContent, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateDynamicContent>) => {
      return { data: state as UpdateDynamicContent };
    });

    this.forceUpdate();
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    let ctrl = this.control.Children.first();
    let content: JSX.Element = null;
    if (ctrl) {
      content = GenerateControl(ctrl);
    }
    return <div style={StyleHelper(this.control, this.props.style)}>{content}</div>;
  }
}
export const K2DynamicContent = withContext(_DynamicContent);
