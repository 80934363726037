import * as React from "react";
import ReactResizeDetector from "react-resize-detector";

import { ElementHtmlAttributes, getAttributes } from "../common/common";
import { UFUpdateControl, UpdateCommandItem, UpdateControl, Orientation, IconPosition } from "../common/communication.base";
import { NclActionSeparator, NclCommandItem, NclTabToolBar, NclToolBar, ControlType, NclInput, NclHeader } from "../common/components.ncl";
import { VisualContext } from "../common/visualContext";
import { K2ComponentState, withContext, WithContextPlacementProps, StyleHelper, AcquireControl } from "./k2hoc";
import { K2Img } from "./K2Image";
import { K2RuleProps, K2RuleWithVCXProps } from "./k2StyleRenderer";
import { K2TruncateText } from "./K2TruncateText";

type ActionRuleProps = K2RuleWithVCXProps & {
  width: number;
  checked: boolean;
};

const styleRules = {
  actionStyleBtn: (props: ActionRuleProps) => ({
    background: props.checked ? props.vcx.getColor(props.vcx.Data.ColorMap.ContentFrame1) : "transparent",
    color: "inherit",
    border: props.checked ? props.vcx.sizeMap(2) + "px solid " + props.vcx.getColor(props.vcx.Data.ColorMap.ContentFrame3) : "none",
    position: "relative" as const,
  }),
  actionRule: (props: K2RuleProps) => ({}),
  vContent: (props: K2RuleWithVCXProps) => ({
    display: "flex",
    flexDirection: "column" as "column",
    padding: `0px ${props.vcx.sizeMap(10)}px`,
  }),
  hContent: (props: K2RuleProps) => ({
    display: "flex",
    flexDirection: "row" as "row",
    alignItems: "center",
  }),
};

interface ActionState extends K2ComponentState<UpdateCommandItem> {
  height?: number;
  width?: number;
}

interface ActionProps extends WithContextPlacementProps {
  color?: string;
}

class _Action extends React.PureComponent<ActionProps, ActionState> {
  private control: NclCommandItem;

  constructor(props: ActionProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclCommandItem;
    }) as NclCommandItem;
    this.state = { data: this.control.init(this) as UpdateCommandItem, vcxVersion: -1 };
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateCommandItem>) => {
      return { data: state as UpdateCommandItem };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  render() {
    let caption: JSX.Element = null;

    if (this.state.data.Title && this.state.data.Title !== "" && this.control.Ncl.FrgtData.ShowCaption) {
      caption = (
        <K2TruncateText className={this.props.renderer.renderFontRule(this.control.VCX.LabelControl.Font, this.control.VCX)}>
          {this.state.data.Title}
        </K2TruncateText>
      );
    }

    let clr: string;
    if (this.state.data.Enabled !== false) {
      clr = this.props.color ? this.props.color : null;
    } else {
      clr = "rgba(0, 0, 0, 0.2)";
    }

    let icon: JSX.Element;

    if (this.state.data.GlyphId && this.control.Ncl.FrgtData.ShowIcon) {
      let height: number,
        width: number = 0;

      if (this.control.Parent instanceof NclToolBar && this.control.Parent.Parent instanceof NclInput) {
        width = height = this.control.VCX.LabelControl.getHeight(this.control.Parent.Parent.Size);
      } else if (this.control.Parent instanceof NclToolBar && this.control.Parent.Parent instanceof NclTabToolBar) {
        width = height = this.control.VCX.InputControl.getInputHeight(this.control.Parent.Parent.Size * 0.7, false, true);
      } else {
        width = height = this.control.VCX.LabelControl.getHeight(1.4);
      }

      icon = (
        <K2Img
          height={this.state.data.Checked ? height - this.control.VCX.sizeMap(2) * 2 : height}
          width={this.state.data.Checked ? width - this.control.VCX.sizeMap(2) * 2 : width}
          glyphId={this.state.data.GlyphId}
          vcx={this.control.VCX}
          strokeColor={clr}
          style={this.getImageStyle()}
        />
      );
    }

    let addAttributes: ElementHtmlAttributes = getAttributes(this.state.data);

    return (
      <div style={StyleHelper(this.control, this.props.style)} className={this.props.renderer.renderRule(styleRules.actionRule, {})}>
        <button
          data-k2-test-id={this.control.MetaData.Name}
          className={this.props.renderer.renderRule(styleRules.actionStyleBtn, {
            width: this.state.width,
            vcx: this.control.VCX,
            checked: this.state.data.Checked,
          })}
          onClick={this.handleClick}
          {...addAttributes}
        >
          {this.getButtonContent(icon, caption)}
          {this.state.data.SubMenuIndicatorVisible && <SubMenuIndicator vcx={this.control.VCX} />}
        </button>
      </div>
    );
  }

  private getButtonContent = (icon: JSX.Element, caption: JSX.Element): JSX.Element => {
    if (this.control.Parent instanceof NclToolBar && this.control.Parent.Parent instanceof NclTabToolBar) {
      return (
        <span className={this.props.renderer.renderRule(styleRules.vContent, { vcx: this.control.VCX })}>
          {icon}
          {caption}
        </span>
      );
    }
    switch (this.control.Ncl.FrgtData.IconPosition) {
      case IconPosition.ipBottom:
        return (
          <span className={this.props.renderer.renderRule(styleRules.vContent, { vcx: this.control.VCX })}>
            {caption}
            {icon}
          </span>
        );
      case IconPosition.ipTop:
        return (
          <span className={this.props.renderer.renderRule(styleRules.vContent, { vcx: this.control.VCX })}>
            {icon}
            {caption}
          </span>
        );
      case IconPosition.ipLeft:
        return (
          <span className={this.props.renderer.renderRule(styleRules.hContent, this.control.VCX)}>
            {icon}
            {caption}
          </span>
        );
      case IconPosition.ipRight:
        return (
          <span className={this.props.renderer.renderRule(styleRules.hContent, this.control.VCX)}>
            {caption}
            {icon}
          </span>
        );
      case IconPosition.ipCenter:
        return (
          <span>
            {caption}
            {icon}
          </span>
        );
    }
    return (
      <span>
        {icon}
        {caption}
      </span>
    );
  };

  private getImageStyle = (): React.CSSProperties => {
    let iconStyle: React.CSSProperties = null;

    //Pro ikony na TabToolPanel nastavit zarovnání na střed
    if (this.control.Parent instanceof NclToolBar && this.control.Parent.Parent instanceof NclTabToolBar) {
      iconStyle = {
        margin: "auto",
        marginTop: "initial",
        marginBottom: "initial",
      };
      return iconStyle;
    }

    return iconStyle;
  };

  private handleClick = () => {
    this.control.executeCommand();
  };
}

export const K2Action = withContext(_Action);

interface _ActionSeparatorProps extends WithContextPlacementProps {
  color?: string;
}

class _ActionSeparator extends React.PureComponent<_ActionSeparatorProps, K2ComponentState<UFUpdateControl>> {
  static displayName = `K2Separator`;
  private control: NclActionSeparator;

  constructor(props: _ActionSeparatorProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclActionSeparator;
    }) as NclActionSeparator;
    this.state = { data: this.control.init(this) as UFUpdateControl, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UFUpdateControl>) => {
      return { data: state as UFUpdateControl };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    let clr = this.props.color ? this.props.color : this.control.VCX.getColor(this.control.VCX.Data.ColorMap.ContentFrame1);
    return (
      <div style={StyleHelper(this.control, Object.assign({}, { display: "flex", width: "auto", flex: "0 0 auto" }, this.props.style))}>
        <ActionSeparator width={this.control.VCX.sizeMap(1)} color={clr} />
      </div>
    );
  }
}

export const K2ActionSeparator = withContext(_ActionSeparator);

interface ActionSeparatorProps {
  height?: number | string;
  width?: number | string;
  color?: string;
  orientation?: Orientation;
}

export class ActionSeparator extends React.PureComponent<ActionSeparatorProps> {
  render() {
    let x,
      y = "0";
    let separator;
    if (this.props.orientation == Orientation.foHorizontal) {
      x = "100%";
      separator = <div style={{ borderTop: `1px solid ${this.props.color}`, width: this.props.width }}></div>;
    } else {
      y = "100%";
      separator = <div style={{ borderRight: `${this.props.width}px solid ${this.props.color}` }}></div>;
    }

    return separator;
  }
}

const arrow = (props: K2RuleWithVCXProps) => ({
  position: "absolute" as const,
  bottom: props.vcx.sizeMap(-5) + "px",
  right: props.vcx.sizeMap(-4) + "px",
});

interface SubMenuIndicatorProps extends WithContextPlacementProps {
  vcx: VisualContext;
}

class _SubMenuIndicator extends React.Component<SubMenuIndicatorProps> {
  constructor(props: SubMenuIndicatorProps) {
    super(props);
  }

  render() {
    return (
      <div className={this.props.renderer.renderRule(arrow, { vcx: this.props.vcx })}>
        <K2Img glyphId="wui*arrow.down" vcx={this.props.vcx} height={15} />
      </div>
    );
  }
}

export const SubMenuIndicator = withContext(_SubMenuIndicator);
