import * as React from "react";
import { withContext, WithContextPlacementProps, K2ComponentState, AcquireControl, StyleHelper } from "./k2hoc";
import { UpdateSplitterPanel, SplitterPanelOrientation, UpdateControl } from "../common/communication.base";
import { NclSplitterPanel } from "../common/components.ncl";
import { GenerateControl } from "./K2GenerateControl";
import { K2RuleWithVCXProps, marginRule } from "./k2StyleRenderer";
import { K2Img } from "./K2Image";
import { Context } from "../appcontext";

type SplitterRuleProps = K2RuleWithVCXProps & {
  isVertical: boolean;
  ratio: number;
};

const SPLITTER_SIZE = 7;
const TOUCH_MARGIN = 20;
const ALLOWED_SWIPE_AREA = 200;

const splitterRule = (props: SplitterRuleProps) => ({
  backgroundColor: props.vcx.getColor(props.vcx.Data.ColorMap.AccentBaseColorBck),
  width: props.isVertical ? `${props.vcx.sizeMap(SPLITTER_SIZE)}px` : "auto",
  height: props.isVertical ? "auto" : `${props.vcx.sizeMap(SPLITTER_SIZE)}px`,
  cursor: props.isVertical ? "col-resize" : "row-resize",
  flex: "0 0 auto",
  transition: `${props.isVertical ? "width" : "height"} 0.25s`,
  alignItems: "center",
});

const mobileSplitter = (props: SplitterRuleProps) => ({
  backgroundColor: props.vcx.getColor(props.vcx.Data.ColorMap.AccentBaseColorBck),
  alignItems: "center",
  position: "absolute" as "absolute",
  height: `${props.vcx.sizeMap(50)}px`,
  width: `${props.vcx.sizeMap(20)}px`,
  top: "50%",
  transform: "translate(0, -50%)",
  right: 0,
});

interface SplitterState extends K2ComponentState<UpdateSplitterPanel> {
  ratio: number;
}

class _SplitterPanel extends React.PureComponent<WithContextPlacementProps, SplitterState> {
  static displayName = "K2SplitterPanel";
  private control: NclSplitterPanel;
  private dragging: boolean;
  private timer: number;
  private currentTarget: HTMLDivElement;
  private rect: DOMRect;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => ctrl instanceof NclSplitterPanel) as NclSplitterPanel;
    this.state = {
      data: this.control.init(this) as UpdateSplitterPanel,
      vcxVersion: -1,
      ratio: 0.5,
    };
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateSplitterPanel>) => {
      return {
        data: state as UpdateSplitterPanel,
        ratio: window.innerWidth < Context.DeviceInfo.ResponsiveBreakpoints[0] ? 1 : (state as UpdateSplitterPanel).Ratio,
      };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  isSplitterVertical = () => {
    if (
      this.control.Ncl.FrgtData.Orientation === SplitterPanelOrientation.spoLeft ||
      this.control.Ncl.FrgtData.Orientation === SplitterPanelOrientation.spoRight
    )
      return true;
    else return false;
  };

  handleMouseDown = (e: React.MouseEvent) => {
    this.dragging = true;
    this.rect = e.currentTarget.parentElement.getBoundingClientRect();
  };

  handleContextMenu = (e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>) => {
    if (this.dragging) {
      e.stopPropagation();
    }
  };

  handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    if (e.currentTarget.children[1] === undefined) return;
    this.currentTarget = e.currentTarget;
    this.rect = e.currentTarget.getBoundingClientRect();
    const splitter = e.currentTarget.children[1].getBoundingClientRect();
    const clientX = e.touches[0].clientX;
    const clientY = e.touches[0].clientY;

    // Vymezeni mista, kde je mozne vyvolat resize
    if (
      (this.isSplitterVertical() && clientX > splitter.left - TOUCH_MARGIN && clientX < splitter.right + TOUCH_MARGIN) ||
      (this.isSplitterVertical() === false && clientY > splitter.top - TOUCH_MARGIN && clientY < splitter.bottom + TOUCH_MARGIN)
    ) {
      e.stopPropagation();

      // Simulace long touche
      this.timer = window.setTimeout(() => {
        if (this.isSplitterVertical()) {
          this.dragStart(this.currentTarget.children[1] as HTMLDivElement, "width");
        } else if (this.isSplitterVertical() === false) {
          this.dragStart(this.currentTarget.children[1] as HTMLDivElement, "height");
        }
      }, 500);
    }
  };

  dragStart = (splitter: HTMLDivElement, dimension: string) => {
    this.dragging = true;
    splitter.style.cssText = `${dimension}: ${this.control.VCX.sizeMap(SPLITTER_SIZE * 2)}px;`;
  };

  dragCancel = (splitter: HTMLDivElement, dimension: string) => {
    clearTimeout(this.timer);
    this.dragging = false;

    if (this.isSplitterVertical() && window.innerWidth < Context.DeviceInfo.ResponsiveBreakpoints[0]) {
      splitter.style.cssText = `${dimension}: ${this.control.VCX.sizeMap(20)}px;`;
    } else {
      splitter.style.cssText = `${dimension}: ${this.control.VCX.sizeMap(SPLITTER_SIZE)}px;`;
    }
  };

  handleMouseTouchMove = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    if (!this.dragging) return;
    e.stopPropagation();

    // Podminka zajisti preruseni resizu na dotykovach zarizenich, pokud uzivatel zacne vyvolavat long touch, ale rychle swipne pryc z oblasti okolo splitteru
    if (
      window.TouchEvent &&
      e.nativeEvent instanceof TouchEvent &&
      this.isSplitterVertical() &&
      !(
        e.nativeEvent.touches[0].clientX > e.currentTarget.children[1].getBoundingClientRect().left - ALLOWED_SWIPE_AREA &&
        (e as React.TouchEvent).touches[0].clientX < e.currentTarget.children[1].getBoundingClientRect().right + ALLOWED_SWIPE_AREA
      )
    ) {
      this.dragCancel(e.currentTarget.children[1] as HTMLDivElement, "width");
      return;
    } else if (
      window.TouchEvent &&
      e.nativeEvent instanceof TouchEvent &&
      this.isSplitterVertical() === false &&
      !(
        e.nativeEvent.touches[0].clientY > e.currentTarget.children[1].getBoundingClientRect().top - ALLOWED_SWIPE_AREA &&
        (e as React.TouchEvent).touches[0].clientY < e.currentTarget.children[1].getBoundingClientRect().bottom + ALLOWED_SWIPE_AREA
      )
    ) {
      this.dragCancel(e.currentTarget.children[1] as HTMLDivElement, "height");
      return;
    }

    let clientX: number;
    let clientY: number;

    if (e.nativeEvent instanceof MouseEvent) {
      clientX = (e as React.MouseEvent<HTMLDivElement>).clientX;
      clientY = (e as React.MouseEvent<HTMLDivElement>).clientY;
    } else {
      clientX = (e as React.TouchEvent<HTMLDivElement>).touches[0].clientX;
      clientY = (e as React.TouchEvent<HTMLDivElement>).touches[0].clientY;
    }

    if (this.isSplitterVertical()) {
      const xPos = clientX - this.rect.left;
      const ratio = xPos / e.currentTarget.offsetWidth;
      this.setState({ ratio: ratio });
    } else {
      const yPos = clientY - this.rect.top;
      const ratio = yPos / e.currentTarget.offsetHeight;
      this.setState({ ratio: ratio });
    }
  };

  handleMouseUpTouchEnd = (e: React.SyntheticEvent) => {
    if (this.timer) {
      if (this.isSplitterVertical()) {
        this.dragCancel(this.currentTarget.children[1] as HTMLDivElement, "width");
      } else {
        this.dragCancel(this.currentTarget.children[1] as HTMLDivElement, "height");
      }
    }
    this.dragging = false;
    this.control.setRatio(this.state.ratio);
  };

  handleMouseLeave = () => {
    this.dragging = false;
  };

  handleDoubleClick = () => {
    this.control.toggleCollapsed();
  };

  handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!this.isSplitterVertical()) return;
    if (window.innerWidth < Context.DeviceInfo.ResponsiveBreakpoints[0]) {
      this.setState({ ratio: this.state.ratio === 0 ? 1 : 0 });
    }
  };

  componentDidUpdate() {
    if (window.innerWidth < Context.DeviceInfo.ResponsiveBreakpoints[0] && this.state.data.Collapsed) {
      this.control.toggleCollapsed();
    }
  }

  setStyle = (first: boolean) => {
    let style: React.CSSProperties = {};

    if (this.state.data.Collapsed) {
      if (
        (first &&
          (this.control.Ncl.FrgtData.Orientation === SplitterPanelOrientation.spoRight ||
            this.control.Ncl.FrgtData.Orientation === SplitterPanelOrientation.spoBottom)) ||
        (!first &&
          (this.control.Ncl.FrgtData.Orientation === SplitterPanelOrientation.spoLeft ||
            this.control.Ncl.FrgtData.Orientation === SplitterPanelOrientation.spoTop))
      ) {
        style = { flexGrow: 1, flexDirection: "column" };
      } else {
        style = { display: "none" };
      }
    } else {
      style = { flexGrow: 1 };
      if (this.isSplitterVertical()) {
        if (first) {
          if (this.control.Children.get(0) === undefined) {
            style = { ...style, flexGrow: 0 };
          } else {
            style = { ...style, width: this.state.ratio * 100 + "%" };
          }

          if (window.innerWidth < Context.DeviceInfo.ResponsiveBreakpoints[0]) {
            style = { ...style, transition: "width 0.5s ease-in-out 0s", padding: `${this.control.VCX.sizeMap(3)}px 0px` };
          }
        } else {
          if (this.control.Children.get(1) === undefined) {
            style = { ...style, flexGrow: 0 };
          } else {
            style = { ...style, width: (1 - this.state.ratio) * 100 + "%" };
          }

          if (window.innerWidth < Context.DeviceInfo.ResponsiveBreakpoints[0]) {
            style = { ...style, transition: "width 0.5s ease-in-out 0s", padding: `${this.control.VCX.sizeMap(3)}px 0px` };
          }
        }
      } else {
        if (first) {
          if (this.control.Children.get(0) === undefined) {
            style = { ...style, flexGrow: 0 };
          } else {
            style = { ...style, height: this.state.ratio * 100 + "%", minHeight: this.control.Children.get(0).ComputedMinHeightWithMargin };
          }
        } else {
          if (this.control.Children.get(1) === undefined) {
            style = { ...style, flexGrow: 0 };
          } else {
            style = { ...style, height: (1 - this.state.ratio) * 100 + "%", minHeight: this.control.Children.get(1).ComputedMinHeightWithMargin };
          }
        }
      }
      style = { flexDirection: "column", overflow: "auto", ...style };
    }

    return style;
  };

  render() {
    return (
      <div
        style={StyleHelper(this.control, {
          position: "relative",
          flexDirection: this.isSplitterVertical() ? "row" : "column",
          WebkitUserSelect: "none",
          ...this.props.style,
        })}
        onTouchStartCapture={this.handleTouchStart}
        onTouchMoveCapture={this.handleMouseTouchMove}
        onTouchEnd={this.handleMouseUpTouchEnd}
        onMouseMove={this.handleMouseTouchMove}
        onMouseUp={this.handleMouseUpTouchEnd}
        onMouseLeave={this.handleMouseLeave}
        onContextMenuCapture={this.handleContextMenu}
      >
        <div style={this.setStyle(true)} className={this.props.renderer.renderRule(marginRule, { vcx: this.control.VCX, mX: 1, mY: 1 })}>
          {GenerateControl(this.control.Children.get(0))}
        </div>
        <div
          className={this.props.renderer.renderRule(
            window.innerWidth < Context.DeviceInfo.ResponsiveBreakpoints[0] && this.isSplitterVertical() ? mobileSplitter : splitterRule,
            { vcx: this.control.VCX, isVertical: this.isSplitterVertical(), ratio: this.state.ratio }
          )}
          onMouseDown={this.handleMouseDown}
          onDoubleClick={this.handleDoubleClick}
          onClick={this.handleClick}
        >
          {this.isSplitterVertical() && window.innerWidth < Context.DeviceInfo.ResponsiveBreakpoints[0] ? (
            <K2Img vcx={this.control.VCX} glyphId={this.state.ratio === 0 ? "wui*triangleright" : "wui*triangleleft"} height={15} strokeColor="white" />
          ) : null}
        </div>
        <div style={this.setStyle(false)} className={this.props.renderer.renderRule(marginRule, { vcx: this.control.VCX, mX: 1, mY: 1 })}>
          {GenerateControl(this.control.Children.get(1))}
        </div>
      </div>
    );
  }
}

export const K2SplitterPanel = withContext(_SplitterPanel);
