import * as React from "react";

import {
  CSUFNclListViewGroupMetadata,
  CSUFNclListViewItemMetadata,
  UpdateControl,
  UpdateListView,
  cJSonDefaultAcceptExecute,
} from "../common/communication.base";
import { NclListView } from "../common/components.ncl";
import { AcquireControl, K2ComponentState, withContext, WithContextPlacementProps, StyleHelper } from "./k2hoc";
import { K2Img } from "./K2Image";
import { K2RuleWithVCXProps } from "./k2StyleRenderer";
import { K2TruncateText } from "./K2TruncateText";
import ReactResizeDetector from "react-resize-detector";

const undefinedRule = (props: K2RuleWithVCXProps) => ({
  border: "1px solid " + props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorBck1),
  color: props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorBck1),
});

type LWProps = K2RuleWithVCXProps & {
  isFocusedItem: boolean;
};

interface MainListViewProps extends K2RuleWithVCXProps {
  FrgtDataSizeAsHeight: number;
}

const mainListViewRule = (props: MainListViewProps) => ({
  overflow: "auto" as "auto",
  minHeight: props.FrgtDataSizeAsHeight + "px",
});

interface allGroupsRuleProps extends K2RuleWithVCXProps {
  RowCountAsMaxHeight: number;
}

const allGroupsRule = (props: allGroupsRuleProps) => ({
  flexGrow: 0,
  flexShrink: 1,
  flexBasis: "auto" as "auto",
  flexDirection: "column" as "column",
  overflow: "auto" as "auto",
  maxHeight: props.RowCountAsMaxHeight + "px",
  width: "inherit",
});

const singleGroupRule = (props: K2RuleWithVCXProps) => ({
  marginTop: Math.floor(props.vcx.LabelControl.getHeight(1) / 2),
  flexGrow: 0,
  flexShrink: 0,
  flexDirection: "column" as "column",
  padding: "0 5px 0 5px",
});

const singleGroupItemsContainerRule = (props: K2RuleWithVCXProps) => ({
  flexGrow: 0,
  flexShrink: 0,
  flexBasis: "auto" as "auto",
  flexDirection: "row" as "row",
  flexWrap: "wrap" as "wrap",
  marginTop: Math.floor(props.vcx.LabelControl.getHeight(1) / 2),
});

const singleGroupItemLabelRule = (props: K2RuleWithVCXProps) => ({
  whiteSpace: "normal" as "normal",
  display: "inline-block" as "inline-block",
});

const singleGroupLabelContainerRule = (props: K2RuleWithVCXProps) => ({
  flexDirection: "row" as "row",
  flexGrow: 1,
  flexShrink: 0,
});

const singleGroupLabelRule = (props: K2RuleWithVCXProps) => ({
  marginLeft: Math.floor(props.vcx.LabelControl.getHeight(1) / 2),
  marginRight: Math.floor(props.vcx.LabelControl.getHeight(1) / 2),
  minWidth: "fit-content",
});

const itemRule = (props: LWProps) => ({
  flexDirection: "column" as "column",
  flexGrow: 0,
  flexShrink: 0,
  flexBasis: "auto" as "auto",
  width: props.vcx.LabelControl.getHeight(1) * 5,
  textAlign: "center" as "center",
  overflow: "visible" as "visible",
  whiteSpace: "pre-wrap" as "pre-wrap",
  backgroundColor: props.isFocusedItem ? props.vcx.getColor(props.vcx.Data.ColorMap.ContentChangeDecorateColorFrg) : "none",
  ":hover": { backgroundColor: props.vcx.getColor(props.vcx.Data.ColorMap.AccentBaseColorBck) },
});

interface ListViewState extends K2ComponentState<UpdateListView> {
  Height: number;
  Width: number;
}

export enum MoveDirection {
  mdLeft,
  mdTop,
  mdRight,
  mdBottom,
}

class _K2ListView extends React.PureComponent<WithContextPlacementProps, ListViewState> {
  static displayName = `K2ListView`;
  private control: NclListView;
  element: HTMLElement;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return true;
    }) as NclListView;
    this.state = { data: this.control.init(this) as UpdateListView, vcxVersion: -1, Height: 0, Width: 0 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateListView>) => {
      return { data: state as UpdateListView };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    let itemsInGroup: Array<JSX.Element> = null;
    let AllGroups: Array<JSX.Element> = null;
    let groups: Array<CSUFNclListViewGroupMetadata> = this.state.data.get("ListViewGroups").toJS();

    AllGroups = groups.map((item: CSUFNclListViewGroupMetadata, index) => {
      itemsInGroup = item.ListViewItems.map((elementI: CSUFNclListViewItemMetadata, index) => {
        let itemPosition: number = elementI.Position;
        let LisFocusedItem: boolean = itemPosition == this.state.data.Position;

        return (
          <div
            className={this.props.renderer.renderRule(itemRule, { vcx: this.control.VCX, isFocusedItem: LisFocusedItem })}
            onClick={() => {
              this.handleClick(itemPosition);
            }}
            onDoubleClick={() => {
              this.handleDblClick(itemPosition);
            }}
            key={"listElementGroupCaption_" + index}
          >
            <K2Img glyphId={elementI.GlyphId} height={32} width={32} vcx={this.control.VCX} />
            <div
              className={
                this.props.renderer.renderFontRule(this.control.VCX.LabelControl.Font, this.control.VCX) +
                " " +
                this.props.renderer.renderRule(singleGroupItemLabelRule, { vcx: this.control.VCX })
              }
            >
              {elementI.Caption}
            </div>
          </div>
        );
      });

      return (
        <div className={this.props.renderer.renderRule(singleGroupRule, { vcx: this.control.VCX })} key={"listElementSingleGroup_" + index}>
          <div className={this.props.renderer.renderRule(singleGroupLabelContainerRule, { vcx: this.control.VCX })}>
            <K2TruncateText
              className={
                this.props.renderer.renderFontRule(this.control.VCX.LabelControl.Font, this.control.VCX) +
                " " +
                this.props.renderer.renderRule(singleGroupLabelRule, { vcx: this.control.VCX })
              }
            >
              {item.Caption}
            </K2TruncateText>
            <div
              style={{ backgroundColor: this.control.VCX.getColor(this.control.VCX.Data.ColorMap.ContentFrame3), height: "1px", width: "98%", margin: "auto" }}
            ></div>
          </div>
          <div className={this.props.renderer.renderRule(singleGroupItemsContainerRule, { vcx: this.control.VCX })}>{itemsInGroup}</div>
        </div>
      );
    });

    return (
      <div
        id={this.control.Ncl.Name}
        tabIndex={1}
        ref={this.refCallBack}
        onKeyDown={this.handleKeyDown}
        onFocus={this.handleFocus}
        style={StyleHelper(this.control, this.props.style)}
        className={this.props.renderer.renderRule(mainListViewRule, {
          vcx: this.control.VCX,
          FrgtDataSizeAsHeight: this.control.Ncl.FrgtData.Size * this.internalIconHeight(),
        })}
      >
        <ReactResizeDetector handleHeight handleWidth onResize={this.onResize} />
        <div
          className={this.props.renderer.renderRule(allGroupsRule, {
            vcx: this.control.VCX,
            RowCountAsMaxHeight: this.control.maxRowCount * this.internalIconHeight(),
          })}
        >
          {AllGroups}
        </div>
      </div>
    );
  }

  setAsActive(isActive: boolean) {
    if (this.element && isActive) {
      this.element.focus();
    }
  }

  private refCallBack = (element: HTMLElement) => {
    if (element) {
      this.element = element;
    }
  };

  private handleDblClick(itemPosition: number) {
    this.control.executeSetPosition(itemPosition);
    this.control.executeShortcut([itemPosition]);
  }

  private handleClick(itemPosition: number) {
    this.control.executeSetPosition(itemPosition);
  }

  private internalIconHeight(): number {
    return this.control.VCX.InputControl.getInputHeight(1, true, false) * 4;
  }

  private onResize = (width: number, height: number) => {
    if (height > 0 && height != this.state.Height) {
      let maxRowCount: number = height / this.internalIconHeight();
      //(this.checkOverflow(this.tableContainerRef))&&maxRowCount--;
      this.control.setMaxRowCount(maxRowCount);
      this.setState({ Height: height });
    }
    if (width > 0 && width != this.state.Width) {
      this.setState({ Width: width });
    }
  };

  private internalMoveLeftOrRight(actualPosition: number, direction: MoveDirection): number {
    let lresult: CSUFNclListViewItemMetadata = null;
    let listViewItem: CSUFNclListViewItemMetadata = null;
    let groups: Array<CSUFNclListViewGroupMetadata> = this.state.data.get("ListViewGroups").toJS();

    for (var i = 0; i < groups.length; i++) {
      let item: CSUFNclListViewGroupMetadata = groups[i];
      for (var index = 0; index < item.ListViewItems.length; index++) {
        let elementI = item.ListViewItems[index];
        if (actualPosition == elementI.Position) {
          if (direction == MoveDirection.mdLeft) {
            if (index == 0) {
              return elementI.Position;
            } else {
              return item.ListViewItems[index - 1].Position;
            }
          }
          if (direction == MoveDirection.mdRight) {
            if (item.ListViewItems.length > index) {
              return item.ListViewItems[index + 1].Position;
            } else {
              return elementI.Position;
            }
          }
        }
      }
    }
  }

  private internalMoveTopOrBottom(actualPosition: number, direction: MoveDirection): number {
    let lresult: CSUFNclListViewItemMetadata = null;
    let listViewItem: CSUFNclListViewItemMetadata = null;
    let IsUpperGroupExists, IsBottomGroupExists: Boolean;
    let groups: Array<CSUFNclListViewGroupMetadata> = this.state.data.get("ListViewGroups").toJS();

    for (var i = 0; i < groups.length; i++) {
      let item: CSUFNclListViewGroupMetadata = groups[i];
      IsUpperGroupExists = i > 0;
      IsBottomGroupExists = i < groups.length;
      for (var index = 0; index < item.ListViewItems.length; index++) {
        let elementI = item.ListViewItems[index];
        if (actualPosition == elementI.Position) {
          if (direction == MoveDirection.mdTop) {
            if (index == 0) {
              if (IsUpperGroupExists) {
                return groups[i - 1].ListViewItems[0].Position;
              } else {
                return elementI.Position;
              }
            } else {
              let newindex: number = Math.max(index - this.internalNumberOfIconsPerRow() + 1, 0);
              return groups[i].ListViewItems[newindex].Position;
            }
          }

          if (direction == MoveDirection.mdBottom) {
            if (index == groups[i].ListViewItems.length - 1) {
              if (IsBottomGroupExists) {
                return groups[i + 1].ListViewItems[0].Position;
              } else {
                return elementI.Position;
              }
            } else {
              let newindex: number = Math.min(index + this.internalNumberOfIconsPerRow() + 1, groups[i].ListViewItems.length - 1);
              return groups[i].ListViewItems[newindex].Position;
            }
          }
        }
      }
    }
  }

  private internalNumberOfIconsPerRow(): number {
    return Math.floor(this.state.Width / this.internalIconHeight());
  }

  private getNextPositionForDirection(FromPosition: number, direction: MoveDirection): number {
    switch (direction) {
      case MoveDirection.mdBottom:
        return this.internalMoveTopOrBottom(FromPosition, MoveDirection.mdBottom);
      case MoveDirection.mdLeft:
        return this.internalMoveLeftOrRight(FromPosition, MoveDirection.mdLeft);
      case MoveDirection.mdRight:
        return this.internalMoveLeftOrRight(FromPosition, MoveDirection.mdRight);
      case MoveDirection.mdTop:
        return this.internalMoveTopOrBottom(FromPosition, MoveDirection.mdTop);
    }
    return FromPosition;
  }

  private handleFocus = (e: React.FocusEvent<HTMLDivElement>) => {
    this.control.setActiveControlRequested();
    e.stopPropagation();
    console.log("handleFocus");
  };

  private handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    console.log("handleKeyDown");
    if (e.key === "ArrowUp") {
      let NextPosition: number = this.getNextPositionForDirection(this.state.data.Position, MoveDirection.mdTop);
      this.control.executeSetPosition(NextPosition);
      e.stopPropagation();
      return false;
    }

    if (e.key === "ArrowDown") {
      let NextPosition: number = this.getNextPositionForDirection(this.state.data.Position, MoveDirection.mdBottom);
      this.control.executeSetPosition(NextPosition);
      e.stopPropagation();
      return false;
    }

    if (e.key === "ArrowLeft") {
      let NextPosition: number = this.getNextPositionForDirection(this.state.data.Position, MoveDirection.mdLeft);
      this.control.executeSetPosition(NextPosition);
      e.stopPropagation();
      return false;
    }

    if (e.key === "ArrowRight") {
      let NextPosition: number = this.getNextPositionForDirection(this.state.data.Position, MoveDirection.mdRight);
      this.control.executeSetPosition(NextPosition);
      e.stopPropagation();
      return false;
    }
    if (e.key === "Enter") {
      this.control.executeShortcut([this.state.data.Position]);
      e.stopPropagation();
      return false;
    }
  };
}

export const K2ListView = withContext(_K2ListView);
