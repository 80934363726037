import * as React from "react";
import { ApplicationBase } from "../app";
import * as ReactDOM from "react-dom";
import { K2StyleRenderer, Renderer } from "./k2StyleRenderer";
import { VisualContext } from "../common/visualContext";
import LoginView from "./views/LoginView";
import { NclDockControl } from "../common/components.ncl";
import { K2PageHolder } from "./K2TabControl";
import {
  TClientFileActionType,
  CSClientData,
  cClientUrlType,
  CSClientUrl,
  cClienSoundType,
  cClientFileType,
  CSClientSound,
  CSClientFile,
} from "../common/communication.base";
import { MediaManager, MediaData } from "../common/mediaManager";
import { Context } from "../appcontext";
import { ViewRealizerManager } from "../viewrealizer";
import K2ErrorBoundary from "./K2ErrorBoundary";
import { Log } from "../common/common";

export interface VCXContext {
  renderer: K2StyleRenderer;
  vcx: VisualContext;
}

export const VCXContext = React.createContext({} as VCXContext);

export class K2App extends ApplicationBase {
  static readonly mainContentId = "maincontent";
  private dockControl: NclDockControl;
  private player: AudioPlayer;

  protected internalRender(): Promise<void> {
    super.internalRender();
    return new Promise((resolve, reject) => {
      let container = document.getElementById(K2App.mainContentId);
      if (container) {
        this.player = new AudioPlayer();
        this.dockControl = new NclDockControl(this.appViewRealizer.getDock(), null, null, null, this.appViewRealizer.getRealizerUID());
        ReactDOM.render(
          <VCXContext.Provider value={{ renderer: Renderer(), vcx: this.appViewRealizer.VCX }}>
            <K2ErrorBoundary>
              <K2PageHolder controlUID={this.dockControl.MetaData.ControlUID} vrUID={this.appViewRealizer.getRealizerUID()} />
            </K2ErrorBoundary>
          </VCXContext.Provider>,
          container,
          async () => {
            await this.dockControl.dockViewRealizer(this.appViewRealizer.getRealizerUID());
            resolve();
          }
        );
      } else {
        reject();
      }
    });
  }

  public getMainDockControl(): NclDockControl {
    return this.dockControl;
  }

  protected internalClear() {
    this.player = null;
    this.dockControl = null;
    ReactDOM.render(null, document.getElementById(K2App.mainContentId));
  }

  protected processClientData(list: Array<CSClientData>) {
    if (list && list.length > 0) {
      let data: CSClientData;
      for (let i = 0; i < list.length; i++) {
        data = list[i];
        if (data.__type === cClientUrlType) {
          this.openUrl(data as CSClientUrl);
        }
        if (data.__type === cClienSoundType) {
          this.play(data as CSClientSound);
        }
        if (data.__type === cClientFileType) {
          this.processFileData(data as CSClientFile);
        }
      }
    }
  }

  private processFileData(data: CSClientFile) {
    if (data.ErrorMessage) {
      if (data.Action != TClientFileActionType.catUnknown) {
        alert(data.ErrorMessage);
      } else {
        MediaManager.setErrorMessge(data.FileName, data.ErrorMessage, data.UseCache);
      }
      return;
    }
    if (!data.Url) alert(`Url for file ${data.FileName} is empty.`);

    if (data.Action === TClientFileActionType.catUnknown) {
      MediaManager.download(data.Url, data.FileName); // unknown action only downloaded data from server and hold it for future used.
    } else {
      if (data.UseCache) {
        MediaManager.get(data.Url, data.FileName).then((result) => {
          this.processFile(result, data.Action);
        });
      } else {
        this.processFile(data.Url, data.Action);
      }
    }
  }

  private processFile(data: MediaData | string, type: TClientFileActionType) {
    switch (type) {
      case TClientFileActionType.catPrint:
      case TClientFileActionType.catOpen:
        if (typeof data === "string") {
          window.open(data.toString(), "__blank");
        } else if (data instanceof MediaData) {
          var win = window.open();
          win.document.write(
            '<iframe src="' +
              URL.createObjectURL(data.blob) +
              '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
          );
        } else {
          throw Error("Unknown data type for open");
        }
        break;
      case TClientFileActionType.catDownload:
        alert("Not implemented.");
        break;
      default:
        break;
    }
  }

  private play(data: CSClientSound) {
    if (!data.Url) {
      Log.warn(`Can't play file, url for file ${data.FileName} is empty.`);
      return;
    }
    if (data.ErrorMessage) {
      if (data.UseCache) {
        MediaManager.setErrorMessge(data.FileName, data.ErrorMessage, data.UseCache);
      } else {
        alert(data.ErrorMessage);
      }
    }

    if (data.UseCache) {
      MediaManager.get(data.Url, data.FileName).then((result) => {
        this.player.play(result);
      });
    } else {
      this.player.play(data.Url);
    }
  }

  private openUrl(data: CSClientUrl) {
    let url = data.Url;
    if (url.match(/^\w*:/i)) {
      if (url.match(/^intent:/i) && !Context.DeviceInfo.IsAndroidDevice) {
        alert('"Intent" can be executed only on Android device.');
        return;
      }
      window.open(url, "__blank"); // For open intent or others activity protocol must use __blank
      return;
    } else if (!url.match(/^https?:\/\//i)) {
      url = "http://" + url;
    }

    window.open(url, "");
  }

  protected loginForm(): void {
    ReactDOM.render(
      <LoginView
        headerImg="img/login_header_logo.jpg"
        footerImg="img/logo_k2.svg"
        ver="K2 luna"
        ToSLink="https://www.k2.cz/"
        privacyPolicyLink="#"
        licenseHolder="K2 software s.r.o."
      />,
      document.getElementById(K2App.mainContentId)
    );
  }

  protected reconnectForm(reconnectFce: () => void): void {
    ReactDOM.render(
      <LoginView
        reconnect={{ reconnectFce: reconnectFce }}
        headerImg="img/login_header_logo.jpg"
        footerImg="img/logo_k2.svg"
        ver="K2 luna"
        ToSLink="https://www.k2.cz/"
        privacyPolicyLink="#"
        licenseHolder="K2 software s.r.o."
      />,
      document.getElementById(K2App.mainContentId)
    );
  }
}
class AudioPlayer {
  private element: HTMLAudioElement;
  private queue: Array<string | MediaData>;
  private isPlaying: boolean = false;
  private current: string | MediaData;

  public constructor() {
    this.element = new Audio();
    this.element.onended = this.handleEnded;
    this.queue = [];
  }

  private handleEnded = (e: Event) => {
    this.isPlaying = false;
    if (this.queue.length > 0) {
      this.internalPlay();
    }
  };

  play(data: string | MediaData) {
    this.queue.push(data);
    this.internalPlay();
  }

  internalPlay() {
    if (!this.isPlaying) {
      this.isPlaying = true;
      this.current = this.queue.pop();
      if (this.current instanceof MediaData) {
        this.element.src = this.current.base64Data;
      } else {
        this.element.src = this.current.toString();
      }
      this.element.play();
    }
  }
}

export function setRequestedActiveControl(e: React.FocusEvent<any>, vrUID: string) {
  let vr = ViewRealizerManager.getViewRealizer(vrUID);
  if (vr) {
    if (e.relatedTarget && e.relatedTarget instanceof HTMLElement) {
      let id = e.relatedTarget.getAttribute("data-k2-test-id");
      if (!id || id === "") {
        id = e.relatedTarget.id;
      }
      let ctrl = vr.findControlByName(e.relatedTarget.id);
      if (ctrl) {
        vr.RequestActiveControlUID = ctrl.MetaData.ControlUID;
      }
    }
  }
}
