import { CSNclControlMetadata, VCXFontNoColorMetaData, FontStyle, Orientation } from "./communication.base";
import { Context } from "../appcontext";
import { error, isNumber } from "util";
import { UAParser } from "ua-parser-js";
import { string } from "prop-types";

export interface Size {
  height: number;
  width: number;
}

export interface ElementHtmlAttributes {
  tabIndex: number;
  readOnly?: boolean;
  disabled?: boolean;
}

interface ElementAttributes {
  TabStop: boolean;
  ReadOnly?: boolean;
  Enabled?: boolean;
}

const hideRule = "hide";

export function intToRGBA(num: number, alpha: number): string {
  let r = (num & 0xff).toString(10);
  let g = ((num >> 8) & 0xff).toString(10);
  let b = ((num >> 16) & 0xff).toString(10);

  return "rgba(" + r + "," + g + "," + b + "," + alpha / 100 + ")";
}

export function getAttributes(componenetState: ElementAttributes): ElementHtmlAttributes {
  // Naplní atributy ReadOnly, Disabled a TabIndex pro elementy, následně vloženo do komponenty pomocí spread values
  let result: ElementHtmlAttributes = {
    tabIndex: undefined,
    readOnly: undefined,
    disabled: undefined,
  };

  result.tabIndex = componenetState.TabStop ? 1 : -1;
  result.readOnly = componenetState.ReadOnly;
  if (typeof componenetState.Enabled != "undefined") {
    result.disabled = !componenetState.Enabled;
  }

  return result;
}

export function isKeyOrDigit(key: string): boolean {
  if (/[a-zA-Z0-9-_ ]/.test(key)) {
    return true;
  }
  return false;
}

export class Helper {
  public static clone<T extends CSNclControlMetadata>(source: T): T {
    let to: Object = {};
    for (var nextKey in source) {
      if (Object.prototype.hasOwnProperty.call(source, nextKey) && nextKey !== "Controls") {
        Object(to)[nextKey] = source[nextKey];
      }
    }

    return to as T;
  }

  public static clearContent(element: HTMLElement) {
    if (element == null) return;
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
  }

  public static show(element: HTMLElement) {
    if (element == null) return;
    if (!Helper.isShow(element)) {
      element.classList.remove(hideRule);
    }
  }

  public static hide(element: HTMLElement) {
    if (element == null) return;
    if (!element.classList.contains(hideRule)) {
      element.classList.add(hideRule);
    }
  }

  public static isShow(element: HTMLElement): boolean {
    return !element.classList.contains(hideRule);
  }

  public static createModalAnchor(parent: HTMLElement): HTMLElement {
    let anchor = document.createElement("div");
    anchor.classList.add("modal");
    Helper.hide(anchor);
    parent.appendChild(anchor);
    return anchor;
  }

  public static getPictureUrl(glyphId: string, requiredSize: number): string {
    if (glyphId == null || glyphId === "") return "";

    let url: string = location.href + "Picture/GetPicture?glyphId=" + glyphId;

    if (requiredSize != null && requiredSize > 0) url += "&requiredSize=" + requiredSize.toString();

    return url;
  }

  private static keyMap: Map<string, string> = new Map<string, string>([
    [" ", "Space"],
    ["Insert", "Ins"],
    ["Escape", "Esc"],
    ["ArrowDown", "Down"],
    ["ArrowUp", "Up"],
    ["ArrowLeft", "Left"],
    ["ArrowRight", "Right"],
    ["Delete", "Del"],
    ["+", "Num +"],
    ["-", "Num -"],
  ]);

  private static specHotKeys: number[] = [9, 13, 27, 37, 38, 39, 40, 45, 46, 107, 109, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123];

  private static ignoreHotKeys: string[] = ["Ctrl+V", "Ctrl+C", "Ctrl+X", "Ctrl+A"];

  private static ignoreNumAccelerator = "Alt+";

  private static convertKey(ev: KeyboardEvent): string {
    if (ev.code.startsWith("Digit")) return ev.code.replace("Digit", ""); //numeric keys

    if (Helper.keyMap.has(ev.key)) {
      return Helper.keyMap.get(ev.key);
    } else {
      return ev.key.toUpperCase();
    }
  }

  public static convertToTxt(ev: KeyboardEvent): string {
    let txt: string = "";
    let pref: string = "";
    let key = Helper.convertKey(ev);
    if (ev.keyCode >= 16 && ev.keyCode <= 18) return "";
    if (ev.ctrlKey && ev.keyCode != 17) pref += "Ctrl+";
    if (ev.altKey && ev.keyCode != 18) pref += "Alt+";

    if (ev.shiftKey && ev.keyCode != 16 && (pref || Helper.specHotKeys.indexOf(ev.keyCode) >= 0)) pref += "Shift+";

    if (pref || (!pref && Helper.specHotKeys.indexOf(ev.keyCode) >= 0)) {
      if (Helper.keyMap.has(key)) {
        txt = pref + Helper.keyMap.get(key);
      } else {
        txt = pref + key.toUpperCase();
      }
    }

    if (txt.startsWith(this.ignoreNumAccelerator) && ev.keyCode >= 96 && ev.keyCode <= 105) return "";

    if (Helper.ignoreHotKeys.indexOf(txt) < 0) {
      return txt;
    } else {
      return "";
    }
  }

  public static setHint(element: HTMLElement, hint: string) {
    if (hint != null && hint != "") {
      element.title = hint;
    }
  }

  public static async sendErrorMessage(stack: any, reactStack?: any) {
    // const resp = await fetch('');
    const error = {
      date: new Date(Date.now()),
      url: window.location.href,
      stack: stack,
      reactStack: reactStack,
    };
    console.log(error);
  }
}

export enum LogLevel {
  Error,
  Warn,
  Debug,
  Info,
}

export class Log {
  private static instance: Log;
  private level: LogLevel;

  private constructor(level: LogLevel) {
    this.level = level;
  }

  public static init(level: LogLevel) {
    if (Log.instance == null) {
      Log.instance = new Log(level);
    } else {
      //err
    }
  }

  public static info(log: string) {
    Log.write(LogLevel.Info, log);
  }

  public static debug(log: string) {
    Log.write(LogLevel.Debug, log);
  }

  public static warn(log: string) {
    Log.write(LogLevel.Warn, log);
  }

  public static error(log: string, exception: any) {
    Log.write(LogLevel.Error, log);
  }

  private static write(level: LogLevel, log: string) {
    if (Log.instance != null) {
      Log.instance.internalWrite(level, log);
    }
  }

  private internalWrite(level: LogLevel, log: string) {
    if (level > this.level) return;
    let msg: string;
    msg = LogLevel[level] + ":" + new Date().toLocaleString() + "-" + log;
    switch (level) {
      case LogLevel.Info:
        console.info(msg);
        break;
      case LogLevel.Error:
        console.error(msg);
        break;
      case LogLevel.Debug:
        console.debug(msg);
        break;
      case LogLevel.Warn:
        console.warn(msg);
        break;
      default:
    }
  }
}

export async function delay(milliseconds: number) {
  return new Promise<void>((resolve) => {
    setTimeout(resolve, milliseconds);
  });
}

var dummy: HTMLDivElement;

export interface Dimension {
  height: number;
  width: number;
}

/**
 * Funkce pro výpočet výšky textu se zadaným fontem
 * @param text Měřený text
 * @param font Použitý font
 */
export function computeTextDimension(text: string, font: VCXFontNoColorMetaData): Dimension {
  let body = document.getElementsByTagName("body")[0];
  if (!dummy) {
    dummy = document.createElement("div");
    dummy.style.position = "absolute";
    dummy.style.top = "0";
    dummy.style.left = "0";
    body.appendChild(dummy);
  } else {
    dummy.innerHTML = "";
  }

  let dummyText = document.createTextNode(text);
  dummy.appendChild(dummyText);
  if (font.FontStyle === FontStyle.fsBold) {
    dummy.style.fontWeight = "bold";
  }
  if (font.FontStyle === FontStyle.fsItalic) {
    dummy.style.fontStyle = "italic";
  }
  if (font.FontStyle === FontStyle.fsStrikeOut) {
    dummy.style.textDecoration = "line-through";
  }
  if (font.FontStyle === FontStyle.fsUnderline) {
    dummy.style.textDecoration = dummy.style.textDecoration + " underline";
  }

  dummy.style.fontSize = font.FontSize + "pt";
  dummy.style.fontFamily = font.FontName;

  dummy.style.display = "block";
  let result = { height: dummy.offsetHeight, width: dummy.offsetWidth };
  dummy.style.display = "none";

  return result;
}

const RegExPattern = "^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$";

export function isUrl(value: string): boolean {
  if (value) {
    let reg: RegExp = new RegExp(RegExPattern);

    return reg.exec(value) !== null;
  }
  return false;
}

export interface CancelablePromise<T> {
  promise: Promise<T>;
  cancel(): void;
}

export function makeCancelable<T>(promise: Promise<T>): CancelablePromise<T> {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    if (promise) {
      promise.then(
        (val) => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
        (error) => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error))
      );
    } else {
      reject(error);
    }
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
}

function calculateStreenWidth(): number {
  let div = document.createElement("div");
  div.style.width = "1in";
  div.style.height = "1in";
  div.style.position = "absolute";
  div.style.left = "-100%";
  div.style.top = "-100%";

  document.body.appendChild(div);

  let dpi_x: number = div.offsetWidth;
  let dpi_y: number = div.offsetWidth;

  document.body.removeChild(div);
  if (Context.DeviceInfo.IsIOS && Context.DeviceInfo.getOrientation() === "landscape") {
    return (window.screen.height / dpi_y) * 25.4;
  }
  return (window.screen.width / dpi_x) * 25.4;
}

export function isPDF(file: string): boolean {
  if (this.state.ObjectId) {
    let file = this.state.ObjectId.toUpperCase();
    if (file.endsWith(".PDF")) return true;
  }

  return false;
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

type DeviceType = "normal" | "mobile";

export class DeviceInfo {
  private uaParser: UAParser;
  private culture: string;
  private independentFormatMode: boolean;
  private styleOfModalWindowShow: DeviceType;
  private isPDFSupport: boolean;
  private className: string;
  private zoomFactor: number;
  private transformColumnsCount: Array<number>;
  private responsiveBreakpoint: number;
  private isAndroidDevice: boolean;
  private isTouchDevice: boolean;
  private isIOS: boolean;

  private get UAParser(): UAParser {
    if (!this.uaParser) {
      this.uaParser = new UAParser(window.navigator.userAgent);
    }

    return this.uaParser;
  }

  public get IsAndroidDevice(): boolean {
    if (this.isAndroidDevice === undefined) this.isAndroidDevice = this.UAParser.getOS().name.toLocaleUpperCase().indexOf("ANDROID") >= 0;

    return this.isAndroidDevice;
  }

  public get IsIOS(): boolean {
    if (!this.isIOS) {
      this.isIOS = this.UAParser.getOS().name.toLocaleUpperCase().indexOf("MAC OS") >= 0;
    }

    return this.isIOS;
  }

  public get IsTouchDevice(): boolean {
    if (!this.isTouchDevice) {
      this.isTouchDevice = (this.IsIOS || this.IsAndroidDevice) && navigator.maxTouchPoints > 0;
    }

    return this.isTouchDevice;
  }

  public get BrowserInfo(): string {
    return this.UAParser.getBrowser().name;
  }

  public get OSInfo(): string {
    return `${this.UAParser.getOS().name} ${this.UAParser.getOS().version}`;
  }

  public get ScreenWidth(): number {
    let screenWidth = calculateStreenWidth();

    let w = parseFloat(this.getQueryVariable("ScreenSize", "-1"));

    if (w > 0) {
      screenWidth = w * 10; // use width in mm
    }

    return screenWidth;
  }

  public get CurrentCulture() {
    if (!this.culture) {
      this.culture = this.getQueryVariable("lng", navigator.language);
    }
    return this.culture;
  }

  public get IndependentFormatMode(): boolean {
    if (this.independentFormatMode === undefined) {
      this.independentFormatMode = !this.detectWinPlatform();

      let qresult = this.getQueryVariable("IndependentFormatMode", "-1");
      if (qresult && qresult !== "-1") {
        if (qresult === "0") {
          this.independentFormatMode = false;
        }
        if (qresult === "1") {
          this.independentFormatMode = true;
        }
      }
    }
    return this.independentFormatMode;
  }

  public get StyleOfModalWindowShow(): DeviceType {
    if (this.detectWinPlatform()) {
      this.styleOfModalWindowShow = "normal";
    } else {
      this.styleOfModalWindowShow = "mobile";
    }

    let qresult = this.getQueryVariable("StyleOfModalWindowShow", this.styleOfModalWindowShow);
    if (qresult !== this.styleOfModalWindowShow) {
      switch (qresult.toUpperCase()) {
        case "MOBILE":
          this.styleOfModalWindowShow = "mobile";
          break;
        case "NORMAL":
          this.styleOfModalWindowShow = "normal";
          break;
        default:
      }
    }

    return this.styleOfModalWindowShow;
  }

  public get IsPDFSupport() {
    if (this.isPDFSupport === undefined) {
      let qresult = this.getQueryVariable("IsPdfSupport", "-1");
      if (qresult && qresult != "-1") {
        if (qresult === "1") {
          this.isPDFSupport = true;
        } else if (qresult === "0") {
          this.isPDFSupport = false;
        }
      } else {
        this.isPDFSupport = this.detectWinPlatform();
      }
    }

    return this.isPDFSupport;
  }

  public get StartClassName(): string {
    if (!this.className) {
      this.className = this.getQueryVariable("ClassName", undefined);
    }
    return this.className;
  }

  public get ZoomFactor(): number {
    if (!this.zoomFactor) {
      this.zoomFactor = this.getDefaultZoom();
      let zoom = Number.parseFloat(this.getQueryVariable("zoom", this.zoomFactor.toString()));
      if (zoom !== NaN) {
        this.zoomFactor = zoom;
      }
    }
    return this.zoomFactor;
  }

  public get TransformColumnsCount(): number {
    if (!this.transformColumnsCount) {
      this.transformColumnsCount = this.getDefaultTransformColumns();
      let trs = this.getQueryVariable("TransformColumnsCount", "");
      if (/\[\d(,\d)?\]/.test(trs)) {
        trs = trs.replace(/\[|\]/g, "");
        if (trs) {
          let values = trs.split(",");
          if (values && values.length > 0) {
            let val: number;
            values.map((item, index) => {
              val = Number.parseInt(item);
              if (val != NaN) {
                this.transformColumnsCount[index] = val;
              }
            });
          }
        }
      }
    }
    if (this.getOrientation() === "portrait") {
      return this.transformColumnsCount[0];
    } else {
      return this.transformColumnsCount[1];
    }
  }

  public get ResponsiveBreakpoints(): Array<number> {
    if (!this.responsiveBreakpoint) {
      this.responsiveBreakpoint = null;
      let value = Number.parseInt(this.getQueryVariable("ResponsiveBreakpoint", "980"));
      if (value != NaN) {
        this.responsiveBreakpoint = value;
      }
    }

    if (this.responsiveBreakpoint > 0) {
      return [this.responsiveBreakpoint];
    }

    return null;
  }

  private getDefaultTransformColumns(): number[] {
    if (this.detectWinPlatform() || Context.DeviceInfo.BrowserInfo === "Safari") {
      return [0, 0];
    }

    return [1, 4];
  }

  private getDefaultZoom(): number {
    if (this.detectWinPlatform()) {
      return 1;
    }

    return 1.25;
  }

  private detectWinPlatform(): boolean {
    let result = navigator.platform.match(/Win32/g);
    let value = result && result.length > 0;
    if (value) {
      return true;
    }
    return false;
  }

  private getQueryVariable(paramName: string, defaultValue: string): string {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0].toLowerCase() == paramName.toLowerCase()) {
        return pair[1];
      }
    }
    return defaultValue;
  }

  public getOrientation(): "portrait" | "landscape" {
    if (window.innerHeight > window.innerWidth) {
      return "portrait";
    } else {
      return "landscape";
    }
  }
}

export function parseData(data: string): any {
  let str: string = data;
  str = str.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t");
  return JSON.parse(str);
}
