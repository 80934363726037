import * as React from "react";
import { K2StyleRenderer, K2RuleWithVCXProps } from "./k2StyleRenderer";
import { NclBaseTabControl } from "../common/components.ncl";
import ResizeObserver from "resize-observer-polyfill";
import { Orientation } from "../common/communication.base";
import { K2Img } from "./K2Image";
import { Context } from "../appcontext";

type ScrollStyles = K2RuleWithVCXProps & {
  orientation: number;
};

const styles = {
  button: (props: ScrollStyles) => ({
    flex: "0 0 auto",
    width: props.orientation === Orientation.foHorizontal ? `${props.vcx.sizeMap(30)}px` : "auto",
    height: props.orientation === Orientation.foHorizontal ? "auto" : `${props.vcx.sizeMap(30)}px`,
    backgroundColor: props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorBck1),
    color: "white",
    border: "none",
  }),
  content: (props: ScrollStyles) => ({
    flex: "1 1 auto",
    scrollbarWidth: "none" as "none",
    "::-webkit-scrollbar": {
      display: "none",
    },
    flexDirection: props.orientation === Orientation.foHorizontal ? ("row" as "row") : ("column" as "column"),
    small: {
      flexDirection: "row",
    },
  }),
};

interface ScrollProps {
  orientation: number;
  renderer: K2StyleRenderer;
  control: any;
  tab: any;
}

interface ScrollState {
  showButtons: boolean;
  firstButtonFill: string;
  secondButtonFill: string;
}

export class K2Scroll extends React.PureComponent<ScrollProps, ScrollState> {
  private content = React.createRef<HTMLDivElement>();
  private resizeObserver: ResizeObserver;
  private prevPages: number = 0;

  constructor(props: ScrollProps) {
    super(props);
    this.state = { showButtons: false, firstButtonFill: "rgb(128, 128, 128)", secondButtonFill: "white" };
  }

  componentDidMount() {
    this.resizeObserver = new ResizeObserver((entries, observer) => this.toggleScrollButtons(entries, observer));
    this.resizeObserver.observe(this.content.current);
  }

  componentDidUpdate(prevProps: ScrollProps, prevState: ScrollState) {
    if (this.prevPages !== this.props.control.Pages.size) {
      this.prevPages = this.props.control.Pages.size;
      this.setState({ showButtons: false });
    }
  }

  componentWillUnmount() {
    this.resizeObserver.unobserve(this.content.current);
  }

  handleScroll = (e: React.UIEvent) => {
    const target = e.target as HTMLDivElement;

    if (
      (this.props.orientation === Orientation.foVertical && target.scrollTop === 0) ||
      (this.props.orientation === Orientation.foHorizontal && target.scrollLeft === 0)
    ) {
      this.setState({ firstButtonFill: "rgb(128, 128, 128)", secondButtonFill: "white" });
    } else if (
      (this.props.orientation === Orientation.foVertical && target.scrollTop === target.scrollHeight - target.offsetHeight) ||
      (this.props.orientation === Orientation.foHorizontal && target.scrollLeft === target.scrollWidth - target.offsetWidth)
    ) {
      this.setState({ firstButtonFill: "white", secondButtonFill: "rgb(128, 128, 128)" });
    } else {
      this.setState({ firstButtonFill: "white", secondButtonFill: "white" });
    }
  };

  toggleScrollButtons = (entries: ResizeObserverEntry[], observer: globalThis.ResizeObserver) => {
    if (this.props.control instanceof NclBaseTabControl && window.innerWidth < Context.DeviceInfo.ResponsiveBreakpoints[0]) {
      // v mobilnim zobrazeni funguje TabControl trochu jinak, takze se tady scroll nepouziva
      this.content.current.style.overflow = "visible";
      this.setState({ showButtons: false });
    } else {
      this.content.current.style.overflow = "auto";

      if (entries[0].target.clientWidth < entries[0].target.scrollWidth || entries[0].target.clientHeight < entries[0].target.scrollHeight) {
        entries[0].target.scrollLeft = entries[0].target.scrollWidth;
        this.setState({ showButtons: true });
      } else {
        this.setState({ showButtons: false });
      }
    }
  };

  render() {
    let firstButton: JSX.Element;
    let secondButton: JSX.Element;
    let clsName: string = this.props.renderer.renderRule(styles.button, { vcx: this.props.control.VCX, orientation: this.props.orientation });

    if (this.props.orientation === Orientation.foHorizontal) {
      firstButton = (
        <button
          className={clsName}
          style={{ display: "block", borderRight: "1px solid white" }}
          onClick={() => (this.content.current.scrollLeft -= this.props.control.VCX.sizeMap(100))}
        >
          <K2Img strokeColor={this.state.firstButtonFill} glyphId="wui*triangleleft" vcx={this.props.control.VCX} height={this.props.control.VCX.sizeMap(20)} />
        </button>
      );
      secondButton = (
        <button
          className={clsName}
          style={{ display: "block", borderLeft: "1px solid white" }}
          onClick={() => (this.content.current.scrollLeft += this.props.control.VCX.sizeMap(100))}
        >
          <K2Img
            strokeColor={this.state.secondButtonFill}
            glyphId="wui*triangleright"
            vcx={this.props.control.VCX}
            height={this.props.control.VCX.sizeMap(20)}
          />
        </button>
      );
    } else {
      firstButton = (
        <button
          className={clsName}
          style={{ display: "block", borderBottom: "1px solid white" }}
          onClick={() => (this.content.current.scrollTop -= this.props.control.VCX.sizeMap(100))}
        >
          <K2Img strokeColor={this.state.firstButtonFill} glyphId="wui*triangleup" vcx={this.props.control.VCX} height={this.props.control.VCX.sizeMap(20)} />
        </button>
      );
      secondButton = (
        <button
          className={clsName}
          style={{ display: "block", borderTop: "1px solid white" }}
          onClick={() => (this.content.current.scrollTop += this.props.control.VCX.sizeMap(100))}
        >
          <K2Img
            strokeColor={this.state.secondButtonFill}
            glyphId="wui*triangledown"
            vcx={this.props.control.VCX}
            height={this.props.control.VCX.sizeMap(20)}
          />
        </button>
      );
    }

    return (
      <div style={{ flex: "1 1 auto", flexDirection: this.props.orientation === Orientation.foHorizontal ? "row" : "column" }} onScroll={this.handleScroll}>
        {this.state.showButtons && firstButton}
        <div
          className={this.props.renderer.renderRule(styles.content, { vcx: this.props.control.VCX, orientation: this.props.orientation })}
          ref={this.content}
        >
          {this.props.children}
        </div>
        {this.state.showButtons && secondButton}
      </div>
    );
  }
}
