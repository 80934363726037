import {
  CSViewRealizerStructure,
  UpdateBase,
  MultiMessage,
  DataRequest,
  UserInfo,
  TNclInputType,
  DEFAULT_LANG_IMG,
  LoginInfo,
  OutData,
  CSClientData,
  clNone,
} from "./common/communication.base";
import { Helper, Log, delay, parseData } from "./common/common";
import { Context } from "./appcontext";
import { ViewRealizer, ViewRealizerManager, RealizerOperations } from "./viewrealizer";
import { NclMessageType, NclMessage, NclMessageHelper, ReceiveData, Connection, PostbackMessage } from "./common/communication";
import { NclBaseTabControl, NclDockControl, NclTabControl } from "./common/components.ncl";
import { ModalPosition } from "./components/K2Modal";
import { CursorProperty } from "csstype";

/**
 * Základní třída celé aplikace.
 * Navazuje spojení se serverem pomoci websocket a zpracovává zprávy přijimané ze serveru.
 * Obsahuje seznam aktivních realieru kterým jsou zprávy předávané ke zpracování.
 */
export abstract class ApplicationBase {
  private isReconnecting: boolean;
  private reconnectUID: string;
  private userInfo: UserInfo;
  private connection: Connection;
  private _appViewRealizer: ViewRealizer;
  private _screenSize: number;
  private _lastScreenSize: number;
  private ignore: number = 0; //promenna pro moznost zablokovani posilani pozadavku na server
  private menuPosition: ModalPosition;
  private modalRealizerUID: string;
  private busyIndicator: BusyIndicator = new BusyIndicator();
  private fullScreenMessage = new FullScreenMessage();
  private timer: any;
  private messagesToSend: Array<NclMessage>;
  private activeVRUID: string;
  private mainTabControlUID: string;
  private companyColor: number;

  constructor() {
    this._appViewRealizer = null;
    this.isReconnecting = false;
    this.messagesToSend = new Array<NclMessage>();
  }

  protected internalRender(): Promise<void> {
    window.addEventListener("keydown", this.keyDown);
    window.addEventListener("orientationchange", this.orientationchange);
    window.addEventListener("mousemove", this.mousemove);
    window.addEventListener("error", this.handleError);
    window.addEventListener("unhandledrejection", this.handleRejection);
    return null;
  }

  private startNoopTimeout(timeout: number) {
    if (timeout > 0) {
      let interval = timeout > 2 * 60 ? 60 : (timeout * 1) / 2;
      let __this = this;
      this.stopNoopTimeout();
      this.timer = setInterval(() => {
        if (this.isReconnecting) return;
        if ((new Date().getTime() - __this.getConnection().LastActivity.getTime()) / 1000 >= timeout) {
          __this.internalSendMessage(NclMessageHelper.CreateNoopMsg());
        }
      }, interval * 1000);
    }
  }

  private stopNoopTimeout() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  getCompanyColor(): number {
    return this.companyColor;
  }

  setCompanyColor(value: number) {
    if (value === clNone) this.companyColor = 0;
    else this.companyColor = value;
  }

  public getMainDockControl(): NclDockControl {
    return null;
  }

  public getReconnectUID(): string {
    return this.reconnectUID;
  }

  public getActiveRealizer(): ViewRealizer {
    let result: ViewRealizer;
    if (this.activeVRUID) {
      result = ViewRealizerManager.getViewRealizer(this.activeVRUID);
    }

    if (!result) {
      return this.appViewRealizer;
    }

    return result;
  }

  public setActiveRealizerUID(realizer: ViewRealizer) {
    if (realizer && realizer.isActive()) {
      this.activeVRUID = realizer.getRealizerUID();
    }
  }

  public registerMainTabControl(ctrl: NclBaseTabControl<any, any>): boolean {
    if (!this.mainTabControlUID) {
      this.mainTabControlUID = ctrl.MetaData.ControlUID;
      return true;
    }
    return false;
  }

  public unRegisterMainTabControl(ctrl: NclBaseTabControl<any, any>) {
    if (this.mainTabControlUID === ctrl.MetaData.ControlUID) {
      this.mainTabControlUID = undefined;
    }
  }

  public get appViewRealizer(): ViewRealizer {
    return this._appViewRealizer;
  }

  private async reconnect(lastmessage: NclMessage) {
    if (this.reconnectUID) {
      if (!this.isReconnecting) {
        this.isReconnecting = true;
        this.busyIndicator.setBusyIndicator(true, "Prosím čekejte...", `Pokus o připojení`, "wait", true);
        this.stopNoopTimeout();
        await delay(500);

        this.connection = null;
        this.getConnection()
          .connect()
          .then(() => {
            this.close(true, false);
            this.ignore = 0;
            this.internalSendMessage(NclMessageHelper.CreateRealizeAppMsg(this.reconnectUID), (reason) => {
              this.busyIndicator.setBusyIndicator(false);
              this.isReconnecting = false;
              if (reason === true) {
                if (lastmessage) {
                  this.sendMessage(lastmessage);
                }
                this.startNoopTimeout(this.userInfo.NoopTimeout);
              } else {
                this.reconnectForm(null);
              }
            });
          })
          .catch((reason) => {
            this.busyIndicator.setBusyIndicator(false);
            return this.reconnect(lastmessage);
          });
      } else {
        this.reconnectForm(async () => {
          this.isReconnecting = false;
          await this.reconnect(lastmessage);
        });
      }
    } else {
      location.reload();
    }
  }

  private tSep: string = undefined;
  private dSep: string = undefined;
  private nSep: string = undefined;
  public getLocalSeparator(type: TNclInputType): string {
    switch (type) {
      case TNclInputType.nitNumeric:
        if (!this.nSep) {
          let n = 1.1;
          this.nSep = n.toLocaleString().substring(1, 2);
        }
        return this.nSep;
      case TNclInputType.nitDate:
        if (!this.dSep) {
          let date = new Date();
          let dateStr = date.toLocaleDateString();
          let dayStr = date.getDate().toString();
          let sepNdx = dateStr.indexOf(dayStr) + dayStr.length;
          this.dSep = dateStr.substring(sepNdx, sepNdx + 1).trim();
        }

        return this.dSep;
      case TNclInputType.nitTime:
        if (!this.tSep) {
          let date = new Date();
          let dateStr = date.toLocaleTimeString();
          let hourStr = date.getHours().toString();
          let hourNdx = dateStr.indexOf(hourStr) + hourStr.length;
          this.tSep = dateStr.substring(hourNdx, hourNdx + 1).trim();
        }
        return this.tSep;
      default:
        break;
    }
    return "";
  }

  private clear() {
    this.modalRealizerUID = null;
    this._appViewRealizer = null;
    ViewRealizerManager.clear();
    this.internalClear();
  }

  protected abstract internalClear(): void;

  public render(vr: ViewRealizer) {
    this._appViewRealizer = vr;
    this.internalRender();
  }

  public getPictureServerUrl(isStatic: boolean): string {
    return isStatic ? `${this.getConnection().getBaseUrl()}/images` : `${this.getConnection().getBaseUrl()}/Picture/GetPicture?`;
  }

  public getCurrentK2LanguageImgFolder(): string {
    if (!this.userInfo) return DEFAULT_LANG_IMG;
    else return `Lang/Lang${this.userInfo.Lng}`;
  }

  public addActualScreenSize(request: DataRequest) {
    if (this._screenSize !== this._lastScreenSize) {
      request.ScreenSize = this._screenSize;
      request.TransformColumnsCount = Context.DeviceInfo.TransformColumnsCount;
      this._lastScreenSize = this._screenSize;
    }
  }

  public getUserLanguage(): string {
    let userLang = navigator.language;
    if (userLang && userLang != "undefined") {
      return userLang;
    }
    return "en-US";
  }

  /**
   * Spuštění aplikace.
   */
  public async run() {
    this.loginForm();
  }

  public showModal(realizerUID: string): Promise<void> {
    if (this.appViewRealizer) {
      this.modalRealizerUID = realizerUID;
      return ViewRealizer.getNearestListener(this.appViewRealizer).showModal(realizerUID);
    }
    return Promise.reject("App realizer not found.");
  }

  public closeModal(realizerUID: string): Promise<void> {
    if (this.appViewRealizer) {
      this.modalRealizerUID = null;
      return ViewRealizer.getNearestListener(this.appViewRealizer).closeModal(realizerUID);
    }

    return Promise.reject("App realizer not found.");
  }

  protected abstract loginForm(): void;

  protected abstract reconnectForm(reconnectFce: () => void): void;

  public realizeApp() {
    if (this.appViewRealizer === null) {
      this.internalSendMessage(NclMessageHelper.CreateRealizeAppMsg(""));
    }
  }

  public async login(user: string, password: string): Promise<boolean> {
    return this.getConnection().login(user, password);
  }

  public async getLoginInfo(user: string): Promise<LoginInfo> {
    return this.getConnection().getLoginInfo(user);
  }

  /**
   * Ukončení aplikace.
   */
  public async close(safeReconnectUID: boolean, sendTerminate: boolean) {
    if (!this._appViewRealizer) return;
    if (sendTerminate) {
      navigator.sendBeacon(
        `${this.connection.getBaseUrl()}/close`,
        JSON.stringify(NclMessageHelper.CreateTerminateMsg(this._appViewRealizer ? this._appViewRealizer.getRealizerUID() : ""))
      );
    }

    if (safeReconnectUID === false) this.reconnectUID = null;
    window.removeEventListener("keydown", this.keyDown);
    window.removeEventListener("orientationchange", this.orientationchange);
    window.removeEventListener("mousemove", this.mousemove);
    window.removeEventListener("error", this.handleError);
    window.removeEventListener("unhandledrejection", this.handleRejection);
    this.clear();
  }

  /**
   * Methods forbid send messages to server.
   */
  public forbidenSendMessages() {
    this.ignore++;
  }

  /**
   * Methods allow send messages to server and send message from queue.
   */
  public allowSendMessages() {
    if (this.ignore > 0) this.ignore--;

    if (this.ignore === 0) {
      if (this.messagesToSend.length > 0) {
        let msg = this.messagesToSend.shift();
        if (msg) {
          let realizer = ViewRealizerManager.getViewRealizer(msg.realizerUID);
          if (!msg.realizerUID || (realizer && realizer.canSendRequest())) {
            this.internalSendMessage(msg);
          } else {
            this.allowSendMessages();
          }
        }
      }
    }
  }

  private canSendMessage(realizerUID: string): boolean {
    return !this.modalRealizerUID || this.modalRealizerUID === realizerUID;
  }

  public isBusy(): boolean {
    return this.ignore > 0 || this.isReconnecting === true;
  }

  public sendMessage(message: NclMessage) {
    if (!this.canSendMessage(message.realizerUID)) return;
    if (!this.isBusy()) {
      let vr = ViewRealizerManager.getViewRealizer(message.realizerUID);
      if (!vr || !vr.canSendRequest()) return;
      this.internalSendMessage(message);
      return;
    }

    this.messagesToSend.push(message);
  }

  private internalSendMessage(message: NclMessage, onReconnectReceive: (reason: boolean) => void = null) {
    this.forbidenSendMessages();
    if (!this.getConnection().send(message)) {
      this.allowSendMessages();
      this.reconnect(message);
      return;
    }
    let start = new Date().getTime();
    this.busyIndicator.setBusyIndicator(true);

    //console.time('sendMessage');
    this.getConnection()
      .receive()
      .then(async (result: ReceiveData) => {
        //console.timeEnd('sendMessage');
        Log.debug("REQUEST:" + JSON.stringify(message) + " RESPONSE: " + JSON.stringify(result) + " - DURATION: " + (new Date().getTime() - start) + "ms");
        this.busyIndicator.setBusyIndicator(false);
        if (result) {
          if (result.message && result.message.messageType === NclMessageType.nmsgTerminate) {
            if (onReconnectReceive !== null) {
              onReconnectReceive(false); //reconnect session on server was killed
            } else {
              this.terminate(result.message);
            }
            return;
          }
          if (message.messageType === NclMessageType.nmsgNoop) {
            if (result.message.realizerUID) {
              this.reconnectUID = result.message.realizerUID;
            }
            this.allowSendMessages();
            return;
          }

          await NclMessageHelper.unzipMessage(result.message);
          await this.receiveNclMessage(result.message);
          this.allowSendMessages();
          if (result.error) {
            Log.error("Send message: " + message + "response: " + result.error.message, result.error);
            onReconnectReceive(false); // error reconnect
          } else {
            if (onReconnectReceive) onReconnectReceive(true); // reconnect success
          }
        } else {
          Log.error("Send message: " + message + "response: null.", null);
          this.allowSendMessages();
        }
      })
      .catch((reason: any) => {
        Log.error(reason, null);
        this.busyIndicator.setBusyIndicator(false);
        if (reason.code === 1000) {
          if (onReconnectReceive) onReconnectReceive(false);
        } else {
          this.allowSendMessages();
          this.reconnect(message);
        }
      });
  }

  public canStopPostback(message: PostbackMessage): boolean {
    if (this.busyIndicator) {
      this.busyIndicator.setPostbackProgress(message.ProgressTitle, message.ProgressPercentage);
    }
    return this.busyIndicator.canStopPostback();
  }

  public setMenuPosition(x: number, y: number) {
    if (x && y) {
      this.menuPosition = { x: x, y: y };
    }
  }

  private receiveNclMessage(data: NclMessage): Promise<void | void[]> {
    if (data) {
      if (data.messageType == NclMessageType.nmsgMulti) {
        let messages: MultiMessage[] = parseData(data.json);
        if (messages != null && messages.length > 0) {
          return new Promise((resolve, reject) => {
            this.next(messages, 0, resolve);
          });
        }
      } else {
        return new Promise((resolve, reject) => {
          this.processMessage(data, resolve);
        });
      }
    }
  }

  private next(messages: MultiMessage[], index: number, callback: () => void) {
    if (messages && messages.length > 0 && index < messages.length) {
      this.processMessage(NclMessageHelper.Create(messages[index]), () => {
        return this.next(messages, index + 1, callback);
      });
    } else {
      callback();
    }
  }

  private async processMessage(message: NclMessage, callback: () => void) {
    switch (message.messageType) {
      case NclMessageType.nmsgRealize:
        let structure: CSViewRealizerStructure = parseData(message.json);
        if (this.appViewRealizer && structure.RealizerUID === this.appViewRealizer.getRealizerUID()) {
          this.close(true, false);
        }
        let vr = ViewRealizerManager.getOrCreate(message.realizeCounter, structure);
        if (vr != null) {
          vr.processData(message.realizeCounter, structure, callback);
          return;
        }
        break;
      case NclMessageType.nmsgData:
        {
          let vr = ViewRealizerManager.getViewRealizer(message.realizerUID);
          if (vr != null) {
            let data: UpdateBase = parseData(message.json);
            vr.processData(message.realizeCounter, data, callback);
            return;
          }
        }
        break;
      case NclMessageType.nmsgClose:
        {
          let vr = ViewRealizerManager.getViewRealizer(message.realizerUID);
          if (vr != null) {
            await vr.close();
            //reamove all request for closing VR.
            this.messagesToSend = this.messagesToSend.filter((msg) => {
              return msg.realizerUID !== message.realizerUID;
            });
            if (callback) callback();
            return;
          }
        }
        break;
      case NclMessageType.nmsgOutData: {
        let files: OutData = parseData(message.json);
        this.processClientData(files.OutData);
        break;
      }
      case NclMessageType.nmsgTerminate:
        this.terminate(message);
        break;
      case NclMessageType.nmsgOK:
      default:
        if (this.reconnectUID == null) {
          this.reconnectUID = message.realizerUID;
          if (message.json) {
            this.userInfo = parseData(message.json) as UserInfo;
            if (this.userInfo && this.userInfo.NoopTimeout > 0) {
              this.startNoopTimeout(this.userInfo.NoopTimeout);
            }
          }
          this.sendMessage(NclMessageHelper.CreateRealizeMsg());
        }
        break;
    }

    if (callback) {
      callback();
    }
  }

  public terminate(message?: NclMessage) {
    // console.log("Receive terminate");
    if (message && message.json) {
      alert(message.json);
    }

    if (this.isReconnecting) {
      this.reconnectForm(null);
      return;
    }

    this._appViewRealizer = null;
    this.stopNoopTimeout();
    location.reload();
  }

  protected abstract processClientData(list: Array<CSClientData>): void;

  private getConnection(): Connection {
    if (this.connection == null) {
      let url: string = window.location.host + window.location.pathname;
      if (url.endsWith("/")) {
        url = url.slice(0, -1);
      }

      if (process.env.NODE_ENV === "development") {
        //ws = 'ws://172.21.83.108:8080/ws';
        url = "labs.k2.cz/mdz_html5";
        // url = '172.21.83.135:8080';//moje PC
        //url = 'https://hetera.k2.cz/k2';
        url = "dziekanikm-p608.k2.int/ws2020_05";
        //url = 'localhost:51110';
      }
      this.connection = new Connection(url, location.protocol == "https:");
    }

    return this.connection;
  }

  /**
   * Odchyt stisku tl. Vyvolá metodu keyDown na vsech zaregistrovaných realizerech a pokud ji nektery obslouží tak
   * končí a události zakáže probublání dále.
   */
  private keyDown = (ev: KeyboardEvent) => {
    let vr: ViewRealizer = Context.getApplication().getActiveRealizer();
    vr = vr == null ? this.appViewRealizer : vr;
    if (vr != null) {
      let hk = Helper.convertToTxt(ev);
      if (hk != "" && vr.processHotKey(hk)) {
        if (hk.includes("LEFT") || hk.includes("RIGHT")) return false;

        ev.preventDefault();
        ev.stopPropagation();
        return false;
      }
    }
  };

  private orientationchange = (event: Event) => {
    let size = Context.DeviceInfo.ScreenWidth;
    if (size != this._lastScreenSize) {
      this._screenSize = size;
      Context.getApplication().getActiveRealizer().sendRequest(RealizerOperations.Update);
    }
  };

  private mousemove(event: MouseEvent) {
    Context.getApplication().setMenuPosition(event.clientX, event.clientY);
  }

  private handleError(e: ErrorEvent) {
    console.log(e.error.stack);
    Helper.sendErrorMessage(e.error.stack);
  }

  private handleRejection(e: PromiseRejectionEvent) {
    throw new Error(e.reason.stack);
  }

  public getMenuPositon(): ModalPosition {
    return this.menuPosition;
  }
}

export class BusyIndicator {
  private busyIndicatorStartTimer: any;
  private busyIndicatorStopTimer: any;
  private busyIndicatorCount: number = 0;
  private busyIndicatorStartFlag: boolean = false;
  private busyIndicatorStopFlag: boolean = false;
  private text: string = "";
  private cursor: CursorProperty = "";
  private oldCursor: CursorProperty;

  private busyIndicatorSmall: HTMLElement = document.getElementById("busyIndicatorSmall");
  private busyIndicatorBig: HTMLElement = document.getElementById("busyIndicatorBig");
  private busyIndicatorTextContainer: HTMLElement = document.getElementById("busyIndicatorTextContainer");
  private busyIndicatorContainer: HTMLElement = document.getElementById("busyIndicatorContainer");
  private busyIndicatorStopBtn: HTMLElement = document.getElementById("busyIndicatorStopBtn");
  private stopPostback: boolean;

  public canStopPostback(): boolean {
    return this.stopPostback;
  }

  public setPostbackProgress = (title: string, message: string): void => {
    if (this.busyIndicatorSmall && this.busyIndicatorBig && this.busyIndicatorStopBtn) {
      if (this.busyIndicatorCount > 0) {
        this.changeVisibilityBigIndicator(true, title, message);
        this.busyIndicatorStopBtn.setAttribute("style", "display: flex;");
        if (!this.busyIndicatorStopBtn.onclick) {
          this.busyIndicatorStopBtn.onclick = (e) => {
            this.stopPostback = true;
          };
          this.busyIndicatorStopBtn.onmouseenter = (e) => {
            document.body.style.cursor = "";
          };
          this.busyIndicatorStopBtn.onmouseleave = (e) => {
            document.body.style.cursor = this.cursor;
          };
        }
      }
    }
  };

  public setBusyIndicator = (
    showIndicator: boolean = true,
    title: string = "",
    message: string = "",
    cursor: CursorProperty = "wait",
    forceBigIndicator: boolean = false
  ): void => {
    this.cursor = cursor;
    if (this.busyIndicatorSmall && this.busyIndicatorBig) {
      if (showIndicator) {
        clearTimeout(this.busyIndicatorStopTimer);
        this.busyIndicatorStopFlag = false;

        this.busyIndicatorCount++;
        if (forceBigIndicator) {
          this.changeVisibilitySmallIndicator(true);
          this.changeVisibilityBigIndicator(true, title, message);
        } else if (this.busyIndicatorCount === 1) {
          this.changeVisibilitySmallIndicator(true);
          if (!this.busyIndicatorStartFlag) {
            this.busyIndicatorStartFlag = true;
            this.busyIndicatorStartTimer = setTimeout(() => {
              this.changeVisibilityBigIndicator(true, title, message);
            }, 750);
          }
        }
        if (message !== "" && this.text !== message) {
          this.changeVisibilityBigIndicator(true, title, message);
        }
      } else {
        this.busyIndicatorCount--;
        this.changeVisibilitySmallIndicator(false);
        this.busyIndicatorStopFlag = true;
        this.busyIndicatorStopTimer = setTimeout(() => {
          clearTimeout(this.busyIndicatorStartTimer);
          this.busyIndicatorStartFlag = false;
          this.changeVisibilityBigIndicator(false, "", "");
        }, 500);
      }
    }
  };
  private changeVisibilitySmallIndicator(visible: boolean) {
    if (visible) {
      this.setCursor();
      this.busyIndicatorSmall.setAttribute("style", "opacity: 1;z-index: 0;");
    } else if (this.busyIndicatorCount === 0) {
      document.body.style.cursor = this.oldCursor;
      this.oldCursor = null;
      this.busyIndicatorSmall.setAttribute("style", "opacity: 0;z-index: -1;");
    }
  }

  private changeVisibilityBigIndicator(visible: boolean, title: string, message: string) {
    if (visible) {
      this.setCursor();
      if (!this.busyIndicatorStopFlag) {
        this.busyIndicatorBig.setAttribute("style", "display: flex;");
        if (title != "" || message !== "") {
          this.busyIndicatorContainer.classList.add("showBusyText");

          this.busyIndicatorTextContainer.getElementsByClassName("busyIndicatorTitle")[0].innerHTML = title;
          this.busyIndicatorTextContainer.getElementsByClassName("busyIndicatorMessage")[0].innerHTML = message;
          this.text = message;
        }
      }
    } else {
      this.stopPostback = undefined;
      this.busyIndicatorContainer.classList.remove("showBusyText");
      this.busyIndicatorBig.setAttribute("style", "display: none;");
      this.busyIndicatorStopBtn && this.busyIndicatorStopBtn.setAttribute("style", "display: none;");
      this.busyIndicatorTextContainer.getElementsByClassName("busyIndicatorTitle")[0].innerHTML = "";
      this.busyIndicatorTextContainer.getElementsByClassName("busyIndicatorMessage")[0].innerHTML = "";
      document.body.style.cursor = this.oldCursor;
      this.oldCursor = null;
    }
  }

  private setCursor() {
    if (!this.oldCursor) this.oldCursor = document.body.style.cursor;

    if (this.cursor === "" || this.cursor === null) {
      this.cursor = "wait";
    }
    document.body.style.cursor = this.cursor;
  }
}

export class FullScreenMessage {
  private fullScreenMessageContainer: HTMLElement = document.getElementById("fullScreenMessage");
  private fullScreenMessageText: HTMLElement = document.getElementById("fullScreenMessageText");

  public showMessage = (text: string): void => {
    if (this.fullScreenMessageContainer && this.fullScreenMessageText) {
      this.fullScreenMessageContainer.setAttribute("style", "display: block;");
      this.fullScreenMessageText.innerHTML = text + this.getReloadButton();
    }
  };

  private getReloadButton(buttonText: string = "Načíst znovu"): string {
    return '<div class="cleaner height20"></div><button onClick="location.reload(true);">' + buttonText + "</button>";
  }
}
