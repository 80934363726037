import * as React from "react";
import { Size } from "../common/common";
import { UpdateControl, UpdateSignInput, SignData } from "../common/communication.base";
import { NclSignInput } from "../common/components.ncl";
import { AcquireControl, K2ComponentState, StyleHelper, withContext, WithContextPlacementProps } from "./k2hoc";
import { K2Img } from "./K2Image";
import { K2RuleWithVCXProps } from "./k2StyleRenderer";
import { K2TruncateText } from "./K2TruncateText";
import { setRequestedActiveControl } from "./app";
import ResizeObserver from "resize-observer-polyfill";

const clearButtonRule = () => ({
  position: "absolute" as const,
  zIndex: 3,
  top: "11vh",
  right: "5vh",
  height: "max-content",
  width: "max-content",
  background: "none",
  border: "none",
});

const signLine = (props: K2RuleWithVCXProps) => ({
  position: "absolute" as const,
  display: "block",
  zIndex: 1,
  width: "81%",
  marginLeft: "8%",
  marginRight: "8%",
  paddingTop: "1.5vh",
  borderTop: "4px " + props.vcx.getColor(props.vcx.Data.ColorMap.ContentFrame2) + " dotted",
  textAlign: "center" as const,
  fontSize: "150%",
  letterSpacing: 10,
  fontStyle: "italic" as const,
  color: props.vcx.getColor(props.vcx.Data.ColorMap.ContentFrame2),
});

const canvasContainerRule = (props: K2RuleWithVCXProps) => ({
  width: "fit-content",
  height: "fit-content",
  backgroundColor: props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorFrg1),
});

const canvasRule = (props: K2RuleWithVCXProps) => ({
  zIndex: 2,
  touchAction: "none",
  backgroundColor: "transparent",
  borderWidth: 2,
  borderColor: props.vcx.getColor(props.vcx.Data.ColorMap.ContentFrame2),
  borderStyle: "dashed",
});

interface SignInputState extends K2ComponentState<UpdateSignInput> {
  size?: Size;
  clearButtonSwitch: boolean;
}

export class _SignInput extends React.PureComponent<WithContextPlacementProps, SignInputState> {
  private control: NclSignInput;
  private ro: ResizeObserver;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclSignInput;
    }) as NclSignInput;

    this.state = { data: this.control.init(this) as UpdateSignInput, clearButtonSwitch: false, vcxVersion: -1, size: { width: 0, height: 0 } };
  }

  private isPainting = false;
  private signData: SignData;
  private prevPos = { offsetX: 0, offsetY: 0 };
  private prevWidth = 1;
  private canvas: HTMLCanvasElement;
  private ctx: CanvasRenderingContext2D;
  private dotSwitch = false;
  private rect: ClientRect = null;
  private wrapper = React.createRef<HTMLDivElement>();

  updateState(state: UpdateControl): void {
    this.setState(() => {
      return { data: state as UpdateSignInput };
    });
  }

  updateVCX(vcxVersion: number): void {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentDidMount(): void {
    this.ro = new ResizeObserver((entries) => {
      if ((entries[0].contentRect.width / 3) * 2 > entries[0].contentRect.height) {
        this.setState({
          size: { height: entries[0].contentRect.height, width: entries[0].contentRect.width },
        });
      } else {
        this.setState({ size: { height: (entries[0].contentRect.width / 3) * 2, width: entries[0].contentRect.width } });
      }
      this.loadImgToCanvas();
    });
    this.ro.observe(this.wrapper.current);
  }

  componentWillUnmount(): void {
    this.ro.unobserve(this.wrapper.current);
    this.control.willUnMount(true);
    this.control = null;
  }

  componentDidUpdate(prevProps: WithContextPlacementProps, prevState: SignInputState): void {
    if (
      this.state.data.SignData &&
      this.ctx &&
      this.state.data.Visible &&
      (prevState.data.SignData !== this.state.data.SignData || this.state.data.Visible != prevState.data.Visible)
    ) {
      this.loadImgToCanvas();
    }
  }

  private refCallback = (canvas: HTMLCanvasElement) => {
    if (canvas) {
      this.canvas = canvas;
      this.ctx = canvas.getContext("2d");
      if (this.state.data.SignData && this.state.data.SignData !== "") {
        this.loadImgToCanvas();
      }
    }
  };

  private handleTouchStart = (touchStartEvent: React.TouchEvent<HTMLCanvasElement>) => {
    touchStartEvent.preventDefault();
    this.rect = this.wrapper.current.getBoundingClientRect();
    const offsetX = touchStartEvent.touches[0].clientX - this.rect.left;
    const offsetY = touchStartEvent.touches[0].clientY - this.rect.top;
    this.isPainting = true;
    this.prevPos = { offsetX, offsetY };
    this.prevWidth = 1;
    this.dotSwitch = true;
  };

  private handleTouchMove = (touchMoveEvent: React.TouchEvent<HTMLCanvasElement>) => {
    touchMoveEvent.preventDefault();
    if (this.isPainting) {
      this.dotSwitch = false;
      const offsetX = touchMoveEvent.touches[0].clientX - this.rect.left;
      const offsetY = touchMoveEvent.touches[0].clientY - this.rect.top;
      this.initPaint(offsetX, offsetY);
    }
  };

  private handleTouchEnd = (touchEndEvent: React.TouchEvent<HTMLCanvasElement>) => {
    touchEndEvent.preventDefault();
    if (this.isPainting) {
      this.stopPaiting();
      if (this.dotSwitch) {
        const offsetX = touchEndEvent.changedTouches[0].clientX - this.rect.left;
        const offsetY = touchEndEvent.changedTouches[0].clientY - this.rect.top;
        this.initPaint(offsetX, offsetY);
      }
    }
  };

  private onMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
    this.rect = this.wrapper.current.getBoundingClientRect();
    const { offsetX, offsetY } = e.nativeEvent;
    this.isPainting = true;
    this.prevPos = { offsetX, offsetY };
    this.prevWidth = 1;
    this.dotSwitch = true;
    document.addEventListener("mouseup", this.stopPaiting); // Při opuštění canvasu při stisknuté myši a následném uvolnění myši mimo canvas je zastaveno vykreslování stopy.
  };

  private onMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (this.isPainting) {
      this.dotSwitch = false;
      const { offsetX, offsetY } = e.nativeEvent;
      this.initPaint(offsetX, offsetY);
    }
  };

  private onMouseUp = (e: React.MouseEvent) => {
    if (this.isPainting) {
      this.stopPaiting();
      if (this.dotSwitch) {
        const { offsetX, offsetY } = e.nativeEvent;
        this.initPaint(offsetX, offsetY);
      }
    }
    document.removeEventListener("mouseup", this.stopPaiting);
  };

  private handleBlur = (e: React.FocusEvent) => {
    setRequestedActiveControl(e, this.control.getRealizerUID());
    this.control.change(this.canvas.toDataURL().replace(/^data:image\/(png|jpg);base64,/, ""), true);
  };

  private stopPaiting = () => {
    this.isPainting = false;
    this.control.change(this.canvas.toDataURL().replace(/^data:image\/(png|jpg);base64,/, ""), false);
    this.signData = this.canvas.toDataURL().replace(/^data:image\/(png|jpg);base64,/, "");
  };

  private initPaint(offsetX: number, offsetY: number) {
    const offSetData = { offsetX, offsetY };
    this.paint(this.prevPos, offSetData);
  }

  private paint = (prevPos: { offsetX: number; offsetY: number }, currPos: { offsetX: number; offsetY: number }) => {
    this.setState({ clearButtonSwitch: true });
    const { offsetX, offsetY } = currPos;
    const { offsetX: x, offsetY: y } = prevPos;

    if (x === offsetX && y === offsetY) {
      //tečka
      this.ctx.beginPath();
      this.ctx.arc(x, y, Math.round(this.state.size.width / 300), 0, 2 * Math.PI, true);
      this.ctx.fillStyle = this.control.VCX.getColor(this.control.VCX.Data.ColorMap.BaseColorBck1);
      this.ctx.fill();
    } else {
      //čára
      let lineWidth: number;
      const a = Math.abs(x - offsetX);
      const b = Math.abs(y - offsetY);
      const pathLength = Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2));

      lineWidth = 4 / (pathLength / 5) > this.prevWidth ? this.prevWidth + 0.3 : this.prevWidth - 0.3; //Výpočet šířky tahu dle vzdálenosti posledních bodů cesty

      lineWidth = Math.min(lineWidth, 5);
      lineWidth = Math.max(lineWidth, 2);
      this.prevWidth = lineWidth;

      lineWidth = Math.round(lineWidth * (this.state.size.width / 300));

      this.ctx.lineWidth = lineWidth;
      this.ctx.lineJoin = "round";
      this.ctx.lineCap = "round";

      this.ctx.beginPath();
      this.ctx.moveTo(x, y);
      this.ctx.lineTo(offsetX, offsetY);
      this.ctx.strokeStyle = this.control.VCX.getColor(this.control.VCX.Data.ColorMap.BaseColorBck1);
      this.ctx.stroke();
      this.prevPos = { offsetX, offsetY };
    }
  };

  private getSignLine(): JSX.Element {
    if (this.state.size.height > 300 && this.state.size.width > 400) {
      return (
        <div className={this.props.renderer.renderRule(signLine, { vcx: this.control.VCX })} style={{ top: Math.round((this.state.size.height / 3) * 2.4) }}>
          <K2TruncateText>Zde se podepište</K2TruncateText>
        </div>
      );
    }
  }

  private getClearButton(): JSX.Element {
    if (this.state.clearButtonSwitch) {
      return (
        <div
          className={this.props.renderer.renderRule(clearButtonRule, { vcx: this.control.VCX })}
          onMouseDown={this.clearCanvas}
          style={{ top: this.rect.top + 10, left: this.state.size.width - 65 }}
        >
          <K2Img
            height={48}
            width={48}
            glyphId={"wui*clear"}
            vcx={this.control.VCX}
            strokeColor={this.control.VCX.getColor(this.control.VCX.Data.ColorMap.BaseColorBck1)}
          />
        </div>
      );
    } else return null;
  }

  private clearCanvas = () => {
    this.setState({ clearButtonSwitch: false });
    this.control.change("", false);
    this.signData = "";
    this.ctx.clearRect(0, 0, this.state.size.width, this.state.size.height);
  };

  render(): JSX.Element {
    return (
      <div style={StyleHelper(this.control, Object.assign({}, this.props.style))} ref={this.wrapper}>
        <div className={this.props.renderer.renderRule(canvasContainerRule, { vcx: this.control.VCX })} style={{ width: "100%" }}>
          <canvas
            className={this.props.renderer.renderRule(canvasRule, { vcx: this.control.VCX })}
            tabIndex={0}
            onBlur={this.handleBlur}
            ref={this.refCallback}
            onMouseDown={this.onMouseDown}
            onMouseMove={this.onMouseMove}
            onMouseUp={this.onMouseUp}
            onTouchStart={this.handleTouchStart}
            onTouchMove={this.handleTouchMove}
            onTouchEnd={this.handleTouchEnd}
            height={this.state.size.height - 10}
            width={this.state.size.width}
          />
          {this.getSignLine()}
          {this.getClearButton()}
        </div>
      </div>
    );
  }

  private loadImgToCanvas = () => {
    if (!this.signData) return;
    this.ctx.clearRect(0, 0, this.state.size.width, this.state.size.height);
    const image = new Image();
    image.onload = () => {
      this.ctx.drawImage(image, 0, 0, this.state.size.width - 4, this.state.size.height - 21);
    };
    image.src = "data:image/png;base64," + this.signData;
  };
}

export const K2SignInput = withContext(_SignInput);
