import * as React from "react";

import { UpdateControl, UFUpdateControl } from "../common/communication.base";
import { NclLibraryReference } from "../common/components.ncl";
import { GenerateControl } from "./K2GenerateControl";
import { WithContextPlacementProps, withContext, K2ComponentState, StyleHelper, AcquireControl } from "./k2hoc";
import { flowRule } from "./K2FlowPanel";

class _LibraryReference extends React.Component<WithContextPlacementProps, K2ComponentState<UFUpdateControl>> {
  static displayName = `K2LibraryReference`;
  private control: NclLibraryReference;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclLibraryReference;
    }) as NclLibraryReference;
    this.state = { data: this.control.init(this) as UFUpdateControl, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UFUpdateControl>) => {
      return { data: state as UFUpdateControl };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    return (
      <div
        style={StyleHelper(this.control, Object.assign({}, { background: "transparent" }, this.props.style))}
        className={this.props.renderer.renderRule(flowRule, { vcx: this.control.VCX, hasParentPreview: this.control.isInPreview() })}
      >
        {this.control.Children.map((control) => {
          return GenerateControl(control, { height: "100%" });
        })}
      </div>
    );
  }
}

export const K2LibraryReference = withContext(_LibraryReference);
