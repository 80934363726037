import { List } from "immutable";
import * as React from "react";
import ReactDOM = require("react-dom");
import Draggable, { ControlPosition, DraggableData } from "react-draggable";

import { Context } from "../appcontext";
import { Log, Size } from "../common/common";
import {
  CSUpdateCommandItem,
  RectInDock,
  RectInDockSizeMode,
  UpdateCommandItem,
  CSUpdateControl,
  FrameStyle,
  RectInDockPositionMode,
} from "../common/communication.base";
import {
  ClientNclCommandItem,
  NclCommandItem,
  NclHeader,
  NclViewBase,
  UFNclControlBase,
  NclControlBase,
  NclMenuView,
  NclMenu,
  NclOpenDialog,
  UFOverAlign,
  UFOverAlignListener,
  NclFloaterView,
} from "../common/components.ncl";
import { ViewRealizer, ViewRealizerManager } from "../viewrealizer";
import { AcquireControl, withContext, WithVCXProps } from "./k2hoc";
import { K2RuleWithVCXProps, marginRule, K2RuleProps } from "./k2StyleRenderer";
import { ViewRealizerReact } from "./ViewRealizerReact";
import { K2View } from "./K2View";
import { IgnorePlugin } from "webpack";

type K2RuleModalProps = K2RuleWithVCXProps & {
  size?: Size;
  zIndex: number;
  overlay?: boolean;
};

type K2RuleTransitionModalProps = K2RuleWithVCXProps & {
  height?: number | string;
  animationName?: string;
};

const defaultResizerSize: number = 10;

const defaultMinSize: Size = { height: 24, width: 100 };
const styleRules = {
  overlayWindowRule: (props: K2RuleModalProps) => ({
    display: "flex",
    //position: 'fixed' as 'fixed',
    position: "absolute" as "absolute",
    zIndex: props.zIndex,
    height: props.size ? props.size.height + "px" : "100%",
    width: props.size ? props.size.width + "px" : "100%",
    overflow: "hidden" as "hidden",
    backgroundColor: props.overlay ? props.vcx.getRGBColor(props.vcx.Data.ColorMap.AlphaColor).toHTML(0.2) : "unset",
  }),
  modalWindowRule: (props: K2RuleModalProps) => ({
    zIndex: props.zIndex,
    //border: props.vcx.ViewControl.OutLineWidth + 'px solid ' + props.vcx.getColor(props.vcx.Data.ColorMap.AccentBaseColorBck),
    height: props.size ? props.size.height + "px" : "auto",
    width: props.size ? props.size.width + "px" : "100%",
  }),
  modalWindowInterRule: (props: K2RuleWithVCXProps) => ({
    display: "flex",
    flexDirection: "column" as "column",
    border: props.vcx.ViewControl.FrameWidth + "px solid " + props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorBck1),
    height: "100%",
    width: "100%",
  }),
  modalWindowHeaderRule: (props: K2RuleWithVCXProps) => ({
    flex: "0 1 auto",
    display: "flex",
    minHeight: props.vcx.ExpanderControl.GetHFHeight() + "px",
  }),
  modalWindowContentRule: (props: K2RuleWithVCXProps) => ({
    flex: 1,
    padding: "0px",
    height: "100%",
    width: "100%",
  }),

  modalWindowToolbarRule: (props: K2RuleWithVCXProps) => ({
    flex: "0 1 auto",
    display: "flex",
  }),
  modalWindowTitleRule: (props: K2RuleWithVCXProps) => ({
    flex: "1 1 100%",
    color: props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorFrg1),
    cursor: "arrow",
  }),
  modalResizerTRule: (props: K2RuleWithVCXProps) => ({
    position: "absolute" as "absolute",
    height: defaultResizerSize + "px",
    width: "calc(100% - " + defaultResizerSize + "px)",
    cursor: "n-resize",
    top: -(defaultResizerSize / 2) + "px",
    left: defaultResizerSize + "px",
  }),
  modalResizerTLRule: (props: K2RuleWithVCXProps) => ({
    position: "absolute" as "absolute",
    height: defaultResizerSize + "px",
    width: defaultResizerSize + "px",
    cursor: "nw-resize",
    top: -(defaultResizerSize / 2) + "px",
    left: -(defaultResizerSize / 2) + "px",
  }),
  modalResizerTRRule: (props: K2RuleWithVCXProps) => ({
    position: "absolute" as "absolute",
    height: defaultResizerSize + "px",
    width: defaultResizerSize + "px",
    cursor: "ne-resize",
    top: -(defaultResizerSize / 2) + "px",
    right: -(defaultResizerSize / 2) + "px",
  }),
  modalResizerBRule: (props: K2RuleWithVCXProps) => ({
    position: "absolute" as "absolute",
    height: defaultResizerSize + "px",
    width: "calc(100% - " + defaultResizerSize + "px)",
    cursor: "n-resize",
    bottom: -(defaultResizerSize / 2) + "px",
    left: defaultResizerSize / 2 + "px",
  }),
  modalResizerBLRule: (props: K2RuleWithVCXProps) => ({
    position: "absolute" as "absolute",
    height: defaultResizerSize + "px",
    width: defaultResizerSize + "px",
    cursor: "sw-resize",
    bottom: -(defaultResizerSize / 2) + "px",
    left: -(defaultResizerSize / 2) + "px",
  }),
  modalResizerBRRule: (props: K2RuleWithVCXProps) => ({
    position: "absolute" as "absolute",
    height: defaultResizerSize + "px",
    width: defaultResizerSize + "px",
    cursor: "se-resize",
    bottom: -(defaultResizerSize / 2) + "px",
    right: -(defaultResizerSize / 2) + "px",
  }),
  modalResizerLRule: (props: K2RuleWithVCXProps) => ({
    position: "absolute" as "absolute",
    width: defaultResizerSize + "px",
    height: "calc(100% - " + defaultResizerSize + "px)",
    cursor: "e-resize",
    left: -(defaultResizerSize / 2) + "px",
    top: defaultResizerSize / 2 + "px",
  }),
  modalResizerRRule: (props: K2RuleWithVCXProps) => ({
    position: "absolute" as "absolute",
    width: defaultResizerSize + "px",
    height: "calc(100% - " + defaultResizerSize + "px)",
    cursor: "e-resize",
    right: -(defaultResizerSize / 2) + "px",
    top: defaultResizerSize / 2 + "px",
  }),
  modalResizerRule: (props: K2RuleWithVCXProps) => ({
    flex: 1,
    alignSelf: "baseline",
  }),
  mobileModalWindowRuleBase: (props: K2RuleTransitionModalProps) => ({
    position: "relative" as "relative",
    width: "95%",
    animation: props.animationName ? props.animationName + " 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both" : "unset",
  }),
  mobileModalWindowRule: (props: K2RuleTransitionModalProps) => ({
    height: props.height ? props.height : "96%",
    maxHeight: "96%",
    left: "2.5%",
    transform: "translateY(calc(var(--vh, 1vh) * 100))",
  }),
  mobileLocalModalWindowRule: (props: K2RuleTransitionModalProps) => ({
    height: "97%",
    top: "1.5%",
    transform: "translateX(-100vw)",
  }),
  mobileMenuWindowKFOpenRule: (props: K2RuleProps) => ({
    "0%": {
      transform: "translateY(calc(var(--vh, 1vh) * 100))",
    },
    "100%": {
      transform: "translateY(calc(5%))",
    },
  }),
  mobileMenuWindowKFCloseRule: (props: K2RuleProps) => ({
    "0%": {
      transform: "translateY(calc(5%))",
    },
    "100%": {
      transform: "translateY(calc(var(--vh, 1vh) * 100))",
    },
  }),
  mobileModalWindowKFOpenRule: (props: K2RuleProps) => ({
    "0%": {
      transform: "translateY(calc(var(--vh, 1vh) * 100))",
    },
    "100%": {
      transform: "translateY(calc(calc(var(--vh, 1vh) * 100) - 102%))",
    },
  }),
  mobileModalWindowKFCloseRule: (props: K2RuleProps) => ({
    "0%": {
      transform: "translateY(calc(calc(var(--vh, 1vh) * 100) - 102%))",
    },
    "100%": {
      transform: "translateY(calc(var(--vh, 1vh) * 100))",
    },
  }),
  mobileLocalModalWindowKFOpenRule: (props: K2RuleProps) => ({
    "0%": {
      transform: "translateX(-100vw)",
    },
    "100%": {
      transform: "translateX(2.5%)",
    },
  }),
  mobileLocalModalWindowKFCloseRule: (props: K2RuleProps) => ({
    "0%": {
      transform: "translateX(2.5%)",
    },
    "100%": {
      transform: "translateX(-100ww)",
    },
  }),
  windowRule: (props: K2RuleWithVCXProps) => ({
    boxShadow: "5px 5px 20px 0px rgba(0,0,0,0.6)",
  }),
};

interface RealizerQueueItem {
  realizerUID: string;
  controlUID?: string;
}

interface ModalState {
  realizersQueue: List<RealizerQueueItem>;
  overlayBck: boolean;
}

export interface K2Modal {
  show(realizerUID: string, parent: React.ReactInstance, controlUID?: string): Promise<void>;
  close(realizerUID: string, controlUID?: string): Promise<void>;
  setAsInActive(value: boolean): void;
}

interface ModalProps extends WithVCXProps {
  onRef?(modalAnchor: K2Modal): void;
  realizerUID: string;
}

class _Modal extends React.Component<ModalProps, ModalState> implements K2Modal {
  static displayName = `K2ModalAnchor`;

  constructor(props: ModalProps) {
    super(props);
    this.state = { realizersQueue: List<RealizerQueueItem>(), overlayBck: false };
  }

  setAsInActive(value: boolean): void {
    this.setState({ overlayBck: value });
  }

  show(realizerUID: string, parent: React.ReactInstance, controlUID?: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.setState(
        {
          realizersQueue: this.state.realizersQueue.push({ realizerUID: realizerUID, controlUID: controlUID }),
        },
        () => {
          resolve();
        }
      );
    });
  }

  close(realizerUID: string, controlUID?: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let i = this.state.realizersQueue.findIndex((value) => {
        return value.realizerUID === realizerUID && value.controlUID === controlUID;
      });
      if (i >= 0) {
        let newQueue: Array<RealizerQueueItem> = this.state.realizersQueue.toArray();
        newQueue.splice(i, 1);
        this.setState(
          {
            realizersQueue: List<RealizerQueueItem>(newQueue),
          },
          () => {
            resolve();
          }
        );
      } else {
        reject("Realizer not found.");
      }
    });
  }

  componentDidMount() {
    if (this.props.onRef) this.props.onRef(this);
  }

  render(): any {
    if (this.state.realizersQueue.count() > 0) {
      return (
        <div>
          {this.state.realizersQueue.map((item: RealizerQueueItem, index: number, maps: List<RealizerQueueItem>) => {
            let vr = ViewRealizerManager.getViewRealizer(item.realizerUID);

            if (vr != null) {
              let priorRealizerUID: string = "";
              let content = null;
              let key = "Modal_" + vr.getRealizerUID();
              if (!item.controlUID) {
                priorRealizerUID = vr.getPriorRealizer() ? vr.getPriorRealizer().getRealizerUID() : "";
                key += `_${priorRealizerUID}_${this.props.realizerUID}`;
                content = <ViewRealizerReact VRUID={vr.getRealizerUID()} key={key} overlayBck={this.state.overlayBck} />;
              } else {
                let ctrl = vr.getControlByUID(item.controlUID) as UFNclControlBase;
                if (ctrl && ctrl instanceof NclViewBase) {
                  key += "_" + ctrl.MetaData.ControlUID;
                  content = <K2View controlUID={ctrl.MetaData.ControlUID} vrUID={vr.getRealizerUID()} overlayBck={this.state.overlayBck} key={key}></K2View>;
                } else {
                  throw new Error("Modal control not found for show.");
                }
              }

              return content;
            }
          })}
        </div>
      );
    }

    return null;
  }
}

export const K2ModalControl = withContext(_Modal);

export interface ModalPosition {
  x: number;
  y: number;
}
interface SimpleModalWindowState {
  show: boolean;
}

interface SimpleModalWindowProps extends WithVCXProps {
  realizerUID: string; // realizer for show
  controlUID: string;
  isOverlayBck?: boolean;
}
/**
 * Interface for modal window.
 */
export interface ModalWindowBase {
  /**
   * Method for close modal window. Promise resolve is called when window is closed, after all animation.
   */
  close(): Promise<void>;
  /**
   * Method for open modal window. Promise resolve is called when window is closed, after all animation.
   */
  show(): Promise<void>;
}

var mobileVH: number;
/**
 * Window for mobile devices
 */
export class SimpleModalWindow extends React.PureComponent<SimpleModalWindowProps, SimpleModalWindowState> implements ModalWindowBase {
  static displayName = `K2SimpleModalWindow`;
  private control: NclViewBase<any, any>;
  private vr: ViewRealizer;
  private callback: () => void;
  private _closeCommand: NclCommandItem;

  constructor(props: SimpleModalWindowProps) {
    super(props);

    this.control = AcquireControl(this.props.controlUID, this.props.realizerUID, (ctrl) => {
      return ctrl instanceof NclViewBase;
    }) as NclViewBase<any, any>;
    this.vr = ViewRealizerManager.getViewRealizer(this.props.realizerUID);
    this.state = { show: undefined };

    if (!mobileVH) {
      mobileVH = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${mobileVH}px`);
    }
  }

  private get closeCommand(): NclCommandItem {
    if (!this._closeCommand) {
      if (this.vr) {
        this._closeCommand = this.vr.createClientControl((context) => {
          return new ClientNclCommandItem("", "wui*close", this, this.handleClose, this.control.Header, true, context);
        }) as NclCommandItem;
      }
    }
    return this._closeCommand;
  }

  private handleClose(ctrl: ClientNclCommandItem) {
    this.control.closeRequest();
  }

  componentWillUnmount() {
    this.control = null;
  }

  componentDidMount() {
    if (!this.control.isShowHeader() && this.control.Header) {
      this.control.Header.updateState({ Visible: true } as CSUpdateControl);
      if (this.control.getRectInDock().FrameStyle >= FrameStyle.frsNone) {
        this.control.getRectInDock().FrameStyle = FrameStyle.frsTitle;
      }
      this.control.Header.RQuickButtons.push(this.closeCommand);
    }
  }

  close(): Promise<void> {
    if (this.state.show === true) {
      return new Promise((resolve, reject) => {
        this.callback = resolve;
        this.setState({ show: false });
      });
    } else {
      return Promise.resolve();
    }
  }

  private handleAnimationtionEnd = () => {
    if (this.callback) {
      this.callback();
      this.callback = null;
    }
  };

  show(): Promise<void> {
    if (this.state.show === undefined) {
      return new Promise((resolve, reject) => {
        this.setState({ show: true });
        this.callback = resolve;
      });
    } else {
      return Promise.resolve();
    }
  }

  render(): JSX.Element {
    let clsName: string = "";
    let animationName: string = undefined;
    let rect = this.control.getRectInDock();
    let fullScreen: boolean = !this.vr.isModal() || (rect && rect.SizeMode === RectInDockSizeMode.ridsmPercent && (rect.Height > 0 || rect.Width > 0));
    if (this.state.show === true) {
      if (this.control instanceof NclMenuView) {
        animationName = this.props.renderer.renderKeyFrame(styleRules.mobileMenuWindowKFOpenRule, { vcx: this.control.VCX });
      } else if (!fullScreen) {
        animationName = this.props.renderer.renderKeyFrame(styleRules.mobileModalWindowKFOpenRule, { vcx: this.control.VCX });
      } else {
        animationName = this.props.renderer.renderKeyFrame(styleRules.mobileLocalModalWindowKFOpenRule, { vcx: this.props.vcx });
      }
    } else if (this.state.show === false) {
      if (this.control instanceof NclMenuView) {
        animationName = this.props.renderer.renderKeyFrame(styleRules.mobileMenuWindowKFCloseRule, { vcx: this.props.vcx });
      } else if (!fullScreen) {
        animationName = this.props.renderer.renderKeyFrame(styleRules.mobileModalWindowKFCloseRule, { vcx: this.props.vcx });
      } else {
        animationName = this.props.renderer.renderKeyFrame(styleRules.mobileLocalModalWindowKFCloseRule, { vcx: this.props.vcx });
      }
    }

    clsName +=
      this.props.renderer.renderRule(this.props.renderer.combineRule(styleRules.windowRule, styleRules.mobileModalWindowRuleBase), {
        vcx: this.props.vcx,
        animationName,
      }) + " ";

    if (this.control instanceof NclMenuView) {
      clsName += this.props.renderer.renderRule(styleRules.mobileModalWindowRule, { vcx: this.props.vcx, height: "90%" }) + " ";
    } else if (this.control instanceof NclOpenDialog) {
      clsName += this.props.renderer.renderRule(styleRules.mobileModalWindowRule, { vcx: this.props.vcx, height: "max-content" }) + " ";
    } else if (!fullScreen) {
      clsName +=
        this.props.renderer.renderRule(styleRules.mobileModalWindowRule, { vcx: this.props.vcx, height: this.control.ComputedMinHeightWithMargin }) + " ";
    } else {
      clsName += this.props.renderer.renderRule(styleRules.mobileLocalModalWindowRule, { vcx: this.props.vcx, animationName: animationName }) + " ";
    }

    return (
      <div
        className={this.props.renderer.renderRule(styleRules.overlayWindowRule, {
          vcx: this.props.vcx,
          zIndex: this.vr.getDepth() * 5,
          overlay: this.props.isOverlayBck,
        })}
      >
        <div
          onAnimationEnd={this.handleAnimationtionEnd}
          className={clsName}
          style={{ touchAction: "pinch-zoom !important" }} // doplněn touch-action, aby bylo možné zoomovat modální okno(např. sestava pro podpis). Pro draggable doplní React automaticky touch-action: none, což bylo nutné přerazit.
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
/**
 * Menu for normal devices
 */
export class ModalMenuWindow extends React.PureComponent<SimpleModalWindowProps, {}> implements ModalWindowBase {
  static displayName = `K2ModalMenuWindow`;
  private control: NclMenuView;
  private vr: ViewRealizer;

  constructor(props: SimpleModalWindowProps) {
    super(props);

    this.control = AcquireControl(this.props.controlUID, this.props.realizerUID, (ctrl) => {
      return ctrl instanceof NclMenuView;
    }) as NclMenuView;

    this.vr = ViewRealizerManager.getViewRealizer(this.props.realizerUID);
  }

  close(): Promise<void> {
    return Promise.resolve();
  }

  show(): Promise<void> {
    return Promise.resolve();
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render(): JSX.Element {
    let size: Size = { height: this.control.ComputedMinHeightWithMargin, width: this.control.VCX.sizeMap((this.control.Content as NclMenu).getMenuWidth()) };
    let position = this.getPosition(size);
    let style: React.CSSProperties = {
      position: "absolute",
      top: position.y,
      left: position.x,
      width: size.width,
    };

    if (position.y === 0) {
      style.height = "98%";
    }

    return (
      <div
        onClick={this.handleOutsideClick}
        onContextMenu={this.handleOutsideClick}
        className={this.props.renderer.renderRule(styleRules.overlayWindowRule, { vcx: this.props.vcx, zIndex: this.vr.getDepth() * 5, overlay: false })}
      >
        <div style={style} className={this.props.renderer.renderRule(styleRules.windowRule, { vcx: this.props.vcx })}>
          {this.props.children}
        </div>
      </div>
    );
  }

  private getPosition(size: Size): ModalPosition {
    let result = Context.getApplication().getMenuPositon();
    let wSize: Size = document.body.getBoundingClientRect();

    if (result.y + size.height > wSize.height) {
      result.y = Math.max(0, result.y - (result.y + size.height - wSize.height));
    }

    if (result.x + size.width > wSize.width) {
      result.x = Math.max(0, result.x - (result.x + size.width - wSize.width));
    }

    return result;
  }

  private handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    this.control.closeRequest();
    if (e.type === "contextmenu") {
      e.preventDefault();
    }
  };
}

interface ModalWindowState {
  isMaximize: boolean;
  windowSize: Size;
  minSize: Size;
  translate?: ModalPosition;
}

/**
 * Window on normal devices. Dragable and resizable
 */
export class ModalWindow extends React.PureComponent<SimpleModalWindowProps, ModalWindowState> implements ModalWindowBase, UFOverAlignListener {
  static displayName = `K2ModalWindow`;
  private root: HTMLElement;
  private window: HTMLElement;
  private anchor: UFOverAlign;
  private anchoredControl: NclControlBase;
  private minContentHeight: number;
  private clientActions: NclCommandItem[];
  private _maximalizeCommand: NclCommandItem;
  private _defaultCommand: NclCommandItem;
  private header: NclHeader;
  private control: NclViewBase<any, any>;
  private vr: ViewRealizer;
  private rectInDock: RectInDock;
  private disabledResizeX: boolean;
  private disabledResizeY: boolean;

  constructor(props: SimpleModalWindowProps) {
    super(props);

    this.control = AcquireControl(this.props.controlUID, this.props.realizerUID, (ctrl) => {
      return ctrl instanceof NclViewBase;
    }) as NclViewBase<any, any>;

    this.vr = ViewRealizerManager.getViewRealizer(this.props.realizerUID);

    this.rectInDock = this.control.getRectInDock();

    this.header = this.control.Header;
    if (this.header && this.header.RQuickButtons) {
      let actions: Array<NclCommandItem> = new Array<NclCommandItem>();
      this.getClientActions().map((item) => {
        actions.push(item);
      });
      this.header.RQuickButtons.map((item) => {
        actions.push(item);
      });

      this.header.RQuickButtons.splice(0, this.header.RQuickButtons.length);

      actions.map((item) => {
        this.header.RQuickButtons.push(item);
      });
    }
    this.state = this.defaultState();
  }

  updateAlignAnchor(): void {
    this.handleDefaultState.call(this);
  }

  close(): Promise<void> {
    return Promise.resolve();
  }

  show(): Promise<void> {
    return Promise.resolve();
  }

  private defaultState(): ModalWindowState {
    return {
      windowSize: undefined,
      isMaximize: false,
      translate: { x: 0, y: 0 },
      minSize: this.computeMinSize(),
    };
  }

  private toggleMaximize(ctrl: ClientNclCommandItem) {
    this.setState((prevState) => {
      ctrl.updateState(prevState.isMaximize ? ({ GlyphId: "wui*maximize" } as CSUpdateCommandItem) : ({ GlyphId: "wui*unmaximize" } as CSUpdateCommandItem));
      return { isMaximize: !prevState.isMaximize };
    });
  }

  private handleDefaultState(ctrl: ClientNclCommandItem) {
    let state = this.defaultState();
    if (this.root) {
      let overlayRect = this.root.getBoundingClientRect();
      if (this.anchor) {
        let anchorRect = this.anchor.getOverRect();

        if (overlayRect && anchorRect) {
          state.translate = { x: anchorRect.left - overlayRect.left, y: anchorRect.top - overlayRect.top };
        }
      } else {
        state.translate = this.computeCenterTranslate(this.getInitialSize());
      }

      let sizeByContent = this.calcSizeByContent();
      //Posun modálního okna v případě přesahu
      if (sizeByContent.width + state.translate.x > overlayRect.width) {
        state.translate.x -= sizeByContent.width + state.translate.x - overlayRect.width;
      }

      if (sizeByContent.height + state.translate.y > overlayRect.height) {
        state.translate.y -= sizeByContent.height + state.translate.y - overlayRect.height + 10;
      }
    }
    if (this.defaultCommand) this.defaultCommand.updateState({ Enabled: false } as UpdateCommandItem);
    if (this.maximalizeCommand) this.maximalizeCommand.updateState({ GlyphId: "wui*maximize" } as CSUpdateCommandItem);
    this.setState(state);
  }

  private getInitialSize(): Size {
    let size = this.calcSizeByContent();
    switch (this.rectInDock.SizeMode) {
      case RectInDockSizeMode.ridsmAnchorWidth:
      case RectInDockSizeMode.ridsmAnchorWidthMinOriginal:
        return this.calcAnchorSize(size);
      case RectInDockSizeMode.ridsmPercent:
        return this.calcPercentSize(size);
      case RectInDockSizeMode.ridsmOriginal:
      default:
        return size;
    }
  }

  private calcAnchorSize(size: Size): Size {
    let result: Size = Object.assign({}, size);
    if (!this.anchor) return result;
    let rect = this.anchor.getOverRect();

    let overMode =
      this.rectInDock.PositionMode == RectInDockPositionMode.ridpmAnchorOverAtLeft ||
      this.rectInDock.PositionMode == RectInDockPositionMode.ridpmAnchorOverAtRight;
    //TODO: chybí implementace zarovnani anchored
    if (rect && overMode) {
      result.width = rect.width;
    }

    if (rect && this.rectInDock.SizeMode === RectInDockSizeMode.ridsmAnchorWidthMinOriginal) {
      if (result.width < rect.width) {
        result.width = rect.width;
      }
    }

    return result;
  }

  private calcSizeByContent(): Size {
    if (this.control) {
      this.disabledResizeY = false;
      this.disabledResizeX = false;

      let whRatio = this.control.Ncl.FrgtData.WHRatio;
      this.minContentHeight = this.control.ComputedMinHeightWithMargin;
      let width: number = this.control.MetaData.Bounds.BandsCount * this.control.VCX.MinRowHeight * whRatio;

      if (this.root) {
        let rect = this.root.getBoundingClientRect();
        if (this.minContentHeight >= rect.height) {
          this.minContentHeight = rect.height * 0.96;
          this.disabledResizeY = true;
        }

        if (width >= rect.width) {
          width = rect.width * 0.98;
          this.disabledResizeX = true;
        }
      }
      return { width: width, height: this.minContentHeight };
    }

    return { height: 0, width: 0 };
  }

  private calcPercentSize(size: Size): Size {
    let result: Size = Object.assign({}, size);
    if (!this.root) return result;
    let rect = this.root.getBoundingClientRect();
    if (this.rectInDock.Width >= 0) {
      result.width = Math.round(rect.width * (this.rectInDock.Width / 100));
    }
    if (this.rectInDock.Height >= 0) {
      result.height = Math.round(rect.height * (this.rectInDock.Height / 100));
    }

    return result;
  }

  private handleResize = (oldSize: Size, newSize: Size, direction: Direction) => {
    if (!this.root || this.isMaximize()) return;
    let translateY: number = 0;
    let translateX: number = 0;
    if (direction === Direction.top || direction === Direction.topLeft || direction === Direction.topRight) {
      translateY = Math.abs(oldSize.height - newSize.height);
      if (oldSize.height < newSize.height) {
        translateY = -translateY;
      }
    }

    if (direction === Direction.left || direction === Direction.bottomLeft || direction === Direction.topLeft) {
      translateX = Math.abs(oldSize.width - newSize.width);
      if (oldSize.width < newSize.width) {
        translateX = -translateX;
      }
    }
    if (this.defaultCommand) this.defaultCommand.updateState({ Enabled: true } as UpdateCommandItem);
    if (translateX != 0 || translateY != 0) {
      this.setState((prevState) => {
        return { windowSize: newSize, translate: { x: prevState.translate.x + translateX, y: translateY + prevState.translate.y } };
      });
    } else {
      this.setState({ windowSize: newSize });
    }
  };

  private handleDragStop = (e: MouseEvent, data: DraggableData) => {
    let { x, y } = data;
    if (!this.root || !this.window || this.isMaximize()) return;
    let rect = this.root.getBoundingClientRect();
    let wrect = this.window.getBoundingClientRect();
    let value = this.props.vcx.ExpanderControl.GetHFHeight() + 2 * this.props.vcx.Data.MarginY;
    if (x < rect.width - value && y < rect.height - value && x + wrect.width > value * 2 && y > 0) {
      if (this.defaultCommand) this.defaultCommand.updateState({ Enabled: true } as UpdateCommandItem);
      this.setState((prevState) => {
        return { translate: { x: x, y: y } };
      });
    }
  };

  private computeMinSize(): Size {
    let result: Size = Object.assign({}, defaultMinSize);
    if (this.minContentHeight) {
      result.height += this.minContentHeight;
    } else {
      if (this.control) {
        this.minContentHeight = this.control.ComputedMinHeightWithMargin;
        if (this.root) {
          this.minContentHeight = Math.min(this.minContentHeight, this.root.getBoundingClientRect().height);
        }
        return this.computeMinSize();
      }
    }

    if (this.rectInDock.SizeMode === RectInDockSizeMode.ridsmAnchorWidthMinOriginal && this.anchor) {
      let rect = this.anchor.getOverRect();
      if (rect && rect.width != 0) {
        result.width = rect.width;
      }
    }

    return result;
  }

  private refCallback = (element: HTMLElement) => {
    if (element) {
      this.root = element;
    }
  };

  private computeCenterTranslate(size: Size): ControlPosition {
    if (!this.root) return { x: 0, y: 0 };
    let rect = this.root.getBoundingClientRect();
    if (size.height < 0 && size.height < 0) return { x: 0, y: 0 };
    let sizeByContent = this.calcSizeByContent();
    let x = Math.round((rect.width - Math.max(size.width, sizeByContent.width)) / 2);
    let y = Math.round((rect.height - Math.max(size.height, sizeByContent.height)) / 2);
    return { x: x, y: y };
  }

  private handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (this.rectInDock.MouseClickClose) {
      if (e.target === this.root) {
        //nevim jestli pro to není lepší způsob, prozatím takto
        this.control.closeRequest();
        if (e.type === "contextmenu") {
          e.preventDefault();
        }
      }
    }
  };

  private isMaximize(): boolean {
    return this.state.isMaximize;
  }

  private getPosition(): ControlPosition {
    if (this.isMaximize()) {
      return { x: 0, y: 0 };
    }
    return { x: this.state.translate.x, y: this.state.translate.y };
  }

  private get maximalizeCommand(): NclCommandItem {
    if (!this._maximalizeCommand && this.header) {
      let vr = ViewRealizerManager.getViewRealizer(this.props.realizerUID);
      if (vr) {
        this._maximalizeCommand = vr.createClientControl((context) => {
          return new ClientNclCommandItem("", "wui*maximize", this, this.toggleMaximize, this.header, true, context);
        }) as NclCommandItem;
      }
    }
    return this._maximalizeCommand;
  }

  private get defaultCommand(): NclCommandItem {
    if (!this._defaultCommand && this.header) {
      let vr = ViewRealizerManager.getViewRealizer(this.props.realizerUID);
      if (vr) {
        this._defaultCommand = vr.createClientControl((context) => {
          return new ClientNclCommandItem("", "wui*defaultsize", this, this.handleDefaultState, this.header, false, context);
        }) as NclCommandItem;
      }
    }
    return this._defaultCommand;
  }

  componentDidMount() {
    if (this.rectInDock.AnchorControlUID) {
      let vr: ViewRealizer = this.control instanceof NclFloaterView ? this.vr : this.vr.getPriorRealizer();
      if (!vr) {
        vr = this.vr;
      }
      if (vr) {
        let ctrl = vr.getControlByUID(this.rectInDock.AnchorControlUID);
        if (ctrl != null && ctrl.Listener && ctrl.Listener) {
          let listener: UFOverAlign = ctrl.Listener;
          if (listener.getOverRect) {
            this.anchor = listener;
            if (this.anchor.registerOverAlignListener) this.anchor.registerOverAlignListener(this);
          } else {
            Log.warn("Not exist component fot attach element" + this.rectInDock.AnchorControlUID + ".");
          }
        } else {
          Log.warn("Not exist attach element" + this.rectInDock.AnchorControlUID + ".");
        }
      } else {
        Log.error("Not exist realizer for find attach element" + this.rectInDock.AnchorControlUID + ".", null);
      }
    }

    if (this.vr) {
      if (!this.anchoredControl) {
        this.anchoredControl = this.vr.getAnchoredControl();
      }
    }

    this.handleDefaultState.call(this);
  }

  private getClientActions(): Array<NclCommandItem> {
    if (!this.clientActions) {
      this.clientActions = new Array<NclCommandItem>();
      if (this.defaultCommand) this.clientActions.push(this.defaultCommand);
      if (this.maximalizeCommand) this.clientActions.push(this.maximalizeCommand);
    }
    return this.clientActions;
  }

  componentWillUnmount() {
    if (this.header && this.header.RQuickButtons) {
      this.getClientActions().map((item) => {
        this.header.RQuickButtons.splice(
          this.header.RQuickButtons.findIndex((value) => {
            return value == item;
          }),
          1
        );
        item.willUnMount(false);
      });
      this.header = null;
    }

    if (this.anchor && this.anchor.unRegisterOverAlignListener) {
      this.anchor.unRegisterOverAlignListener(this);
    }

    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    let wSize = { height: 0, width: 0 };
    let style: React.CSSProperties = { touchAction: "pinch-zoom !important" }; // doplněn touch-action, aby bylo možné zoomovat modální okno(např. sestava pro podpis). Pro draggable doplní React automaticky touch-action: none, což bylo nutné přerazit.
    if (this.root) {
      if (this.state.isMaximize === true) {
        style.height = "98%";
        style.width = "99%";
        style.transform = `translate(0.5%, 1%)`;
      } else {
        wSize = this.state.windowSize;
        if (wSize) {
          style.height = wSize.height + "px";
          style.width = wSize.width + "px";
        } else {
          let initS = this.getInitialSize();
          let contentS = this.calcSizeByContent();
          if (initS.height === contentS.height) {
            if (!this.disabledResizeY) {
              style.minHeight = initS.height + "px";
              style.height = "max-content";
            } else {
              style.height = initS.height + "px";
            }
          } else {
            if (this.rectInDock.SizeMode === RectInDockSizeMode.ridsmPercent && this.rectInDock.Height > 0) {
              style.height = this.rectInDock.Height + "%";
            } else {
              style.height = initS.height + "px";
            }
          }
          if (initS.width === contentS.width) {
            style.width = initS.width + "px";
          } else {
            if (this.rectInDock.SizeMode === RectInDockSizeMode.ridsmPercent && this.rectInDock.Width > 0) {
              style.width = this.rectInDock.Width + "%";
            } else {
              style.width = initS.width + "px";
            }
          }
        }
      }
    }
    let content = (
      <div className={this.props.renderer.renderRule(styleRules.modalWindowRule, { vcx: this.props.vcx, zIndex: this.vr.getDepth() * 10 })}>
        <div className={this.props.renderer.renderRule(styleRules.modalWindowInterRule, { vcx: this.props.vcx })}>
          <div
            className={
              this.props.renderer.renderRule(marginRule, { vcx: this.props.vcx, mY: 1, mX: 1 }) +
              " " +
              this.props.renderer.renderRule(styleRules.modalWindowContentRule, { vcx: this.props.vcx })
            }
          >
            {this.props.children}
          </div>
        </div>
      </div>
    );

    let denyDirection: ResizableDirection = 0;
    if (this.disabledResizeX === true || this.state.isMaximize) {
      denyDirection = ResizableDirection.left | ResizableDirection.right;
    }

    if (this.disabledResizeY === true || this.state.isMaximize) {
      denyDirection |= ResizableDirection.up | ResizableDirection.down;
    }
    content = (
      <Draggable handle={".handle_" + this.props.controlUID} disabled={this.isMaximize()} onStop={this.handleDragStop} position={this.getPosition()}>
        <div
          ref={(ref) => {
            this.window = ref;
          }}
          style={style}
          className={this.props.renderer.renderRule(styleRules.windowRule, { vcx: this.control.VCX })}
        >
          <K2Resizable
            style={style}
            renderer={this.props.renderer}
            denyDirection={denyDirection}
            vcx={this.props.vcx}
            initSize={wSize}
            bounds="__parent"
            onResize={this.handleResize}
            minSize={this.state.minSize}
          >
            {content}
          </K2Resizable>
        </div>
      </Draggable>
    );

    return (
      <div
        ref={this.refCallback}
        onClick={this.handleOutsideClick}
        onContextMenu={this.handleOutsideClick}
        className={
          "__parent" +
          " " +
          this.props.renderer.renderRule(styleRules.overlayWindowRule, {
            vcx: this.props.vcx,
            zIndex: this.vr.getDepth() * 5,
            overlay: this.props.isOverlayBck === true,
          })
        }
      >
        {content}
      </div>
    );
  }
}

//#region Resizalble

interface ResizableProps extends WithVCXProps, React.HTMLAttributes<HTMLDivElement> {
  initSize: Size;
  bounds: string;
  onResize: (oldSize: Size, newSize: Size, direction: Direction) => void;
  minSize: Size;
  denyDirection?: ResizableDirection;
}

export enum ResizableDirection {
  up = 1,
  down = 2,
  left = 4,
  right = 8,
}

interface ResizableState {
  isResizing: boolean;
  direction?: Direction;
}

enum Direction {
  left,
  right,
  top,
  bottom,
  topLeft,
  topRight,
  bottomLeft,
  bottomRight,
}

class K2Resizable extends React.PureComponent<ResizableProps, ResizableState> {
  private elementRef: HTMLDivElement;
  private bounds: HTMLElement;

  constructor(props: ResizableProps) {
    super(props);
    this.state = { isResizing: false };
    this.bounds = null;
  }

  private refCallback = (element: HTMLDivElement) => {
    if (element) {
      this.elementRef = element;
    }
  };

  private handleMouseTDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    this.setState({ isResizing: true, direction: Direction.top });
    event.preventDefault();
  };

  private handleMouseTLDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    this.setState({ isResizing: true, direction: Direction.topLeft });
    event.preventDefault();
  };

  private handleMouseTRDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    this.setState({ isResizing: true, direction: Direction.topRight });
    event.preventDefault();
  };

  private handleMouseBRDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    this.setState({ isResizing: true, direction: Direction.bottomRight });
    event.preventDefault();
  };

  private handleMouseBLDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    this.setState({ isResizing: true, direction: Direction.bottomLeft });
    event.preventDefault();
  };

  private handleMouseBDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    this.setState({ isResizing: true, direction: Direction.bottom });
    event.preventDefault();
  };

  private handleMouseLDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    this.setState({ isResizing: true, direction: Direction.left });
    event.preventDefault();
  };

  private handleMouseRDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    this.setState({ isResizing: true, direction: Direction.right });
    event.preventDefault();
  };

  private handleMouseUp = (event: MouseEvent) => {
    if (!this.state.isResizing) return;
    this.setState({ isResizing: false });
    event.preventDefault();
  };

  private handleMouseMove = (event: MouseEvent) => {
    if (!this.state.isResizing) return;
    this.computeNewSize(event, this.state.direction);
    event.preventDefault();
  };

  private computeNewSize(event: MouseEvent, direction: Direction) {
    let clientRect = this.elementRef.getBoundingClientRect();

    if (event.pageX === 0 || event.pageY === 0) return;
    let { pageX, pageY } = event;
    let result: Size = Object.assign({}, this.props.initSize);
    let value: number;
    if (direction === Direction.left || direction === Direction.bottomLeft || direction === Direction.topLeft) {
      value = clientRect.right - pageX;
      if (!this.isValidValue(value, clientRect.height, true)) return;
      result.width = value;
    }

    if (direction === Direction.right || direction === Direction.bottomRight || direction === Direction.topRight) {
      value = pageX - clientRect.left;
      if (!this.isValidValue(value, clientRect.width, true)) return;
      result.width = value;
    }

    if (direction === Direction.top || direction === Direction.topLeft || direction === Direction.topRight) {
      value = clientRect.bottom - pageY;
      if (!this.isValidValue(value, clientRect.height, false)) return;
      result.height = value;
    }

    if (direction === Direction.bottom || direction === Direction.bottomRight || direction === Direction.bottomLeft) {
      value = pageY - clientRect.top;
      if (!this.isValidValue(value, clientRect.height, false)) return;
      result.height = value;
    }

    this.props.onResize.call(this, clientRect, result, direction);
  }

  private isValidValue(value: number, prevValue: number, isWidth: boolean): boolean {
    if (value <= 0) return;
    if (this.bounds != null) {
      let rect = this.bounds.getBoundingClientRect();
      if (isWidth) return rect.width - rect.width * 0.01 > value && (value > this.props.minSize.width || value - prevValue > 0);
      else return rect.height - rect.height * 0.01 > value && (value > this.props.minSize.height || value - prevValue > 0);
    }

    return true;
  }

  private canResize(direction: Direction): boolean {
    if (!this.props.denyDirection) return true;

    if ((this.props.denyDirection & ResizableDirection.down) == ResizableDirection.down) {
      if (direction === Direction.bottom || direction === Direction.bottomLeft || direction === Direction.bottomRight) return false;
    }

    if ((this.props.denyDirection & ResizableDirection.up) == ResizableDirection.up) {
      if (direction === Direction.top || direction === Direction.topLeft || direction === Direction.topRight) return false;
    }

    if ((this.props.denyDirection & ResizableDirection.left) == ResizableDirection.left) {
      if (direction === Direction.left || direction === Direction.topLeft || direction === Direction.bottomLeft) return false;
    }

    if ((this.props.denyDirection & ResizableDirection.right) == ResizableDirection.right) {
      if (direction === Direction.right || direction === Direction.topRight || direction === Direction.bottomRight) return false;
    }

    return true;
  }

  render() {
    return (
      <div ref={this.refCallback} className={this.props.renderer.renderRule(styleRules.modalResizerRule, { vcx: this.props.vcx })} style={this.props.style}>
        {this.canResize(Direction.topLeft) && (
          <span className={this.props.renderer.renderRule(styleRules.modalResizerTLRule, { vcx: this.props.vcx })} onMouseDown={this.handleMouseTLDown} />
        )}
        {this.canResize(Direction.top) && (
          <span className={this.props.renderer.renderRule(styleRules.modalResizerTRule, { vcx: this.props.vcx })} onMouseDown={this.handleMouseTDown} />
        )}
        {this.canResize(Direction.topRight) && (
          <span className={this.props.renderer.renderRule(styleRules.modalResizerTRRule, { vcx: this.props.vcx })} onMouseDown={this.handleMouseTRDown} />
        )}
        {this.canResize(Direction.left) && (
          <span className={this.props.renderer.renderRule(styleRules.modalResizerLRule, { vcx: this.props.vcx })} onMouseDown={this.handleMouseLDown} />
        )}
        {this.props.children}
        {this.canResize(Direction.right) && (
          <span className={this.props.renderer.renderRule(styleRules.modalResizerRRule, { vcx: this.props.vcx })} onMouseDown={this.handleMouseRDown} />
        )}
        {this.canResize(Direction.bottomLeft) && (
          <span className={this.props.renderer.renderRule(styleRules.modalResizerBLRule, { vcx: this.props.vcx })} onMouseDown={this.handleMouseBLDown} />
        )}
        {this.canResize(Direction.bottom) && (
          <span className={this.props.renderer.renderRule(styleRules.modalResizerBRule, { vcx: this.props.vcx })} onMouseDown={this.handleMouseBDown} />
        )}
        {this.canResize(Direction.bottomRight) && (
          <span className={this.props.renderer.renderRule(styleRules.modalResizerBRRule, { vcx: this.props.vcx })} onMouseDown={this.handleMouseBRDown} />
        )}
      </div>
    );
  }

  componentDidMount() {
    if (this.props.bounds) {
      let parent: HTMLElement = this.elementRef;
      this.bounds = null;
      while (parent !== null) {
        if (parent.classList.contains(this.props.bounds)) {
          this.bounds = parent;
          break;
        }
        parent = parent.parentElement;
      }

      if (this.bounds != null) {
        this.bounds.addEventListener("mouseup", this.handleMouseUp);
        this.bounds.addEventListener("mousemove", this.handleMouseMove);
      }
    }
  }

  componenvtWillUnmount() {
    if (this.bounds != null) {
      this.bounds.removeEventListener("mouseup", this.handleMouseUp);
      this.bounds.removeEventListener("mousemove", this.handleMouseMove);
    }
  }
}

//#endregion Resizalble
