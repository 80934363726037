import * as React from "react";

import { UpdateControl, UpdateCheckBox, DisplayMode } from "../common/communication.base";
import { withContext, K2ComponentState, WithContextPlacementProps, StyleHelper, AcquireControl } from "./k2hoc";
import { NclCheckBox } from "../common/components.ncl";
import { K2Img } from "./K2Image";
import { K2RuleWithVCXProps } from "./k2StyleRenderer";
import { K2Text } from "./K2TruncateText";
import { ElementHtmlAttributes, getAttributes } from "../common/common";

type CheckBoxProps = K2RuleWithVCXProps & {
  size: number;
};

interface CheckBoxState extends K2ComponentState<UpdateCheckBox> {
  Focused: boolean;
}

const cssRule = {
  checkBox: {
    margin: (props: CheckBoxProps) => ({
      background: props.inEditMode
        ? props.vcx.getColor(props.vcx.Data.ColorMap.DataChangeColorBck)
        : props.vcx.getColor(props.vcx.Data.ColorMap.DataBrowseColorBck),
      height: props.size + 2 * props.vcx.InputControl.getInputFrameWidth() + "px",
      width: props.size + 2 * props.vcx.InputControl.getInputFrameWidth() + "px",
      marginRight: "0.5em",
      minWidth: props.vcx.LabelControl.getHeight(1),
      borderStyle: "solid" as "solid",
      borderWidth: props.vcx.InputControl.getInputFrameWidth(),
      borderColor: props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorBck1),
      "> div": {
        padding: 0,
      },
      ":hover": {
        outline: props.vcx.InputControl.getInputFrameWidth() + "px solid " + props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorBck1) + " !important",
      },
    }),
    parent: (props: K2RuleWithVCXProps) => ({
      display: "flex",
      alignItems: "center",
      userSelect: "none" as "none",
    }),
    label: (props: K2RuleWithVCXProps) => ({
      borderStyle: "dotted" as "dotted",
      borderWidth: props.vcx.InputControl.getInputFrameWidth(),
      borderColor: "transparent",
      outlineColor: "transparent",
    }),
  },
  focusedCheckBox: (props: K2RuleWithVCXProps) => ({
    outline: props.vcx.InputControl.getInputFrameWidth() + "px solid " + props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorBck1) + " !important",
  }),
  focusedLabel: (props: K2RuleWithVCXProps) => ({
    borderColor: props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorBck1) + " !important",
    outlineColor: props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorBck1) + " !important",
  }),
};

class _CheckBox extends React.PureComponent<WithContextPlacementProps, CheckBoxState> {
  static displayName = `K2CheckBox`;
  private control: NclCheckBox;
  private element: HTMLDivElement;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclCheckBox;
    }) as NclCheckBox;
    this.state = { data: this.control.init(this) as UpdateCheckBox, vcxVersion: -1, Focused: false };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateCheckBox>) => {
      return { data: state as UpdateCheckBox };
    });

    this.forceUpdate();
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  setAsActive(isActive: boolean) {
    if (this.element && isActive) {
      this.element.focus();
    }
  }

  private handleContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    this.control.contextMenu();
    e.preventDefault();
  };

  render() {
    let addAttributes: ElementHtmlAttributes = getAttributes(this.state.data);

    let checkBoxClass: string = "";
    let labelClass: string = "";
    if (this.state.Focused) {
      checkBoxClass = this.props.renderer.renderRule(this.props.renderer.combineRules(cssRule.checkBox.margin, cssRule.focusedCheckBox), {
        vcx: this.control.VCX,
        size: this.control.VCX.LabelControl.getHeight(this.control.Size),
        inEditMode: this.control.InEditMode,
      });
      labelClass = this.props.renderer.renderRule(this.props.renderer.combineRules(cssRule.checkBox.label, cssRule.focusedLabel), { vcx: this.control.VCX });
    } else {
      checkBoxClass = this.props.renderer.renderRule(cssRule.checkBox.margin, {
        vcx: this.control.VCX,
        size: this.control.VCX.LabelControl.getHeight(this.control.Size),
        inEditMode: this.control.InEditMode,
      });
      labelClass = this.props.renderer.renderRule(cssRule.checkBox.label, { vcx: this.control.VCX });
    }

    return (
      <div
        ref={(ref) => {
          this.element = ref;
        }}
        style={StyleHelper(this.control, this.props.style)}
        data-k2-test-id={this.control.Ncl.Name}
        className={this.props.renderer.renderRule(cssRule.checkBox.parent, { vcx: this.control.VCX })}
        {...addAttributes}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onKeyDown={this.handleKeyDown}
        onContextMenu={this.handleContextMenu}
      >
        <div className={checkBoxClass} onClick={this.clickEvent}>
          {this.state.data.Checked && (
            <K2Img
              glyphId="wui*checkbox"
              vcx={this.control.VCX}
              strokeColor={this.control.VCX.getColor(this.control.VCX.Data.ColorMap.BaseColorBck1)}
              stretch={true}
            />
          )}
        </div>
        <div onClick={this.clickEvent}>
          <K2Text
            vcx={this.control.VCX}
            displayMode={DisplayMode.fpdmDecorated}
            className={labelClass + " " + this.props.renderer.renderFontRule(this.control.VCX.LabelControl.Font, this.control.VCX)}
          >
            {this.state.data.Title}
          </K2Text>
        </div>
      </div>
    );
  }

  private handleFocus = (e: React.FocusEvent<HTMLDivElement>) => {
    this.control.setActiveControlRequested();
    this.setState({ Focused: true });
    e.stopPropagation();
  };

  private handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    this.setState({ Focused: false });
  };

  private handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!this.state.data.ReadOnly) {
      if (e.keyCode == 13) {
        this.control.check();
        e.stopPropagation();
      }
      if (e.keyCode == 32) {
        this.control.check();
        e.stopPropagation();
      }
    }
  };

  private clickEvent = () => {
    if (!this.state.data.ReadOnly) this.control.check();
  };
}

export const K2CheckBox = withContext(_CheckBox);
