import * as React from "react";

import { ElementHtmlAttributes, getAttributes } from "../common/common";
import { UpdateCommandItem, UpdateControl, TUFActionDisplayStyle, UFUpdateControl } from "../common/communication.base";
import { NclCommandItem, NclBreaker, NclImage } from "../common/components.ncl";
import { SubMenuIndicator } from "./K2Action";
import { K2ComponentState, withContext, WithContextPlacementProps, StyleHelper, AcquireControl } from "./k2hoc";
import { K2Img } from "./K2Image";
import { K2RuleWithVCXProps } from "./k2StyleRenderer";
import { K2TruncateText } from "./K2TruncateText";

const breakerRules = {
  baseStyle: (props: K2RuleWithVCXProps) => ({
    flex: "1 0 100%",
    small: {
      //display: "none"
      flex: "0 1 auto",
    },
  }),
};

class _ControlBreaker extends React.PureComponent<WithContextPlacementProps, K2ComponentState<UFUpdateControl>> {
  // UpdateCommandItem
  private control: NclBreaker;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => ctrl instanceof NclBreaker) as NclBreaker;
    this.state = { data: this.control.init(this) as UFUpdateControl, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UFUpdateControl>) => {
      return { data: state as UFUpdateControl };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  render() {
    return (
      <div
        style={StyleHelper(this.control, this.props.style)}
        className={this.props.renderer.renderRule(breakerRules.baseStyle, { vcx: this.control.VCX })}
      ></div>
    );
  }
}

export const K2ControlBreaker = withContext(_ControlBreaker);

type K2BtnRuleProps = K2RuleWithVCXProps & {
  checked: boolean;
};

type K2StyleRuleProps = K2RuleWithVCXProps & {
  displayStyle: TUFActionDisplayStyle;
  heightIcon?: number;
};
const actionRules = {
  baseStyle: (props: K2StyleRuleProps) => ({
    height: props.displayStyle == TUFActionDisplayStyle.ufadsButtonTile ? "100%" : "auto",
    paddingBottom: props.vcx.sizeMap(1) + "px",
    writingMode: "horizontal-tb" as "horizontal-tb",
    small: {
      justifyContent: "center" as "center",
      paddingBottom: "0",
      height: props.heightIcon > 0 ? props.heightIcon + "px" : 1.5 * props.vcx.LabelControl.getHeight(1) + "px",
    },
  }),
  contentStyle: (props: K2StyleRuleProps) => ({
    alignItems: "center",
    flexDirection: props.displayStyle == TUFActionDisplayStyle.ufadsButtonLine ? ("row" as "row") : ("column" as "column"),
    small: {
      flexDirection: "column" as "column",
    },
  }),
  buttonStyle: (props: K2BtnRuleProps) => ({
    padding: props.vcx.sizeMap(1) + "px",
    width: "100%",
    background: props.checked ? props.vcx.getColor(props.vcx.Data.ColorMap.ContentFrame1) : "transparent",
    color: "inherit",
    border: props.checked ? props.vcx.sizeMap(2) + "px solid " + props.vcx.getColor(props.vcx.Data.ColorMap.ContentFrame3) : "none",
    position: "relative" as const,
  }),
  captionStyle: (props: K2RuleWithVCXProps) => ({
    alignSelf: "center",
    small: {
      display: "none",
    },
  }),
  placeholder: (props: K2StyleRuleProps) => ({
    display: "flex",
    ">div": {
      width: props.vcx.LabelControl.getHeight(1) * (props.displayStyle == TUFActionDisplayStyle.ufadsButtonTile ? 3 : 1.2) + "px",
      height:
        props.heightIcon > 0
          ? props.heightIcon + "px"
          : props.vcx.LabelControl.getHeight(1) * (props.displayStyle == TUFActionDisplayStyle.ufadsButtonTile ? 1.7 : 1.2) + "px",
      small: {
        width: 1.5 * props.vcx.LabelControl.getHeight(1) + "px",
        height: props.heightIcon > 0 ? props.heightIcon + "px" : 1.5 * props.vcx.LabelControl.getHeight(1) + "px",
      },
    },
  }),
};

interface ActionProps extends WithContextPlacementProps {
  color?: string;
  captionLinesCount?: number;
  heightIcon?: number;
}

class _Action extends React.PureComponent<ActionProps, K2ComponentState<UpdateCommandItem>> {
  private control: NclCommandItem;

  constructor(props: ActionProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclCommandItem;
    }) as NclCommandItem;
    this.state = { data: this.control.init(this) as UpdateCommandItem, vcxVersion: -1 };
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateCommandItem>) => {
      return { data: state as UpdateCommandItem };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  render() {
    let clr: string;
    if (this.state.data.Enabled !== false) {
      clr = this.props.color ? this.props.color : null;
    } else {
      clr = "rgba(0, 0, 0, 0.2)";
    }

    let addAttributes: ElementHtmlAttributes = getAttributes(this.state.data);

    let cls = "";
    if (this.props.className) {
      cls = " " + this.props.className;
    }

    return (
      <div
        style={StyleHelper(this.control, Object.assign({ width: "auto" }, this.props.style))}
        className={
          this.props.renderer.renderRule(actionRules.baseStyle, {
            vcx: this.control.VCX,
            displayStyle: this.control.Ncl.FrgtData.DisplayStyle,
            heightIcon: this.props.heightIcon,
          }) + cls
        }
      >
        <button
          data-k2-test-id={this.control.MetaData.Name}
          className={this.props.renderer.renderRule(actionRules.buttonStyle, { vcx: this.control.VCX, checked: this.state.data.Checked })}
          onClick={this.handleClick}
          {...addAttributes}
        >
          <div
            className={this.props.renderer.renderRule(actionRules.contentStyle, {
              vcx: this.control.VCX,
              displayStyle: this.control.Ncl.FrgtData.DisplayStyle,
            })}
          >
            <div
              className={this.props.renderer.renderRule(actionRules.placeholder, {
                vcx: this.control.VCX,
                displayStyle: this.control.Ncl.FrgtData.DisplayStyle,
              })}
            >
              {this.state.data.GlyphId && (
                <K2Img
                  key={this.control.MetaData.ControlUID + "_icon"}
                  glyphId={this.state.data.GlyphId}
                  vcx={this.control.VCX}
                  strokeColor={clr}
                  height={NclImage.isSVG(this.state.data.GlyphId) ? null : 48}
                />
              )}
            </div>
            {this.control.Ncl.FrgtData.DisplayStyle != TUFActionDisplayStyle.ufadsButtonSmall && this.state.data.Title && (
              <K2TruncateText
                style={{ color: this.state.data.Enabled ? null : "rgba(0, 0, 0, 0.6)" }}
                key={this.control.MetaData.ControlUID + "_caption"}
                className={
                  this.props.renderer.renderFontRule(this.control.VCX.LabelControl.Font, this.control.VCX) +
                  " " +
                  this.props.renderer.renderRule(actionRules.captionStyle, { vcx: this.control.VCX })
                }
              >
                {this.state.data.Title}
              </K2TruncateText>
            )}
          </div>
          {this.state.data.SubMenuIndicatorVisible && <SubMenuIndicator vcx={this.control.VCX} />}
        </button>
      </div>
    );
  }

  private handleClick = () => {
    this.control.executeCommand();
  };
}

export const K2RibbonAction = withContext(_Action);
