import * as React from "react";
import { WithContextPlacementProps, K2ComponentState, withContext, StyleHelper, AcquireControl } from "./k2hoc";
import { K2Text } from "./K2TruncateText";
import { NclDataLabel } from "../common/components.ncl";
import {
  UpdateDataLabel,
  UpdateControl,
  Orientation,
  HorizontalAlignment,
  VerticalAlignment,
  DisplayMode,
  TUFDisplayValueAs,
} from "../common/communication.base";
import { K2RuleWithVCXProps } from "./k2StyleRenderer";
import { K2Img } from "./K2Image";

const lblCSS = {
  alignItems: {
    bottom: (props: K2RuleWithVCXProps) => ({
      alignItems: "flex-end",
    }),
    center: (props: K2RuleWithVCXProps) => ({
      alignItems: "center",
    }),
    top: (props: K2RuleWithVCXProps) => ({
      alignItems: "flex-start",
    }),
  },
  justifyContent: {
    center: (props: K2RuleWithVCXProps) => ({
      justifyContent: "space-around",
    }),
    default: (props: K2RuleWithVCXProps) => ({
      justifyContent: "space-between",
    }),
  },
  textAlignment: {
    center: (props: K2RuleWithVCXProps) => ({
      textAlign: "center" as "center",
    }),
    right: (props: K2RuleWithVCXProps) => ({
      textAlign: "right" as "right",
    }),
    left: (props: K2RuleWithVCXProps) => ({
      textAlign: "left" as "left",
    }),
  },
  parent: {
    withPrefix: (props: K2RuleWithVCXProps) => ({
      //            minWidth: 0,
      flex: "1",
      width: "100%",
    }),
    withoutPrefix: (props: K2RuleWithVCXProps) => ({
      width: "100%",
    }),
    contentRule: (props: K2RuleWithVCXProps) => ({
      flex: "1",
    }),
  },
  children: {
    suffix: (props: K2RuleWithVCXProps) => ({
      flex: "0 1 auto",
      textAlign: "right" as "right",
      color: props.vcx.getColor(props.vcx.Data.ColorMap.ContentDecorateColorFrg),
    }),
    prefix: (props: K2RuleWithVCXProps) => ({
      color: props.vcx.getColor(props.vcx.Data.ColorMap.ContentDecorateColorFrg),
    }),
    image: (props: K2RuleWithVCXProps) => ({
      minWidth: "20px",
      width: "auto",
      height: "20px",
    }),
    mainText: {
      withPrefix: (props: K2RuleWithVCXProps) => ({
        flexGrow: 1,
        width: "100%",
      }),
      withoutPrefix: (props: K2RuleWithVCXProps) => ({
        //flexGrow: 0,
        width: "100%",
        flex: 1,
      }),
      contentRule: (props: K2RuleWithVCXProps) => ({
        flex: 1,
      }),
    },
    title: {
      orientationVertical: (props: K2RuleWithVCXProps) => ({
        width: "100%",
        marginRight: props.vcx.sizeMap(4) + "px",
      }),
      orientationHorizontal: (props: K2RuleWithVCXProps) => ({
        marginRight: props.vcx.sizeMap(4) + "px",
      }),
      contentRule: (props: K2RuleWithVCXProps) => ({
        flex: "0 1 auto",
      }),
    },
  },
  visibilityDiv: {
    contentRule: (props: K2RuleWithVCXProps) => ({
      //            minWidth: 0,
      position: "relative" as "relative",
    }),
    orientation: {
      horizontal: (props: K2RuleWithVCXProps) => ({
        flexDirection: "row" as "row",
      }),
      vertical: (props: K2RuleWithVCXProps) => ({
        flexDirection: "column" as "column",
      }),
    },
    textDecoration: {
      underlined: (props: K2RuleWithVCXProps) => ({
        textDecoration: "underline",
      }),
    },
    withPrefix: (props: K2RuleWithVCXProps) => ({
      width: "100%",
    }),
  },
};

class _DataLabel extends React.PureComponent<WithContextPlacementProps, K2ComponentState<UpdateDataLabel>> {
  static displayName = "K2DataLabel";
  private control: NclDataLabel;

  //Zastupuje funkci neimplementovanych casti klienta: Bottomline
  unimplemented: boolean = false;

  /**
   * Konstruktor K2Label
   * @param props
   */
  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclDataLabel;
    }) as NclDataLabel;
    this.state = { data: this.control.init(this) as UpdateDataLabel, vcxVersion: -1 };
  }

  getStyling(): React.CSSProperties {
    let style: React.CSSProperties = {};

    if (this.control.Ncl.FrgtData.Orientation === Orientation.foHorizontal) {
      style.flexDirection = "row";
    } else style.flexDirection = "column";

    //Podtržení textu pomocí rámečku
    if (this.control.Ncl.FrgtData.BottomLine) {
      //          style.textDecoration = "underline";
      style.borderBottom = "1px " + this.control.VCX.getColor(this.control.VCX.Data.ColorMap.ContentFrameMin) + " solid";
    }

    if (this.control.Ncl.FrgtData.VerticalAlignment === VerticalAlignment.fvaCenter) {
      style.alignItems = "center";
    }

    return Object.assign({}, this.props.style, style);
  }

  private getStyleClasses(styleEnum?: StyleEnum): string {
    let neededStyling = "";
    let vcx = { vcx: this.control.VCX };

    if (styleEnum) {
      switch (styleEnum) {
        case StyleEnum.LabelParent:
          neededStyling += this.props.renderer.renderRule(lblCSS.parent.contentRule, vcx) + " ";
          if (this.state.data.PrefixText !== "") {
            neededStyling += this.props.renderer.renderRule(lblCSS.parent.withPrefix, vcx) + " ";
            switch (this.control.Ncl.FrgtData.VerticalAlignment) {
              case VerticalAlignment.fvaBottom:
                neededStyling += this.props.renderer.renderRule(lblCSS.alignItems.bottom, vcx) + " ";
                break;
              case VerticalAlignment.fvaCenter:
                neededStyling += this.props.renderer.renderRule(lblCSS.alignItems.center, vcx) + " ";
                break;
              case VerticalAlignment.fvaTop:
                neededStyling += this.props.renderer.renderRule(lblCSS.alignItems.top, vcx) + " ";
                break;
            }

            if (this.control.Ncl.FrgtData.VerticalAlignment === VerticalAlignment.fvaCenter) {
              neededStyling += this.props.renderer.renderRule(lblCSS.justifyContent.center, vcx) + " ";
            } else neededStyling += this.props.renderer.renderRule(lblCSS.justifyContent.default, vcx) + " ";
          } else neededStyling += this.props.renderer.renderRule(lblCSS.parent.withoutPrefix, vcx) + " ";
          break;
        case StyleEnum.Image:
          neededStyling += this.props.renderer.renderRule(lblCSS.children.image, vcx) + " ";
          break;
        case StyleEnum.MainText:
          neededStyling += this.props.renderer.renderRule(lblCSS.children.mainText.contentRule, vcx) + " ";
          if (this.state.data.PrefixText !== "") {
            switch (this.control.Ncl.FrgtData.HorizontalAlignment) {
              case HorizontalAlignment.fhaCenter:
                neededStyling +=
                  this.props.renderer.renderRule(this.props.renderer.combineRules(lblCSS.children.mainText.withPrefix, lblCSS.textAlignment.center), vcx) + " ";
                break;
              case HorizontalAlignment.fhaRight:
                neededStyling +=
                  this.props.renderer.renderRule(this.props.renderer.combineRules(lblCSS.children.mainText.withPrefix, lblCSS.textAlignment.right), vcx) + " ";
                break;
              case HorizontalAlignment.fhaLeft:
                neededStyling +=
                  this.props.renderer.renderRule(this.props.renderer.combineRules(lblCSS.children.mainText.withPrefix, lblCSS.textAlignment.left), vcx) + " ";
                break;
            }
          } else {
            switch (this.control.Ncl.FrgtData.HorizontalAlignment) {
              case HorizontalAlignment.fhaCenter:
                neededStyling +=
                  this.props.renderer.renderRule(this.props.renderer.combineRules(lblCSS.children.mainText.withoutPrefix, lblCSS.textAlignment.center), vcx) +
                  " ";
                break;
              case HorizontalAlignment.fhaRight:
                neededStyling +=
                  this.props.renderer.renderRule(this.props.renderer.combineRules(lblCSS.children.mainText.withoutPrefix, lblCSS.textAlignment.right), vcx) +
                  " ";
                break;
              case HorizontalAlignment.fhaLeft:
                neededStyling +=
                  this.props.renderer.renderRule(this.props.renderer.combineRules(lblCSS.children.mainText.withoutPrefix, lblCSS.textAlignment.left), vcx) +
                  " ";
                break;
            }
          }
          break;
        case StyleEnum.Suffix:
          neededStyling += this.props.renderer.renderRule(lblCSS.children.suffix, vcx) + " ";
          break;
        case StyleEnum.Prefix:
          neededStyling += this.props.renderer.renderRule(lblCSS.children.prefix, vcx) + " ";
          break;
        case StyleEnum.Title:
          neededStyling += this.props.renderer.renderRule(lblCSS.children.title.contentRule, vcx) + " ";

          switch (this.control.Ncl.FrgtData.VerticalAlignment) {
            case VerticalAlignment.fvaBottom:
              neededStyling += this.props.renderer.renderRule(lblCSS.alignItems.bottom, vcx) + " ";
              break;
            case VerticalAlignment.fvaCenter:
              neededStyling += this.props.renderer.renderRule(lblCSS.alignItems.center, vcx) + " ";
              break;
            case VerticalAlignment.fvaTop:
              neededStyling += this.props.renderer.renderRule(lblCSS.alignItems.top, vcx) + " ";
              break;
          }
          if (this.control.Ncl.FrgtData.Orientation === Orientation.foVertical) {
            neededStyling += " " + this.props.renderer.renderRule(lblCSS.children.title.orientationVertical, vcx) + " ";
          } else neededStyling += " " + this.props.renderer.renderRule(lblCSS.children.title.orientationHorizontal, vcx) + " ";
          break;
        case StyleEnum.K2VisibilityDiv:
          if (this.state.data.PrefixText !== "") {
            neededStyling += this.props.renderer.renderRule(lblCSS.visibilityDiv.withPrefix, vcx) + " ";
          }
          if (this.control.Ncl.FrgtData.VerticalAlignment === VerticalAlignment.fvaCenter) {
            neededStyling += this.props.renderer.renderRule(lblCSS.alignItems.center, vcx) + " ";
          }
          if (this.control.Ncl.FrgtData.Orientation === Orientation.foHorizontal) {
            neededStyling += this.props.renderer.renderRule(lblCSS.visibilityDiv.orientation.horizontal, vcx) + " ";
          } else {
            neededStyling += this.props.renderer.renderRule(lblCSS.visibilityDiv.orientation.vertical, vcx) + " ";
          }
          neededStyling += this.props.renderer.renderRule(lblCSS.visibilityDiv.contentRule, vcx) + " ";
          if (this.unimplemented) {
            neededStyling += this.props.renderer.renderRule(lblCSS.visibilityDiv.textDecoration.underlined, vcx) + " ";
          }
          break;
      }
    }

    return neededStyling;
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateDataLabel>) => {
      return { data: state as UpdateDataLabel };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    switch (this.control.Ncl.DisplayAs) {
      case TUFDisplayValueAs.dvaImage:
        return this.renderImageLabel();
      default:
        return this.renderTextLabel();
    }
  }

  /**
   * Vrací label s textem
   */
  renderTextLabel() {
    return (
      <div
        className={this.props.renderer.renderFontRule(this.control.VCX.LabelControl.Font, this.control.VCX)}
        style={StyleHelper(this.control, Object.assign({}, this.getStyling(), this.props.style))}
        data-k2-test-id={this.control.MetaData.Name}
        onContextMenu={this.handleContextMenu}
      >
        <K2Text displayMode={this.control.Ncl.FrgtData.TitleDisplayMode} vcx={this.control.VCX} className={this.getStyleClasses(StyleEnum.Title)}>
          {this.state.data.Title}
        </K2Text>
        <div className={this.getStyleClasses(StyleEnum.LabelParent)}>
          <K2Text className={this.getStyleClasses(StyleEnum.Prefix)} vcx={this.control.VCX} displayMode={this.control.Ncl.FrgtData.ValueDisplayMode}>
            {this.state.data.PrefixText}
          </K2Text>
          <K2Text className={this.getStyleClasses(StyleEnum.MainText)} vcx={this.control.VCX} displayMode={this.control.Ncl.FrgtData.ValueDisplayMode}>
            {this.state.data.Text}
          </K2Text>
          <K2Text className={this.getStyleClasses(StyleEnum.Suffix)} vcx={this.control.VCX} displayMode={this.control.Ncl.FrgtData.ValueDisplayMode}>
            {this.state.data.SuffixText}
          </K2Text>
        </div>
      </div>

      /**
       *
       */
    );
  }

  /**
   * Vrací label s obrázkem
   * TODO: CSS
   */
  renderImageLabel() {
    let margin: string;
    if (this.control.Ncl.FrgtData.Orientation === Orientation.foHorizontal) {
      if (this.control.Ncl.FrgtData.HorizontalAlignment === HorizontalAlignment.fhaLeft) {
        margin = "0 auto 0 0";
      } else if (this.control.Ncl.FrgtData.HorizontalAlignment === HorizontalAlignment.fhaCenter) {
        margin = "0 auto";
      } else {
        margin = "0 0 0 auto";
      }
    }

    return (
      <div
        style={StyleHelper(this.control, this.props.style)}
        className={this.props.renderer.renderFontRule(this.control.VCX.LabelControl.Font, this.control.VCX) + this.getStyleClasses(StyleEnum.K2VisibilityDiv)}
        onContextMenu={this.handleContextMenu}
      >
        {this.control.Ncl.FrgtData.TitleDisplayMode !== DisplayMode.fpdmNone && (
          <K2Text displayMode={this.control.Ncl.FrgtData.TitleDisplayMode} vcx={this.control.VCX} className={this.getStyleClasses(StyleEnum.Title)}>
            {this.state.data.Title}
          </K2Text>
        )}
        <div style={{ margin: margin }}>
          {
            <K2Img
              glyphId={this.state.data.GlyphId}
              vcx={this.control.VCX}
              height={this.control.ComputedMinHeight}
              width={this.control.Ncl.FrgtData.Orientation === Orientation.foHorizontal ? this.control.ComputedMinHeight : 0}
            />
          }
        </div>
      </div>
    );
  }

  private handleContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    this.control.contextMenu();
    e.preventDefault();
  };
}

enum StyleEnum {
  Prefix = 1,
  Suffix,
  K2VisibilityDiv,
  Image,
  MainText,
  Title,
  LabelParent,
}

export const K2DataLabel = withContext(_DataLabel);
