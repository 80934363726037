import * as React from "react";

import { Align, FrgtGridLinesStyle, FrgtPanelBaseData, UFUpdateControl, UpdateControl } from "../common/communication.base";
import { ContainerListener, NclBaseContainer, NclExpander, ScrollOption, UFNclControlBase, NclPanel } from "../common/components.ncl";
import { VisualContext } from "../common/visualContext";
import { GenerateControl } from "./K2GenerateControl";
import { K2ComponentState, withContext, WithContextPlacementProps, WithContextProps, StyleHelper, AcquireControl } from "./k2hoc";
import { K2RuleWithVCXProps } from "./k2StyleRenderer";
import { object } from "prop-types";

interface RestBandFillingProps {
  restBandCount: number;
  verticalLines: FrgtGridLinesStyle;
  vcx: VisualContext;
}

interface BandProps extends WithContextProps {
  verticalLines: FrgtGridLinesStyle;
  horizontalLines: FrgtGridLinesStyle;
  vcx: VisualContext;
  rowNdx: number;
  partsCount: number;
  rest: number;
  allChildIsFill: boolean;
  allowFlexShrink: boolean;
  computeMaxBandHeight: number;
}

interface BandState {
  height?: number;
}

class RestBandFilling extends React.PureComponent<RestBandFillingProps, {}> {
  static displayName = `RestBandFilling`;
  render() {
    if (this.props.verticalLines === FrgtGridLinesStyle.glsLast || this.props.verticalLines === FrgtGridLinesStyle.glsAll) {
      let returnJsx = ``;
      let size = this.props.vcx.cssSizeMap(1);
      let borderColor = this.props.vcx.getColor(this.props.vcx.Data.ColorMap.ContentFrame1);
      for (let i = 0; i < this.props.restBandCount; i++) {
        if (
          (this.props.verticalLines === FrgtGridLinesStyle.glsLast && this.props.restBandCount - 1 === i) ||
          this.props.verticalLines === FrgtGridLinesStyle.glsAll
        ) {
          returnJsx += `<div style="flex: 1; border-left: ${size} solid ${borderColor};"></div>`;
        } else {
          returnJsx += `<div style="flex: 1;"></div>`;
        }
      }
      return <div dangerouslySetInnerHTML={{ __html: returnJsx }} style={{ flex: this.props.restBandCount, flexDirection: "row" }}></div>;
    } else {
      return <div style={{ flex: this.props.restBandCount, padding: this.props.restBandCount > 0 ? `${this.props.vcx.sizeMap(3)}px` : "" }} />;
    }
  }
}

class Band extends React.PureComponent<BandProps, BandState> {
  static displayName = `Band`;

  constructor(props: BandProps) {
    super(props);
    this.state = {
      height: props.computeMaxBandHeight,
    };
  }

  setBandHeight(heightValue: number): void {
    this.setState({ height: heightValue });
  }

  private getRules(allChildFill: boolean, allowFlexShrink: boolean, columnNdx: number = -1): React.CSSProperties {
    let size: string = this.props.vcx.cssSizeMap(1);
    let rules: React.CSSProperties = {};

    rules = {
      flexDirection: "row" as "row",
      flexGrow: allChildFill ? 1 : 0,
      flexShrink: allChildFill && allowFlexShrink ? 1 : 0,
      flexBasis: "auto",
    } as React.CSSProperties;

    if (this.props.horizontalLines === FrgtGridLinesStyle.glsFirst && this.props.rowNdx === 0) {
      rules.borderBottom = size + " solid " + this.props.vcx.getColor(this.props.vcx.Data.ColorMap.ContentFrame1);
    } else if (this.props.horizontalLines === FrgtGridLinesStyle.glsLast && this.props.rowNdx === this.props.partsCount - 1) {
      rules.borderTop = size + " solid " + this.props.vcx.getColor(this.props.vcx.Data.ColorMap.ContentFrame1);
    } else if (this.props.horizontalLines === FrgtGridLinesStyle.glsAll && this.props.rowNdx !== this.props.partsCount - 1) {
      rules.borderBottom = size + " solid " + this.props.vcx.getColor(this.props.vcx.Data.ColorMap.ContentFrame1);
    }

    if (this.state.height > 0) {
      //nastavení height bandu dle největšího otevřeného expanderu
      rules.height = this.state.height + "px";
    }

    if (this.state.height === 0) {
      rules.flexGrow = 0;
    }

    return rules;
  }

  render() {
    return (
      <div style={this.getRules(this.props.allChildIsFill, this.props.allowFlexShrink)}>
        {this.props.children}
        <RestBandFilling vcx={this.props.vcx} verticalLines={this.props.verticalLines} restBandCount={this.props.rest} />
      </div>
    );
  }
}

interface BandOptions {
  fill: boolean;
  rest: number;
  shrink: boolean;
  height?: number;
}

class SimplyFlowPanel extends React.Component<WithContextPlacementProps> {
  static displayName = `K2SimplyFlowPanel`;
  private control: NclBaseContainer;
  private bands: Array<Band>;
  private bandsOptions: Array<BandOptions>;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return true;
    }) as NclBaseContainer;
    this.state = { data: {} as UFUpdateControl, maxHeight: 0 };
    this.bands = [];
    this.bandsOptions = [];
  }

  componentWillUnmount() {
    this.control = null;
  }

  render() {
    if (this.bandsOptions.length == 0) {
      this.control.Parts.map((controls) => {
        this.bandsOptions.push({
          fill: this.allChildIsFill(controls),
          rest: this.getRest(controls),
          shrink: this.allowFlexShrink(controls),
          height: this.allowFlexShrink(controls, true) ? this.computeMaxHeight(controls) : undefined,
        });
      });
    }

    return this.control.Parts.map((controls, index, map) => {
      let options: BandOptions = this.bandsOptions[index];
      return (
        <Band
          key={this.control.MetaData.ControlUID + "-" + index}
          verticalLines={this.control.VerticalLines}
          horizontalLines={this.control.HorizontalLines}
          vcx={this.control.VCX}
          rowNdx={index}
          partsCount={map.length}
          rest={options.rest}
          allChildIsFill={options.fill}
          allowFlexShrink={options.shrink}
          computeMaxBandHeight={options.height}
          ref={(ref) => {
            if (this.bands.indexOf(ref) < 0) {
              this.bands.push(ref);
            }
          }}
        >
          {controls.map((control, i) => {
            return GenerateControl(control, this.getRules(i));
          })}
        </Band>
      );
    });
  }

  public reCalculateHeight(byControlUID: string) {
    // voláno při collapse expanderu
    let mh: number;
    this.bandsOptions.map((opt, index) => {
      if (opt.height != undefined) {
        if (index < this.control.Parts.length) {
          let band = this.control.Parts[index];
          band.map((item) => {
            if (item.MetaData.ControlUID === byControlUID) {
              mh = this.computeMaxHeight(band);
              if (opt.height !== mh) {
                opt.height = mh;
                this.bands[index].setBandHeight(mh);
              }
            }
          });
        }
      }
    });
  }

  // nastavení bandu dle největšího určujícího expaneru
  public computeMaxHeight(controls: UFNclControlBase[]): number {
    let result: number = -1;
    let collapsedCount = 0;
    if (controls.length > 1) {
      let panelFrgt: any;
      controls.map((control) => {
        if ((control instanceof NclExpander && control.isCollapsed()) || !control.State.Visible) collapsedCount++;
        if (control.State.Visible) {
          panelFrgt = control.MetaData.FrgtData;
          if (
            panelFrgt.Scroll === undefined ||
            NclPanel.getScrollOption(panelFrgt) !== ScrollOption.Enabled ||
            control.MetaData.Bounds.Align !== Align.Client
          ) {
            result = Math.max(control.ComputedMinHeightWithMargin, result);
          }
        }
      });
    }
    if (collapsedCount === controls.length) {
      return 0;
    }

    return result;
  }

  private getRules(columnNdx: number = -1): React.CSSProperties {
    let size: string = this.control.VCX.cssSizeMap(1);
    let rules: React.CSSProperties = {};

    if (this.control.VerticalLines === FrgtGridLinesStyle.glsFirst && columnNdx === 0) {
      rules.borderRight = size + " solid " + this.control.VCX.getColor(this.control.VCX.Data.ColorMap.ContentFrame1);
    } else if (this.control.VerticalLines === FrgtGridLinesStyle.glsLast && this.control.Parts.length - 1 === columnNdx) {
      rules.borderLeft = size + " solid " + this.control.VCX.getColor(this.control.VCX.Data.ColorMap.ContentFrame1);
    } else if (this.control.VerticalLines === FrgtGridLinesStyle.glsAll && columnNdx !== 0) {
      rules.borderLeft = size + " solid " + this.control.VCX.getColor(this.control.VCX.Data.ColorMap.ContentFrame1);
    }

    return rules;
  }

  private getRest(controls: UFNclControlBase[]): number {
    let usedPlaceUnit: number = 0; // pouzite misto
    for (var i = 0; i < controls.length; i++) {
      usedPlaceUnit += controls[i].MetaData.Bounds.BandsCount;
    }
    let result: number = this.control.MetaData.Bounds.InteriorBandsCount - usedPlaceUnit;
    if (result < 0) return 0;
    return result;
  }

  private allChildIsFill(controls: UFNclControlBase[]): boolean {
    for (var i = 0; i < controls.length; i++) {
      if (controls[i].MetaData.Bounds.Align !== Align.Client) return false;
    }

    return true;
  }

  private allowFlexShrink(controls: UFNclControlBase[], onlyWithScroll: boolean = false): boolean {
    let result: boolean = false;
    let panelFrgt: any;
    controls.map((control) => {
      panelFrgt = control.MetaData.FrgtData;
      if (panelFrgt.Scroll !== undefined) {
        if (NclPanel.getScrollOption(panelFrgt) == ScrollOption.Enabled) {
          result = false;
          return;
        }
      } else {
        if (!onlyWithScroll) {
          result = true;
          return;
        }
      }
    });
    return result;
  }
}

type FlowRule = K2RuleWithVCXProps & {
  hasParentPreview: boolean;
};

export const flowRule = (props: FlowRule) => ({
  background: props.hasParentPreview
    ? "transparent"
    : props.inEditMode
    ? props.vcx.getColor(props.vcx.Data.ColorMap.ContentChangeColorBck)
    : props.vcx.getColor(props.vcx.Data.ColorMap.ContentColorBck1),
});

type FlowPanelState = K2ComponentState<UFUpdateControl> & {
  maxHeight: number;
};

class _FlowPanel extends React.PureComponent<WithContextPlacementProps, FlowPanelState> implements ContainerListener {
  static displayName = `K2FlowPanel`;
  private control: NclBaseContainer;
  private simplyFlow: SimplyFlowPanel;
  private baseCls: string;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      let ctn = ctrl as NclBaseContainer;
      if (ctn && ctn.Children) return true;
      return false;
    }) as NclBaseContainer;
    this.state = { data: this.control.init(this) as UFUpdateControl, maxHeight: 0, vcxVersion: -1 };
    this.baseCls = this.props.renderer.renderRule(flowRule, {
      vcx: this.control.VCX,
      inEditMode: this.control.InEditMode,
      hasParentPreview: this.control.isInPreview(),
    });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UFUpdateControl>) => {
      return { data: state as UFUpdateControl };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  reCalculateHeight(byControlUID: string) {
    if (this.simplyFlow) {
      this.simplyFlow.reCalculateHeight(byControlUID);
    }
  }

  render() {
    let frgtData = this.control.MetaData.FrgtData as FrgtPanelBaseData;
    let isScroll = frgtData && frgtData.Scroll;
    let clsName: string = this.props.className && this.props.className != undefined ? this.props.className + " " : "";
    clsName += ` ${this.baseCls}`;
    return (
      <div
        style={StyleHelper(this.control, Object.assign({}, { flexDirection: "column", overflow: isScroll ? "auto" : "hidden" }, this.props.style))}
        className={clsName}
      >
        <SimplyFlowPanel ref={(ref) => (this.simplyFlow = ref)} controlUID={this.control.MetaData.ControlUID} vrUID={this.control.getRealizerUID()} />
      </div>
    );
  }
}

export const K2FlowPanel = withContext(_FlowPanel);
export const K2SimplyFlowPanel = withContext(SimplyFlowPanel);
