import React = require("react");
import { K2RuleWithVCXProps } from "./k2StyleRenderer";
import { UpdateControl, UFUpdateControl } from "../common/communication.base";
import { K2ComponentState, WithContextPlacementProps, withContext, StyleHelper, AcquireControl } from "./k2hoc";
import { NclLocatorPanel } from "../common/components.ncl";
import { GenerateChildrenOfControl, GenerateControl } from "./K2GenerateControl";
import { K2Action } from "./K2Action";
import { Color } from "csstype";

interface LocatorPanelProps extends WithContextPlacementProps {
  btnColor?: string;
}

class _LocatorPanel extends React.PureComponent<LocatorPanelProps, K2ComponentState<UFUpdateControl>> {
  static displayName = `K2LocatorPanel`;
  private control: NclLocatorPanel;

  constructor(props: LocatorPanelProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclLocatorPanel;
    }) as NclLocatorPanel;
    this.state = { data: this.control.init(this) as UFUpdateControl, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UFUpdateControl>) => {
      return { data: state as UFUpdateControl };
    });

    this.forceUpdate();
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    return (
      <div style={StyleHelper(this.control, this.props.style)} id={this.control.Ncl.Name}>
        {this.control.SwitchBtn && (
          <K2Action
            controlUID={this.control.SwitchBtn.MetaData.ControlUID}
            color={this.props.btnColor}
            vrUID={this.control.getRealizerUID()}
            style={{ width: "auto", height: `${this.control.VCX.InputControl.getInputHeight(1, true, false)}px`, alignSelf: "center" }}
          />
        )}
        {this.control.Children.reverse().map((item, index) => {
          return GenerateControl(item, { width: "100%" });
        })}
      </div>
    );
  }
}
export const K2LocatorPanel = withContext(_LocatorPanel);
