import * as React from "react";

import { getAttributes } from "../common/common";
import { cJSonCommandExecuteLookup, UpdateControl, UpdateInput, UpdateFormattableInput, TDataTextFormat, Align } from "../common/communication.base";
import { NclInput, NclFormattableInput } from "../common/components.ncl";
import { K2ComponentState, withContext, WithContextPlacementProps, StyleHelper, AcquireControl } from "./k2hoc";
import { K2RuleWithVCXProps } from "./k2StyleRenderer";
import { inputFocusedLabel, WrapLabel, inputTag } from "./K2Input";
import { setRequestedActiveControl } from "./app";

const textAreaRule = (props: K2RuleWithVCXProps) => ({
  flex: 1,
  userSelect: "auto" as "auto",
});

const htmlRule = (props: K2RuleWithVCXProps) => ({
  height: "100%",
  flex: 1,
  background: props.vcx.ColorMap.getColor(props.vcx.Data.ColorMap.DataBrowseColorBck),
  borderWidth: props.vcx.InputControl.getInputFrameWidth() + "px",
  borderStyle: "solid" as "solid",
  borderColor: props.vcx.getColor(props.vcx.Data.ColorMap.ContentFrame1),
  zoom: props.vcx.Zoom + "%",
  whiteSpace: "pre-wrap" as "pre-wrap",
});

interface FormattableInputState extends K2ComponentState<UpdateFormattableInput> {
  Value: string;
  Focused: boolean;
}

class _FormattableInput extends React.PureComponent<WithContextPlacementProps, FormattableInputState> {
  static displayName = `K2FormattableInput`;
  private element: HTMLElement;
  private control: NclFormattableInput;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclFormattableInput;
    }) as NclFormattableInput;
    this.state = { data: this.control.init(this) as UpdateFormattableInput, vcxVersion: -1, Focused: false, Value: "" };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateFormattableInput>) => {
      return { data: state as UpdateFormattableInput, Value: (state as UpdateFormattableInput).Text };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  setAsActive(isActive: boolean) {
    if (this.element && isActive) {
      this.element.focus();
    }
  }

  private getTextareaTag(): JSX.Element {
    let textAreaClass: string = this.props.renderer.renderFontRule(this.control.VCX.InputControl.InputFont, this.control.VCX) + " ";
    textAreaClass += this.props.renderer.renderRule(this.props.renderer.combineRule(textAreaRule, inputTag), {
      vcx: this.control.VCX,
      inEditMode: this.control.InEditMode,
    });
    return (
      <textarea
        className={textAreaClass}
        onKeyDown={this.handleKeyDown}
        onFocus={this.handleFocus}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        ref={(ref) => {
          this.element = ref;
        }}
        {...getAttributes(this.state.data)}
        value={this.state.Value}
        spellCheck={false}
        rows={this.control.Size}
      />
    );
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  private handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (
      e.key === "Enter" ||
      e.key === "Delete" ||
      e.key === "ArrowUp" ||
      e.key === "ArrowDown" ||
      e.key === "ArrowLeft" ||
      e.key === "ArrowRight" ||
      e.key === "z" ||
      e.key === "y"
    ) {
      e.stopPropagation();
    }
  };

  private handleFocus = (e: React.FocusEvent<any>) => {
    this.control.setActiveControlRequested();
    this.setState({ Focused: true });
    e.stopPropagation();
  };

  private handleBlur = (e: React.FocusEvent<any>) => {
    this.setState({ Focused: false });
    setRequestedActiveControl(e, this.control.getRealizerUID());
    this.changeData(this.state.Value, true);
  };

  private handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let value = e.target.value;
    if (this.state.Value != value) {
      this.setState({ Value: value });
      this.changeData(value, false);
    }
  };

  private changeData(data: string, accept: boolean) {
    this.control.change(data, accept);
  }

  render() {
    let content: JSX.Element = null;
    switch (this.state.data.TextFormat) {
      case TDataTextFormat.dtfText:
        content = this.getTextareaTag();
        break;
      case TDataTextFormat.dtfHTML:
        content = this.getHTML();
        break;
    }

    let labelClass: string = "";
    if (this.state.Focused && !this.state.data.ReadOnly) {
      labelClass = this.props.renderer.renderRule(this.props.renderer.combineRules(WrapLabel, inputFocusedLabel), {
        vcx: this.control.VCX,
        lines: this.control.Size,
        inEditMode: this.control.InEditMode,
      });
    } else {
      labelClass = this.props.renderer.renderRule(WrapLabel, { vcx: this.control.VCX, lines: this.control.Size, inEditMode: this.control.InEditMode });
    }

    return (
      <div style={StyleHelper(this.control, this.props.style)} className={labelClass}>
        {content}
      </div>
    );
  }
  getHTML(): JSX.Element {
    return (
      <div className={this.props.renderer.renderRule(htmlRule, { vcx: this.control.VCX })}>
        <iframe
          style={{ border: "none", width: "100%", height: this.control.MetaData.Bounds.Align === Align.Client ? null : this.control.ComputedMinHeight + "px" }}
          srcDoc={this.state.data.Text}
          sandbox=""
        />
      </div>
    );
  }
}

export const K2FormattableInput = withContext(_FormattableInput);
