import React = require("react");

import { Context } from "../appcontext";
import { CSNclMenuItemBaseMetadata, IconPosition, Orientation, UpdateControl, UpdateHeadered, UpdateMenuContainer } from "../common/communication.base";
import { NclMenuContainer, NclMenuDivider, NclMenuGroup, NclMenuItem, NclMenuItemBase, NclMenuView, NclOpenDialog } from "../common/components.ncl";
import { ActionSeparator } from "./K2Action";
import { AcquireControl, K2ComponentState, StyleHelper, withContext, WithContextPlacementProps } from "./k2hoc";
import { K2Img } from "./K2Image";
import { K2RuleWithVCXProps, matchSmallMediaBreakpoint } from "./k2StyleRenderer";
import { K2TruncateText } from "./K2TruncateText";

const menuRule = (props: K2RuleWithVCXProps) => ({
  background: props.vcx.ColorMap.getColor(props.vcx.Data.ColorMap.BaseColorFrg1),
  flexDirection: "column" as "column",
});

const headerRule = (props: K2RuleWithVCXProps) => ({
  border: props.vcx.sizeMap(1) + "px solid " + props.vcx.getColor(props.vcx.Data.ColorMap.ContentFrame1),
});

const styleRules = {
  buttonRule: (props: K2RuleWithVCXProps) => ({
    border: "none",
    width: "100%",
    verticalAlign: "middle",
    background: "none",
    textDecoration: "none",
    userSelect: "none" as "none",
    color: props.vcx.ColorMap.getColor(props.vcx.Data.ColorMap.ContentNormalColorFrg),
    padding: "0px " + props.vcx.sizeMap(5) + "px",
    ":disabled": {
      color: props.vcx.ColorMap.getColor(props.vcx.Data.ColorMap.ContentFrame3),
    },
    ":hover": {
      backgroundColor: "#91C9F7",
    },
    ":after": {
      all: "unset !important",
    },
  }),

  buttonIconSpace: (props: K2RuleWithVCXProps) => ({
    width: 20,
  }),

  buttonCaption: (props: K2RuleWithVCXProps) => ({
    textAlign: "left" as "left",
    alignSelf: "center",
    marginLeft: props.vcx.sizeMap(10) + "px",
  }),

  submenuContainer: (props: K2RuleWithVCXProps) => ({
    boxShadow: "5px 5px 20px 0px rgba(0,0,0,0.6)",
    backgroundColor: props.vcx.ColorMap.getColor(props.vcx.Data.ColorMap.DataBrowseColorBck),
  }),
  submenuInsideContainer: (props: K2RuleWithVCXProps) => ({
    backgroundColor: props.vcx.ColorMap.getColor(props.vcx.Data.ColorMap.DataBrowseColorBck),
  }),
  menuRule: (props: K2RuleWithVCXProps) => ({
    width: "100%",
    height: "auto",
    flexDirection: "column" as "column",
    overflow: "visible auto",
    background: "#f2f2f2",
    padding: props.vcx.sizeMap(2) + "px",
  }),
};

interface MenuState extends K2ComponentState<UpdateMenuContainer> {
  submenuPosition: IconPosition;
  parentHorizontalPosition: number;
}

interface MenuProps extends WithContextPlacementProps {
  depth: number;
  subMenuDisplayInside: boolean;
  parentMenuWidth?: number;
  parentMenuXposition?: number;
}

class _Menu extends React.PureComponent<MenuProps, MenuState> {
  static displayName = `K2Menu`;
  private control: NclMenuItemBase<CSNclMenuItemBaseMetadata, UpdateControl>;

  private menuContainerWidth: number;
  private submenuContainerWidth: number;

  private openMenuTimer: any;
  private closeMenuTimer: any;

  private closeMenuRunning: boolean;
  private openMenuRunning: boolean;

  constructor(props: MenuProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclMenuItemBase;
    }) as NclMenuItemBase<CSNclMenuItemBaseMetadata, UpdateControl>;
    this.state = { data: this.control.init(this) as UpdateMenuContainer, submenuPosition: IconPosition.ipRight, parentHorizontalPosition: 0, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateMenuContainer>) => {
      return { data: state as UpdateMenuContainer };
    });

    this.forceUpdate();
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  componentDidUpdate() {
    if (this.props.parentMenuWidth && this.menuContainerWidth && this.props.parentMenuXposition) {
      if (this.props.subMenuDisplayInside) {
        this.setState({ submenuPosition: IconPosition.ipCenter });
      } else if (
        this.props.parentMenuXposition + this.control.VCX.sizeMap(this.props.parentMenuWidth) + this.control.VCX.sizeMap(this.menuContainerWidth) >
        window.innerWidth
      ) {
        this.setState({ submenuPosition: IconPosition.ipLeft });
      } else {
        this.setState({ submenuPosition: IconPosition.ipRight });
      }
    }
  }

  private handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (this.control instanceof NclMenuItemBase && this.state.data.Enabled !== false) {
      if (this.control instanceof NclMenuGroup) e.stopPropagation();

      (this.control as NclMenuContainer<any, any>).execute(null, null, !this.props.subMenuDisplayInside);
    }
    e.stopPropagation();
  };

  private handleMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
    if (this.control instanceof NclMenuContainer && !this.props.subMenuDisplayInside) {
      if (e.type === "mouseleave") {
        if (this.openMenuRunning) {
          clearTimeout(this.openMenuTimer);
        } else {
          this.closeMenuTimer = setTimeout(() => {
            if (this.control instanceof NclMenuContainer) {
              this.control.execute(true);
            }
            this.closeMenuRunning = false;
          }, 400);
          this.closeMenuRunning = true;
        }
      } else {
        if (this.closeMenuRunning) {
          clearTimeout(this.closeMenuTimer);
        } else {
          this.openMenuTimer = setTimeout(() => {
            (this.control as NclMenuContainer<any, any>).execute(false, true);
            this.openMenuRunning = false;
          }, 400);
          this.openMenuRunning = true;
        }
      }
    }
  };

  private refCallback = (element: HTMLDivElement) => {
    if (element) {
      setTimeout(() => {
        this.setState({ parentHorizontalPosition: Math.round(element.getBoundingClientRect().left) });
      }, 300); //timeout z důvodu čekání na celé vykreslení, aby vrácené rozměry byly korektní.
    }
  };

  private getMenuItemIcon(): JSX.Element {
    let background: string = "none";
    let glyphId: string = this.control.Ncl.GlyphId;

    if (this.control.Ncl.ExplicitCheck) {
      background = this.control.VCX.getColor(this.control.VCX.Data.ColorMap.GridRulerColorBck);
      if (!this.control.Ncl.GlyphId) {
        glyphId = "wui*ok";
      }
    }

    if (!glyphId) {
      return <div className={this.props.renderer.renderRule(styleRules.buttonIconSpace, { vcx: this.control.VCX })} />;
    } else {
      return (
        <K2Img
          glyphId={glyphId}
          vcx={this.control.VCX}
          strokeColor={
            this.state.data.Enabled === false
              ? this.control.VCX.getColor(this.control.VCX.Data.ColorMap.ContentFrame3)
              : this.control.VCX.getColor(this.control.VCX.Data.ColorMap.ContentNormalColorFrg)
          }
          style={{ textAlign: "center", alignSelf: "center", width: "20px", backgroundColor: background }}
          height={20}
        />
      );
    }
  }

  private getMenuHotKey(): JSX.Element {
    if (this.props.subMenuDisplayInside) {
      return;
    }

    if (this.control instanceof NclMenuItem && this.control.Ncl.HotKey) {
      return <p style={{ textAlign: "right", alignSelf: "center", marginRight: this.control.VCX.sizeMap(10) + "px" }}>{this.control.Ncl.HotKey}</p>;
    }
  }

  render() {
    let menu: JSX.Element = null;
    let subMenu: JSX.Element = null;
    let isRoot = !(this.control.Parent instanceof NclMenuItemBase);

    if (!isRoot) {
      //polozka menu se vykresli vzdy kdyz se nejedna o root
      if (this.control instanceof NclMenuDivider) {
        menu = (
          <ActionSeparator
            orientation={Orientation.foHorizontal}
            width={"100%"}
            height={this.control.VCX.sizeMap(1)}
            color={this.control.VCX.getColor(this.control.VCX.Data.ColorMap.ContentFrame1)}
          />
        );
      } else {
        let menuItemStyle: React.CSSProperties = {};
        let menuIconStyle: React.CSSProperties = { flex: "1", textAlign: "right", alignSelf: "center", maxWidth: "24px" };
        if (this.state.data.isOpen) {
          menuItemStyle = { backgroundColor: this.control.VCX.getColor(this.control.VCX.Data.ColorMap.ContentFrame1) };
          menuIconStyle = { ...menuIconStyle, transform: "rotate(180deg)" };
        }

        menu = (
          <button
            style={menuItemStyle}
            onClick={this.handleClick}
            className={
              this.props.renderer.renderRule(styleRules.buttonRule, { vcx: this.control.VCX }) +
              " " +
              this.props.renderer.renderFontRule(this.control.VCX.LabelControl.Font, this.control.VCX)
            }
            disabled={this.state.data.Enabled === false}
          >
            <div style={{ height: this.control.ComputedMinHeight + "px" }}>
              {this.getMenuItemIcon()}
              <K2TruncateText className={this.props.renderer.renderRule(styleRules.buttonCaption, { vcx: this.control.VCX })}>
                {this.control.Ncl.Caption}
              </K2TruncateText>
              <div style={{ flex: "1 1 auto" }}></div>
              {this.getMenuHotKey()}
              {this.control instanceof NclMenuContainer ? (
                <div style={{ flex: "0 0 auto" }}>
                  <K2Img
                    glyphId={this.props.subMenuDisplayInside ? "wui*roofdown" : "wui*roofright"}
                    vcx={this.control.VCX}
                    style={menuIconStyle}
                    height={this.control.VCX.LabelControl.getHeight(1)}
                  />
                </div>
              ) : null}
            </div>
          </button>
        );
      }
    }

    if (this.control instanceof NclMenuContainer && this.state.data.isOpen) {
      // container se vygeneruje pokud ma nastaven stav isOpen
      this.menuContainerWidth = this.control.getMenuWidth();

      subMenu = (
        <div ref={this.refCallback} className={this.props.renderer.renderRule(styleRules.menuRule, { vcx: this.control.VCX })}>
          {this.control.Actions.map((item) => {
            return (
              <K2Menu
                controlUID={item.MetaData.ControlUID}
                vrUID={item.getRealizerUID()}
                key={item.MetaData.ControlUID}
                depth={this.props.depth + 1}
                subMenuDisplayInside={this.props.subMenuDisplayInside}
                parentMenuWidth={this.menuContainerWidth}
                parentMenuXposition={this.state.parentHorizontalPosition}
              />
            );
          })}
        </div>
      );
      if (isRoot) {
        // root je automaticky rozbaleny kontejner
        menu = subMenu;
        subMenu = null;
      }
    }

    if (subMenu && menu) {
      // pokud ma submenu i menu tak se vygeneruji vedle sebe
      let marginLeft: number = 0;
      let marginTop: number = 0;
      let subMenuUnit: string = "px";
      if (this.control instanceof NclMenuContainer) {
        this.submenuContainerWidth = this.control.getMenuWidth();
      }

      // horizontální posunutí submenu, pokud se nevejde na obrazovku
      if (!this.props.subMenuDisplayInside) {
        marginLeft = this.state.submenuPosition === IconPosition.ipLeft ? this.submenuContainerWidth * -1 : this.props.parentMenuWidth - 3;
      } else {
        marginLeft = this.props.depth * 10;
        subMenuUnit = "%";
      }

      // Vertikální posunutí submenu, pokud se nevejde na obrazovku
      if (this.control instanceof NclMenuContainer && !this.props.subMenuDisplayInside) {
        let totalMenuHeight: number = this.control.getMenuHeight() + 10;
        marginTop =
          Context.getApplication().getMenuPositon().y + totalMenuHeight > window.innerHeight
            ? Context.getApplication().getMenuPositon().y + totalMenuHeight - window.innerHeight + 20
            : 0;
      }

      let subMenuStyle: React.CSSProperties = {
        position: this.props.subMenuDisplayInside ? ("relative" as "relative") : ("fixed" as "fixed"),
        marginLeft: this.control.VCX.sizeMap(marginLeft) + subMenuUnit,
        marginTop: marginTop * -1,
        width: (this.props.subMenuDisplayInside ? "auto" : this.control.VCX.sizeMap(this.submenuContainerWidth)) + subMenuUnit,
        zIndex: this.props.subMenuDisplayInside ? 1 : this.props.depth * 2,
      };

      menu = (
        <div style={{ width: "100%", flexDirection: "column" as "column" }}>
          {menu}
          {
            <div
              style={subMenuStyle}
              className={this.props.renderer.renderRule(this.props.subMenuDisplayInside ? styleRules.submenuInsideContainer : styleRules.submenuContainer, {
                vcx: this.control.VCX,
              })}
            >
              {subMenu}
            </div>
          }
        </div>
      );
    }

    let css: React.CSSProperties = StyleHelper(this.control, Object.assign({}, this.props.style, { minHeight: "auto" }));
    css = { ...css, width: "100%", flexShrink: 0 };
    let className: string = this.props.renderer.renderRule(
      () => {
        return css;
      },
      { vcx: this.control.VCX }
    );

    if (this.state.data.Enabled !== false) {
      return (
        <div onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseEnter} className={className}>
          {menu}
        </div>
      );
    } else {
      return <div className={className}>{menu}</div>;
    }
  }
}
export const K2Menu = withContext(_Menu);
