import * as React from "react";

import { Context } from "../appcontext";
import { ElementHtmlAttributes, getAttributes } from "../common/common";
import {
  CSNclTabControlMetadata,
  GlyphId,
  IconPosition,
  Orientation,
  UFUpdateControl,
  UpdateControl,
  UpdateDockControl,
  UpdateTabControl,
  UpdatePageControl,
} from "../common/communication.base";
import { NclBaseTabControl, NclDockControl, NclMultiContent, NclPage, NclVRTabControl } from "../common/components.ncl";
import { ViewRealizer, ViewRealizerManager } from "../viewrealizer";
import { GenerateControl } from "./K2GenerateControl";
import { AcquireControl, K2ComponentState, StyleHelper, withContext, WithContextPlacementProps } from "./k2hoc";
import { K2Img } from "./K2Image";
import { K2RuleWithVCXProps, matchSmallMediaBreakpoint } from "./k2StyleRenderer";
import { K2TruncateText } from "./K2TruncateText";
import { ViewRealizerReact } from "./ViewRealizerReact";
import { VCXColorMap } from "../common/visualContext";
import { K2RibbonAction } from "./K2RibbonAction";
import { K2Action } from "./K2Action";
import { K2Scroll } from "./K2Scroll";

type TBIconProps = K2RuleWithVCXProps & {
  iconPosition?: IconPosition;
};

type ResponsiveTabProps = K2RuleWithVCXProps & {
  isShow: boolean;
  depth?: number;
};

type ResponsiveButtonProps = K2RuleWithVCXProps & {
  accent: boolean;
};

type ResponsiveTabsProps = ResponsiveTabProps & {
  first: boolean;
};

const tabButtonRules = {
  horizontalResponsive: (props: ResponsiveTabsProps) => ({
    display: "flex",
    small: {
      height: "100%",
      display: props.isShow ? "flex" : "none",
      zIndex: props.depth ? props.depth : "unset",
      borderTop: !props.isShow && props.first ? 0 : "1px solid " + props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorFrg1),
    },
  }),
  responsiveMenuIcon: (props: ResponsiveTabProps) => ({
    display: "flex",
    small: {
      transform: props.isShow ? "rotate(180deg)" : "none",
    },
  }),
  responsiveButton: (props: ResponsiveButtonProps) => ({
    display: "none",
    small: {
      alignItems: "center",
      flex: "none",
      display: "flex",
      border: 0,
      backgroundColor: props.accent
        ? props.vcx.getColor(props.vcx.Data.ColorMap.AccentBaseColorBck)
        : props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorBck1),
      height: "100%",
      width: "auto",
      paddingLeft: props.vcx.sizeMap(4) + "px",
    },
  }),
  base: (props: K2RuleWithVCXProps) => ({
    borderTop: "0",
    borderLeft: "0",
    backgroundColor: props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorBck1),
    textAlign: "center" as "center",
    textDecoration: "none",
    color: props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorFrg1),
    justifyContent: "center",
    alignItems: "center",
    padding: props.vcx.sizeMap(3) + "px",
    flexDirection: "row" as "row",
  }),
  selected: (props: K2RuleWithVCXProps) => ({
    backgroundColor: props.vcx.getColor(props.vcx.Data.ColorMap.AccentBaseColorBck) + " !important",
    color: props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorFrg1),
    small: {
      display: "flex",
    },
  }),
  vertical: (props: K2RuleWithVCXProps) => ({
    height: 2.5 * props.vcx.ExpanderControl.GetHFHeight() - props.vcx.sizeMap(4) + "px", //width panel
    width: "100%",
    borderRight: "0",
    borderBottom: props.vcx.sizeMap(1) + "px solid " + props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorFrg1),
    "> div": {
      width: "100%",
    },
    small: {
      height: "100%",
      flex: "1",
      borderBottom: 0,
    },
  }),
  horizontal: (props: K2RuleWithVCXProps) => ({
    minWidth: 1.3 * props.vcx.ExpanderControl.GetHFHeight() - props.vcx.sizeMap(4) + "px",
    borderBottom: "0",
    flex: "none",
    borderRightWidth: props.vcx.sizeMap(1) + "px",
    borderRightStyle: "solid" as "solid",
    borderRightColor: props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorFrg1),
    display: "flex",
    justifyContent: "center",
    small: {
      width: "100%",
      flex: "1",
      borderRight: 0,
    },
  }),
  btnContent: (props: TBIconProps) => ({
    flexDirection: props.iconPosition % 2 === 0 ? ("row" as "row") : ("column" as "column"),
    flex: 1,
    "> div": {
      width: props.iconPosition === IconPosition.ipCenter ? "100%" : "auto",
    },
    small: {
      flexDirection: "row" as "row",
    },
    "> span": {
      zIndex: 1,
    },
  }),
  content: (props: K2RuleWithVCXProps) => ({
    flexDirection: "row" as "row",
    flex: "1 0 0%",
    justifyContent: "center",
    "> p": {
      width: "100%",
      textAlign: "center",
      alignSelf: "center",
      textOverflow: "inherit",
      display: "flex",
      alignItems: "center",
      overflow: "hidden",
      flexWrap: "wrap",
      whiteSpace: "pre-wrap",
      justifyContent: "center",
      small: {
        width: "auto",
      },
    },
  }),
};

interface TabButtonProps extends WithContextPlacementProps {
  orientation: Orientation;
  currentPage: string;
  iconPosition: IconPosition;
  showExpandButton?: boolean;
  forceShow?: boolean;
  isShowResponsiveMenu: boolean;
  showResponsiveMenu?: () => void;
  getCurrentPage?: (ref: HTMLDivElement) => void;
}

class TabPage extends React.PureComponent<TabButtonProps, K2ComponentState<UpdatePageControl>> {
  static displayName = `K2TabButton`;
  private ref: HTMLDivElement;
  private control: NclPage;

  constructor(props: TabButtonProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclPage;
    }) as NclPage;
    this.state = { data: this.control.init(this) as UpdatePageControl, vcxVersion: -1 };
  }

  private get GlyphId(): GlyphId {
    if (this.state.data.GlyphId) return this.state.data.GlyphId;
    return this.control.MetaData.FrgtData.Icon;
  }

  public updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdatePageControl>) => {
      return { data: state as UpdatePageControl };
    });
  }

  public updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  scrollInToView() {
    if (this.ref) {
      this.ref.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  }

  private getClassName(): string {
    let className: string = this.props.renderer.renderRule(tabButtonRules.base, { vcx: this.control.VCX });

    if (this.props.orientation === Orientation.foVertical) {
      className += " " + this.props.renderer.renderRule(tabButtonRules.vertical, { vcx: this.control.VCX });
    } else {
      className += " " + this.props.renderer.renderRule(tabButtonRules.horizontal, { vcx: this.control.VCX });
    }

    if (this.props.currentPage === this.control.Ncl.PageUID) {
      className += " " + this.props.renderer.renderRule(tabButtonRules.selected, { vcx: this.control.VCX });
    }
    return className;
  }

  componentDidUpdate() {
    if (this.props.currentPage === this.control.Ncl.PageUID) {
      this.props.getCurrentPage(this.ref);
    }
  }

  render() {
    if (this.state.data.Hide === true || !this.control.Content) return null;
    let glId = this.GlyphId;
    let addAttributes: ElementHtmlAttributes = getAttributes(this.state.data);
    let classname: string = "";
    if (this.props.className) {
      classname = this.props.className + " ";
    }
    let useDepth: boolean = false;
    if (this.props.forceShow) {
      if (this.control.getTabParent() instanceof NclVRTabControl) {
        useDepth = this.control.Ncl.IsDynamic === 1;
      } else {
        useDepth = true;
      }
    }

    return (
      <div
        style={StyleHelper(this.control, Object.assign({}, { flex: "0 0 auto", width: "auto" }, this.props.style))}
        className={
          classname +
          this.props.renderer.renderRule(tabButtonRules.horizontalResponsive, {
            vcx: this.control.VCX,
            first: this.props.showExpandButton,
            depth: useDepth ? this.control.Depth + 1 : 0,
            isShow: this.props.forceShow == true || this.props.currentPage === this.control.Ncl.PageUID,
          })
        }
      >
        <button data-k2-test-id={"page_" + this.control.Content.MetaData.Name} className={this.getClassName()} onClick={this.handleClick} {...addAttributes}>
          <div
            ref={(ref) => {
              this.ref = ref;
            }}
            className={this.props.renderer.renderRule(tabButtonRules.btnContent, {
              vcx: this.control.VCX,
              iconPosition: this.GlyphId ? this.props.iconPosition : null,
            })}
          >
            {!glId ? null : (
              <K2Img
                glyphId={glId}
                height={this.control.VCX.sizeMap(20)}
                vcx={this.control.VCX}
                strokeColor={this.control.VCX.getColor(this.control.VCX.Data.ColorMap.BaseColorFrg1)}
              />
            )}

            {(glId && this.props.iconPosition === IconPosition.ipCenter) || !this.state.data.Title ? null : (
              <div className={this.props.renderer.renderRule(tabButtonRules.content, { vcx: this.control.VCX })}>
                <K2TruncateText className={this.props.renderer.renderFontRule(this.control.VCX.BookTabControl.AccessorFont, this.control.VCX)}>
                  {this.state.data.Title}
                </K2TruncateText>
                {this.props.showExpandButton == true && (
                  <div
                    className={this.props.renderer.renderRule(tabButtonRules.responsiveButton, {
                      vcx: this.control.VCX,
                      accent: this.props.forceShow == false || this.control.Ncl.PageUID === this.props.currentPage,
                    })}
                  >
                    <K2Img
                      glyphId={"wui*roofdown"}
                      height={this.control.VCX.sizeMap(12)}
                      vcx={this.control.VCX}
                      strokeColor={this.control.VCX.getColor(this.control.VCX.Data.ColorMap.BaseColorFrg1)}
                      className={this.props.renderer.renderRule(tabButtonRules.responsiveMenuIcon, {
                        vcx: this.control.VCX,
                        isShow: this.props.isShowResponsiveMenu,
                        depth: 0,
                      })}
                    />
                  </div>
                )}
              </div>
            )}

            {this.control.Ncl.IsDynamic === 1 && (
              <span onClick={this.handleCloseClick} data-k2-test-id={"close_" + this.control.Content.MetaData.Name}>
                <K2Img
                  glyphId={"wui*close"}
                  vcx={this.control.VCX}
                  height={this.control.VCX.sizeMap(20)}
                  strokeColor={this.control.VCX.getColor(this.control.VCX.Data.ColorMap.BaseColorFrg1)}
                />
              </span>
            )}
          </div>
        </button>
      </div>
    );
  }

  private handleClick = () => {
    this.control.getTabParent().changeCurrentPage(this.control.Ncl.PageUID);

    if (this.props.showExpandButton === true && this.props.showResponsiveMenu && matchSmallMediaBreakpoint()) this.props.showResponsiveMenu.call(this);
  };

  private handleCloseClick = (e: any) => {
    (this.control.getTabParent() as NclVRTabControl).closePage(this.control.Ncl.PageUID);
    e.stopPropagation();
  };
}

const K2TabPage = withContext(TabPage);

type TabProps = K2RuleWithVCXProps & {
  orientation: Orientation;
  main?: boolean;
  pages?: number;
  companyColor?: string;
};

type TabContentProps = K2RuleWithVCXProps & {
  showResponsiveMenu: boolean;
};

const tabControlRules = {
  base: (props: TabProps) => ({
    flexDirection: props.orientation == Orientation.foVertical ? ("row" as "row") : ("column" as "column"),
    small: {
      flexDirection: "column" as "column",
    },
  }),
  basePanel: (props: TabProps) => ({
    flexDirection: props.orientation == Orientation.foVertical ? ("column" as "column") : ("row" as "row"),
    borderBottom:
      props.orientation == Orientation.foHorizontal
        ? props.vcx.sizeMap(4) + "px solid " + (props.companyColor ? props.companyColor : props.vcx.getColor(props.vcx.Data.ColorMap.AccentBaseColorBck))
        : "none",
    height:
      props.orientation == null || props.orientation == Orientation.foHorizontal
        ? props.vcx.ExpanderControl.GetHFHeight() + props.vcx.sizeMap(6) + "px"
        : "100%",
    small: {
      flexDirection: "row" as "row",
      borderBottom:
        props.main === true
          ? props.vcx.sizeMap(4) + "px solid " + (props.companyColor ? props.companyColor : props.vcx.getColor(props.vcx.Data.ColorMap.AccentBaseColorBck))
          : "none",
      height: `${props.vcx.ExpanderControl.GetHFHeight() + props.vcx.sizeMap(6)}px`,
    },
  }),
  tabPanel: (props: TabProps) => ({
    flexDirection: props.orientation == Orientation.foHorizontal ? ("row" as "row") : ("column" as "column"),
    flex: props.orientation == Orientation.foHorizontal ? "0 1 auto" : "1 0 auto",
    width: props.orientation == Orientation.foVertical ? 2.5 * props.vcx.ExpanderControl.GetHFHeight() + "px" : "100%",
    borderRight:
      props.orientation == Orientation.foVertical
        ? props.vcx.sizeMap(4) + "px solid " + (props.companyColor ? props.companyColor : props.vcx.getColor(props.vcx.Data.ColorMap.AccentBaseColorBck))
        : "none",
    backgroundColor: props.orientation == Orientation.foVertical ? props.vcx.ColorMap.getColor(props.vcx.Data.ColorMap.BaseColorBck1) : "unset",
    small: {
      borderRight: 0,
      flex: 1,
      height: "100%",
      flexDirection: "column" as "column",
      backgroundColor: props.vcx.ColorMap.getColor(props.vcx.Data.ColorMap.BaseColorBck1),
      borderTop: props.pages == 0 ? `${props.vcx.sizeMap(1)}px solid ${props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorFrg1)}` : "none",
    },
  }),
  tabContent: (props: K2RuleWithVCXProps) => ({
    flex: 1,
  }),
  tabContentOverlay: (props: TabContentProps) => ({
    display: "none",
    small: {
      left: 0,
      top: 0,
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundColor: props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorFrg1),
      opacity: "0.5",
      display: props.showResponsiveMenu ? "flex" : "none",
    },
  }),
  staticPage: (props: K2RuleWithVCXProps) => ({
    borderRight: props.vcx.sizeMap(1) + "px solid " + props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorFrg1),
  }),

  tabStaticPanel: (props: K2RuleWithVCXProps) => ({
    display: "none",
    small: {
      display: "flex",
    },
  }),
  tabActionPanel: (props: TabProps) => ({
    backgroundColor: props.companyColor ? props.companyColor : props.vcx.ColorMap.getColor(props.vcx.Data.ColorMap.BaseColorBck1),
    borderRight:
      props.orientation == Orientation.foVertical
        ? props.vcx.sizeMap(4) + "px solid " + (props.companyColor ? props.companyColor : props.vcx.getColor(props.vcx.Data.ColorMap.AccentBaseColorBck))
        : "none",
    width: props.orientation == Orientation.foHorizontal ? props.vcx.ExpanderControl.GetHFHeight() + props.vcx.sizeMap(4) + "px" : "100%",
    height: props.orientation == Orientation.foVertical ? 2.5 * props.vcx.ExpanderControl.GetHFHeight() + "px" : "100%",
    small: {
      height: "100%",
      width: props.vcx.ExpanderControl.GetHFHeight() + props.vcx.sizeMap(4) + "px",
      borderLeft: props.vcx.sizeMap(1) + "px solid " + props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorFrg1),
      borderTop: `1px solid ${props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorFrg1)}`,
      borderRight: "none",
    },
  }),
};

interface TabControlState extends K2ComponentState<UpdateTabControl> {
  isShowResponsiveMenu?: boolean;
  LastCurrentPage?: string;
  tab?: HTMLDivElement;
}

class _TabControl extends React.Component<WithContextPlacementProps, TabControlState> {
  static displayName = `K2TabControl`;
  private pageRefs: Array<TabPage>;
  private control: NclBaseTabControl<CSNclTabControlMetadata, UpdateTabControl>;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclBaseTabControl;
    }) as NclBaseTabControl<CSNclTabControlMetadata, UpdateTabControl>;
    this.state = { data: this.control.init(this) as UpdateTabControl, vcxVersion: -1 };
    this.pageRefs = new Array<TabPage>();
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: TabControlState) => {
      if (prevState.data.CurrentPage != (state as UpdateTabControl).CurrentPage) {
        return { data: state as UpdateTabControl, isShowResponsiveMenu: false, LastCurrentPage: prevState.data.CurrentPage };
      }
      return { data: state as UpdateTabControl };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  getCurrentPage = (currentPage: HTMLDivElement) => {
    this.setState({ tab: currentPage });
  };

  render() {
    this.pageRefs = [];
    let mainPanel: boolean = this.control instanceof NclVRTabControl;
    let countPages: number = mainPanel
      ? this.control.Pages.filter((value) => {
          return value.Ncl.IsDynamic === 1;
        }).count()
      : this.control.Pages.count();
    let tabIndex: number = 0;
    let currentIsStatic: boolean;

    return (
      <div
        className={this.props.renderer.renderRule(tabControlRules.base, { vcx: this.control.VCX, orientation: this.getOrientation() })}
        style={StyleHelper(this.control, { minHeight: this.control.VCX.MinRowHeight * 5, ...this.props.style })}
      >
        <div
          className={this.props.renderer.renderRule(tabControlRules.basePanel, {
            companyColor: this.control.CompanyColor,
            vcx: this.control.VCX,
            orientation: this.getOrientation(),
            main: mainPanel,
          })}
        >
          <K2Scroll tab={this.state.tab} orientation={this.getOrientation()} renderer={this.props.renderer} control={this.control}>
            {this.control.Pages.map((value: NclPage, key: number) => {
              if (mainPanel && !value.Ncl.IsDynamic) {
                if (!currentIsStatic && this.state.data.CurrentPage === value.Ncl.PageUID) {
                  currentIsStatic = true;
                }
                return (
                  <K2TabPage
                    getCurrentPage={this.getCurrentPage}
                    className={this.props.renderer.renderRule(tabControlRules.staticPage, { vcx: this.control.VCX })}
                    isShowResponsiveMenu={false}
                    showExpandButton={false}
                    forceShow={true}
                    key={value.MetaData.ControlUID}
                    controlUID={value.MetaData.ControlUID}
                    vrUID={this.control.getRealizerUID()}
                    orientation={this.getOrientation()}
                    currentPage={this.state.data.CurrentPage}
                    iconPosition={this.control.Ncl.FrgtData.TabIconPosition}
                  />
                );
              }
            })}
            <div
              className={this.props.renderer.renderRule(tabControlRules.tabPanel, {
                companyColor: this.control.CompanyColor,
                vcx: this.control.VCX,
                orientation: this.getOrientation(),
                pages: countPages,
              })}
            >
              {this.control.Pages.map((value: NclPage, key: number) => {
                if (!mainPanel || value.Ncl.IsDynamic) {
                  tabIndex++;
                  return (
                    <K2TabPage
                      showExpandButton={
                        ((this.state.isShowResponsiveMenu && tabIndex === 1) ||
                          ((this.state.data.CurrentPage === value.Ncl.PageUID || this.state.LastCurrentPage == value.Ncl.PageUID) &&
                            !this.state.isShowResponsiveMenu)) &&
                        countPages > 1
                      }
                      forceShow={this.state.isShowResponsiveMenu || (this.state.LastCurrentPage == value.Ncl.PageUID && currentIsStatic)}
                      isShowResponsiveMenu={this.state.isShowResponsiveMenu}
                      showResponsiveMenu={this.showResponsiveMenu}
                      key={value.MetaData.ControlUID}
                      controlUID={value.MetaData.ControlUID}
                      vrUID={this.control.getRealizerUID()}
                      orientation={this.getOrientation()}
                      currentPage={this.state.data.CurrentPage}
                      iconPosition={this.control.Ncl.FrgtData.TabIconPosition}
                      ref={(ref) => {
                        if (ref && ref instanceof TabPage) this.pageRefs.push(ref);
                      }}
                      getCurrentPage={this.getCurrentPage}
                    />
                  );
                }
              })}
            </div>
          </K2Scroll>
          {this.control.Btn && (
            <div
              className={this.props.renderer.renderRule(tabControlRules.tabActionPanel, {
                companyColor: this.control.CompanyColor,
                vcx: this.control.VCX,
                orientation: this.getOrientation(),
              })}
            >
              <K2RibbonAction
                style={{ width: "100%" }}
                controlUID={this.control.Btn.MetaData.ControlUID}
                vrUID={this.control.getRealizerUID()}
                color={this.control.VCX.getColor(this.control.VCX.Data.ColorMap.BaseColorFrg1)}
              />
            </div>
          )}
        </div>
        <div className={this.props.renderer.renderRule(tabControlRules.tabContent, { vcx: this.control.VCX })}>
          <div
            onClick={this.handleOutsideClick}
            className={this.props.renderer.renderRule(tabControlRules.tabContentOverlay, {
              vcx: this.control.VCX,
              showResponsiveMenu: this.state.isShowResponsiveMenu,
            })}
          />
          {this.state.data.CurrentPage &&
            this.control.Pages.map((control) => {
              if (
                this.control instanceof NclVRTabControl ||
                control.Content instanceof NclVRTabControl ||
                control.Content.MetaData.ControlUID == this.state.data.CurrentPage
              ) {
                return GenerateControl(control.Content);
              }
            })}
        </div>
      </div>
    );
  }

  componentDidUpdate() {
    if (this.state.isShowResponsiveMenu && this.pageRefs.length > 0) {
      this.pageRefs[this.pageRefs.length - 1].scrollInToView();
    }
  }

  private handleOutsideClick = () => {
    if (this.state.isShowResponsiveMenu == true) {
      this.setState({ isShowResponsiveMenu: false });
    }
  };

  private showResponsiveMenu = () => {
    this.setState({ isShowResponsiveMenu: !this.state.isShowResponsiveMenu });
  };

  private getOrientation(): Orientation {
    return this.control.Ncl.ListDetailTabControl ? Orientation.foVertical : Orientation.foHorizontal;
  }
}

export const K2TabControl = withContext(_TabControl);

class _PageHolder extends React.PureComponent<WithContextPlacementProps, K2ComponentState<UpdateDockControl>> {
  static displayName = `K2TabPlaceHolder`;
  private control: NclDockControl;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(
      this.props.controlUID,
      this.props.vrUID,
      (ctrl) => {
        return ctrl instanceof NclDockControl;
      },
      false
    ) as NclDockControl;

    if (!this.control) {
      this.control = Context.getApplication().getMainDockControl();
    }

    if (!this.control) {
      throw new Error("Dont't exist dock control.");
    }

    this.state = { data: this.control.init(this) as UpdateDockControl, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateDockControl>) => {
      return { data: state as UpdateDockControl };
    });
  }

  dock(state: UpdateDockControl): Promise<void> {
    return new Promise((resolve, reject) => {
      this.setState({ data: state }, resolve);
    });
  }

  undock(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.setState({ data: { DockRealizerUID: null } as UpdateDockControl }, resolve);
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    let vr: ViewRealizer = ViewRealizerManager.getViewRealizer(this.state.data.DockRealizerUID);
    if (vr == null) {
      return null;
    } else {
      return <ViewRealizerReact VRUID={vr.getRealizerUID()} overlayBck={false} />;
    }
  }
}

export const K2PageHolder = withContext(_PageHolder);

class _MultiContent extends React.PureComponent<WithContextPlacementProps, K2ComponentState<UpdateTabControl>> {
  static displayName = `K2MultiContent`;
  private control: NclMultiContent;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclMultiContent;
    }) as NclMultiContent;
    this.state = { data: this.control.init(this) as UpdateTabControl, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateTabControl>) => {
      return { data: state as UpdateTabControl };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    return (
      <div style={StyleHelper(this.control, this.props.style)}>
        {this.control.Pages.map((control) => {
          return GenerateControl(control.Content);
        })}
      </div>
    );
  }
}
export const K2MultiContent = withContext(_MultiContent);
