import * as React from "react";
import { K2RuleWithVCXProps } from "./k2StyleRenderer";
import { WithContextPlacementProps, withContext, K2ComponentState, StyleHelper, AcquireControl } from "./k2hoc";
import { NclTabToolBar } from "../common/components.ncl";
import { UpdateControl, UFUpdateControl } from "../common/communication.base";
import { K2ToolBar } from "./K2ToolBar";

const toolBarRule = (props: K2RuleWithVCXProps) => ({
  border: props.vcx.sizeMap(1) + "px solid " + props.vcx.getColor(props.vcx.Data.ColorMap.ContentFrame1),
  backgroundColor: props.vcx.getColor(props.vcx.Data.ColorMap.DataBrowseColorBck),
  color: props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorBck1),
  flex: "1 0 auto",
  padding: props.vcx.sizeMap(6) + "px",
});

class _TabToolBar extends React.PureComponent<WithContextPlacementProps, K2ComponentState<UFUpdateControl>> {
  static displayName = `K2TabToolBar`;
  private control: NclTabToolBar;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclTabToolBar;
    }) as NclTabToolBar;
    this.state = { data: this.control.init(this) as UFUpdateControl, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UFUpdateControl>) => {
      return { data: state as UFUpdateControl };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    return (
      <div style={StyleHelper(this.control, this.props.style)}>
        <div className={this.props.renderer.renderRule(toolBarRule, { vcx: this.control.VCX })}>
          <K2ToolBar controlUID={this.control.ToolBars[0].MetaData.ControlUID} vrUID={this.props.vrUID} />
        </div>
      </div>
    );
  }
}

export const K2TabToolBar = withContext(_TabToolBar);
