import * as React from "react";

import { Align, UFUpdateControl, UpdateControl, VerticalAlignment } from "../common/communication.base";
import { NclButton, NclStackPanel, UFNclControlBase } from "../common/components.ncl";
import { GenerateControl } from "./K2GenerateControl";
import { K2ComponentState, withContext, WithContextPlacementProps, StyleHelper, AcquireControl } from "./k2hoc";
import { K2RuleWithVCXProps } from "./k2StyleRenderer";

type ContentRule = K2RuleWithVCXProps & {
  inPreview: boolean;
};

export const stackRule = (props: ContentRule) => ({
  background: props.inPreview ? props.vcx.getColor(props.vcx.Data.ColorMap.DataBrowseColorBck) : "transparent",
});

class _StackPanel extends React.Component<WithContextPlacementProps, K2ComponentState<UFUpdateControl>> {
  static displayName = `K2StackPanel`;
  private control: NclStackPanel;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclStackPanel;
    }) as NclStackPanel;
    this.state = { data: this.control.init(this) as UFUpdateControl, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UFUpdateControl>) => {
      return { data: state as UFUpdateControl };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  private getControlsAlignSelf(control: UFNclControlBase) {
    if (control.MetaData.Bounds.Align == Align.Client) return;
    switch (control.MetaData.FrgtData.VerticalAlignment) {
      case VerticalAlignment.fvaTop:
        return "flex-start";
      case VerticalAlignment.fvaBottom:
        return "flex-end";
      case VerticalAlignment.fvaCenter:
        return "flex-center";
      default:
        return "flex-auto";
    }
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    return (
      <div
        style={StyleHelper(this.control, this.props.style)}
        className={this.props.renderer.renderRule(stackRule, { vcx: this.control.VCX, inPreview: this.control.isInPreview() })}
      >
        {this.control.Children.map((control: UFNclControlBase, index: number) => {
          return GenerateControl(control, {
            alignSelf: this.getControlsAlignSelf(control),
            width: "auto",
            flex: control.MetaData.Bounds.Align === Align.Left || control.MetaData.Bounds.Align === Align.Right ? "0 0 auto" : "1 1 100%",
          });
        })}
      </div>
    );
  }
}

export const K2StackPanel = withContext(_StackPanel);
