import {
  VisualContextMetadata,
  VCXFontNoColorMetaData,
  FontStyle,
  VCXMetaData,
  VCXDataInputControlMetaData,
  VCXLabelBaseMetaData,
  K2JSONObject,
  VCXColorMapMetaData,
  VCXExpanderControlMetaData,
  VCXTabControlMetaData,
  VCXViewControlMetaData,
  VCXGridControlMetaData,
  DataActionDecorate,
  K2Boolean,
} from "./communication.base";
import { version } from "punycode";
import { computeTextDimension } from "./common";
import { Context } from "../appcontext";
import { matchSmallMediaBreakpoint } from "../components/k2StyleRenderer";

export const UFInteriorMargin: number = 1; // Pevny margin obsahu (napr headeru v expanderu)

export const defaultVCX = {
  SimpleChartControl: {
    ChartCaptionFont: { FontStyle: 0, FontSize: 14, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    Font: { FontStyle: 0, FontSize: 8, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    __type: "TUFVCSimpleChartControl",
  },
  ColorMap: {
    Alpha: 0,
    ContentDecorateColorFrg: 4882945,
    DataChangeROColorFrg: 0,
    ContentChangeDecorateColorFrg: 4882945,
    DataChangeROColorBck: 16250871,
    InactiveAlphaValue: 40,
    ContentFrameMinForChange: 14614189,
    ContentNormalColorFrg: 0,
    DataBrowseColorFrg: 0,
    DataBrowseColorBck: 16579836,
    ContentFrameMin: 15132390,
    InactiveAlphaColor: 0,
    DataChangeColorFrg: 0,
    DataChangeColorBck: 14811135,
    WarningColorFrg: 16777215,
    WarningColorBck: 42495,
    HighlightColorSet: {
      Color8: 15522557,
      Color7: 12441829,
      Color6: 11465471,
      Color5: 10934782,
      Color4: 14994398,
      Color3: 12970956,
      Color2: 14929331,
      Color1: 11449595,
      __type: "TUFVCColorSet",
    },
    AlphaColor: 0,
    ContentFrame1ForChange: 13499782,
    BaseColorFrg1: 16777215,
    BaseColorBck1: 4882945,
    ContentColorBck1: 16250871,
    ContentFrame2ForChange: 12516449,
    LumaShift: 0,
    ContentFrame3ForChange: 9432068,
    ContentChangeColorBck: 15335115,
    ContentFrame3: 8487297,
    ContentFrame2: 12369084,
    ContentFrame1: 13750737,
    ErrorColorFrg: 16777215,
    ErrorColorBck: 4474111,
    Gray: 0,
    GridRulerColorBck: 15335115,
    AccentBaseColorFrg: 16777215,
    AccentBaseColorBck: 7983106,
    HighLightColorFrg: 16777215,
    HighLightColorBck: 52377,
    __type: "TUFVCColorMap",
  },
  DecorateMode: 0,
  ExpanderControl: {
    LiteHeaderFont: { FontStyle: 0, FontSize: 10, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    HeaderFont: { FontStyle: 1, FontSize: 10, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    __type: "TUFVCExpanderControl",
  },
  SplitterControl: { Size: 7, __type: "TUFVCSplitterControl" },
  Caption: "BOHEMIANS PRAHA 1905",
  Description: "BOHEMIANS PRAHA 1905",
  BookTabControl: {
    CurrentAccessorFont: { FontStyle: 0, FontSize: 8, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    AccessorFont: { FontStyle: 0, FontSize: 8, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    __type: "TUFVCTabControl",
  },
  GridControl: {
    HeadingFont: { FontStyle: 0, FontSize: 9, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    Font: { FontStyle: 0, FontSize: 10, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    __type: "TUFVCGridControl",
  },
  Placement: { MaximizeAll: 0, __type: "TUFVCPlacement" },
  TreeViewControl: {
    HeadingFont: { FontStyle: 0, FontSize: 9, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    Font: { FontStyle: 0, FontSize: 10, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    __type: "TUFVCTreeViewControl",
  },
  DashboardControl: {
    TileFont: { FontStyle: 0, FontSize: 14, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    TileSmallFont: { FontStyle: 0, FontSize: 8, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    PivotTableFixedFont: { FontStyle: 0, FontSize: 9, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    GraphFont: { FontStyle: 0, FontSize: 9, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    SimpleTableFont: { FontStyle: 0, FontSize: 8, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    FilterInnerFont: { FontStyle: 0, FontSize: 9, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    PivotTablePropertiesFont: { FontStyle: 0, FontSize: 6, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    PivotTableValueFont: { FontStyle: 0, FontSize: 9, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    __type: "TUFVCDashboardControl",
  },
  ViewControl: { OutLineWidth: 1, FrameWidth: 1, Margin: 3, __type: "TUFVCViewControl" },
  UI: "{94B5DFDC-2674-40AD-91E5-8D392A28CDCB}",
  MarginY: 3,
  MarginX: 3,
  DataInputControl: {
    FrameWidth: 1,
    InputLabel: { Font: { FontStyle: 0, FontSize: 9, FontName: "Segoe UI", __type: "TUFVCFontNoColor" }, __type: "TUFVCInputLabel" },
    __type: "TUFVCInputControl",
    InputEdit: { InteriorBorder: 0, Font: { FontStyle: 0, FontSize: 10, FontName: "Segoe UI", __type: "TUFVCFontNoColor" }, __type: "TUFVCInputEditBase" },
  },
  ListViewControl: {
    SmallIconSize: 16,
    LargeIconSize: 32,
    Font: { FontStyle: 0, FontSize: 8, FontName: "Segoe UI", __type: "TUFVCFontNoColor" },
    __type: "TUFVCListViewControl",
  },
  Zoom: 1,
  LabelControl: { Font: { FontStyle: 15, FontSize: 9, FontName: "Segoe UI", __type: "TUFVCFontNoColor" }, __type: "TUFVCLabelControl" },
  TitleControl: { Font: { FontStyle: 5, FontSize: 15, FontName: "Segoe UI", __type: "TUFVCFontNoColor" }, __type: "TUFVCTitleControl" },
  __type: "TUFVisualContext",
} as VisualContextMetadata;
const defVCXVersion = -100;
export abstract class VisualContext {
  protected data: VisualContextMetadata;
  private zoomFactorDiv100: number;
  private zoomFactor: number;
  private ic: VCXInput;
  private lc: VCXLabel;
  private cm: VCXColorMap;
  private ec: VCXExpander;
  private btc: VCXBookTab;
  private vc: VCXView;
  private gc: VCXGrid;
  private static defVCX: VisualContext;
  protected version: number;

  protected constructor(data: VisualContextMetadata, zoomFactor: number, version: number) {
    this.data = data;
    this.zoomFactor = zoomFactor;
    this.zoomFactorDiv100 = zoomFactor / 100;
    this.version = version;
  }

  private static createInternal(data: VisualContextMetadata, zoomFactor: number, version: number): VisualContext {
    return new VCX(data, zoomFactor, version);
  }

  public static create(data: VisualContextMetadata, zoomFactor: number, version: number): VisualContext {
    if (!VisualContext.defVCX) {
      VisualContext.setDefault(data, zoomFactor);
    }
    return new VCX(data, zoomFactor, version);
  }

  public static get Default(): VisualContext {
    if (!VisualContext.defVCX) {
      VisualContext.defVCX = VisualContext.createInternal(defaultVCX, 100, defVCXVersion);
    }
    return VisualContext.defVCX;
  }

  private static setDefault(value: VisualContextMetadata, zoomFactor: number) {
    if (VisualContext.defVCX) {
      throw new Error("Mishmash default VCX.");
    }

    VisualContext.defVCX = VisualContext.createInternal(value, zoomFactor, defVCXVersion);
  }

  public getVersion(): number {
    return this.version;
  }

  public get ZoomFactor(): number {
    return this.zoomFactor;
  }

  public get Zoom(): number {
    return this.zoomFactor * this.data.Zoom;
  }

  public get Data(): VisualContextMetadata {
    return this.data;
  }

  public get InputControl(): VCXInput {
    if (!this.ic) {
      this.ic = new VCXInput(this.data.DataInputControl, this.getCorrectParent());
    }

    return this.ic;
  }

  public get LabelControl(): VCXLabel {
    if (!this.lc) {
      this.lc = new VCXLabel(this.data.LabelControl, this.getCorrectParent());
    }

    return this.lc;
  }

  public get ExpanderControl(): VCXExpander {
    if (!this.ec) {
      this.ec = new VCXExpander(this.data.ExpanderControl, this.getCorrectParent());
    }

    return this.ec;
  }

  public get BookTabControl(): VCXBookTab {
    if (!this.btc) {
      this.btc = new VCXBookTab(this.data.BookTabControl, this.getCorrectParent());
    }

    return this.btc;
  }

  public get ViewControl(): VCXView {
    if (!this.vc) {
      this.vc = new VCXView(this.data.ViewControl, this.getCorrectParent());
    }

    return this.vc;
  }

  public get GridControl(): VCXGrid {
    if (!this.gc) {
      this.gc = new VCXGrid(this.data.GridControl, this.getCorrectParent());
    }

    return this.gc;
  }

  public get ColorMap(): VCXColorMap {
    if (!this.cm) {
      this.cm = new VCXColorMap(this.data.ColorMap, this.getCorrectParent());
    }

    return this.cm;
  }

  public get MinRowHeight(): number {
    return this.LabelControl.getHeight(1);
  }

  public sizeMap(size: number): number {
    if (this.data == null) {
      return size;
    }
    return Math.fround(size * (this.data.Zoom * Context.DeviceInfo.ZoomFactor) * this.zoomFactorDiv100);
  }

  public cssSizeMap(size: number, unit: string = "px"): string {
    if (this.data == null) {
      return size.toString() + unit;
    }
    return this.sizeMap(size) + unit;
  }

  public interioirMargin(value: number): number {
    return value * UFInteriorMargin;
  }

  public getColor(key: number): string {
    return this.ColorMap.getColor(key);
  }

  public getRGBColor(key: number): RGB {
    return this.ColorMap.getColorRGB(key);
  }

  public invalidateCache() {
    let vcx: VCX = this.getCorrectParent();
    if (vcx) {
      vcx.internalInvalidateCache();
    }
  }

  protected abstract getCorrectParent(): VCX;
  public abstract createVCXForZoomFactor(zoomFactor: number): VisualContext;
}

interface FontMeasures {
  TextHeight?: number;
  Widths: Map<string, number>;
}

let unsupportedFonts: Array<string> = [];
let supportedFonts: Array<string> = [];
let safeFont = "sans-serif";

class VCX extends VisualContext {
  private cacheFont: Map<string, FontMeasures>;
  private cache: Map<number, VisualContext>;

  public constructor(data: VisualContextMetadata, zoomFactor: number, version: number) {
    super(data, zoomFactor, version);
    this.cacheFont = new Map<string, FontMeasures>();
    this.cache = new Map<number, VisualContext>();
    this.internalInvalidateCache();
  }

  public internalInvalidateCache() {
    this.cacheFont.clear();
  }

  private calcMesures(font: VCXFontNoColorMetaData) {
    let unsupportedFont = unsupportedFonts.indexOf(font.FontName);
    let supportedFont = supportedFonts.indexOf(font.FontName);

    if (unsupportedFont === -1 && supportedFont === -1) {
      if (!this.fontExists(font.FontName)) {
        unsupportedFonts.push(font.FontName);
        font.FontName = safeFont;
      } else {
        supportedFonts.push(font.FontName);
      }
    } else if (unsupportedFont > -1) {
      font.FontName = safeFont;
    }

    let f: VCXFontNoColorMetaData = Object.assign({}, font, { FontSize: this.sizeMap(font.FontSize) });
    let mc = computeTextDimension("M", f);
    let measure: FontMeasures = {
      TextHeight: mc.height,
      Widths: new Map([
        ["0", computeTextDimension("0", f).width],
        ["*", computeTextDimension("*", f).width],
        ["M", mc.width],
      ]),
    };

    this.cacheFont.set(JSON.stringify(font), measure);
  }

  public fontExists(fontName: string) {
    let canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const text = "abcdefghijklmnopqrstuvwxyz0123456789";

    context.font = "72px monospace";
    const baseFontSize = context.measureText(text).width;

    context.font = `72px "${fontName}", monospace`;
    const newFontSize = context.measureText(text).width;
    canvas = null;

    if (newFontSize === baseFontSize) {
      return false;
    } else {
      return true;
    }
  }

  public getMeasures(data: VCXFontNoColorMetaData): FontMeasures {
    let key = JSON.stringify(data);
    if (!this.cacheFont.has(key)) {
      this.calcMesures(data);
    }

    if (!this.cacheFont.has(key)) key = JSON.stringify(data); // FontName can be changed

    return this.cacheFont.get(key);
  }

  public getTextWidth(text: string, font: VCXFontNoColorMetaData): number {
    let measures: FontMeasures = this.getMeasures(font);

    if (measures) {
      if (measures.Widths.has(text)) {
        return measures.Widths.get(text);
      } else {
        let width = computeTextDimension(text, font).width;
        measures.Widths.set(text, width);
        return width;
      }
    }

    throw Error("Tohle by se nemelo stat :D.");
  }

  protected getCorrectParent(): VCX {
    return this;
  }

  public createVCXForZoomFactor(zoomFactor: number): VisualContext {
    let vcx: VisualContext;
    let zoomF = this.ZoomFactor * (zoomFactor / 100);
    if (!this.cache.has(zoomF)) {
      vcx = new VCX(this.data, zoomF, this.version);
      this.cache.set(zoomF, vcx);
    } else {
      vcx = this.cache.get(zoomF);
    }

    return vcx;
  }
}

class VCXControl<T extends VCXMetaData> implements K2JSONObject {
  protected parent: VCX;
  protected data: T;
  constructor(data: T, parent: VCX) {
    this.parent = parent;
    this.data = data;
  }

  public get __type(): string {
    return this.__type;
  }
}

export class VCXFontNoColor extends VCXControl<VCXFontNoColorMetaData> {
  constructor(data: VCXFontNoColorMetaData, parent: VCX) {
    super(data, parent);
  }

  public get IsBold(): boolean {
    return (this.data.FontStyle & FontStyle.fsBold) === FontStyle.fsBold;
  }

  public get IsItalic(): boolean {
    return (this.data.FontStyle & FontStyle.fsItalic) === FontStyle.fsItalic;
  }

  public get IsUnderline(): boolean {
    return (this.data.FontStyle & FontStyle.fsUnderline) === FontStyle.fsUnderline;
  }

  public get IsStrikeOut(): boolean {
    return (this.data.FontStyle & FontStyle.fsStrikeOut) === FontStyle.fsStrikeOut;
  }

  public get FontSize(): number {
    return this.parent.sizeMap(this.data.FontSize);
  }

  public get FontName(): string {
    if (unsupportedFonts.indexOf(this.data.FontName) > -1) {
      return safeFont;
    }

    return this.data.FontName;
  }

  public get TextHeight() {
    return this.getMeasures().TextHeight;
  }

  public get _0Width() {
    return this.getMeasures().Widths.get("0");
  }

  public get _MWidth() {
    return this.getMeasures().Widths.get("M");
  }

  public get _AstWidth() {
    return this.getMeasures().Widths.get("*");
  }

  public cloneWith(data: VCXFontNoColorMetaData): VCXFontNoColor {
    return new VCXFontNoColor(Object.assign({}, this.data, data), this.parent);
  }

  public computeTextWidth(value: string): number {
    return this.parent.getTextWidth(value, this.data);
  }

  private getMeasures(): FontMeasures {
    return this.parent.getMeasures(this.data);
  }
}

class VCXExpander extends VCXControl<VCXExpanderControlMetaData> {
  private hFont: VCXFontNoColor;
  private lFont: VCXFontNoColor;

  public get HeaderFont(): VCXFontNoColor {
    if (!this.hFont) {
      this.hFont = new VCXFontNoColor(this.data.HeaderFont, this.parent);
    }

    return this.hFont;
  }

  public get LitleHeaderFont(): VCXFontNoColor {
    if (!this.lFont) {
      this.lFont = new VCXFontNoColor(this.data.LiteHeaderFont, this.parent);
    }

    return this.lFont;
  }

  public GetHFHeight(): number {
    return this.HeaderFont.TextHeight + 2 * this.GetHFMargin() + 2 * UFInteriorMargin;
  }

  public GetHFMargin(): number {
    return Math.floor(this.HeaderFont.TextHeight / 5) + this.parent.InputControl.getInputInteriorBorder();
  }
}

export class VCXGrid extends VCXControl<VCXGridControlMetaData> {
  private hFont: VCXFontNoColor;
  private font: VCXFontNoColor;

  public get HeaderFont(): VCXFontNoColor {
    if (!this.hFont) {
      this.hFont = new VCXFontNoColor(this.data.HeadingFont, this.parent);
    }

    return this.hFont;
  }

  public get Font(): VCXFontNoColor {
    if (!this.font) {
      this.font = new VCXFontNoColor(this.data.Font, this.parent);
    }

    return this.font;
  }

  public get LineWidth(): number {
    return 1;
  }

  public GetRowHeightWithoutMargin(): number {
    return this.Font.TextHeight;
  }

  public GetRowHeight(multiplier: number): number {
    return this.Font.TextHeight * multiplier + 4 * UFInteriorMargin; //4*....původně bylo 2* ale našel jsem že je v řádku gridu ještě 1px transparentní border na každé buňce,
    //který je při selected dotted. Aby neskákala výška řádku.
  }

  GetExteriorCellWidth(width: number): number {
    return width + 2 * UFInteriorMargin;
  }
}

class VCXInput extends VCXControl<VCXDataInputControlMetaData> {
  private lFont: VCXFontNoColor;
  private iFont: VCXFontNoColor;
  public getInputHeight(lines: number, showFrame: boolean | K2Boolean, showLabel: boolean): number {
    let result: number = this.getEditHeight(lines);
    if (showFrame) {
      result += this.getInputFrameHeight();
    }

    if (showLabel) {
      result += this.parent.LabelControl.Font.TextHeight;
    }

    return result;
  }

  public get LabelFont(): VCXFontNoColor {
    if (!this.lFont) {
      this.lFont = new VCXFontNoColor(this.data.InputLabel.Font, this.parent);
    }

    return this.lFont;
  }

  public get InputFont(): VCXFontNoColor {
    if (!this.iFont) {
      this.iFont = new VCXFontNoColor(this.data.InputEdit.Font, this.parent);
    }

    return this.iFont;
  }

  private getInputFrameHeight(): number {
    return 4 * this.getInputFrameWidth();
  }

  public getInputFrameWidth(): number {
    let result: number = this.parent.sizeMap(this.data.FrameWidth);
    if (result === 0 && this.data.FrameWidth > 0) {
      result = 1;
    }

    return result;
  }
  public getInputInteriorBorder(): number {
    let result: number = this.parent.sizeMap(this.data.InputEdit.InteriorBorder);
    if (result === 0 && this.data.InputEdit.InteriorBorder > 0) {
      result = 1;
    }

    return result;
  }

  public getEditHeight(lines: number): number {
    return 2 * UFInteriorMargin + 2 * this.parent.sizeMap(this.data.InputEdit.InteriorBorder) + lines * this.InputFont.TextHeight;
  }
}

class VCXLabel extends VCXControl<VCXLabelBaseMetaData> {
  private font: VCXFontNoColor;

  public getHeight(lines: number): number {
    return this.Font.TextHeight * lines;
  }

  public get Font(): VCXFontNoColor {
    if (!this.font) {
      this.font = new VCXFontNoColor(this.data.Font, this.parent);
    }

    return this.font;
  }
}

class VCXBookTab extends VCXControl<VCXTabControlMetaData> {
  private aFont: VCXFontNoColor;
  private acFont: VCXFontNoColor;

  public get AccessorFont(): VCXFontNoColor {
    if (!this.aFont) {
      this.aFont = new VCXFontNoColor(this.data.AccessorFont, this.parent);
    }

    return this.aFont;
  }

  public get CurrentAccessorFont(): VCXFontNoColor {
    if (!this.acFont) {
      this.acFont = new VCXFontNoColor(this.data.CurrentAccessorFont, this.parent);
    }

    return this.acFont;
  }
}

class VCXView extends VCXControl<VCXViewControlMetaData> {
  public get FrameWidth(): number {
    return this.parent.sizeMap(this.data.FrameWidth);
  }

  public get Margin(): number {
    return this.parent.sizeMap(this.data.Margin);
  }

  public get OutLineWidth(): number {
    return this.parent.sizeMap(this.data.OutLineWidth);
  }
}

export class RGB {
  r: number;
  g: number;
  b: number;

  constructor(r: number, g: number, b: number) {
    this.r = r;
    this.g = g;
    this.b = b;
  }

  public toHTML(opacity: number): string {
    return `rgba(${this.r},${this.g},${this.b},${opacity})`;
  }
}

export interface DecorateResult {
  backColor: string;
  foregroundColor: string;
  bold?: boolean;
}

export class VCXColorMap extends VCXControl<VCXColorMapMetaData> {
  getColor(key: number): string {
    return this.colorMapHTML(key);
  }

  getColorRGB(key: number): RGB {
    return this.colorMap(key);
  }

  private colorMap(value: number): RGB {
    let result: RGB = VCXColorMap.intToRGB(value);

    if (this.data.LumaShift > 0) {
      result = VCXColorMap.shiftColorLuma(result, this.data.LumaShift);
    }

    if (this.data.Gray === 1) {
      result = VCXColorMap.colorToGrayScale(result);
    }

    if (this.data.Alpha > 0) {
      result = VCXColorMap.combineAlpha(result, VCXColorMap.intToRGB(this.data.AlphaColor), this.data.Alpha);
    }

    return result;
  }

  private colorMapHTML(key: number): string {
    return VCXColorMap.rgbToHTMLColor(this.colorMap(key));
  }

  public static combineAlpha(value: RGB, alphaColor: RGB, alpha: number): RGB {
    let a: number = alpha / 255;

    return new RGB(
      Math.round(value.r + (alphaColor.r - value.r) * a),
      Math.round(value.g + (alphaColor.g - value.g) * a),
      Math.round(value.b + (alphaColor.b - value.b) * a)
    );
  }

  public static shiftColorLuma(color: RGB, lumnaShift: number): RGB {
    let value = lumnaShift;
    if (value > 1000) {
      value = 1000;
    } else if (value < -1000) {
      value = -1000;
    }

    value = value / 1000;

    return color; // TODO: implementovat
  }

  private static colorToGrayScale(color: RGB): RGB {
    let v = (color.r >> 2) + (color.g >> 1) + (color.b >> 2);
    return new RGB(v, v, v);
  }

  private static intToRGB(num: number): RGB {
    let r: number = num & 0xff;
    let g: number = (num >> 8) & 0xff;
    let b: number = (num >> 16) & 0xff;

    return new RGB(r, g, b);
  }

  public static intToHTMLRGB(num: number): string {
    let rgb = VCXColorMap.intToRGB(num);

    return VCXColorMap.rgbToHTMLColor(rgb);
  }

  private static RGBToInt(rgb: RGB): number {
    let result: number = rgb.r + (rgb.g << 8) + (rgb.b << 16);
    return result;
  }

  private static HTMLColorToRGB(color: string): RGB {
    let r = parseInt(color.slice(1, 3), 16);
    let g = parseInt(color.slice(3, 5), 16);
    let b = parseInt(color.slice(5, 7), 16);
    return new RGB(r, g, b);
  }

  public static HTMLColorToInt(color: string): number {
    return this.RGBToInt(this.HTMLColorToRGB(color));
  }

  public static rgbToHTMLColor(rgb: RGB) {
    let r = rgb.r.toString(16);
    let g = rgb.g.toString(16);
    let b = rgb.b.toString(16);

    if (r.length === 1) {
      r = 0 + r;
    }
    if (g.length === 1) {
      g = 0 + g;
    }
    if (b.length === 1) {
      b = 0 + b;
    }

    return "#" + r + g + b;
  }

  public getColorsForActionDecorate(decorate: DataActionDecorate): DecorateResult {
    if (decorate) {
      switch (decorate) {
        case DataActionDecorate.dadeBase:
          return { backColor: this.getColor(this.data.BaseColorBck1), foregroundColor: this.getColor(this.data.BaseColorFrg1) };
        case DataActionDecorate.dadeError:
          return { backColor: this.getColor(this.data.ErrorColorBck), foregroundColor: this.getColor(this.data.ErrorColorFrg) };
        case DataActionDecorate.dateHighLight:
          return { backColor: this.getColor(this.data.HighLightColorBck), foregroundColor: this.getColor(this.data.HighLightColorFrg) };
        case DataActionDecorate.dadeContent:
          return { backColor: this.getColor(this.data.ContentColorBck1), foregroundColor: this.getColor(this.data.ContentNormalColorFrg) };
        case DataActionDecorate.dadeContentSuppress:
          return { backColor: this.getColor(this.data.ContentColorBck1), foregroundColor: this.getColor(this.data.ContentFrame2) };
        case DataActionDecorate.dadeTransparent:
          return { backColor: "rgba(0,0,0,0)", foregroundColor: this.getColor(this.data.BaseColorBck1) };
        case DataActionDecorate.dadeTransparentBold:
          return { backColor: "rgba(0,0,0,0)", foregroundColor: this.getColor(this.data.BaseColorBck1), bold: true };
      }
    }
    return { backColor: this.getColor(this.data.AccentBaseColorBck), foregroundColor: this.getColor(this.data.AccentBaseColorFrg) };
  }
}
