import * as React from "react";

import { ViewRealizer, ViewRealizerControlListener, ViewRealizerManager } from "../viewrealizer";
import { GenerateControl } from "./K2GenerateControl";
import { K2ModalControl, K2Modal } from "./K2Modal";
import { RectInDock } from "../common/communication.base";
import { UFNclControlBase, NclViewBase, NclOpenDialog } from "../common/components.ncl";
import { K2View } from "./K2View";
import { K2OpenDialog } from "./K2OpenDialog";

interface ViewRealizerReactProps {
  VRUID: string;
  overlayBck: boolean;
}

interface ViewRealizerState {
  rootUID: string;
}

export class ViewRealizerReact extends React.PureComponent<ViewRealizerReactProps, ViewRealizerState> implements ViewRealizerControlListener {
  private modal: K2Modal;
  private parent: React.ReactInstance;
  private vr: ViewRealizer;

  constructor(props: ViewRealizerReactProps) {
    super(props);
    this.vr = ViewRealizerManager.getViewRealizer(this.props.VRUID);
    this.state = { rootUID: this.vr.getRoot().MetaData.ControlUID };
  }
  setAsInActive(): void {
    if (this.modal != null) {
      return this.modal.setAsInActive(this.vr.getIsVCXAsInActive());
    }
  }

  showModal(realizerUID: string, controlUID?: string): Promise<void> {
    if (this.modal != null) {
      return this.modal.show(realizerUID, this.parent, controlUID);
    }

    return Promise.reject("Modal isn't support.");
  }

  closeModal(realizerUID: string, controlUID?: string): Promise<void> {
    if (this.modal != null) {
      return this.modal.close(realizerUID, controlUID);
    }

    return Promise.reject("Modal isn't support.");
  }

  isSupportModal(): boolean {
    if (this.vr) {
      return this.vr.getPriorRealizer() === null || this.vr.getPriorRealizer().isModal();
    }

    return false;
  }

  reRealize(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.setState(
        (prevState) => {
          //pokud jsem dal jen novy root tak se mi K2view neprekreslilo, kdyz dam null a novy tak se prekresli. necham to tak, myslim ze to bude jinak az zakomponuju control mimo props.
          return { rootUID: "" };
        },
        () => {
          this.setState(
            (prevState) => {
              return { rootUID: this.vr.getRoot().MetaData.ControlUID };
            },
            () => {
              resolve();
            }
          );
        }
      );
    });
  }

  private refCallBack = (element: React.ReactInstance) => {
    if (element) this.parent = element;
  };

  private refModalCallback = (modal: K2Modal) => {
    this.modal = modal;
  };

  render() {
    if (this.vr && this.state.rootUID != "") {
      let ctrl = this.vr.getControlByUID(this.state.rootUID) as UFNclControlBase;
      if (!ctrl) return null;
      let modal: JSX.Element = null;
      if (this.isSupportModal()) {
        modal = <K2ModalControl vcx={this.vr.VCX} onRef={this.refModalCallback} realizerUID={this.vr.getRealizerUID()} />;
      }
      if (ctrl instanceof NclViewBase) {
        return (
          <K2View controlUID={ctrl.MetaData.ControlUID} vrUID={this.vr.getRealizerUID()} ref={this.refCallBack} overlayBck={this.props.overlayBck}>
            {modal}
          </K2View>
        );
      } else {
        throw new Error("Invalid control type: " + ctrl.MetaData.__type);
      }
    } else {
      return null;
    }
  }

  componentDidMount() {
    if (this.vr) {
      this.vr.afterMount(this);
    }
  }

  componentWillUnmount() {
    this.modal = null;
    this.parent = null;
    if (this.vr) {
      this.vr.willUnMount();
    }
    this.vr = null;
  }
}
