import * as React from "react";
import { K2RuleWithVCXProps, marginRule } from "./k2StyleRenderer";
import { WithContextPlacementProps, withContext, K2ComponentState, StyleHelper, AcquireControl } from "./k2hoc";
import { NclExpander, NclHeader, NclContainerBase, NclView, NclViewBase, NclMenuView } from "../common/components.ncl";
import { UpdateControl, UpdateExpander, Align, UpdateHeadered, cJSonFunctionExecuteShortcut } from "../common/communication.base";
import { K2TruncateText } from "./K2TruncateText";
import { K2ActionSeparator, K2Action } from "./K2Action";
import { K2FlowPanel } from "./K2FlowPanel";
import { K2ToolBar } from "./K2ToolBar";
import { K2LocatorPanel } from "./K2LocatorPanel";
import { renderToSheetList } from "fela-dom";

const exRule = (props: K2RuleWithVCXProps) => ({
  flexDirection: "column" as "column",
  minWidth: props.vcx.ExpanderControl.GetHFHeight() + "px",
});

const headerRule = (props: K2RuleWithVCXProps) => ({
  width: "100%",
  height: "auto",
  //	minHeight: props.vcx.ExpanderControl.GetHFHeight()+'px',
  paddingLeft: "0.5em",
  fontWeight: "bold" as "bold",
  flex: "none",
  flexDirection: "row" as "row",
  alignItems: "center" as "center",
  userSelect: "none" as "none",
  justifyContent: "space-between",
});

type HeaderRule = K2RuleWithVCXProps & {
  bckColor: string;
  color: string;
  border: boolean;
};

const headerBackground = (props: HeaderRule) => ({
  backgroundColor: props.bckColor,
  color: props.color,
  border: props.border ? props.vcx.sizeMap(1) + "px solid " + props.vcx.getColor(props.vcx.Data.ColorMap.ContentFrame1) : "none",
});

type ContentRule = K2RuleWithVCXProps & {
  inPreview: boolean;
};

const exContentRule = (props: ContentRule) => ({
  borderLeftWidth: props.vcx.sizeMap(1) + "px",
  borderLeftStyle: "solid" as "solid",
  borderLeftColor: props.vcx.getColor(props.vcx.Data.ColorMap.ContentFrame1),
  borderRightWidth: props.vcx.sizeMap(1) + "px",
  borderRightStyle: "solid" as "solid",
  borderRightColor: props.vcx.getColor(props.vcx.Data.ColorMap.ContentFrame1),
  borderBottomWidth: props.vcx.sizeMap(1) + "px",
  borderBottomStyle: "solid" as "solid",
  borderBottomColor: props.vcx.getColor(props.vcx.Data.ColorMap.ContentFrame1),
  background: props.inPreview ? props.vcx.getColor(props.vcx.Data.ColorMap.DataBrowseColorBck) : "transparent",
  flex: "1 0 auto",
});

const borderRule = (props: K2RuleWithVCXProps) => ({
  borderLeft: props.vcx.sizeMap(1) + "px solid " + props.vcx.getColor(props.vcx.Data.ColorMap.ContentFrame1),
  flex: "1 0 auto",
});

const cursorRule = (props: K2RuleWithVCXProps) => ({
  userSelect: "none" as "none",
});

const linkRule = (props: K2RuleWithVCXProps) => ({
  display: "block",
  ":hover": {
    textDecoration: "underline",
    cursor: "pointer",
  },
});

interface HeaderProps extends WithContextPlacementProps {
  title?: string;
  titleSuffix?: string;
  titleSuffixCommandEnabled?: boolean;
}

class Header extends React.PureComponent<HeaderProps, K2ComponentState<UpdateControl>> {
  private control: NclHeader;

  constructor(props: HeaderProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclHeader;
    }) as NclHeader;
    this.state = { data: this.control.init(this) as UpdateControl, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateControl>) => {
      return { data: state as UpdateControl };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  getHeaderClasses(): string {
    let color = !this.control.Headered.isLite()
      ? this.control.VCX.getColor(this.control.VCX.Data.ColorMap.AccentBaseColorFrg)
      : this.control.VCX.getColor(this.control.VCX.Data.ColorMap.AccentBaseColorBck);
    let bckColor = !this.control.Headered.isLite()
      ? this.control.VCX.getColor(this.control.VCX.Data.ColorMap.AccentBaseColorBck)
      : this.control.VCX.getColor(this.control.VCX.Data.ColorMap.ContentColorBck1);
    let border = this.control.Headered.isLite();

    if (this.control.Headered instanceof NclView) {
      color = this.control.VCX.getColor(this.control.VCX.Data.ColorMap.AccentBaseColorFrg);
      bckColor = this.control.VCX.getColor(this.control.VCX.Data.ColorMap.BaseColorBck1);
      border = false;
    } else if (this.control.Headered instanceof NclMenuView) {
      color = this.control.VCX.getColor(this.control.VCX.Data.ColorMap.BaseColorBck1);
      bckColor = this.control.VCX.ColorMap.getColor(this.control.VCX.Data.ColorMap.BaseColorFrg1);
      border = true;
    }

    let rule: string = "";
    rule =
      this.props.renderer.renderRule(headerBackground, { vcx: this.control.VCX, bckColor: bckColor, color: color, border: border }) +
      " " +
      " " +
      this.props.renderer.renderRule(headerRule, { vcx: this.control.VCX }) +
      " " +
      this.props.renderer.renderFontRule(
        !this.control.Headered.isLite() ? this.control.VCX.ExpanderControl.HeaderFont : this.control.VCX.ExpanderControl.LitleHeaderFont,
        this.control.VCX
      );

    return rule;
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    let separator,
      rButtons,
      toolbar,
      locator,
      lButton: JSX.Element = null;
    if (this.control.LQuickButton) {
      lButton = (
        <K2Action
          controlUID={this.control.LQuickButton.MetaData.ControlUID}
          vrUID={this.control.getRealizerUID()}
          style={{ flex: "none", width: "auto", paddingRight: this.control.VCX.cssSizeMap(4, "px") }}
        />
      );
    }
    if (this.control.Separator) {
      separator = <K2ActionSeparator controlUID={this.control.Separator.MetaData.ControlUID} vrUID={this.props.vrUID} />;
    }
    if (this.control.RQuickButtons) {
      rButtons = (
        <div style={{ flex: "none", overflow: "hidden", paddingRight: `${this.control.VCX.sizeMap(3)}px` }}>
          {this.control.RQuickButtons.map((ctrl, index) => {
            return (
              <K2Action
                controlUID={ctrl.MetaData.ControlUID}
                vrUID={ctrl.getRealizerUID()}
                key={ctrl.MetaData.ControlUID + "_a_" + index}
                style={{ flex: "none", width: "auto" }}
              />
            );
          })}
        </div>
      );
    }
    if (this.control.ToolBar) {
      toolbar = <K2ToolBar controlUID={this.control.ToolBar.MetaData.ControlUID} vrUID={this.props.vrUID} style={{ width: "auto" }} />;
    }

    if (this.control.LocatorPanel) {
      locator = (
        <K2LocatorPanel
          controlUID={this.control.LocatorPanel.MetaData.ControlUID}
          vrUID={this.control.getRealizerUID()}
          style={{ alignSelf: "center", flex: "none", width: "auto" }}
        />
      );
    }

    let classFin: string = this.getHeaderClasses();
    if (this.props.className && this.props.className != undefined) {
      classFin += " " + this.props.className;
    }

    let titleEl: JSX.Element;
    if (this.props.titleSuffixCommandEnabled && this.props.titleSuffix) {
      titleEl = (
        <div style={{ flex: "1 0 0%" }}>
          <K2TruncateText>{this.props.title + " - "}</K2TruncateText>
          <K2TruncateText className={this.props.renderer.renderRule(linkRule, { vcx: this.control.VCX })} onClick={this.handleClick}>
            {this.props.titleSuffix}
          </K2TruncateText>
        </div>
      );
    } else {
      let title = this.props.title;
      if (this.props.titleSuffix) {
        title += ` - ${this.props.titleSuffix}`;
      }
      titleEl = <K2TruncateText style={{ flex: "1 0 0%" }}>{title}</K2TruncateText>;
    }
    return (
      <div className={classFin} style={{ overflow: "auto" }}>
        {lButton}
        {titleEl}
        <div style={{ flex: "0 1 auto", flexDirection: "row", height: "100%" }}>
          {locator}
          {toolbar}
          {separator}
          {rButtons}
        </div>
      </div>
    );
  }

  private handleClick = () => {
    this.control.appendFunction({ Name: cJSonFunctionExecuteShortcut }, true);
  };
}

export const K2Header = withContext(Header);

class _Expander extends React.PureComponent<WithContextPlacementProps, K2ComponentState<UpdateExpander>> {
  static displayName = `K2Expander`;
  private control: NclExpander;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclExpander;
    }) as NclExpander;
    this.state = { data: this.control.init(this) as UpdateExpander, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateExpander>) => {
      return { data: state as UpdateExpander };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  componentDidUpdate(prevProps: WithContextPlacementProps, prevState: K2ComponentState<UpdateExpander>) {
    if (prevState.data.Collapsed != this.state.data.Collapsed) {
      if (this.control.Parent instanceof NclContainerBase) {
        this.control.Parent.changeVisibleAnyPartsOfContainer(this.control.Ncl.ControlUID);
      }
    }
  }

  render() {
    let classFin: string = this.props.renderer.renderRule(exRule, { vcx: this.control.VCX });
    if (this.props.className && this.props.className != undefined) {
      classFin += " " + this.props.className;
    }
    return (
      <div data-k2-test-id={this.control.MetaData.Name} style={StyleHelper(this.control, this.props.style)} className={classFin}>
        <K2Header
          key={this.control.Ncl.ControlUID + "_header"}
          controlUID={this.control.Header.MetaData.ControlUID}
          vrUID={this.props.vrUID}
          title={this.state.data.Collapsed && this.state.data.CollapsedTitle ? this.state.data.CollapsedTitle : this.state.data.Title}
        />
        <K2FlowPanel
          controlUID={this.control.Content.MetaData.ControlUID}
          vrUID={this.control.getRealizerUID()}
          className={
            this.props.renderer.renderRule(exContentRule, { vcx: this.control.VCX, inPreview: this.control.isInPreview() }) +
            " " +
            this.props.renderer.renderRule(marginRule, { vcx: this.control.VCX, mX: 2, mY: 2 })
          }
        />
      </div>
    );
  }
}

export const K2Expander = withContext(_Expander);
