import { DataRequest, MultiMessage, LoginInfo } from "./communication.base";
import { Log, parseData } from "./common";
import * as JSZip from "jszip";
import { Context } from "../appcontext";

export enum NclMessageType {
  nmsgOK = 0,
  nmsgRealize = 1,
  nmsgData = 2,
  nmsgClose = 3,
  nmsgTerminate = 4,
  nmsgMulti = 5,
  // nmsgMultiWithOutData = 6, Not used on the client
  nmsgOutData = 7,
  nmsgNoop = 8,
  nmsgPostback = 9,
}

export enum ConnectionState {
  Unknown = 0,
  Connected = 1,
  Close = 2,
  Loging = 3,
  Logged = 4,
  Logouting = 5,
  LoggedOut = 6,
}

export interface NclMessage {
  messageType: NclMessageType;
  realizerUID?: string;
  realizeCounter?: number;
  useCompress?: boolean;
  json?: string;
  compressData?: any;
}

enum TPostbackKind {
  pkNone, // Už neposílat postback informace
  pkContinue, // Pokračovat v zasílání postback informací
  pkStop, // Ukončit zpracování vlákna
  pkProgress, // Posílají se informace o průběhu
  pkQuestion, // Posílá se otázka
  pkAnswer, // Posílá se odpověď na otázku
}

export interface PostbackMessage {
  PostbackKind: TPostbackKind;
  ProgressTitle: string;
  ProgressPercentage: string;
}

export class NclMessageHelper {
  public static CreateTerminateMsg(realizerUID: string): NclMessage {
    return { messageType: NclMessageType.nmsgTerminate, realizerUID: realizerUID };
  }

  public static CreateLoginMsg(user: string, password: string): NclMessage {
    return { messageType: NclMessageType.nmsgRealize, json: JSON.stringify([user, password, Context.DeviceInfo.OSInfo, Context.DeviceInfo.BrowserInfo]) };
  }

  public static CreateLoginInfoMsg(user: string): NclMessage {
    return { messageType: NclMessageType.nmsgRealize, json: user };
  }

  public static CreateNoopMsg(): NclMessage {
    return { messageType: NclMessageType.nmsgNoop };
  }

  public static CreateRealizeMsg(): NclMessage {
    return {
      messageType: NclMessageType.nmsgRealize,
      json: JSON.stringify({
        ClassName: Context.DeviceInfo.StartClassName,
        ScreenSize: Context.DeviceInfo.ScreenWidth,
        TransformColumnsCount: Context.DeviceInfo.TransformColumnsCount,
        Culture: Context.DeviceInfo.CurrentCulture,
        PDFSupport: Context.DeviceInfo.IsPDFSupport,
        IndepententFormatMode: Context.DeviceInfo.IndependentFormatMode,
      }),
    };
  }

  public static CreateRealizeAppMsg(reconnectId: string): NclMessage {
    if (reconnectId) {
      return { messageType: NclMessageType.nmsgRealize, realizerUID: reconnectId };
    }
    return { messageType: NclMessageType.nmsgRealize };
  }

  public static CreateUpdateMsg(realizerUID: string, realizeCounter: number, request: DataRequest): NclMessage {
    return { messageType: NclMessageType.nmsgData, realizerUID: realizerUID, json: JSON.stringify(request), realizeCounter: realizeCounter };
  }

  public static Create(msg: MultiMessage): NclMessage {
    return { messageType: msg.Operation, realizerUID: msg.RealizerUID, json: msg.JSon, realizeCounter: msg.RealizeCounter };
  }

  public static CreatePostbackMsg(stop: boolean): NclMessage {
    return { messageType: NclMessageType.nmsgPostback, json: stop ? `{PostbackKind:${TPostbackKind.pkStop}}` : `{PostbackKind:${TPostbackKind.pkContinue}}` };
  }

  public static unzipMessage(msg: NclMessage): Promise<boolean> {
    if (msg.useCompress === true) {
      const promise: Promise<boolean> = new Promise<boolean>((resolve, reject) => {
        if (!msg.compressData) reject("Invalid message data: " + JSON.stringify(msg));
        let zip = new JSZip();
        zip.loadAsync(msg.compressData, { base64: true }).then((zip: JSZip) => {
          zip
            .file("message")
            .async("text")
            .then((data: string) => {
              msg.json = data.replace(/\0/g, "");
              resolve(true);
            });
        });
      });

      return promise;
    } else {
      return Promise.resolve<boolean>(true);
    }
  }
}

export interface ReceiveCallBack {
  resolve: (data: any) => void;
  reject: (reason: any) => void;
}

export class K2CommunicationError extends Error {
  private _closeEvent: CloseEvent;
  private _message: string;
  private _detailMessage: string;

  constructor(closeEvent?: CloseEvent, message?: string) {
    super(K2CommunicationError.getDetailMessage(closeEvent, message));
    this._closeEvent = closeEvent;
    this._message = message;
  }

  private static getDetailMessage(closeEvent?: CloseEvent, message?: string): string {
    let result: string = "";
    if (message) {
      result = message + "\n";
    }

    if (closeEvent) {
      result += "Code: " + closeEvent.code + " Reason: " + closeEvent.reason;
    }

    return result;
  }

  get closeEvent(): CloseEvent {
    return this._closeEvent;
  }
}

export interface ReceiveData {
  message: NclMessage;
  error: K2CommunicationError;
}

/**
 * Třída pro komunikaci s WS pomoci WebSocketu
 */
export class Connection {
  private receiveCallbacksQueue: Array<ReceiveCallBack>; //fronta callbacku
  private receiveMessagesQueue: Array<NclMessage>; //fronta příchozích zpráv
  private wsUrl: string; // url WebSocketu
  private baseUrl: string; // url pro přístup k obrázkům
  private socket: WebSocket;
  private closeEvent: CloseEvent;
  private checkMessCount: number;
  private lastActivity: Date;
  protected lastMessage: Blob;

  public constructor(url: string, https: boolean) {
    this.baseUrl = https ? `https://${url}` : `http://${url}`;
    this.wsUrl = https ? `wss://${url}/ws` : `ws://${url}/ws`;
    this.clear();
  }

  public getBaseUrl(): string {
    return this.baseUrl;
  }

  public get isConnected(): boolean {
    return this.socket !== null && this.socket.readyState === WebSocket.OPEN;
  }

  public get LastActivity(): Date {
    return this.lastActivity;
  }

  public async connect(): Promise<void> {
    return this.disconnect().then(
      () => {
        this.clear();
        this.socket = new WebSocket(this.wsUrl);
        return this.initListeners();
      },
      () => {
        Log.warn("Couldn't be disconnect socket.");
      }
    );
  }

  public async disconnect(code?: number, reason?: string): Promise<CloseEvent> {
    if (!this.isConnected) {
      return Promise.resolve(this.closeEvent);
    }

    return new Promise<CloseEvent>((resolve, reject) => {
      const callbacks = {
        resolve: (dummy: any) => {
          this.receiveCallbacksQueue.push(callbacks);
        },

        reject: resolve,
      };

      this.receiveCallbacksQueue.push(callbacks);
      this.socket.close(code, reason);
    });
  }

  public async receive(): Promise<ReceiveData> {
    if (this.receiveMessagesQueue.length !== 0) {
      return Promise.resolve<ReceiveData>({ message: this.receiveMessagesQueue.shift(), error: null });
    }

    if (!this.isConnected) {
      return Promise.reject({
        message: null,
        error: new K2CommunicationError(this.closeEvent, "Not connected."),
      });
    }

    const promise: Promise<ReceiveData> = new Promise<ReceiveData>((resolve, reject) => {
      this.receiveCallbacksQueue.push({ resolve: resolve, reject: reject });
    });

    return promise;
  }

  private isSendMessageAllowed(message: NclMessage): boolean {
    return (
      this.checkMessCount === 0 || //jen pokud se zrovna neceka na odpoved
      (this.checkMessCount >= 0 && message && message.messageType === NclMessageType.nmsgPostback)
    ); // jediny postback chodi v prubehu request  -  response, ale taky musi byt parovy
  }

  public send(message: NclMessage): boolean {
    if (!this.isSendMessageAllowed(message)) return false;

    if (!this.isConnected) {
      Log.error("Socket isn't connected.", new K2CommunicationError(this.closeEvent, "Socket isn't connected."));
      return false;
    }

    let msg = JSON.stringify(message);
    this.lastActivity = new Date();
    this.socket.send(msg);
    this.checkMessCount++;
    return true;
  }

  public async getLoginInfo(user: string): Promise<LoginInfo> {
    return new Promise<LoginInfo>(async (resolve, reject) => {
      this.connect()
        .then(() => {
          if (this.send(NclMessageHelper.CreateLoginInfoMsg(user))) {
            return this.receive().then((data) => {
              if (data.message) {
                if (data.message.messageType === undefined || data.message.messageType === NclMessageType.nmsgOK) {
                  resolve(parseData(data.message.json) as LoginInfo);
                } else {
                  reject(data.message.json);
                }
              } else {
                if (data.error) {
                  Log.error("LoginInfo", data.error);
                  reject(data.error.message);
                }

                reject("LoginInfo Failed");
              }
            });
          } else {
            reject("Request GetLoginInfo not sended.");
          }
        })
        .catch((reason) => {
          if (reason instanceof Event) {
            reject(this.lastMessage);
          } else {
            reject(reason);
          }
        });
    });
  }

  public async login(user: string, password: string): Promise<boolean> {
    if (this.send(NclMessageHelper.CreateLoginMsg(user, password))) {
      return this.receive()
        .then((data) => {
          if (data.message) {
            if (data.message.messageType === undefined || data.message.messageType === NclMessageType.nmsgOK) {
              return Promise.resolve(true);
            } else {
              return Promise.reject(data.message.json);
            }
          } else {
            if (data.error) {
              Log.error("Login", data.error);
              return Promise.reject(data.error.message);
            }

            return Promise.reject("Login Failed");
          }
        })
        .catch((reason) => {
          if (reason instanceof Event) {
            return Promise.reject(this.lastMessage);
          } else {
            return Promise.reject(reason);
          }
        });
    } else {
      return Promise.reject("Request Login not sended.");
    }
  }

  private processMessage(data: string) {
    let obj: NclMessage = null;
    if (data) {
      obj = parseData(data);
      if (obj != null) {
        if (obj.messageType === NclMessageType.nmsgPostback) {
          NclMessageHelper.unzipMessage(obj)
            .then((e) => {
              let pm: PostbackMessage = parseData(obj.json);

              if (Context.getApplication().canStopPostback(pm)) {
                this.send(NclMessageHelper.CreatePostbackMsg(true));
              } else {
                this.send(NclMessageHelper.CreatePostbackMsg(false));
              }
            })
            .catch((reason) => {
              console.log(reason);
            });
          return;
        }
        if (this.receiveCallbacksQueue.length !== 0) {
          this.receiveCallbacksQueue.shift().resolve({ message: obj, error: null });
          return;
        }
        if (this.checkMessCount != 0) {
          //checkMessCount !=0 means that message received from server without client request. Terminate - server ended klient session.. Or an error has occurred.
          if (obj.messageType === NclMessageType.nmsgTerminate) {
            Context.getApplication().terminate(obj);
            return;
          }
          throw new Error("Send/Receive message mishmash:" + data);
        } else {
          this.receiveMessagesQueue.push(obj);
        }
        return;
      }
    }
    throw new Error("Unknown receive data:" + data);
  }

  private blobToString(blob: Blob, callback: any) {
    var f = new FileReader();
    f.onload = function (e) {
      callback(e.target.result);
    };
    f.readAsText(blob);
  }

  private initListeners(): Promise<void> {
    let _socket = this.socket;
    return new Promise((resolve, reject) => {
      const handleMessage = async (ev: MessageEvent) => {
        this.lastMessage = ev.data;
        this.lastActivity = new Date();
        this.checkMessCount--;
        this.blobToString(await ev.data, (json: string) => {
          this.processMessage(json);
        });
      };

      const handleOpen = (ev: Event): any => {
        this.lastActivity = new Date();
        _socket.onmessage = handleMessage;
        _socket.onclose = async (ev: CloseEvent) => {
          Log.error(`Websocket error reason code 2: ${ev.code} + ${ev.reason}`, null);
          this.closeEvent = ev;
          while (this.receiveCallbacksQueue.length !== 0) {
            this.receiveCallbacksQueue.shift().reject(this.closeEvent);
          }

          if (ev.code === 1000) {
            //iis send only this code in te case that app closed
            if (Context.getApplication().appViewRealizer != null) {
              Context.getApplication().terminate();
            }
          } else {
            reject(`Websocket error reason code 2: ${ev.code} + ${ev.reason}`);
          }
        };
        resolve();
      };

      _socket.onopen = handleOpen;
      _socket.onerror = (e) => {
        Log.error(`Websocket error`, null);
        reject(`Websocket error`);
      };
    });
  }

  private clear() {
    this.closeEvent = null;
    this.socket = null;
    this.receiveCallbacksQueue = new Array<ReceiveCallBack>();
    this.receiveMessagesQueue = new Array<NclMessage>();
    this.checkMessCount = 0;
  }
}
