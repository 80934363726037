import { List } from "immutable";
import * as React from "react";

import { Context } from "../appcontext";
import { CSFile, TOpenOption, UFUpdateControl, UpdateControl, cClientFileType } from "../common/communication.base";
import { NclButton, NclOpenDialog } from "../common/components.ncl";
import { VisualContext } from "../common/visualContext";
import { K2Button } from "./K2Button";
import { K2Header } from "./K2Expander";
import { AcquireControl, K2ComponentState, StyleHelper, withContext, WithContextPlacementProps } from "./k2hoc";
import { K2Img } from "./K2Image";
import { K2RuleWithVCXProps, K2StyleRenderer } from "./k2StyleRenderer";
import { K2TruncateText } from "./K2TruncateText";

interface OpenDialogState {
  IsOpen: boolean;
  Index: number;
  UpData: List<FileItem>;
  dragging: boolean;
}

const openDialogContainer = (props: K2RuleWithVCXProps) => ({
  flex: 1,
  flexDirection: "column" as "column",
  padding: "1%",
  backgroundColor: "#FFF",
});

/* DropArea */
const dropArea = (props: K2RuleWithVCXProps) => ({
  flex: 1,
  flexDirection: "column" as "column",
  justifyContent: "center",
  border: "2px " + props.vcx.getColor(props.vcx.Data.ColorMap.ContentFrame1) + " dashed",
  minHeight: "15vh",
});

const dropAreaContent = (props: K2RuleWithVCXProps) => ({
  margin: "auto",
  maxWidth: "100%",
  textAlign: "center" as "center",
});

const openDialogOriginalAccessor = (props: K2RuleWithVCXProps) => ({
  display: "none",
});

const openDialogAccessor = (props: K2RuleWithVCXProps) => ({
  display: "block",
  width: "100%",
  height: "100%",
  ">label": {
    display: "block",
    width: "100%",
    backgroundColor: props.vcx.getColor(props.vcx.Data.ColorMap.AccentBaseColorBck),
    color: props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorFrg1),
    padding: Math.floor(props.vcx.MinRowHeight / 2),
    ":hover": {
      backgroundColor: props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorBck1),
    },
  },
  ">p": {
    fontSize: "130%",
    lineHeight: "140%",
  },
});

/* Buttons */
const buttonArea = (props: K2RuleWithVCXProps) => ({
  flex: "0 0 auto",
  marginTop: props.vcx.MinRowHeight,
});

const buttonContainer = (props: K2RuleWithVCXProps) => ({
  width: "100%",
  flex: "0 0 auto",
  justifyContent: "flex-end",
});

const okButton = {
  minWidth: "20%",
  padding: 0,
};

const cancelButton = {
  minWidth: "10%",
  padding: 0,
};

interface FileItem extends CSFile {
  Id: number;
  Size: number;
}

class _OpenDialog extends React.PureComponent<WithContextPlacementProps, OpenDialogState> {
  static displayName = "K2OpenDialog";

  private fileReader: FileReader;
  private input: HTMLInputElement;
  private control: NclOpenDialog;

  private dragEventCounter: number = 0;
  private filesBuffer: FileList;
  private fileUIDCounter: number = 0;
  private fileAccessFilter: string;
  private fileAllowType: string;

  private allowMultipleSelect: boolean;
  private ref = React.createRef<HTMLDivElement>();

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclOpenDialog;
    }) as NclOpenDialog;
    this.state = { IsOpen: false, Index: 0, UpData: List<FileItem>(), dragging: false };
    this.allowMultipleSelect = (this.control.Ncl.FrgtData.Options & TOpenOption.ofAllowMultiSelect) == TOpenOption.ofAllowMultiSelect;
    this.handleRemoveFile = this.handleRemoveFile.bind(this);
    this.computeAllowType();
  }

  componentDidUpdate() {
    if (this.state.IsOpen) {
      (document.activeElement as HTMLElement).blur();
      this.ref.current.focus();
    }
  }

  componentWillUnmount() {
    this.control = null;
  }

  render() {
    if (this.filesBuffer && this.state.Index < this.filesBuffer.length) {
      this.readNextFile();
    } else {
      this.setMouseCursor("default");
      if (this.input) {
        //čistím input abych mohl opětovně vybrat stejný soubor a došlo tak k odpálění onChange
        this.input.value = "";
      }
    }

    let dropAreaComputed: string = this.props.renderer.renderRule(dropArea, { vcx: this.control.VCX });
    if (this.state.dragging) {
      dropAreaComputed += " file-uploader--dragging";
    }

    return (
      <div tabIndex={-1} ref={this.ref} onFocus={this.handleFocus}>
        {this.control.isLite() && (
          <K2Header
            controlUID={this.control.Header.MetaData.ControlUID}
            vrUID={this.control.getRealizerUID()}
            className={"handle_" + this.control.MetaData.ControlUID}
          />
        )}
        <div
          className={this.props.renderer.renderRule(openDialogContainer, { vcx: this.control.VCX })}
          onDrag={this.overrideEventDefaults}
          onDragStart={this.overrideEventDefaults}
          onDragEnd={this.overrideEventDefaults}
          onDragOver={this.overrideEventDefaults}
          onDragEnter={this.dragenterListener}
          onDragLeave={this.dragleaveListener}
          onDrop={this.dropListener}
        >
          <div className={dropAreaComputed}>
            <div className={this.props.renderer.renderRule(dropAreaContent, { vcx: this.control.VCX })}>{this.openDialogAccessor()}</div>
          </div>

          <K2FileList
            items={this.state.UpData}
            handleRemoveFile={this.handleRemoveFile}
            allowMultipleSelect={this.allowMultipleSelect}
            renderer={this.props.renderer}
            vcx={this.control.VCX}
          />

          <div className={this.props.renderer.renderRule(buttonArea, { vcx: this.control.VCX })}>{this.fileDialogButtons()}</div>
        </div>
      </div>
    );
  }

  private computeAllowType() {
    let all: boolean = false;
    if (this.control.Ncl.FrgtData.Filter.toString().match("\\*\\.\\*")) {
      this.fileAccessFilter = "";
      all = true;
    }

    let regex = new RegExp("\\*\\.\\w+|\\*\\.\\*", "g");
    let matches = this.control.Ncl.FrgtData.Filter.toString().match(regex);
    if (matches) {
      if (!all) {
        this.fileAccessFilter = matches.join(",");
      }

      this.fileAllowType = matches.join(",").replace(new RegExp("\\*\\.\\*", "g"), "").replace(new RegExp("\\*", "g"), "");
    }
  }

  private openDialogAccessor(): JSX.Element {
    let suffix: string = "";
    if (this.allowMultipleSelect) {
      suffix = "y";
    }
    return (
      <div className={this.props.renderer.renderRule(openDialogAccessor, { vcx: this.control.VCX })}>
        <K2TruncateText className={"mouse_only"}>{`Přetáhněte soubor${suffix} sem`}</K2TruncateText>
        <K2TruncateText className={"mouse_only"}>nebo</K2TruncateText>
        <label id={"filesUp_select_file_label"}>
          <K2TruncateText>{`Vybrat soubor${suffix} na disku`}</K2TruncateText>
          <input
            type="file"
            multiple={this.allowMultipleSelect}
            onChange={this.handleChange}
            onClick={this.handleClickOD}
            ref={(ref) => (this.input = ref)}
            className={this.props.renderer.renderRule(openDialogOriginalAccessor, { vcx: this.control.VCX })}
            accept={this.fileAllowType}
          />
        </label>
      </div>
    );
  }

  private fileDialogButtons(): JSX.Element {
    return (
      <div className={this.props.renderer.renderRule(buttonContainer, { vcx: this.control.VCX })}>
        {this.control.OK && this.state.UpData.count() ? (
          <K2Button
            controlUID={this.control.OK.MetaData.ControlUID}
            vrUID={this.props.vrUID}
            beforeExecute={this.beforeOKExecute}
            style={Object.assign({}, okButton, { paddingRight: this.control.VCX.MinRowHeight })}
          />
        ) : null}
        {this.control.Cancel && <K2Button id={"filesUp"} controlUID={this.control.Cancel.MetaData.ControlUID} vrUID={this.props.vrUID} style={cancelButton} />}
      </div>
    );
  }

  private checkFilesTypeBeforeUpadte(name: string): boolean {
    let type: string = name.split(".")[name.split(".").length - 1];
    let allowFileResult: boolean = !this.fileAccessFilter || this.fileAccessFilter == "" ? true : false;
    if (!allowFileResult) {
      let allowFileTypeArray: Array<string> = this.fileAccessFilter.split(",");
      if (type.length) {
        allowFileTypeArray.map((item) => {
          if (item.endsWith(type)) {
            allowFileResult = true;
          }
        });
      }
    }
    return allowFileResult;
  }

  private clearSelectedFiles(): void {
    if (this.allowMultipleSelect) {
      this.setState({ Index: 0 });
    } else {
      this.setState({ UpData: List<FileItem>(), Index: 0 });
    }
    this.filesBuffer = null;
  }

  private beforeOKExecute = (control: NclButton) => {
    this.control.acceptFiles(this.state.UpData.toArray());
  };

  private handleRemoveFile(index: number): void {
    let newData = List<FileItem>();
    if (index > 0 && this.state.UpData) {
      this.state.UpData.filter((item) => item.Id != index).map((file: FileItem) => {
        newData = newData.push(file);
      });
    }
    this.setState({ UpData: newData });
  }

  private handleFocus = (e: React.FocusEvent<HTMLDivElement>) => {
    if (this.state.IsOpen) {
      Context.getApplication().allowSendMessages();
      this.setState({ IsOpen: false });
    }
  };

  private handleClickOD = (e: React.MouseEvent<HTMLInputElement>) => {
    if (!this.state.IsOpen) {
      this.setState({ IsOpen: true });
      Context.getApplication().forbidenSendMessages();
    }
  };

  private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.clearSelectedFiles();
    if (e.target.files.length > 0) {
      this.filesBuffer = this.input.files;
      this.readNextFile();
    }
  };

  private setMouseCursor(style: string): void {
    document.body.style.cursor = style;
  }

  private readNextFile() {
    this.setMouseCursor("wait");
    if (this.filesBuffer.length > this.state.Index) {
      this.fileReader = new FileReader();
      this.fileReader.onloadend = this.handleFileRead;
      this.fileReader.readAsArrayBuffer(this.filesBuffer[this.state.Index]);
    }
  }

  private handleFileRead = (e: Event) => {
    if (!this.filesBuffer && this.filesBuffer.length >= this.state.Index) return;

    if (!this.allowMultipleSelect && this.state.UpData.count() >= 1) {
      this.setMouseCursor("default");
      this.filesBuffer = null;
      return;
    }

    let newIndex = this.state.Index + 1;
    if (this.filesBuffer[this.state.Index]) {
      if (this.checkFilesTypeBeforeUpadte(this.filesBuffer[this.state.Index].name)) {
        if (this.filesBuffer[this.state.Index]) {
          this.fileUIDCounter++;
          let newData: List<FileItem> = this.state.UpData.push({
            Id: this.fileUIDCounter,
            FileName: this.filesBuffer[this.state.Index].name,
            Data: this.getFileData(),
            Size: this.filesBuffer[this.state.Index].size,
            __type: cClientFileType,
          });
          this.setState({
            UpData: newData,
            Index: newIndex,
          });
        }
      } else {
        this.setState({ Index: newIndex });
      }
    }
  };

  private getFileData(): string {
    let data = this.fileReader.result;
    if (data instanceof ArrayBuffer) {
      return btoa(
        new Uint8Array(data).reduce(function (data, byte) {
          return data + String.fromCharCode(byte);
        }, "")
      );
    } else {
      return data;
    }
  }

  /* Drag&Drop files */
  private dragenterListener = (event: React.DragEvent<HTMLDivElement>) => {
    this.overrideEventDefaults(event);
    this.dragEventCounter++;
    if (event.dataTransfer.items && event.dataTransfer.items[0]) {
      this.setState({ dragging: true });
    } else if (event.dataTransfer.types && event.dataTransfer.types[0] === "Files") {
      //support for IE
      this.setState({ dragging: true });
    }
  };

  private dragleaveListener = (event: React.DragEvent<HTMLDivElement>) => {
    this.overrideEventDefaults(event);
    this.dragEventCounter--;

    if (this.dragEventCounter === 0) {
      this.setState({ dragging: false });
    }
  };

  private dropListener = (event: React.DragEvent<HTMLDivElement>) => {
    this.overrideEventDefaults(event);
    this.setState({ dragging: false });
    if (event.dataTransfer.files) {
      this.clearSelectedFiles();
      this.filesBuffer = event.dataTransfer.files;
    }
  };

  private overrideEventDefaults = (event: Event | React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
}
export const K2OpenDialog = withContext(_OpenDialog);

interface FileListProps {
  handleRemoveFile(index: number): void;
  items: List<FileItem>;
  allowMultipleSelect: boolean;
  renderer: K2StyleRenderer;
  vcx: VisualContext;
}

const selectedFileArea = (props: K2RuleWithVCXProps) => ({
  flex: "0 0 auto",
  height: "auto",
  flexDirection: "column" as "column",
  marginTop: props.vcx.MinRowHeight,
});

const selectedFileHeader = (props: K2RuleWithVCXProps) => ({
  display: "block",
  flex: "0 0 auto",
  width: "100%",
  height: "auto",
  padding: Math.floor(props.vcx.MinRowHeight / 2),
  backgroundColor: props.vcx.getColor(props.vcx.Data.ColorMap.AccentBaseColorBck),
  color: props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorFrg1),
  ">button": {
    display: "block",
    float: "right" as "right",
    backgroundColor: props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorFrg1),
    border: "none",
    padding: "1px 15px",
  },
});

const selectFileList = (props: K2RuleWithVCXProps) => ({
  flex: "0 0 auto",
  flexDirection: "column" as "column",
  width: "100%",
  height: "auto",
  maxHeight: "20vh",
  overflow: "auto",
  backgroundColor: props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorFrg1),
  border: "2px " + props.vcx.getColor(props.vcx.Data.ColorMap.ContentFrame1) + " solid",
  borderTop: "none",
});

class K2FileList extends React.PureComponent<FileListProps> {
  static displayName = "K2FileList";

  constructor(props: FileListProps) {
    super(props);
    this.handleRemoveFile = this.handleRemoveFile.bind(this);
    this.handleRemoveAllFiles = this.handleRemoveAllFiles.bind(this);
  }

  handleRemoveFile(index: number) {
    this.props.handleRemoveFile(index);
  }

  handleRemoveAllFiles() {
    this.props.handleRemoveFile(0);
  }

  render() {
    let suffix: string = "ý";
    let suffix2: string = "";
    if (this.props.allowMultipleSelect) {
      suffix = "é";
      suffix2 = "y";
    }

    return (
      <div className={this.props.renderer.renderRule(selectedFileArea, { vcx: this.props.vcx })}>
        <div className={this.props.renderer.renderRule(selectedFileHeader, { vcx: this.props.vcx })}>
          Vybran{suffix} soubor{suffix2} - {this.props.items.count()}
          {this.props.items.count() > 1 && <button onClick={this.handleRemoveAllFiles}>Smazat vše</button>}
        </div>
        {this.getSelectedFiles()}
      </div>
    );
  }

  private getSelectedFiles(): JSX.Element {
    const selectedFileList: Array<JSX.Element> = [];

    this.props.items.map((value: FileItem, key: number) => {
      selectedFileList.push(
        <K2FileListItem
          item={value}
          handleRemoveFile={this.handleRemoveFile}
          renderer={this.props.renderer}
          vcx={this.props.vcx}
          key={"FileListItem_" + key}
        ></K2FileListItem>
      );
    });

    if (!this.props.items.count()) {
      return;
    }

    return <div className={this.props.renderer.renderRule(selectFileList, { vcx: this.props.vcx })}>{selectedFileList}</div>;
  }
}

interface FileListItem {
  handleRemoveFile(index: number): void;
  item: FileItem;
  renderer: K2StyleRenderer;
  vcx: VisualContext;
}

const selectedFileView = (props: K2RuleWithVCXProps) => ({
  flex: "0 0 auto",
  width: "100%",
  borderBottom: "1px " + props.vcx.getColor(props.vcx.Data.ColorMap.ContentFrame1) + " solid",
  padding: "2px 5px",
  color: props.vcx.getColor(props.vcx.Data.ColorMap.ContentDecorateColorFrg),
  fontSize: "105%",
  ">p": {
    flex: 1,
  },
  ">span": {
    flex: "0 1 auto",
    flexDirection: "reverse-row",
    fontSize: "90%",
    color: props.vcx.getColor(props.vcx.Data.ColorMap.ContentNormalColorFrg),
    paddingTop: 1,
    ">button": {
      border: "none",
      backgroundColor: "#FFF",
    },
  },
  ":hover": {
    backgroundColor: "#eee",
  },
  ":last-child": {
    border: "none",
  },
});

const selectedFileSize = (props: K2RuleWithVCXProps) => ({
  paddingRight: 10,
});

class K2FileListItem extends React.Component<FileListItem> {
  constructor(props: FileListItem) {
    super(props);
    this.removeFileFromList = this.removeFileFromList.bind(this);
  }

  private fileSizeFormat(num: number): string {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  }

  removeFileFromList() {
    this.props.handleRemoveFile(this.props.item.Id);
  }

  render() {
    return (
      <div className={this.props.renderer.renderRule(selectedFileView, { vcx: this.props.vcx })}>
        <K2TruncateText>{this.props.item.FileName.toString()}</K2TruncateText>
        <span className={this.props.renderer.renderRule(selectedFileSize, { vcx: this.props.vcx })}>
          {this.fileSizeFormat(Math.round(this.props.item.Size / 1024))} kB
        </span>
        <span>
          <button onClick={this.removeFileFromList}>
            <K2Img
              glyphId={"wui*close"}
              strokeColor={this.props.vcx.getColor(this.props.vcx.Data.ColorMap.ContentFrame1)}
              vcx={this.props.vcx}
              height={20}
              width={20}
              stretch={true}
            />
          </button>
        </span>
      </div>
    );
  }
}
