import { List } from "immutable";
import * as React from "react";
import ReactResizeDetector from "react-resize-detector";

import { ElementHtmlAttributes, getAttributes, isKeyOrDigit } from "../common/common";
import {
  ColumnProportionEm,
  GridOperations,
  OrderByItem,
  TAlignment,
  UpdateControl,
  UpdateInnerDataGrid,
  reCalculateColumnWidths,
  UpdateQuickFilter,
  UpdateAggregationPanel,
  CSAggrData,
  CSColumn,
  CSColumnBase,
  TCondFormattingOption,
  UpdateDataGrid,
  TTreeDataHasChildNodes,
  TUFContextDisplayStyle,
  ColumnsProportion,
} from "../common/communication.base";
import {
  NclDataGrid,
  NclDataGridContent,
  NclDataGridFooter,
  NclImage,
  NclInnerDataGrid,
  NclInput,
  NclQuickFilter,
  UFNclControlBase,
  NclAggregationPanel,
  DataGridListener,
  UFOverAlign,
  UFOverAlignListener,
} from "../common/components.ncl";
import { K2Header } from "./K2Expander";
import { AcquireControl, K2ComponentState, StyleHelper, withContext, WithContextPlacementProps, WithVCXProps } from "./k2hoc";
import { K2Img } from "./K2Image";
import { K2RuleWithVCXProps, getTextDecoration } from "./k2StyleRenderer";
import { K2ToolBar } from "./K2ToolBar";
import { K2TruncateText } from "./K2TruncateText";
import { ColumnBaseHelper, _Input } from "./K2Input";
import { GenerateControl } from "./K2GenerateControl";
import { KeyboardEventHandler } from "react";
import { VCXColorMap, VisualContext } from "../common/visualContext";

type K2ColumnRuleProps = K2RuleWithVCXProps & {
  proportion: ColumnProportionEm;
  fixed: boolean;
};

const dgColumnBaseRule = (props: K2ColumnRuleProps) => ({
  border: "1px solid " + props.vcx.getColor(props.vcx.Data.ColorMap.ContentFrame1),
  backgroundColor: props.fixed ? props.vcx.getColor(props.vcx.Data.ColorMap.ContentColorBck1) : "unset",
});
const column_padding_x = 6;
const dgHeaderColumnRule = (props: K2ColumnRuleProps) => ({
  padding: `0px ${column_padding_x}px`,
  ">div": {
    justifyContent:
      props.proportion && props.proportion.DisplayStyleRT !== TUFContextDisplayStyle.cdsImage ? getContentAlign(props.proportion.Alignment) : "center",
  },
});

export interface HeaderColumnProps extends WithVCXProps {
  columnProportion: ColumnProportionEm;
  onContextMenu: () => void;
  onColumnClick: (clickCount: number, shiftKey: boolean) => void;
  orderBy?: number;
  orderByCount?: number;
  fixed: boolean;
  onColumnResize?: (diffX: number, isCompleted: boolean) => void;
  lastColumn?: boolean;
  setColumns?: (options: { sizes: DOMRect[]; draggedColumnIndex: number }) => void;
  deleteColumn?: () => void;
}

interface HeaderColumnState {
  isResizing?: boolean;
  translateX?: number;
}

export class HeaderColumn extends React.PureComponent<HeaderColumnProps, HeaderColumnState> {
  static displayName = `K2HeaderColumn`;

  constructor(props: HeaderColumnProps) {
    super(props);
    this.state = { translateX: 0 };
  }
  private getRule(): string {
    let rule: string = this.props.renderer.renderRule(this.props.renderer.combineRule(dgColumnBaseRule, dgHeaderColumnRule), {
      fixed: this.props.fixed,
      proportion: this.props.columnProportion,
      vcx: this.props.vcx,
    });
    return rule;
  }

  private getOrderInfo = (): JSX.Element => {
    let icon: string = "wui*arrow.down";

    if (this.props.orderBy > 0) {
      icon = "wui*arrow.up";
    }

    if (this.props.orderByCount > 1 && Math.abs(this.props.orderBy) > 0) {
      return (
        <div>
          <K2Img glyphId={icon} vcx={this.props.vcx} height={19} strokeColor={this.props.vcx.getColor(this.props.vcx.Data.ColorMap.ContentFrame3)} />
          <span style={{ fontSize: "70%", marginTop: "20%" }}>{Math.abs(this.props.orderBy)}</span>
        </div>
      );
    } else if (Math.abs(this.props.orderBy) > 0) {
      return (
        <div>
          <K2Img glyphId={icon} vcx={this.props.vcx} height={19} strokeColor={this.props.vcx.getColor(this.props.vcx.Data.ColorMap.ContentFrame3)} />
        </div>
      );
    }
    return null;
  };

  render() {
    if (!this.props.vcx) return null;
    if (!this.props.renderer) return null;
    return (
      <th className={this.getRule()} style={{ position: "relative" }}>
        <div
          draggable={true}
          onContextMenu={this.handleContextMenu}
          onClick={this.handleClick}
          onDragStart={this.handleDragStart}
          onDragEnd={this.handleDragEnd}
          onTouchStart={this.handleDragStart}
          onTouchEnd={this.handleDragEnd}
        >
          <K2TruncateText className={this.props.renderer.renderFontRule(this.props.vcx.GridControl.HeaderFont, this.props.vcx)}>
            {this.props.columnProportion.Caption}
          </K2TruncateText>
          {this.getOrderInfo()}
        </div>
        <span
          className={this.props.lastColumn ? "column-resizable-handle last-column-resizable-handle" : "column-resizable-handle"}
          style={{ transform: `translateX(${this.state.translateX}px)` }}
          onMouseDown={this.handleMouseDown}
        >
          {(this.state.isResizing || this.state.translateX !== 0) && (
            <div style={{ borderRight: `1px solid ${this.props.vcx.getColor(this.props.vcx.Data.ColorMap.ContentNormalColorFrg)}` }} />
          )}
        </span>
      </th>
    );
  }

  private handleDragStart = (e: React.DragEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    let nodes = Array.from(e.currentTarget.parentElement.parentElement.children).map((node) => {
      return node.getBoundingClientRect();
    });

    let draggedColumnIndex = (e.currentTarget.parentElement as HTMLTableHeaderCellElement).cellIndex;
    this.props.setColumns({ sizes: nodes, draggedColumnIndex: draggedColumnIndex });
  };

  private handleDragEnd = (e: React.DragEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    let yPos = 0;

    if (e.nativeEvent instanceof DragEvent) {
      yPos = (e as React.DragEvent).clientY;
    } else {
      yPos = (e as React.TouchEvent).changedTouches[0].clientY;
    }

    if (yPos < e.currentTarget.getBoundingClientRect().top) {
      this.props.deleteColumn();
    }
  };

  private handleMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    this.setState({ isResizing: true });
    document.body.style.cursor = "col-resize";
    event.preventDefault();
  };

  componentDidUpdate(prevProps: HeaderColumnProps, prevState: HeaderColumnState) {
    if (prevState.isResizing !== this.state.isResizing) {
      if (this.state.isResizing == true) {
        document.addEventListener("mousemove", this.handleMouseMove);
        document.addEventListener("mouseup", this.handleMouseUp);
      } else {
        document.removeEventListener("mousemove", this.handleMouseMove);
        document.removeEventListener("mouseup", this.handleMouseUp);
      }
    }
  }

  private handleMouseMove = (e: MouseEvent) => {
    this.setState({ translateX: this.state.translateX + e.movementX / window.devicePixelRatio });
    if (this.props.onColumnResize && e.movementX !== 0) {
      this.props.onColumnResize.call(this, e.movementX, false);
    }
    e.preventDefault();
  };

  private handleMouseUp = (e: MouseEvent) => {
    let diff = this.state.translateX;
    this.setState({ isResizing: false, translateX: 0 });
    document.body.style.cursor = "";
    if (this.props.onColumnResize && diff !== 0) {
      this.props.onColumnResize.call(this, diff, true);
    }
    e.preventDefault();
  };

  private timeout: any;
  private clickCount: number = 0;

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
  }

  private handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (this.props.onColumnClick) {
      if (this.timeout) clearTimeout(this.timeout);

      this.clickCount++;
      let shiftKey = e.shiftKey;

      this.timeout = setTimeout(() => {
        if (this.clickCount >= 2) {
          this.props.onColumnClick.call(this, 2, shiftKey);
        } else {
          this.props.onColumnClick.call(this, 1, shiftKey);
        }
        this.clickCount = 0;
      }, 250);
      e.preventDefault();
    }
  };

  private handleContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    if (this.props.onContextMenu) {
      this.props.onContextMenu.call(this, null);
    }
    e.preventDefault();
  };
}

export const K2HeaderColumn = withContext(HeaderColumn);

const dgHeaderBaseRule = (props: K2RuleWithVCXProps) => ({
  height: props.vcx.GridControl.GetRowHeight(1) + "px",
});

const dgHeaderRule = (props: K2RuleWithVCXProps) => ({
  background: props.vcx.getColor(props.vcx.Data.ColorMap.ContentColorBck1),
  color: props.vcx.getColor(props.vcx.Data.ColorMap.ContentDecorateColorFrg),
});

interface DGHeaderProps extends WithVCXProps {
  columnsProportion: Array<ColumnProportionEm>;
  onContextMenu: (rowIndex: number, colIndex: number) => void;
  onColumnClick: (rowIndex: number, colIndex: number, clickCount: number, shiftKey: boolean) => void;
  onColumnResize?: (colIndex: number, diffX: number, isCompleted: boolean) => void;
  fixedColumnsCount: number;
  setColumns?: (options: { sizes: DOMRect[]; draggedColumnIndex: number }) => void;
  deleteColumn?: () => void;
}

interface HeaderProps extends DGHeaderProps {
  orderBy: Array<OrderByItem>;
}

class DGHeader extends React.PureComponent<HeaderProps> {
  static displayName = `K2Header`;

  render() {
    let isFixed: boolean;
    return (
      <tr
        className={
          this.props.renderer.renderFontRule(this.props.vcx.GridControl.HeaderFont, this.props.vcx) +
          " " +
          this.props.renderer.renderRule(dgHeaderBaseRule, { vcx: this.props.vcx }) +
          " " +
          this.props.renderer.renderRule(dgHeaderRule, { vcx: this.props.vcx })
        }
      >
        {this.props.columnsProportion.map((value, index, list) => {
          isFixed = index < this.props.fixedColumnsCount;
          let orderBy = this.props.orderBy.find((item) => {
            return item.ColNdx === index;
          });
          return (
            <K2HeaderColumn
              fixed={index < this.props.fixedColumnsCount}
              orderByCount={this.props.orderBy.length}
              orderBy={orderBy ? orderBy.OrderByNdx : undefined}
              onColumnClick={(clickCount, shiftKey) => this.handleClick(index, clickCount, shiftKey)}
              onContextMenu={() => this.handleContextMenu(index)}
              onColumnResize={this.handleColumnResize(index)}
              key={value.Caption + index.toString() + "_hc"}
              columnProportion={value}
              vcx={this.props.vcx}
              lastColumn={index === list.length - 1}
              setColumns={this.props.setColumns}
              deleteColumn={this.props.deleteColumn}
            />
          );
        })}
      </tr>
    );
  }

  private handleColumnResize = (index: number) => (diffX: number, isCompleted: boolean) => {
    if (this.props.onColumnResize) {
      this.props.onColumnResize(index, diffX, isCompleted);
    }
  };

  private handleClick(index: number, clickCount: number, shiftKey: boolean): void {
    if (this.props.onColumnClick) {
      this.props.onColumnClick.call(this, 0, index, clickCount, shiftKey);
    }
  }

  private handleContextMenu(index: number): void {
    if (this.props.onContextMenu) {
      this.props.onContextMenu.call(this, -1, index);
    }
  }
}

const K2DGHeader = withContext(DGHeader);

interface QuickFilterProps extends WithContextPlacementProps {
  columnsProportion: Array<ColumnProportionEm>;
  fixedColumnsCount: number;
  reorderOptions: {
    dragIndex: number;
    dropIndex: number;
    updated: boolean;
    deleted: boolean;
  };
}

class _QuickFilter extends React.Component<QuickFilterProps, K2ComponentState<UpdateQuickFilter>> {
  static displayName = `K2DataGrid`;
  private control: NclQuickFilter;

  constructor(props: QuickFilterProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclQuickFilter;
    }) as NclQuickFilter;

    this.state = { data: this.control.init(this) as UpdateQuickFilter, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateQuickFilter>) => {
      return { data: state as UpdateQuickFilter };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render(): JSX.Element {
    if (!this.control.Filters || this.state.data.Visible !== true) return null;
    let filters = this.control.Filters;

    if (this.props.reorderOptions.deleted) {
      filters.splice(this.props.reorderOptions.dragIndex, 1);
    } else if (this.props.reorderOptions.updated) {
      (this.control.Parent as NclInnerDataGrid).Container.reorderColumns(this.props.reorderOptions.dragIndex, this.props.reorderOptions.dropIndex, filters);
    }

    return (
      <tr tabIndex={1} className={this.props.renderer.renderRule(dgHeaderRule, { vcx: this.control.VCX })}>
        {filters.map((value: UFNclControlBase, index: number) => {
          let proportion: ColumnProportionEm = null;
          if (this.props.columnsProportion.length > index) {
            proportion = this.props.columnsProportion[index];

            return (
              <th
                key={"column_filter_" + index}
                className={this.props.renderer.renderRule(dgColumnBaseRule, {
                  fixed: index < this.props.fixedColumnsCount,
                  proportion: proportion,
                  vcx: this.control.VCX,
                })}
              >
                {GenerateControl(value, { minHeight: this.control.VCX.GridControl.GetRowHeight(1) + "px", padding: 0 })}
              </th>
            );
          } else {
            return null;
          }
        })}
      </tr>
    );
  }
}

const K2QuickFilter = withContext(_QuickFilter);

const aggrColumnRule = (props: K2RuleWithVCXProps) => ({
  padding: `0px ${column_padding_x}px`,
  border: "1px solid " + props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorBck1),
  background: props.vcx.getColor(props.vcx.Data.ColorMap.DataBrowseColorBck),
  color: props.vcx.getColor(props.vcx.Data.ColorMap.DataBrowseColorFrg),
  height: "100%",
  width: "100%",
});

type AggrPanelProps = K2RuleWithVCXProps & {
  invalidData: Boolean;
};

const aggrPanelRule = (props: AggrPanelProps) => ({
  height: props.vcx.GridControl.GetRowHeight(1) + "px",
  width: "100%",
  opacity: props.invalidData ? 0.6 : 1,
  background: props.vcx.getColor(props.vcx.Data.ColorMap.ContentColorBck1),
});

interface AggregationPanelProps extends WithContextPlacementProps {
  columnsProportion: Array<ColumnProportionEm>;
  fixedColumnsCount: number;
  clientWidthDiffs?: Array<number>;
  width?: number;
  reorderOptions: {
    dragIndex: number;
    dropIndex: number;
    updated: boolean;
    deleted: boolean;
  };
}

class _AggregationPanel extends React.Component<AggregationPanelProps, K2ComponentState<UpdateAggregationPanel>> {
  static displayName = `K2AggregationPanel`;
  private control: NclAggregationPanel;

  constructor(props: AggregationPanelProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclAggregationPanel;
    }) as NclAggregationPanel;

    this.state = { data: this.control.init(this) as UpdateAggregationPanel, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateAggregationPanel>) => {
      return { data: state as UpdateAggregationPanel };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  componentDidUpdate(prevProps: AggregationPanelProps, prevState: K2ComponentState<UpdateAggregationPanel>) {
    if ((!this.props.reorderOptions.updated && !this.props.reorderOptions.deleted) || this.state.data.Data == null) return;
    let values = this.state.data.Data.toJS();

    if (this.props.reorderOptions.deleted) {
      values.splice(this.props.reorderOptions.dragIndex, 1);
    } else if (this.props.reorderOptions.updated) {
      (this.control.Parent as NclDataGrid).reorderColumns(this.props.reorderOptions.dragIndex, this.props.reorderOptions.dropIndex, values);
    }

    this.setState({ data: this.state.data.with({ Data: values }) });
  }

  render(): JSX.Element {
    if (!this.state.data.Data || this.state.data.Visible !== true) return null;
    let values: Array<CSAggrData> = this.state.data.Data.toJS();
    return (
      <div
        className={
          this.props.renderer.renderFontRule(this.control.VCX.GridControl.Font, this.control.VCX) +
          " " +
          this.props.renderer.renderRule(aggrPanelRule, { vcx: this.control.VCX, invalidData: this.state.data.InvalidData })
        }
      >
        <table tabIndex={1} className={this.props.renderer.renderRule(dgTableRule, { vcx: this.control.VCX, fillHeight: true, width: this.props.width })}>
          {this.props.columnsProportion && (
            <colgroup>
              {this.props.columnsProportion.map((value, i) => {
                let diff: number = 0;
                if (this.props.clientWidthDiffs && i < this.props.clientWidthDiffs.length) {
                  diff = this.props.clientWidthDiffs[i];
                }
                return <col key={`col__${i}`} style={{ width: `${value.Width + diff}px` }} />;
              })}
            </colgroup>
          )}
          <tbody>
            <tr>
              {values.map((value: CSAggrData, index: number) => {
                let proportion: ColumnProportionEm = null;
                if (this.props.columnsProportion && this.props.columnsProportion.length > index) {
                  proportion = this.props.columnsProportion[index];

                  return (
                    <th
                      key={"column_aggr_" + index}
                      style={{ height: this.control.VCX.GridControl.GetRowHeight(1) + "px" }}
                      className={this.props.renderer.renderRule(dgColumnBaseRule, {
                        fixed: index < this.props.fixedColumnsCount,
                        proportion: proportion,
                        vcx: this.control.VCX,
                      })}
                      onContextMenu={(e) => this.handleContextMenu(e, index)}
                    >
                      <div
                        className={this.props.renderer.renderRule(aggrColumnRule, { vcx: this.control.VCX })}
                        style={{ minWidth: `${this.control.VCX.GridControl.HeaderFont.computeTextWidth(proportion.Caption) + (column_padding_x * 2 - 1)}px` }}
                      >
                        {value.GlyphId && (
                          <K2Img
                            glyphId={value.GlyphId}
                            vcx={this.control.VCX}
                            height={this.control.VCX.sizeMap(16)}
                            strokeColor={this.control.VCX.getColor(this.control.VCX.Data.ColorMap.BaseColorBck1)}
                            style={{ width: "auto", alignSelf: "center" }}
                          />
                        )}
                        <K2TruncateText style={{ width: "100%", textAlign: getTextAlign(value.Align), alignSelf: "center", fontWeight: "normal" }}>
                          {value.Text.toString()}
                        </K2TruncateText>
                      </div>
                    </th>
                  );
                } else {
                  return null;
                }
              })}
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
  private handleContextMenu = (e: React.MouseEvent<HTMLDivElement>, index: number) => {
    this.control.contextMenu(index);
    e.preventDefault();
  };
}

const K2AggregationPanel = withContext(_AggregationPanel);

const dgTBodyRule = (props: K2RuleWithVCXProps) => ({
  color: props.vcx.getColor(props.vcx.Data.ColorMap.DataBrowseColorFrg),
});

interface RowsProps extends DGHeaderProps {
  rows: List<List<CSColumn>>;
  inEditMode: boolean;
  invokeAction: (op: GridOperations) => void;
  onKeyDown: KeyboardEventHandler<HTMLTableSectionElement>;
  onKeyPress: KeyboardEventHandler<HTMLTableSectionElement>;
  rowHeightMultiplier: number;
}

class Rows extends React.Component<RowsProps, {}> {
  private rows: Map<number, Row>;
  static displayName = `K2Rows`;
  private scrollContainerRef: any; //ToDo xANYx
  private shiftFlag: boolean = false;

  constructor(props: RowsProps) {
    super(props);
    this.rows = new Map<number, Row>();
  }

  public getRow(index: number): Row {
    if (index >= 0 && this.rows && index < this.rows.size) {
      return this.rows.get(index);
    }
    return null;
  }

  componentWillUnmount() {
    this.scrollContainerRef.removeEventListener("touchmove", this.handleTouchMove);
  }

  render() {
    this.rows.clear();
    return (
      <tbody
        ref={(ref) => {
          this.scrollContainerRef = ref;
        }}
        onTouchStart={this.handleTouchStart}
        onTouchMove={this.handleTouchMove}
        onMouseDown={this.handleMouseDown}
        onMouseMove={this.handleMouseMove}
        onMouseUp={this.handleMouseUp}
        onKeyDown={this.handleKeyDown}
        onKeyPress={this.props.onKeyPress}
        onKeyUp={this.handleKeyUp}
        onWheelCapture={this.handleWhell}
        className={
          this.props.renderer.renderRule(dgTBodyRule, { vcx: this.props.vcx }) +
          " " +
          this.props.renderer.renderFontRule(this.props.vcx.GridControl.Font, this.props.vcx)
        }
      >
        {this.props.rows.map((value: List<CSColumn>, index: number, array: List<List<CSColumn>>) => {
          if (value) {
            return (
              <K2Row
                rowHeightMultiplier={this.props.rowHeightMultiplier}
                fixedColumnsCount={this.props.fixedColumnsCount}
                onColumnClick={(rowIndex, colIndex, clickCount, shiftKey) => {
                  this.handleColumnClick(index, colIndex, clickCount, shiftKey);
                }}
                onContextMenu={(rowIndex, colIndex) => {
                  this.handleContextMenu(index, colIndex);
                }}
                key={"row_" + index}
                data={value}
                columnsProportion={this.props.columnsProportion}
                vcx={this.props.vcx}
                ref={(ref) => {
                  if (ref && ref instanceof Row) this.rows.set(index, ref);
                }}
              />
            );
          } else {
            return null;
          }
        })}
      </tbody>
    );
  }

  private handleContextMenu(rowIndex: number, colIndex: number) {
    if (this.props.onContextMenu) {
      this.props.onContextMenu.call(this, rowIndex, colIndex);
    }
  }

  private handleColumnClick(rowIndex: number, colIndex: number, clickCount: number, shiftKey: boolean) {
    if (this.props.onColumnClick) {
      this.props.onColumnClick.call(this, rowIndex, colIndex, clickCount, shiftKey);
    }
  }

  //Posouvání kurzoru v DataGridu pomocí gesta(myš/dotyk)
  touchStartVal: number = -100;
  touchStartValX: number = -100;
  touchMoveVal: number = -100;
  mousePress: boolean = false;

  private touchHandler() {
    let touchDistance: number = 0;
    if (this.touchStartVal > -100 && this.touchMoveVal > -100) {
      touchDistance = Math.round(this.touchStartVal - this.touchMoveVal);
      if (Math.abs(touchDistance) > this.props.vcx.GridControl.GetRowHeight(this.props.rowHeightMultiplier)) {
        touchDistance < 0 ? this.props.invokeAction(GridOperations.prior) : this.props.invokeAction(GridOperations.next);

        this.touchStartVal = this.touchMoveVal;
      }
    }
  }

  private handleMouseDown = (mouseDownEvent: React.MouseEvent<HTMLDivElement>) => {
    this.mousePress = true;
    this.touchStartVal = mouseDownEvent.clientY;
    this.touchMoveVal = -100;
  };

  private handleMouseMove = (mouseMoveEvent: React.MouseEvent<HTMLDivElement>) => {
    if (this.mousePress) {
      mouseMoveEvent.preventDefault();
      this.touchMoveVal = mouseMoveEvent.clientY;
      this.touchHandler();
    }
  };

  private handleMouseUp = (mouseUpEvent: React.MouseEvent<HTMLDivElement>) => {
    this.mousePress = false;
    this.touchMoveVal = mouseUpEvent.clientY;
    this.touchHandler();
  };

  private handleTouchStart = (touchStartEvent: React.TouchEvent<HTMLDivElement>) => {
    this.touchStartVal = touchStartEvent.touches[0].clientY;
    this.touchStartValX = touchStartEvent.touches[0].clientX;
    this.touchMoveVal = -100;
  };

  private handleTouchMove = (touchMoveEvent: React.TouchEvent<HTMLDivElement>) => {
    if (touchMoveEvent.touches.length === 1) {
      if (Math.abs(this.touchStartValX - touchMoveEvent.touches[0].clientX) < Math.abs(this.touchStartVal - touchMoveEvent.touches[0].clientY)) {
        touchMoveEvent.preventDefault();
        this.touchMoveVal = touchMoveEvent.touches[0].clientY;
        this.touchHandler();
      }
    }
  };
  //KONEC Posouvání kurzoru v DataGridu pomocí gesta(myš/dotyk)

  private handleKeyDown = (e: React.KeyboardEvent<HTMLTableSectionElement>) => {
    if (e.shiftKey) {
      this.shiftFlag = true;
    }
    this.props.onKeyDown(e);
  };

  private handleKeyUp = (e: React.KeyboardEvent<HTMLTableSectionElement>) => {
    if (e.keyCode === 16) {
      this.shiftFlag = false;
    }
  };

  private handleWhell = (e: React.WheelEvent<HTMLDivElement>) => {
    if (!this.shiftFlag) {
      if (e.deltaY > 0) {
        this.props.invokeAction(GridOperations.next);
      } else {
        this.props.invokeAction(GridOperations.prior);
      }
    }
  };
}

const K2Rows = withContext(Rows);

type RowRuleProps = K2RuleWithVCXProps & {
  rowHeightMultiplier: number;
};
const dgRowRule = (props: RowRuleProps) => ({
  height: props.vcx.GridControl.GetRowHeight(props.rowHeightMultiplier) + "px",
});

interface RowProps extends DGHeaderProps {
  data: List<CSColumn>;
  fixedColumnsCount: number;
  rowHeightMultiplier: number;
}

interface RowState {
  isSelected: boolean;
  inEditMode: boolean;
}

class Row extends React.Component<RowProps, RowState> {
  static displayName = `K2Row`;

  private columns: Map<number, Column>;
  private content: HTMLTableRowElement;

  constructor(props: RowProps) {
    super(props);
    this.state = { isSelected: false, inEditMode: false };
    this.columns = new Map<number, Column>();
  }

  private getClassName(): string {
    let clsName: string = this.props.renderer.renderRule(dgRowRule, { vcx: this.props.vcx, rowHeightMultiplier: this.props.rowHeightMultiplier });
    return clsName;
  }

  getOverRect(columnNdx: number): DOMRect {
    if (this.columns && this.columns.has(columnNdx)) {
      let column = this.columns.get(columnNdx);
      if (column) return column.getOverRect();
    }

    return null;
  }

  setAsActive(isActive: boolean) {
    //if(this.firstColumn) this.firstColumn.setAsActive();
    if (this.content && isActive) {
      this.content.focus();
    }
  }

  updateInEditMode(value: boolean) {
    this.setState({ inEditMode: value });
  }

  updateSelected(selected: boolean, oldColumn: number, newColumn: number) {
    this.setState({ isSelected: selected });
    let column: Column;
    if (oldColumn >= 0) {
      column = this.columns.get(oldColumn);
      if (column) {
        column.updateSelected(false);
      }
    }
    if (selected) {
      if (newColumn >= 0) {
        column = this.columns.get(newColumn);
        if (column) {
          column.updateSelected(true);
        }
      }
    }
  }

  render() {
    let columns: any = null;
    if (this.props.data) {
      columns = this.props.data.map((value: CSColumn, index: number, array: List<CSColumn>) => {
        if (index < this.props.columnsProportion.length) {
          return (
            <K2Column
              canEdit={this.state.inEditMode === true ? (value.CanEdit ? true : false) : undefined}
              isSelectedText={this.state.isSelected}
              fixed={index < this.props.fixedColumnsCount}
              onColumnClick={(clickCount, shiftKey) => {
                this.handleColumnClick(index, clickCount, shiftKey);
              }}
              onContextMenu={() => {
                this.handleContextMenu(index);
              }}
              ref={(ref) => {
                if (ref && ref instanceof Column) this.columns.set(index, ref);
              }}
              key={"column_" + index}
              value={value}
              columnProportion={this.props.columnsProportion[index]}
              vcx={this.props.vcx}
            />
          );
        } else {
          return null;
        }
      });
    }
    return (
      <tr
        tabIndex={1}
        className={this.getClassName()}
        ref={(ref) => {
          this.content = ref;
        }}
      >
        {columns}
      </tr>
    );
  }

  private handleContextMenu(index: number) {
    if (this.props.onContextMenu) {
      this.props.onContextMenu.call(this, 0, index);
    }
  }

  private handleColumnClick(index: number, clickCount: number, shiftKey: boolean) {
    if (this.props.onColumnClick) {
      this.props.onColumnClick.call(this, 0, index, clickCount, shiftKey);
    }
  }
} //getexterior

const K2Row = withContext(Row);

function getTextAlign(align: TAlignment): "center" | "left" | "right" {
  switch (align) {
    case TAlignment.taCenter:
      return "center";
    case TAlignment.taLeftJustify:
      return "left";
    case TAlignment.taRightJustify:
      return "right";
  }
}

function getContentAlign(align: TAlignment): "center" | "flex-start" | "flex-end" {
  switch (align) {
    case TAlignment.taCenter:
      return "center";
    case TAlignment.taLeftJustify:
      return "flex-start";
    case TAlignment.taRightJustify:
      return "flex-end";
  }
}

type K2ColumnProps = K2ColumnRuleProps & {
  column: CSColumnBase;
  isSelectedText: boolean;
  canEdit: boolean;
};

const dgColumnRule = (props: K2ColumnProps) => ({
  cursor: "default",
  overflow: "hidden" as "hidden",
  verticalAlign: "middle",
  maxWidth: 1, // todo aby se buňky tabulky zmenšovali i přes vlastní obsah
  // display: 'inline-block',
  ">div": {
    justifyContent: props.proportion ? getContentAlign(props.proportion.Alignment) : "right",
  },
  opacity: props.column && (props.column.IsCancelled == true || props.canEdit === false) ? 0.6 : "inherit",
  backgroundColor: ColumnBaseHelper.getBackgroundColor(props.column.CondFormatting, props.isSelectedText, props.fixed, props.vcx),
  color: ColumnBaseHelper.getForegroundColor(props.column.CondFormatting, props.isSelectedText, props.fixed, props.vcx),
  fontWeight: ColumnBaseHelper.IsBold(props.column.CondFormatting) ? ("bold" as "bold") : ("normal" as "normal"),
  fontStyle: ColumnBaseHelper.IsItalic(props.column.CondFormatting) ? ("italic" as "italic") : ("normal" as "normal"),
  textDecoration: getTextDecoration(ColumnBaseHelper.IsStrike(props.column.CondFormatting), ColumnBaseHelper.IsUnderline(props.column.CondFormatting)),
  height: "inherit",
});

const triangleRule = (props: K2RuleWithVCXProps & { color: string }) => ({
  width: 0,
  height: 0,
  borderRight: props.vcx.cssSizeMap(8) + " solid transparent",
  borderTop: props.vcx.cssSizeMap(8) + " solid " + props.color,
});

const innerColumnRule = (props: K2RuleWithVCXProps & { isSelected: boolean; isImage: boolean }) => ({
  padding: props.isImage ? "0px" : `0px ${column_padding_x}px`,
  alignItems: "center",
  height: "100%",
  border: props.isSelected ? "1px dashed " + props.vcx.getColor(props.vcx.Data.ColorMap.ContentFrame3) : "unset",
});

interface ColumnProps extends HeaderColumnProps {
  value: CSColumn;
  fixed: boolean;
  isSelectedText: boolean;
  canEdit: boolean;
}

interface ColumnState {
  isSelected: boolean;
}

class Column extends React.PureComponent<ColumnProps, ColumnState> {
  static displayName = `K2Column`;
  private element: HTMLElement;

  constructor(props: ColumnProps) {
    super(props);
    this.state = { isSelected: false };
  }
  private getRule(): string {
    return this.props.renderer.renderRule(this.props.renderer.combineRule(dgColumnBaseRule, dgColumnRule), {
      fixed: this.props.fixed,
      proportion: this.props.columnProportion,
      column: this.props.value,
      isSelectedText: this.props.isSelectedText,
      canEdit: this.props.canEdit,
      vcx: this.props.vcx,
    });
  }

  updateSelected(selected: boolean) {
    this.setState({ isSelected: selected });
  }

  setAsActive(isActive: boolean) {}

  getOverRect(): DOMRect {
    if (this.element) {
      return this.element.getBoundingClientRect();
    }

    return null;
  }

  render() {
    let triangle: JSX.Element = this.getStateTriangle();

    let isGlyphData: boolean = this.props.value.GlyphId && this.props.value.GlyphId.length > 0;
    let textBackColor = this.props.value.IsSelectedText ? this.props.vcx.getColor(this.props.vcx.Data.ColorMap.AccentBaseColorBck) : "";
    let textColor = this.props.value.IsSelectedText ? this.props.vcx.getColor(this.props.vcx.Data.ColorMap.AccentBaseColorFrg) : "";
    return (
      <td
        className={this.getRule()}
        onContextMenu={this.handleContextMenu}
        onClick={this.handleClick}
        ref={(ref) => {
          this.element = ref;
        }}
      >
        <div
          className={this.props.renderer.renderRule(innerColumnRule, {
            vcx: this.props.vcx,
            isSelected: this.state.isSelected,
            isImage: !!this.props.value.GlyphId,
          })}
        >
          {triangle}
          {isGlyphData && <K2Img glyphId={this.props.value.GlyphId} vcx={this.props.vcx} height={this.props.vcx.GridControl.GetRowHeightWithoutMargin()} />}
          <K2TruncateText keyboardAccelerator={false} textBackColor={textBackColor} textColor={textColor}>
            {this.props.value.Text}
          </K2TruncateText>
        </div>
      </td>
    );
  }

  private getStateTriangle(): JSX.Element {
    if (this.props.value) {
      if (this.props.value.IsModified || this.props.value.HasError) {
        return (
          <div
            className={this.props.renderer.renderRule(triangleRule, {
              vcx: this.props.vcx,
              color: this.props.value.HasError
                ? this.props.vcx.getColor(this.props.vcx.Data.ColorMap.ErrorColorBck)
                : this.props.vcx.getColor(this.props.vcx.Data.ColorMap.ContentChangeDecorateColorFrg),
            })}
          />
        );
      }
    }

    return null;
  }

  private timeout: any;
  private clickCount: number = 0;

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
  }

  private handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (this.props.onColumnClick) {
      if (this.timeout) clearTimeout(this.timeout);

      this.clickCount++;
      let shiftKey = e.shiftKey;

      this.timeout = setTimeout(() => {
        if (this.clickCount >= 2) {
          this.props.onColumnClick.call(this, 2, shiftKey);
        } else {
          this.props.onColumnClick.call(this, 1, shiftKey);
        }
        this.clickCount = 0;
      }, 250);
      e.preventDefault();
    }
  };

  private handleContextMenu = (e: React.MouseEvent<HTMLTableDataCellElement>) => {
    if (this.props.onContextMenu) {
      this.props.onContextMenu.call(this, null);
    }
    e.preventDefault();
  };
}

const K2Column = withContext(Column);

const dgTableRule = (props: K2RuleWithVCXProps & { fillHeight?: boolean; width?: number }) => ({
  //flex:'auto',
  borderCollapse: "collapse" as "collapse",
  width: props.width ? `${props.width}px` : "auto",
  height: props.fillHeight ? "100%" : "auto",
  minWidth: "100%",
  tableLayout: "fixed" as "fixed",
  backgroundColor: props.inEditMode
    ? props.vcx.getColor(props.vcx.Data.ColorMap.DataChangeColorBck)
    : props.vcx.getColor(props.vcx.Data.ColorMap.DataBrowseColorBck),
});

const dgRule = (props: K2RuleWithVCXProps) => ({
  flex: "1 1 auto",
  flexDirection: "column" as "column",
});

const dgScrollContentrRule = (props: K2RuleWithVCXProps) => ({
  display: "flex",
  flex: 1,
  flexDirection: "column" as "column",
  width: "100%",
  height: "100%",
  overflowY: "hidden" as "hidden",
  overflowX: "auto" as "auto",
  backgroundColor: props.inEditMode
    ? props.vcx.getColor(props.vcx.Data.ColorMap.DataChangeColorBck)
    : props.vcx.getColor(props.vcx.Data.ColorMap.DataBrowseColorBck),
  touchAction: "pan-x",
});

const dgNoDataWarning = (props: K2RuleWithVCXProps) => ({
  margin: "auto",
  width: "100%",
  backgroundColor: props.vcx.getColor(props.vcx.Data.ColorMap.ContentFrameMin),
  padding: "6px 55% 3px 45%",
  fontSize: "80%",
});

type K2RulerProps = K2RuleWithVCXProps & {
  rect: DOMRect;
};

interface InnerDataGridState extends K2ComponentState<UpdateInnerDataGrid> {
  Width: number;
  Height: number;
  clientWidthDiffs?: Array<number>;
  xDragPos: number;
  dragging: boolean;
  reordered: boolean;
  deleted: boolean;
}

class _InnerDataGrid extends React.PureComponent<WithContextPlacementProps, InnerDataGridState> implements DataGridListener, UFOverAlign {
  private rows: Rows;
  static displayName = `K2DataGrid`;
  private control: NclInnerDataGrid;
  private requestSetAsActive: boolean;
  private listener: UFOverAlignListener;
  private columns: DOMRect[];
  private draggedColumnIndex: number;
  private hoveredColumnIndex: number;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclInnerDataGrid;
    }) as NclInnerDataGrid;

    this.state = {
      data: this.control.init(this) as UpdateInnerDataGrid,
      Width: 0,
      Height: 0,
      vcxVersion: -1,
      xDragPos: undefined,
      dragging: false,
      reordered: false,
      deleted: false,
    };
  }

  registerOverAlignListener(listener: UFOverAlignListener): void {
    this.listener = listener;
  }

  unRegisterOverAlignListener(listener: UFOverAlignListener): void {
    if (this.listener === listener) {
      this.listener = null;
    }
  }

  getOverRect(): DOMRect {
    if (this.rows) {
      let row = this.rows.getRow(this.control.RowNdx);
      if (row) return row.getOverRect(this.control.ColNdx);
    }

    return null;
  }

  private containerRule = (props: K2RuleWithVCXProps) => ({
    flex: 1,
  });

  updateState(state: UpdateControl) {
    this.setState((prevState: InnerDataGridState) => {
      let diffs = prevState.clientWidthDiffs;
      if (state instanceof UpdateInnerDataGrid) {
        if (state.CanEditItems !== prevState.data.CanEditItems) {
          this.updateCanEditItems(state.CanEditItems);
        }
        if (state.AutoAdjustWidth != prevState.data.AutoAdjustWidth || !state.IsEqualsColumnsProportion(prevState.data)) {
          state = reCalculateColumnWidths<UpdateInnerDataGrid>(state, prevState.Width, state.AutoAdjustWidth, this.control.VCX);
          diffs = null;
        }
        return { data: state as UpdateInnerDataGrid, clientWidthDiffs: diffs };
      }
    });
  }

  private updateCanEditItems(value: boolean) {
    if (this.rows) {
      let row = this.rows.getRow(this.control.RowNdx);
      if (row) {
        row.updateInEditMode(value);
      }
    }
  }

  updatePosition(oldRow: number, newRow: number, oldCol: number, newCol: number) {
    if (this.rows && (oldRow !== newRow || oldCol !== newCol)) {
      let ar: Row;
      if (oldRow !== newRow) {
        ar = this.rows.getRow(oldRow);
        if (ar) {
          ar.updateSelected(false, oldCol, newCol);
        }
      }
      ar = this.rows.getRow(newRow);
      if (ar) {
        ar.updateSelected(true, oldCol, newCol);
        if (this.requestSetAsActive) {
          ar.setAsActive(true);
          this.requestSetAsActive = false;
        }
      }
      if (this.listener) {
        this.listener.updateAlignAnchor();
      }
    }
  }

  componentDidUpdate(prevProps: WithContextPlacementProps, prevState: InnerDataGridState) {
    if (!this.state.clientWidthDiffs && prevState.Width != this.state.Width && this.state.data.AutoAdjustWidth) {
      this.setState({
        data: reCalculateColumnWidths<UpdateInnerDataGrid>(this.state.data, this.state.Width, this.state.data.AutoAdjustWidth, this.control.VCX),
      });
    }

    if (this.state.reordered || this.state.deleted) {
      this.setState({ reordered: false, deleted: false, xDragPos: undefined });
      this.hoveredColumnIndex = undefined;
    }
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  setAsActive(isActive: boolean) {
    if (isActive) {
      if (this.rows) {
        let ar = this.rows.getRow(this.control.RowNdx);
        if (ar) {
          ar.setAsActive(isActive);
        }
      } else {
        this.requestSetAsActive = true;
      }
    } else {
      this.requestSetAsActive = false;
    }
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  private getNoDataWarning(): JSX.Element {
    if (!this.state.data.DataSource.size && this.state.data.ColumnsProportion.length > 0) {
      return <div className={this.props.renderer.renderRule(dgNoDataWarning, { vcx: this.control.VCX })}>Žádné údaje</div>;
    }
    return;
  }

  private calcTotalWidth(): number {
    if ((!this.state.clientWidthDiffs || !this.state.data.AutoAdjustWidth) && !this.state.data.ColumnsProportion) return null;
    let result = 0;
    this.state.data.ColumnsProportion.map((value, i) => {
      result += value.Width;
    });

    if (this.state.clientWidthDiffs) {
      this.state.clientWidthDiffs.map((value) => {
        result += value;
      });
    }

    return result;
  }

  handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  handleDragEnter = (e: React.DragEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    if (!this.state.dragging) return;

    let xPos = 0;

    if (e.nativeEvent instanceof DragEvent) {
      xPos = (e as React.DragEvent).clientX;
    } else {
      xPos = (e as React.TouchEvent).touches[0].clientX;
    }

    let offsetLeft = e.currentTarget.getBoundingClientRect().left;
    this.columns?.map((columns, index) => {
      if (xPos > columns.left && xPos < columns.right) {
        if (this.draggedColumnIndex >= index) {
          this.setState({ xDragPos: columns.left - offsetLeft });
          this.hoveredColumnIndex = index;
        } else {
          this.setState({ xDragPos: columns.right - offsetLeft });
          this.hoveredColumnIndex = index;
        }
      }
    });
  };

  handleDrop = () => {
    if (!this.state.dragging) return;

    if (this.draggedColumnIndex === this.hoveredColumnIndex) return;
    // reorder table header
    let columns = [...this.state.data.ColumnsProportion];
    this.control.Container.reorderColumns(this.draggedColumnIndex, this.hoveredColumnIndex, columns);

    // reorder table body
    let dataList = this.state.data.DataSource.map((row) => {
      return row
        .insert(this.draggedColumnIndex < this.hoveredColumnIndex ? this.hoveredColumnIndex + 1 : this.hoveredColumnIndex, row.get(this.draggedColumnIndex))
        .delete(this.draggedColumnIndex > this.hoveredColumnIndex ? this.draggedColumnIndex + 1 : this.draggedColumnIndex);
    });

    let columnsMoved = this.control.columnMove(this.draggedColumnIndex, this.hoveredColumnIndex, columns);

    if (columnsMoved) {
      this.setState({ dragging: false, data: this.state.data.with({ ColumnsProportion: columns, DataSource: List(dataList) }), reordered: true });
    } else {
      this.setState({ dragging: false });
    }
  };

  setColumns = (options: { sizes: DOMRect[]; draggedColumnIndex: number }) => {
    this.columns = options.sizes;
    this.draggedColumnIndex = options.draggedColumnIndex;
    this.setState({ dragging: true });
  };

  deleteColumn = () => {
    // delete table header
    let columns = [...this.state.data.ColumnsProportion];
    columns.splice(this.draggedColumnIndex, 1);

    // delete table body
    let dataList = this.state.data.DataSource.map((row) => {
      return row.delete(this.draggedColumnIndex);
    });

    let columnsMoved = this.control.columnMove(this.draggedColumnIndex, -1, columns);

    if (columnsMoved) {
      this.setState({ dragging: false, data: this.state.data.with({ ColumnsProportion: columns, DataSource: List(dataList) }), deleted: true });
    } else {
      this.setState({ dragging: false });
    }
  };

  render() {
    let columnsProportion: Array<ColumnProportionEm> = this.state.data.ColumnsProportion;
    let addAttributes: ElementHtmlAttributes = getAttributes(this.state.data);

    if (!columnsProportion) return null;
    let width = this.calcTotalWidth();
    return (
      <div
        style={StyleHelper(this.control, { height: "100px", ...this.props.style })}
        className={this.props.renderer.renderRule(dgRule, { vcx: this.control.VCX })}
        onFocus={this.handleFocus}
        {...addAttributes}
        onDragOver={this.handleDragOver}
        onDragEnter={this.handleDragEnter}
        onDrop={this.handleDrop}
        onTouchMove={this.handleDragEnter}
        onTouchEnd={this.handleDrop}
      >
        <div className={this.props.renderer.renderRule(dgScrollContentrRule, { vcx: this.control.VCX, inEditMode: this.state.data.CanEditItems })}>
          <div style={{ flex: "1 1 100%", position: "relative" }}>
            {this.state.dragging && this.hoveredColumnIndex !== undefined && (
              <div style={{ border: "1px solid", position: "absolute", left: this.state.xDragPos, height: "100%" }}></div>
            )}
            <div style={{ flex: "1 1 auto", flexDirection: "column" }}>
              <ReactResizeDetector handleHeight handleWidth refreshMode={"debounce"} refreshRate={500} onResize={this.onResize} />
              <table className={this.props.renderer.renderRule(dgTableRule, { vcx: this.control.VCX, inEditMode: this.state.data.CanEditItems, width: width })}>
                {this.state.data.ColumnsProportion && (
                  <colgroup>
                    {this.state.data.ColumnsProportion.map((value, i) => {
                      let diff: number = 0;
                      if (this.state.clientWidthDiffs && i < this.state.clientWidthDiffs.length) {
                        diff = this.state.clientWidthDiffs[i];
                      }
                      return <col key={`col__${i}`} style={{ width: `${value.Width + diff}px` }} />;
                    })}
                  </colgroup>
                )}
                <thead>
                  <K2DGHeader
                    key={this.control.Ncl.ControlUID + "_header"}
                    fixedColumnsCount={this.state.data.FixedColumnsCount}
                    orderBy={this.state.data.OrderBy.toJS()}
                    columnsProportion={columnsProportion}
                    vcx={this.control.VCX}
                    onContextMenu={this.handleContextMenu}
                    onColumnClick={this.handleHeaderColumnClick}
                    onColumnResize={this.handleColumnResize}
                    setColumns={this.setColumns}
                    deleteColumn={this.deleteColumn}
                  />
                  {this.control.QuickFilter && (
                    <K2QuickFilter
                      vrUID={this.control.getRealizerUID()}
                      controlUID={this.control.QuickFilter.MetaData.ControlUID}
                      fixedColumnsCount={this.state.data.FixedColumnsCount}
                      columnsProportion={columnsProportion}
                      reorderOptions={{
                        dragIndex: this.draggedColumnIndex,
                        dropIndex: this.hoveredColumnIndex,
                        updated: this.state.reordered,
                        deleted: this.state.deleted,
                      }}
                    />
                  )}
                </thead>
                <K2Rows
                  invokeAction={this.handleInvokeAction}
                  onKeyDown={this.handleKeyDown}
                  onKeyPress={this.handleKeyPress}
                  key={this.control.Ncl.ControlUID + "_rows"}
                  fixedColumnsCount={this.state.data.FixedColumnsCount}
                  rowHeightMultiplier={this.control.getRowHeightMultiplier()}
                  inEditMode={false /*this.control.InEditMode*/}
                  rows={this.state.data.DataSource}
                  columnsProportion={columnsProportion}
                  ref={(rows) => {
                    this.rows = rows as Rows;
                  }}
                  vcx={this.control.VCX}
                  onContextMenu={this.handleContextMenu}
                  onColumnClick={this.handleColumnClick}
                />
              </table>
            </div>
          </div>
          <div style={{ flex: "auto", alignItems: "flex-end" }}>
            {this.control.Container.AggregationPanel && (
              <K2AggregationPanel
                controlUID={this.control.Container.AggregationPanel.MetaData.ControlUID}
                vrUID={this.control.getRealizerUID()}
                fixedColumnsCount={this.state.data.FixedColumnsCount}
                columnsProportion={columnsProportion}
                width={width}
                clientWidthDiffs={this.state.clientWidthDiffs}
                reorderOptions={{
                  dragIndex: this.draggedColumnIndex,
                  dropIndex: this.hoveredColumnIndex,
                  updated: this.state.reordered,
                  deleted: this.state.deleted,
                }}
              />
            )}
          </div>

          {/* {this.getNoDataWarning()} */}
        </div>
        {this.control.Container.showFooter() && (
          <K2DataGridFooter controlUID={this.control.Container.Footer.MetaData.ControlUID} vrUID={this.control.getRealizerUID()} />
        )}
      </div>
    );
  }

  private handleInvokeAction = (op: GridOperations) => {
    this.control.Container.invokeAction(op);
  };

  private handleContextMenu = (rowIndex: number, colIndex: number) => {
    this.control.contextMenu(rowIndex, colIndex);
  };

  private handleHeaderColumnClick = (rowIndex: number, colIndex: number, clickCount: number, shiftKey: boolean) => {
    this.control.Container.changeSortBy(colIndex, shiftKey);
  };

  private handleColumnClick = (rowIndex: number, colIndex: number, clickCount: number, shiftKey: boolean) => {
    this.control.click(rowIndex, colIndex, clickCount);
  };

  private handleColumnResize = (colIndex: number, diffX: number, isCompleted: boolean) => {
    let diffs: Array<number>;
    if (isCompleted) {
      if (this.state.data.ColumnsProportion && colIndex < this.state.data.ColumnsProportion.length) {
        if (this.state.clientWidthDiffs) {
          diffs = [...this.state.clientWidthDiffs];
        } else {
          diffs = [];
          this.state.data.ColumnsProportion.map((value, i) => {
            diffs[i] = 0;
          });
        }
      }
      if (diffs) {
        diffs[colIndex] += diffX;
        if (this.state.data.ColumnsProportion[colIndex].Width + diffs[colIndex] <= 10) return; //check minimum
        this.control.setColumnsWidth(diffs, this.state.data.ColumnsProportion);
        this.setState({ clientWidthDiffs: diffs });
      }
    }
  };

  private handleFocus = (e: React.FocusEvent<HTMLDivElement>) => {
    this.control.Container.setActiveControlRequested();
    e.stopPropagation();
  };

  private handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "ArrowUp") {
      this.control.Container.invokeAction(GridOperations.prior);
      e.stopPropagation();
      return false;
    }

    if (e.key === "ArrowDown") {
      this.control.Container.invokeAction(GridOperations.next);
      e.stopPropagation();
      return false;
    }

    if (e.key === "ArrowLeft" && this.control.ColNdx - 1 >= 0) {
      this.control.click(this.control.RowNdx, this.control.ColNdx - 1);
      e.stopPropagation();
      return false;
    }

    if (e.key === "ArrowRight" && this.state.data.ColumnsProportion && this.control.ColNdx + 1 < this.state.data.ColumnsProportion.length) {
      this.control.click(this.control.RowNdx, this.control.ColNdx + 1);
      e.stopPropagation();
      return false;
    }

    if (e.key === "PageDown") {
      this.control.Container.invokeAction(GridOperations.nextPage);
      e.stopPropagation();
      return false;
    }

    if (e.key === "PageUp") {
      this.control.Container.invokeAction(GridOperations.priorPage);
      e.stopPropagation();
      return false;
    }

    if (e.ctrlKey && e.key === "Home") {
      this.control.Container.invokeAction(GridOperations.firstPage);
      e.stopPropagation();
      return false;
    }

    if (e.ctrlKey && e.key === "End") {
      this.control.Container.invokeAction(GridOperations.lastPage);
      e.stopPropagation();
      return false;
    }
    let x = e.keyCode | e.which;
    if (x === 8 && this.control.Container.Header && this.control.Container.Header.LocatorPanel) {
      //backspace
      let locator: NclInput = this.control.Container.Header.LocatorPanel.getLastLocatrInput();
      if (locator && locator.Listener && locator.Listener instanceof _Input) {
        locator.Listener.setAsActive(true);
        locator.Listener.handleKeyDown(e);
      }
    }
  };

  private handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (isKeyOrDigit(e.key) && this.control.Container.Header && this.control.Container.Header.LocatorPanel) {
      let locator: NclInput = this.control.Container.Header.LocatorPanel.getLastLocatrInput();
      if (locator && locator.Listener && locator.Listener instanceof _Input) {
        locator.Listener.setAsActive(true);
        locator.Listener.handleKeyPress(e);
      }
    }
  };

  private onResize = (width: number, height: number) => {
    if ((height > 0 || width > 0) && (width != this.state.Width || height != this.state.Height)) {
      if (height != this.state.Height) {
        let maxRowCount: number = height / this.control.VCX.GridControl.GetRowHeight(this.control.getRowHeightMultiplier());
        this.control.Container.setMaxRowCount(Math.floor(maxRowCount - 1), true); // -1 radek pro header
      }
      this.setState({ Width: width, Height: height });
    }
  };
}

const K2InnerDataGrid = withContext(_InnerDataGrid);

const dgContentRule = (props: K2RuleWithVCXProps) => ({
  flex: "1 1 auto",
  flexDirection: "column" as "column",
});

class _DataGridContent extends React.Component<WithContextPlacementProps, K2ComponentState<UpdateControl>> {
  static displayName = `K2DataGrid`;
  private control: NclDataGridContent;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclDataGridContent;
    }) as NclDataGridContent;
    this.state = { data: this.control.init(this) as UpdateControl, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateControl>) => {
      return { data: state as UpdateControl };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    return (
      <div style={StyleHelper(this.control, this.props.style)} className={this.props.renderer.renderRule(dgContentRule, { vcx: this.control.VCX })}>
        <K2InnerDataGrid controlUID={this.control.DataGrid.MetaData.ControlUID} vrUID={this.control.getRealizerUID()} />
      </div>
    );
  }
}

const K2DataGridContent = withContext(_DataGridContent);

const dgFooterRule = (props: K2RuleWithVCXProps) => ({
  flex: "none",
  background: props.vcx.getColor(props.vcx.Data.ColorMap.ContentColorBck1),
  height: props.vcx.ExpanderControl.GetHFHeight() + "px",
  color: props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorBck1),
  border: "1px solid " + props.vcx.getColor(props.vcx.Data.ColorMap.ContentFrame1),
});

class _DataGridFooter extends React.Component<WithContextPlacementProps, K2ComponentState<UpdateControl>> {
  static displayName = `K2DataGridFooter`;
  private control: NclDataGridFooter;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclDataGridFooter;
    }) as NclDataGridFooter;
    this.state = { data: this.control.init(this) as UpdateControl, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateControl>) => {
      return { data: state as UpdateControl };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    return (
      <div style={StyleHelper(this.control, this.props.style)} className={this.props.renderer.renderRule(dgFooterRule, { vcx: this.control.VCX })}>
        <K2ToolBar controlUID={this.control.LeftToolbar.MetaData.ControlUID} vrUID={this.control.getRealizerUID()} />
        <K2ToolBar
          controlUID={this.control.RightToolbar.MetaData.ControlUID}
          vrUID={this.control.getRealizerUID()}
          style={{ justifyContent: "flex-end", width: "auto" }}
        />
      </div>
    );
  }
}

const K2DataGridFooter = withContext(_DataGridFooter);

class _DataGrid extends React.PureComponent<WithContextPlacementProps, K2ComponentState<UpdateDataGrid>> implements UFOverAlign {
  private control: NclDataGrid;
  static displayName = `K2DataGrid`;
  private element: HTMLDivElement;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclDataGrid;
    }) as NclDataGrid;
    this.state = { data: this.control.init(this) as UpdateDataGrid, vcxVersion: -1 };
  }

  getOverRect(): DOMRect {
    if (this.control && this.control.Content && this.control.Content.DataGrid && this.control.Content.DataGrid.Listener) {
      let listener: UFOverAlign = this.control.Content.DataGrid.Listener;
      if (listener.getOverRect) {
        return listener.getOverRect();
      }
    }

    return null;
  }

  registerOverAlignListener(listener: UFOverAlignListener) {
    if (this.control && this.control.Content && this.control.Content.DataGrid && this.control.Content.DataGrid.Listener) {
      let l: UFOverAlign = this.control.Content.DataGrid.Listener;

      if (l.registerOverAlignListener) {
        l.registerOverAlignListener(listener);
      }
    }
  }

  unRegisterOverAlignListener(listener: UFOverAlignListener) {
    if (this.control && this.control.Content && this.control.Content.DataGrid && this.control.Content.DataGrid.Listener) {
      let l: UFOverAlign = this.control.Content.DataGrid.Listener;

      if (l.registerOverAlignListener) {
        l.unRegisterOverAlignListener(listener);
      }
    }
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateDataGrid>) => {
      return { data: state as UpdateDataGrid };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  setAsActive(isActive: boolean) {
    if (this.element && isActive) {
      this.element.focus();
    }
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    let addAttributes: ElementHtmlAttributes = getAttributes(this.state.data);
    return (
      <div
        ref={(ref) => {
          this.element = ref;
        }}
        data-k2-test-id={this.control.Ncl.Name}
        style={StyleHelper(this.control, this.props.style)}
        className={this.props.renderer.renderRule(dgRule, { vcx: this.control.VCX })}
        onFocus={this.handleFocus}
        {...addAttributes}
      >
        {this.control.isShowHeader() && (
          <K2Header
            controlUID={this.control.Header.MetaData.ControlUID}
            vrUID={this.props.vrUID}
            title={this.state.data.Title}
            titleSuffix={this.state.data.TitleSuffix}
            titleSuffixCommandEnabled={this.state.data.TitleSuffixCommandEnabled}
          />
        )}
        <K2DataGridContent controlUID={this.control.Content.MetaData.ControlUID} vrUID={this.props.vrUID} />
      </div>
    );
  }

  /*shouldComponentUpdate(nextProps:  WithContextPlacementProps<NclDataGrid>, nextState: K2ComponentState<UpdateBaseGrid>, nextContext: any):boolean{
		let result : boolean = true;
		if(shouldUpdate(this.control.VCX)) return true;
		if(this.state.data.Data != nextState.data.Data) return result;
		if(this.state.data.Columns != nextState.data.Columns) return result;
		
		if(this.state.data.Position != nextState.data.Position && this.state.data.RowCount != 0){
			if(this.rows != null){
				this.rows.setState({position:nextState.data.Position});
				result = false;
			}
		}

		return result;
	}*/

  private handleFocus = (e: React.FocusEvent<HTMLDivElement>) => {
    this.control.setActiveControlRequested();
    e.stopPropagation();
  };
}

export const K2DataGrid = withContext(_DataGrid);
