import * as React from "react";

import { ElementHtmlAttributes, getAttributes } from "../common/common";
import { DataActionDecorate, IconPosition, K2Boolean, UpdateCommandItem, UpdateControl } from "../common/communication.base";
import { NclButton } from "../common/components.ncl";
import { DecorateResult } from "../common/visualContext";
import { SubMenuIndicator } from "./K2Action";
import { K2ComponentState, withContext, WithContextPlacementProps, StyleHelper, AcquireControl } from "./k2hoc";
import { K2Img } from "./K2Image";
import { K2RuleWithVCXProps } from "./k2StyleRenderer";
import { K2TruncateText } from "./K2TruncateText";

type ButtonProps = K2RuleWithVCXProps & {
  fillWidth?: K2Boolean;
  decorate?: DecorateResult;
};

const styleRules = {
  buttonRule: (props: ButtonProps) => ({
    border: "none",
    width: props.fillWidth ? "100%" : "inherit",

    verticalAlign: "middle",
    textDecoration: "none",
    userSelect: "none" as const,
  }),
  buttonDecorateRule: (props: ButtonProps) => ({
    background: props.decorate.backColor ? props.decorate.backColor : "unset",
    color: props.decorate.foregroundColor ? props.decorate.foregroundColor : "unset",
    fontWeight: props.decorate.bold ? ("bold" as const) : ("normal" as const),
    position: "relative" as const,
  }),
  contentRule: () => ({
    justifyContent: "center",
  }),
  contentVRule: () => ({
    flexDirection: "column" as const,
  }),
  childVRule: () => ({
    flex: "1 1 auto",
    justifyContent: "center",
    alignItems: "center",
  }),
  childMinStretchRule: () => ({
    flex: "1 1 auto",
  }),
  childMinStretchRuleNoCaption: () => ({
    flex: "0 1 100%",
  }),
  contentHRule: () => ({
    flexDirection: "row" as const,
  }),
  childHRule: () => ({
    flex: "1 1 auto",
    justifyContent: "center",
    alignItems: "center",
  }),
  contentCRule: () => ({
    textAlign: "center" as const,
    justifyContent: "center",
    alignItems: "center",
    position: "relative" as const,
  }),
  childCRule: () => ({
    position: "absolute" as const,
  }),
  imgCenter: () => ({
    width: "100%",
  }),
};

export type BeforeExecuteProc = (control: NclButton) => void;

interface K2ButtonProps extends WithContextPlacementProps {
  beforeExecute?: BeforeExecuteProc;
  id?: string;
}

class _Button extends React.PureComponent<K2ButtonProps, K2ComponentState<UpdateCommandItem>> {
  static displayName = "K2Button";
  private title: K2TruncateText;
  private control: NclButton;
  private element: HTMLButtonElement;
  private clsMap: Map<string, Array<string>>;
  constructor(props: K2ButtonProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclButton;
    }) as NclButton;
    this.state = { data: this.control.init(this) as UpdateCommandItem, vcxVersion: -1 };
    this.clsMap = new Map<string, Array<string>>();
    this.clsMap.set(
      "style",
      this.props.renderer
        .renderRule(() => {
          return StyleHelper(this.control, Object.assign({}, { width: "100%", display: "flex" }, this.props.style));
        }, null)
        .split(" ")
    );

    this.clsMap.set(
      "base",
      this.props.renderer.renderRule(styleRules.buttonRule, { vcx: this.control.VCX, fillWidth: this.control.Ncl.FrgtData.FillWidth }).split(" ")
    );
  }

  setAsActive(isActive: boolean) {
    if (this.element && isActive) {
      this.element.focus({ preventScroll: true });
    }
  }

  updateState(state: UpdateControl) {
    this.setState(() => {
      return { data: state as UpdateCommandItem };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  private getCls(): Array<string> {
    const vcx = { vcx: this.control.VCX };
    let result = [];
    switch (this.control.Ncl.FrgtData.IconPosition) {
      case IconPosition.ipBottom:
      case IconPosition.ipTop:
        if (this.clsMap.has("0")) {
          result = this.clsMap.get("0");
        } else {
          result = [
            this.props.renderer.renderRule(this.props.renderer.combineRules(styleRules.contentRule, styleRules.contentVRule), vcx),
            this.props.renderer.renderRule(
              this.props.renderer.combineRules(
                styleRules.childVRule,
                this.control.Ncl.FrgtData.ShowCaption ? styleRules.childMinStretchRule : styleRules.childMinStretchRuleNoCaption
              ),
              vcx
            ),
            this.props.renderer.renderRule(styleRules.childVRule, vcx),
          ];
          this.clsMap.set("0", result);
        }
        break;
      case IconPosition.ipLeft:
      case IconPosition.ipRight:
        if (this.clsMap.has("1")) {
          result = this.clsMap.get("1");
        } else {
          result = [
            this.props.renderer.renderRule(this.props.renderer.combineRules(styleRules.contentRule, styleRules.contentHRule), vcx),
            this.props.renderer.renderRule(styleRules.childHRule, vcx),
            this.props.renderer.renderRule(
              this.props.renderer.combineRules(
                styleRules.childHRule,
                this.control.Ncl.FrgtData.ShowCaption ? styleRules.childMinStretchRule : styleRules.childMinStretchRuleNoCaption
              ),
              vcx
            ),
          ];
          this.clsMap.set("1", result);
        }
        break;
      case IconPosition.ipCenter:
        if (this.control.Ncl.FrgtData.ShowCaption && this.state.data.Title) {
          if (this.clsMap.has("2")) {
            result = this.clsMap.get("2");
          } else {
            result = [
              this.props.renderer.renderRule(styleRules.contentCRule, vcx),
              this.props.renderer.renderRule(styleRules.childCRule, vcx),
              this.props.renderer.renderRule(styleRules.imgCenter, vcx),
            ];
            this.clsMap.set("2", result);
          }
        } else {
          if (this.clsMap.has("3")) {
            result = this.clsMap.get("3");
          } else {
            result = [this.props.renderer.renderRule(styleRules.contentCRule, vcx), "", this.props.renderer.renderRule(styleRules.imgCenter, vcx)];
            this.clsMap.set("3", result);
          }
        }
        break;
    }

    return result;
  }

  render() {
    let content: JSX.Element = null;
    const addAttributes: ElementHtmlAttributes = getAttributes(this.state.data);

    const cls = this.getCls();
    content = this.getContent(cls[0], cls[1], cls[2]);

    let finId: string = this.control.MetaData.Name;
    if (this.props.id != "" && this.props.id != undefined) {
      finId = this.props.id + finId;
    }

    let style: React.CSSProperties;
    if (this.state.data.Visible === false) {
      style = { display: "none !important" };
    }
    return (
      <div style={style} className={this.clsMap.get("style").join(" ")}>
        <button
          type="button"
          tabIndex={this.state.data.TabStop ? 0 : -1}
          data-k2-test-id={finId}
          ref={(ref) => (this.element = ref)}
          className={
            this.clsMap.get("base").join(" ") +
            " " +
            this.props.renderer.renderRule(styleRules.buttonDecorateRule, { vcx: this.control.VCX, decorate: this.getActualDecorate() })
          }
          onClick={this.handleClick}
          onFocus={this.handleFocus}
          onKeyDown={this.handleKeyDown}
          accessKey={this.title ? this.title.accessKey : null}
          {...addAttributes}
        >
          {content}
          {this.state.data.SubMenuIndicatorVisible && <SubMenuIndicator vcx={this.control.VCX} />}
        </button>
      </div>
    );
  }

  private getActualDecorate(): DecorateResult {
    let result: DecorateResult;
    if (this.state.data.Enabled === true) {
      result = this.control.VCX.ColorMap.getColorsForActionDecorate(this.state.data.Decorate ? this.state.data.Decorate : this.control.Ncl.FrgtData.Decorate);

      if (this.control.Ncl.BackgroundColorCalc > 0) {
        result.backColor = this.control.VCX.getColor(this.control.Ncl.BackgroundColorCalc);
      }

      if (this.control.Ncl.ForegroundColorCalc > 0) {
        result.foregroundColor = this.control.VCX.getColor(this.control.Ncl.ForegroundColorCalc);
      }
    } else {
      result = this.control.VCX.ColorMap.getColorsForActionDecorate(DataActionDecorate.dadeContentSuppress);
    }
    return result;
  }

  private getChildren(titleCls = "", imgCls = ""): JSX.Element[] {
    let height: number = this.control.ComputedMinHeight;
    const width = 0;

    if (this.control.Ncl.FrgtData.IconPosition === IconPosition.ipBottom || this.control.Ncl.FrgtData.IconPosition === IconPosition.ipTop) {
      height = height - this.control.VCX.LabelControl.getHeight(1);
    } else {
      height = this.control.VCX.InputControl.getInputHeight(Math.max(this.control.Size, this.control.Parent.Size) * 0.7, true, false);
    }
    const actDec = this.getActualDecorate();

    const img = (
      <div className={imgCls} key={this.control.Ncl.ControlUID + "_img"}>
        <K2Img
          glyphId={this.state.data.GlyphId}
          height={height}
          width={width}
          backgroundColor={actDec.backColor}
          strokeColor={actDec.foregroundColor}
          vcx={this.control.VCX}
        />
      </div>
    );
    const title = (
      <div className={titleCls} key={this.control.Ncl.ControlUID + "_txt"}>
        <K2TruncateText
          ref={(ref) => {
            this.title = ref;
          }}
          className={this.props.renderer.renderFontRule(this.control.VCX.LabelControl.Font, this.control.VCX)}
        >
          {this.state.data.Title}
        </K2TruncateText>
      </div>
    );

    if (!this.state.data.GlyphId || this.control.Ncl.FrgtData.ShowIcon === 0) {
      return this.control.Ncl.FrgtData.ShowCaption ? [title] : null;
    } else {
      if (!this.control.Ncl.FrgtData.ShowCaption) return [img];

      switch (this.control.Ncl.FrgtData.IconPosition) {
        case IconPosition.ipBottom:
          return [title, img];
        case IconPosition.ipTop:
          return [img, title];
        case IconPosition.ipLeft:
          return [img, title, <div className={imgCls} key={this.control.Ncl.ControlUID + "_dv"} />];
        case IconPosition.ipRight:
          return [<div className={imgCls} key={this.control.Ncl.ControlUID + "_dv"} />, title, img];
        case IconPosition.ipCenter:
          return [title, img];
      }
      return [title, img];
    }
  }

  private getContent(contentCls: string, titleCls = "", imgCls = ""): JSX.Element {
    return <div className={contentCls}>{this.getChildren(titleCls, imgCls)}</div>;
  }

  private execute() {
    if (this.props.beforeExecute) {
      this.props.beforeExecute.call(this, this.control);
    }

    this.control.executeCommand(null);
  }

  private handleClick = () => {
    this.execute();
  };

  private handleFocus = () => {
    setTimeout(() => {
      this.control.setActiveControlRequested();
    }, 80);
  };

  private handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === "Enter") {
      this.execute();

      e.stopPropagation();
      e.preventDefault();
    } else if (e.key === "ArrowRight") {
      (this.element.parentElement.nextElementSibling?.firstElementChild as HTMLButtonElement)?.focus();
    } else if (e.key === "ArrowLeft") {
      (this.element.parentElement.previousElementSibling?.firstElementChild as HTMLButtonElement)?.focus();
    }
  };
}

export const K2Button = withContext(_Button);
