import { VisualContextMetadata } from "../common/communication.base";

/**
 * Interface css vlastonstí které bude umět CSS Renderer
 */
export interface K2Style extends IStyle {}

export type K2RuleProps = {};

export type K2RuleWithVCXProps = K2RuleProps & {
  vcx: VisualContext;
  inEditMode?: boolean;
};

export type K2KeyFrame<T = K2RuleProps> = TKeyFrame<T>;

export type K2Rule<T = K2RuleProps> = (props: T, renderer: IRenderer) => K2Style;

export interface K2StyleRenderer {
  renderRule<T = K2RuleWithVCXProps>(rule: K2Rule<T>, props: T): string;
  combineRule<A = K2RuleWithVCXProps, B = K2RuleWithVCXProps>(a: K2Rule<A>, b: K2Rule<B>): K2Rule<A & B>;
  combineRules<T = K2RuleWithVCXProps>(...rules: Array<K2Rule<T>>): K2Rule;
  renderFontRule(font: VCXFontNoColor, vcx: VisualContext): string;
  renderStatic(style: string, selector?: string): void;
  renderKeyFrame<T = { vcx: VisualContextMetadata }>(keyFrame: K2KeyFrame<T>, props: T): string;
}

//--------------------------Common rules -----------------------------------------//

export type K2RuleWithMarginProps = K2RuleWithVCXProps & {
  mX: number;
  mY: number;
};

export const marginRule = (props: K2RuleWithMarginProps) => ({
  paddingTop: props.vcx.Data.MarginY ? props.vcx.Data.MarginY * props.mY + "px" : "auto",
  paddingBottom: props.vcx.Data.MarginY ? props.vcx.Data.MarginY * props.mY + "px" : "auto",
  paddingLeft: props.vcx.Data.MarginX ? props.vcx.Data.MarginX * props.mX + "px" : "auto",
  paddingRight: props.vcx.Data.MarginX ? props.vcx.Data.MarginX * props.mX + "px" : "auto",
});

type K2RuleWithFontProps = K2RuleWithVCXProps & {
  font: VCXFontNoColor;
};

const fontRule = (props: K2RuleWithFontProps) => ({
  fontFamily: props.font.FontName,
  fontSize: props.font.FontSize + "pt",
  fontWeight: props.font.IsBold ? ("bold" as "bold") : ("normal" as "normal"),
  fontStyle: props.font.IsItalic ? ("italic" as "italic") : ("normal" as "normal"),
  textDecoration: getTextDecoration(props.font.IsStrikeOut, props.font.IsUnderline),
});

export function getTextDecoration(isStrikeOut: boolean, isUnderline: boolean): "line-through" | "underline" | "unset" | "line-through underline" {
  if (isStrikeOut && isUnderline) {
    return "line-through underline";
  }

  if (isStrikeOut) {
    return "line-through";
  }

  if (isUnderline) {
    return "underline";
  }

  return "unset";
}

//-------------------------- FELA ------------------------------------------------//
import { render } from "fela-dom";
import { createRenderer, IRenderer, combineRules, IStyle, TKeyFrame, IConfig } from "fela";
import { VisualContext, VCXFontNoColor } from "../common/visualContext";
import webPreset from "fela-preset-web";
import namedMediaQuery from "fela-plugin-named-keys";
import { Context } from "../appcontext";

export class FelaStyleRenderer implements K2StyleRenderer {
  private renderer: IRenderer;
  private breakpoints: Array<number>;

  constructor(breakpoints: Array<number>) {
    this.breakpoints = breakpoints && breakpoints.length > 0 ? breakpoints : [980];

    let config: IConfig = {
      plugins: [
        namedMediaQuery({
          small: `@media (max-width: ${this.breakpoints[0]}px)`,
        }),
        ...webPreset,
      ],
      selectorPrefix: "K2_",
      devMode: process.env.NODE_ENV === "development",
    };

    this.renderer = createRenderer(config);
    render(this.renderer);
  }

  matchSmallMediaBreakpoint(): boolean {
    return window.matchMedia(`(max-width: ${this.breakpoints[0]}px)`).matches;
  }

  renderRule<T = { vcx: VisualContextMetadata }>(rule: K2Rule<T>, props: T): string {
    if (!rule) return "";
    return this.renderer.renderRule(rule, props);
  }

  combineRule<A = K2RuleWithVCXProps, B = K2RuleWithVCXProps>(a: K2Rule<A>, b: K2Rule<B>): K2Rule<A & B> {
    return combineRules(a, b);
  }

  combineRules<T = K2RuleWithVCXProps>(...rules: Array<K2Rule<T>>): K2Rule {
    return combineRules(...rules);
  }

  renderFontRule(font: VCXFontNoColor, vcx: VisualContext): string {
    return this.renderer.renderRule(fontRule, { font: font, vcx: vcx });
  }

  renderStatic(style: string, selector: string) {
    this.renderer.renderStatic(style, selector);
  }

  renderKeyFrame<T = { vcx: VisualContextMetadata }>(keyFrame: K2KeyFrame<T>, props: T): string {
    if (!keyFrame) return "";
    return this.renderer.renderKeyframe(keyFrame, props);
  }
}

let renderer: FelaStyleRenderer;

export function Renderer(): FelaStyleRenderer {
  if (!renderer) {
    renderer = new FelaStyleRenderer(Context.DeviceInfo.ResponsiveBreakpoints);
  }

  return renderer;
}

export function matchSmallMediaBreakpoint(): boolean {
  return Renderer().matchSmallMediaBreakpoint();
}
