import React = require("react");
import { K2RuleWithVCXProps } from "./k2StyleRenderer";
import { UpdateControl, UFUpdateControl } from "../common/communication.base";
import { K2ComponentState, WithContextPlacementProps, withContext, StyleHelper, AcquireControl } from "./k2hoc";
import { NclGroupBox } from "../common/components.ncl";
import { GenerateControl, GenerateChildrenOfControl } from "./K2GenerateControl";
import { K2TruncateText } from "./K2TruncateText";

const groupBoxTitle = (props: K2RuleWithVCXProps) => ({
  flex: "0 0 auto",
  margin: props.vcx.sizeMap(3) + "px",
  minHeight: props.vcx.InputControl.getInputHeight(1, true, false) + "px",
  color: props.vcx.getColor(props.vcx.Data.ColorMap.ContentDecorateColorFrg),
  borderBottom: props.vcx.sizeMap(2) + "px solid " + props.vcx.getColor(props.vcx.Data.ColorMap.ContentDecorateColorFrg),
  "> p": {
    marginBottom: props.vcx.ExpanderControl.GetHFMargin() + "px",
    marginLeft: "0.5em",
    marginRight: "0.5em",
  },
});

const gbRule = (props: K2RuleWithVCXProps) => ({
  display: "flex",
  flexDirection: "column" as "column",
});

class _GroupBox extends React.PureComponent<WithContextPlacementProps, K2ComponentState<UFUpdateControl>> {
  static displayName = `K2GroupBox`;
  private control: NclGroupBox;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclGroupBox;
    }) as NclGroupBox;
    this.state = { data: this.control.init(this) as UFUpdateControl, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UFUpdateControl>) => {
      return { data: state as UFUpdateControl };
    });

    this.forceUpdate();
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    return (
      <div
        style={StyleHelper(this.control, this.props.style)}
        className={this.props.renderer.renderRule(gbRule, { vcx: this.control.VCX, inEditMode: this.control.InEditMode })}
      >
        <div className={this.props.renderer.renderRule(groupBoxTitle, { vcx: this.control.VCX })}>
          <K2TruncateText className={this.props.renderer.renderFontRule(this.control.VCX.ExpanderControl.HeaderFont, this.control.VCX)}>
            {this.state.data.Title}
          </K2TruncateText>
        </div>
        {GenerateChildrenOfControl(this.control)}
      </div>
    );
  }
}
export const K2GroupBox = withContext(_GroupBox);
