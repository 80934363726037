import * as React from "react";

import { UpdateControl } from "../common/communication.base";
import { NclToolBar, NclTabToolBar, NclInput } from "../common/components.ncl";
import { GenerateControl } from "./K2GenerateControl";
import { K2ComponentState, withContext, WithContextPlacementProps, StyleHelper, AcquireControl } from "./k2hoc";
import { K2RuleWithVCXProps } from "./k2StyleRenderer";
import { ActionSeparator, K2Action } from "./K2Action";

const toolBarRule = (props: K2RuleWithVCXProps) => ({
  flex: "0 1 auto",
});

const actionsRule = (props: K2RuleWithVCXProps) => ({
  flex: "1",
  width: "100%",
  "> div": {
    height: "100%",
  },
});

const menuRule = (props: K2RuleWithVCXProps) => ({
  display: "flex",
  flex: "0 1 auto",
});

class _ToolBar extends React.PureComponent<WithContextPlacementProps, K2ComponentState<UpdateControl>> {
  static displayName = `K2ToolBar`;
  private control: NclToolBar;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclToolBar;
    }) as NclToolBar;
    this.state = { data: this.control.init(this) as UpdateControl, vcxVersion: -1 };
  }

  // TODO: najít kde je volán update do již UnMount componenty a ten podchytit
  _isMounted = false;
  componentDidMount() {
    this._isMounted = true;
  }

  updateState(state: UpdateControl) {
    if (this._isMounted) {
      this.setState((prevState: K2ComponentState<UpdateControl>) => {
        return { data: state };
      });
    } else {
      console.error("TODO: najít kde je volán update již UnMount componenty a ten podchytit");
    }
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
    this._isMounted = false;
  }

  render() {
    let menu: JSX.Element = null;

    if (this.control.MenuBtn) {
      menu = (
        <div className={this.props.renderer.renderRule(menuRule, { vcx: this.control.VCX })}>
          <ActionSeparator width={this.control.VCX.sizeMap(1)} color={this.control.VCX.getColor(this.control.VCX.Data.ColorMap.ContentFrame1)} />
          <span style={{ paddingRight: `${this.control.VCX.sizeMap(3)}px` }}></span>
          <K2Action
            controlUID={this.control.MenuBtn.MetaData.ControlUID}
            vrUID={this.control.getRealizerUID()}
            color={this.control.HasMainMenu ? this.control.VCX.getColor(this.control.VCX.Data.ColorMap.BaseColorBck1) : null}
          />
        </div>
      );
    }

    let classFin: string = this.props.renderer.renderRule(toolBarRule, { vcx: this.control.VCX });
    if (this.props.className && this.props.className != undefined) {
      classFin += " " + this.props.className;
    }

    return (
      <div
        style={StyleHelper(
          this.control,
          Object.assign(
            {},
            {
              width: "100%",
              minWidth: this.control.MenuBtn
                ? this.control.Actions.length === 0
                  ? this.control.VCX.sizeMap(30) + "px"
                  : this.control.VCX.sizeMap(80) + "px"
                : "fit-content",
            },
            this.props.style
          )
        )}
        className={classFin}
      >
        <div
          style={{ overflow: !(this.control.Parent instanceof NclInput) ? "auto" : "hidden" }}
          className={this.props.renderer.renderRule(actionsRule, { vcx: this.control.VCX })}
        >
          {this.control.Actions.map((ctrl, index) => {
            return GenerateControl(ctrl, { flex: "none", width: "auto" });
          })}
        </div>
        {menu}
      </div>
    );
  }
}

export const K2ToolBar = withContext(_ToolBar);
