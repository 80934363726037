import * as React from "react";

import { UpdateControl, UpdateHeadered, CSNclViewMetadata } from "../common/communication.base";
import { withContext, K2ComponentState, WithContextPlacementProps, StyleHelper, AcquireControl } from "./k2hoc";
import {
  NclFloaterView,
  NclViewBase,
  NclFloaterAccessor,
  NclMenuView,
  NclMenu,
  UFNclControlBase,
  NclOpenDialog,
  ViewListener,
  NclInplaceView,
} from "../common/components.ncl";
import { K2FlowPanel } from "./K2FlowPanel";
import { K2Header } from "./K2Expander";
import { SimpleModalWindow, ModalMenuWindow, ModalWindow, ModalWindowBase } from "./K2Modal";
import { K2Menu } from "./K2Menu";
import { ViewRealizerManager } from "../viewrealizer";
import { Context } from "../appcontext";
import { K2OpenDialog } from "./K2OpenDialog";
import { Helper } from "../common/common";

interface ViewProps extends WithContextPlacementProps {
  overlayBck: boolean;
}

class _View extends React.PureComponent<ViewProps, K2ComponentState<UpdateHeadered>> {
  static displayName = `K2View`;
  private control: NclViewBase<CSNclViewMetadata, UpdateHeadered>;
  private window: ModalWindowBase;

  constructor(props: ViewProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclViewBase;
    }) as NclViewBase<CSNclViewMetadata, UpdateHeadered>;
    this.state = { data: this.control.init(this) as UpdateHeadered, vcxVersion: -1 };
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateHeadered>) => {
      return { data: state as UpdateHeadered };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  close(): Promise<void> {
    if (this.window) {
      return this.window.close();
    } else {
      return Promise.resolve();
    }
  }

  show(): Promise<void> {
    if (this.window) {
      return this.window.show();
    } else {
      return Promise.resolve();
    }
  }

  private handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.keyCode === 27 && this.control instanceof NclFloaterView && this.control.Parent instanceof NclFloaterAccessor) {
      this.control.Parent.hide();
      e.stopPropagation();
    }
  };

  render() {
    let isMenu: boolean = false;
    let content: JSX.Element = null;
    if (this.control.Content instanceof NclMenu) {
      isMenu = true;
      content = (
        <K2Menu
          style={{ height: "100%" }}
          controlUID={this.control.Content.MetaData.ControlUID}
          vrUID={this.control.Content.getRealizerUID()}
          depth={0}
          subMenuDisplayInside={Context.DeviceInfo.StyleOfModalWindowShow === "mobile"}
        />
      );
    } else if (this.control instanceof NclOpenDialog) {
      content = <K2OpenDialog controlUID={this.control.MetaData.ControlUID} vrUID={this.control.getRealizerUID()} />;
    } else {
      content = <K2FlowPanel controlUID={this.control.Content.MetaData.ControlUID} vrUID={this.control.Content.getRealizerUID()} />;
    }
    let vr = ViewRealizerManager.getViewRealizer(this.control.getRealizerUID());

    let view = (
      <div
        data-k2-edit-mode={this.control.InEditMode}
        data-k2-test-id={this.control.MetaData.Name}
        style={StyleHelper(this.control, Object.assign({ flexDirection: "column", height: "100%", position: "relative" }, this.props.style))}
        onKeyDown={this.handleKeyDown}
      >
        {this.props.children}
        {this.control.isShowHeader() && (
          <K2Header
            controlUID={this.control.Header.MetaData.ControlUID}
            vrUID={this.control.getRealizerUID()}
            title={this.state.data.Title}
            className={"handle_" + this.control.MetaData.ControlUID}
          />
        )}
        {content}
      </div>
    );

    if (
      !vr.showAsLocalModal() &&
      !vr.showAsModal() &&
      !(this.control instanceof NclFloaterView) &&
      !(this.control instanceof NclMenuView) &&
      !(this.control instanceof NclOpenDialog)
    )
      return view; // View dock in main docked layout

    let window = null;

    switch (Context.DeviceInfo.StyleOfModalWindowShow) {
      case "mobile": {
        if (this.control instanceof NclMenuView && this.control.isOnlyActionsInFirstLevel(3)) {
          window = (
            <ModalMenuWindow
              isOverlayBck={this.props.overlayBck}
              ref={(ref) => {
                this.window = ref;
              }}
              renderer={this.props.renderer}
              vcx={this.control.VCX}
              realizerUID={vr.getRealizerUID()}
              controlUID={this.control.MetaData.ControlUID}
            >
              {view}
            </ModalMenuWindow>
          );
        } else {
          window = (
            <SimpleModalWindow
              isOverlayBck={this.props.overlayBck}
              ref={(ref) => {
                this.window = ref;
              }}
              renderer={this.props.renderer}
              vcx={this.control.VCX}
              realizerUID={vr.getRealizerUID()}
              controlUID={this.control.MetaData.ControlUID}
            >
              {view}
            </SimpleModalWindow>
          );
        }
        break;
      }
      case "normal": {
        if (isMenu) {
          window = (
            <ModalMenuWindow
              isOverlayBck={this.props.overlayBck}
              ref={(ref) => {
                this.window = ref;
              }}
              renderer={this.props.renderer}
              vcx={this.control.VCX}
              realizerUID={vr.getRealizerUID()}
              controlUID={this.control.MetaData.ControlUID}
            >
              {view}
            </ModalMenuWindow>
          );
        } else {
          window = (
            <ModalWindow
              isOverlayBck={this.props.overlayBck}
              ref={(ref) => {
                this.window = ref;
              }}
              renderer={this.props.renderer}
              vcx={this.control.VCX}
              realizerUID={vr.getRealizerUID()}
              controlUID={this.control.MetaData.ControlUID}
            >
              {view}
            </ModalWindow>
          );
        }
        break;
      }
    }

    return window;

    return null;
  }
}

export const K2View = withContext(_View);
