import { LogLevel, Log } from "./common/common";
import { Context } from "./appcontext";
import { K2App } from "./components/app";

window.onload = (ev: Event) => {
  cssMouseDevice(); // V případě zařízení s myší přidá specifické pseudotřídy.(:hover atp.)
  Log.init(LogLevel.Error);
  Context.registerApplication(new K2App());
  Context.getApplication().run();
};

window.onunload = (ev: Event) => {
  Context.getApplication().close(false, true);
};

if (process.env.NODE_ENV !== "development") {
  // Zakázat tlačítko ZPĚT v prohlížeči
  history.pushState(null, null, document.URL);
  window.addEventListener("popstate", function () {
    history.pushState(null, null, document.URL);
    Context.getApplication().getActiveRealizer().processHotKey("ESC");
  });

  // tlačítko REFRESH v prohlížeči na dotaz
  window.addEventListener("beforeunload", function (event) {
    if (Context.getApplication().appViewRealizer) {
      event.preventDefault();
      event.returnValue = ""; //chrome potřebuje návratovou hodnotu
    }
  });

  // Zakázet pravé tlačítko myši pro potlačení výchozího contexMenu nad internetovým prohlížečem.
  window.addEventListener("contextmenu", function () {
    event.preventDefault();
  });
}

/* Pro dotyková zařízení */
function cssMouseDevice(): void {
  // rozezná, zda se jedná o zařízení s myší a dle toho volá načtení css souboru vhodného pouze pro toto zařízení
  if (navigator.maxTouchPoints === 0) {
    addCssFile("default_mouse_device");
  }
}

function addCssFile(file: string): void {
  // načte css soubor do hlavičky webu
  if (!document.getElementById(file)) {
    var head = document.getElementsByTagName("head")[0];
    var link = document.createElement("link");
    link.id = file;
    link.rel = "stylesheet";
    link.type = "text/css";
    link.href = "./css/" + file + ".css";
    link.media = "all";
    head.appendChild(link);
  }
}
/* KONEC - Pro dotyková zařízení */

// var DESIGN_LAYOUT = false; // vykresli pouze zakladni layout, tlacitka a taby
