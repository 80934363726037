import axios, { AxiosRequestConfig } from "axios";

import { Log } from "./common";
import { ReceiveCallBack } from "./communication";
import { GlyphId } from "./communication.base";
import { NclImage, InlineSVGGlyphsPattern } from "./components.ncl";

export class MediaData {
  content: string;
  contentType: string;
  oneUseOnly: boolean;
  errorMessage: string;

  constructor(content: string, contentType: string, errorMessage: string = "", oneUseOnly: boolean = false) {
    this.content = content;
    this.contentType = contentType;
    this.oneUseOnly = oneUseOnly;
    this.errorMessage = errorMessage;
  }

  public get base64Data(): string {
    return `data:${this.contentType};base64,${this.content}`;
  }

  public get blob(): Blob {
    const binStr = atob(this.content);
    const len = binStr.length;
    const arr = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      arr[i] = binStr.charCodeAt(i);
    }
    return new Blob([arr], { type: this.contentType });
  }
}

export class MediaManager {
  private static instance: MediaManager;
  private cache: Map<string, MediaData>;
  private receiveCallbacksQueue: Map<string, Array<ReceiveCallBack>>;

  private constructor() {
    this.cache = new Map<string, MediaData>();
    this.receiveCallbacksQueue = new Map<string, Array<ReceiveCallBack>>();
  }

  private static get Instance(): MediaManager {
    if (!MediaManager.instance) MediaManager.instance = new MediaManager();

    return MediaManager.instance;
  }

  private static pushReceiveCallback(key: string, callback: ReceiveCallBack) {
    let manager: MediaManager = MediaManager.Instance;
    let callbacks: Array<ReceiveCallBack> = null;
    if (!manager.receiveCallbacksQueue.has(key)) {
      callbacks = new Array<ReceiveCallBack>();
      manager.receiveCallbacksQueue.set(key, callbacks);
    } else {
      callbacks = manager.receiveCallbacksQueue.get(key);
    }

    callbacks.push(callback);
  }

  public static clear() {
    MediaManager.instance = null;
  }

  public static tryGetData(key: string): Promise<MediaData> {
    let data = MediaManager.Instance.cache.get(key);
    if (data) {
      if (data.oneUseOnly) {
        MediaManager.instance.cache.delete(key);
      }
      return Promise.resolve<MediaData>(data);
    } else {
      if (MediaManager.Instance.receiveCallbacksQueue.has(key)) {
        return new Promise<MediaData>((resolve, reject) => {
          MediaManager.pushReceiveCallback(key, { resolve: resolve, reject: reject });
        });
      }
    }
  }

  public static getSVG(glyphId: GlyphId): Promise<MediaData> {
    let re: RegExp = new RegExp(InlineSVGGlyphsPattern);
    let inline: RegExpExecArray = re.exec(glyphId);
    if (inline) {
      return Promise.resolve<MediaData>(new MediaData(glyphId.replace(inline[0], ""), "image/svg+xml"));
    }

    let result = MediaManager.tryGetData(glyphId);
    if (result != null) {
      return Promise.resolve<MediaData>(result);
    } else {
      let address = NclImage.getSourceUrl(glyphId);
      if (address) {
        return MediaManager.downloadAndSet(address.url, glyphId, false, false, address.altUrl);
      }
    }
  }

  public static get(url: string, key: string): Promise<MediaData> {
    if (url === "") return Promise.reject("Url is empty.");
    if (key === "") key = url;
    let result = MediaManager.tryGetData(key);
    if (result != null) {
      return Promise.resolve<MediaData>(result);
    } else {
      return MediaManager.downloadAndSet(url, key, true, false);
    }
  }

  public static download(url: string, key: string, oneUseOnly: boolean = true) {
    MediaManager.downloadAndSet(url, key, true, oneUseOnly);
  }

  public static setErrorMessge(key: string, errorMessage: string, oneUseOnly: boolean = true) {
    MediaManager.Instance.cache.set(key, new MediaData("", "", errorMessage, oneUseOnly));
  }

  private static downloadAndSet(url: string, key: string, useBase64: boolean, oneUseOnly: boolean, altUrl: string = undefined): Promise<MediaData> {
    const promise: Promise<MediaData> = new Promise<MediaData>((resolve, reject) => {
      MediaManager.pushReceiveCallback(key, { resolve: resolve, reject: reject });

      let callbacks: Array<ReceiveCallBack> = MediaManager.Instance.receiveCallbacksQueue.get(key);
      if (callbacks.length === 1) {
        Log.debug("Get data from :" + url);
        MediaManager.loadData(url, key, useBase64, oneUseOnly, callbacks, altUrl);
      }
    });
    return promise;
  }

  private static loadData(url: string, key: string, useBase64: boolean, oneUseOnly: boolean, callbacks: Array<ReceiveCallBack>, altUrl: string = undefined) {
    let config: AxiosRequestConfig = { method: "get" };
    if (useBase64) config.responseType = "arraybuffer";
    axios
      .get(url, config)
      .then((response) => {
        if (response.status === 200 && response.data) {
          let data = new MediaData(
            !useBase64 ? response.data : new Buffer(response.data, "binary").toString("base64"),
            response.headers ? response.headers["content-type"] : "",
            "",
            oneUseOnly
          );
          if (!MediaManager.Instance.cache.has(key)) {
            MediaManager.Instance.cache.set(key, data);
          }

          while (callbacks.length > 0) {
            callbacks.shift().resolve(data);
          }
        } else {
          if (altUrl) {
            MediaManager.loadData(altUrl, key, useBase64, oneUseOnly, callbacks);
          } else {
            while (callbacks.length > 0) {
              callbacks.shift().reject(response.statusText);
            }
          }
        }

        MediaManager.Instance.receiveCallbacksQueue.delete(key);
      })
      .catch((error) => {
        while (callbacks.length > 0) {
          callbacks.shift().reject(error);
        }

        MediaManager.Instance.receiveCallbacksQueue.delete(key);
      });
  }
}
