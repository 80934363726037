import { ETIME } from "constants";
import { List } from "immutable";

import { Context } from "../appcontext";
import { RealizerOperations, ViewRealizer, ViewRealizerAttachDetachControlFce, ViewRealizerManager, VRContext } from "../viewrealizer";
import { Helper, Log, parseData } from "./common";
import {
  Align,
  CheckBoxDataRequest,
  cJSonDefaultAcceptExecute,
  cJSonFunctionContextMenu,
  cJSonFunctionExecute,
  cJSonFunctionGridOperation,
  cJsonFunctionHideFloater,
  cJsonFunctionShowFloater,
  ControlDataRequest,
  CSFile,
  CSNclButtonMetadata,
  CSNclCheckBoxMetaData,
  CSNclCommandItemMetadata,
  CSNclContainerMetadata,
  CSNclControlMetadata,
  CSNclDataGridBaseMetadata,
  CSNclDataGridContent,
  CSNclDataGridMetadata,
  CSNclDataLabelMetadata,
  CSNclDynamicContentMetadata,
  CSNclExpanderMetadata,
  CSNclFloaterAccessorMetadata,
  CSNclFloaterViewMetadata,
  CSNclFormattableInputMetadata,
  CSNclGroupBoxMetaData,
  CSNclHeaderedMetadata,
  CSNclHeaderMetaData,
  CSNclImageMetadata,
  CSNclInnerDataGridMetadata,
  CSNclInputMetadata,
  CSNclLocatorPanelMetadata,
  CSNclMenuContainerMetadata,
  CSNclMenuDividerMetadata,
  CSNclMenuGroupMetadata,
  CSNclMenuItemBaseMetadata,
  CSNclMenuItemMetadata,
  CSNclMenuMetadata,
  CSNclMenuViewMetadata,
  CSNclOpenDialogMetadata,
  CSNclPageMetadata,
  CSNclPanelMetadata,
  CSNclListViewMetadata,
  CSNclPreviewPanelMetadata,
  CSNclSplitterPanelMetadata,
  CSNclTabControlMetadata,
  CSNclTabToolBarMetadata,
  CSNclToolBarMetadata,
  CSNclViewMetadata,
  CSNclVRTabControlMetadata,
  CSUFNclControlMetadata,
  CSUFUpdateControl,
  CSUpdateCommandItem,
  CSUpdateControl,
  CSUpdateDynamicContent,
  CSUpdateMenuContainer,
  DataActionDecorate,
  InnerDataGridDataRequest,
  DisplayMode,
  FrgtCommandItemData,
  FrgtGridLinesStyle,
  Function,
  GlyphId,
  GridOperations,
  HorizontalAlignment,
  IconPosition,
  InputDataRequest,
  OpenDialogDataRequest,
  Orientation,
  SplitterDataRequest,
  TabControlDataRequest,
  UFUpdateControl,
  Updatable,
  UpdateCheckBox,
  UpdateCommandItem,
  UpdateControl,
  UpdateDataLabel,
  UpdateDockControl,
  UpdateDynamicContent,
  UpdateExpander,
  UpdateFormattableInput,
  UpdateHeadered,
  UpdateImage,
  UpdateInnerDataGrid,
  UpdateInput,
  UpdateMenuContainer,
  UpdateSplitterPanel,
  UpdateTabControl,
  UpdateVRTabControl,
  VerticalAlignment,
  CSNclSignInputMetadata,
  UpdateSignInput,
  SignData,
  SignInputDataRequest,
  FrameStyle,
  RectInDock,
  CSNclDataGridFooter,
  cJSonFunctionGridChangeSortBy,
  UpdateListView,
  CSNclInnerSectionMetaData,
  CSNclInnerToolBarMetadata,
  CSNclRibbonMetadata,
  TUFActionDisplayStyle,
  CSNclBreakerMetadata,
  UpdateRibbon,
  ListViewDataRequest,
  TNclInputType,
  cJSonFunctionNextPriorValue,
  DEFAULT_LANG_IMG,
  UpdateInnerToolbar,
  UpdatePageControl,
  CSNCLTreeViewMetadata,
  CSNclInnerTreeViewMetadata,
  UpdateInnerTreeView,
  cJSonFunctionToggleNodeExecute,
  TreeViewDataRequest,
  FrgtPanelBaseData,
  FrgtSplitterPanelData,
  SplitterPanelOrientation,
  TTreeDataMoveNodeMode,
  cJSonFunctionMoveNodeExecute,
  TreeDataItem,
  CSTreeDataItem,
  TTreeDataHasChildNodes,
  ModifyTreeItem,
  TDataItemModifyType,
  ModifyItem,
  CSDataItem,
  CSUpdateInnerDataGrid,
  CSNclQuickFilterMetadata,
  UpdateQuickFilter,
  CSUpdateQuickFilter,
  CSNclAggregationPanel,
  UpdateAggregationPanel,
  CSColumn,
  UpdateDataGrid,
  cJSonDataGridDblClickExecute,
  RibbonDataRequest,
  CSUpdateInnerTreeView,
  InnerColumnsListDataRequest,
  GetColumnWidthEm,
  ColumnsProportion,
  CSUpdateRibbon,
} from "./communication.base";
import { VisualContext } from "./visualContext";

export type ForEachNestedControlFce = (control: NclControlBase) => any;

export interface UFOverAlign {
  getOverRect(): DOMRect;
  registerOverAlignListener?(listener: UFOverAlignListener): void;
  unRegisterOverAlignListener?(listener: UFOverAlignListener): void;
}

export interface UFOverAlignListener {
  updateAlignAnchor(): void;
}

export interface NclControlBase extends Updatable {
  appendFunction(fce: Function): void;
  init(listener: ControlListener): UpdateControl;
  willUnMount(onlyListener: boolean): void;
  getRealizerUID(): string;
  isInPreview(): boolean;
  MetaData: CSNclControlMetadata;
  State: ControlStateBase;
  Listener: any; //typ any je zde schválně, jelikož je pak možné využít metody findDOMNode v reactu
  InEditMode: boolean;
  VCX: VisualContext;
  ComputedMinHeight: number;
  ComputedMinHeightWithMargin: number;
  MarginXFactor: number;
  MarginYFactor: number;
  Size: number;
}

export interface UFNclControlBase extends NclControlBase {
  setAsActiveControl(isActive: boolean): void;
  setVCX(vcx: VisualContext, forceRefresh: boolean): void;
  MetaData: CSUFNclControlMetadata;
}

export interface ControlListener {
  setAsActive?(isActive: boolean): void;
  updateState(state: UpdateControl): void;
  updateVCX(vcxVersion: number): void;
}
/**
 * Interface for window.
 */
export interface ViewListener extends ControlListener {
  /**
   * Method for close window. Promise resolve is called when window is closed, after all animation.
   */
  close(): Promise<void>;
  /**
   * Method for open window. Promise resolve is called when window is closed, after all animation.
   */
  show(): Promise<void>;
}

/**
 * Interface for dock component
 */
export interface DockListener extends ControlListener {
  /**
   * Dock realizer to dock component.Promise resolve is called when VR is docked and all animation ended.
   */
  dock(state: UpdateDockControl): Promise<void>;
  /**
   * UnDock realizer from dock component.Promise resolve is called when VR is docked and all animation ended.
   */
  undock(): Promise<void>;
}

export interface ContainerListener extends ControlListener {
  reCalculateHeight(byControlUID: string): void; //vyvolá přepočet výšky bandů
}

export interface DataGridListener extends ControlListener {
  updatePosition(oldRow: number, newRow: number, oldCol: number, newCol: number): void;
}
/**
 * interface se základními vlastnostmi které mohou číst i ostatní komponenty a ne jen ty které ncl vlastní.
 */
export interface ControlStateBase {
  readonly Visible: boolean;
  readonly Enabled: boolean;
  readonly TabStop: boolean;
}

export interface InputSelectionText {
  start: number;
  end: number;
}

var lastFceKey: { key: string; timestamp: number };
var limitedFceList = [cJSonFunctionExecute]; //list of functions for which multi-run is blocked

abstract class NclControl<T extends CSNclControlMetadata, S extends UpdateControl> implements NclControlBase {
  protected vr: ViewRealizer;
  protected vrAttachDetachFce: ViewRealizerAttachDetachControlFce = null;
  private listener: ControlListener;
  protected state: S;
  protected ncl: T;
  private request: ControlDataRequest;
  private isPreparedDataRequest: boolean = false;
  private nestedControls: NclControlBase[];
  protected parent: NclControlBase;

  constructor(ncl: T, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    this.vr = vr;
    this.vrAttachDetachFce = vrAttachDetachFce;
    this.ncl = Helper.clone(ncl);
    if (!this.ncl) {
      throw new Error("Ncl isn't defined.");
    }
    this.parent = parent;
    if (this.vrAttachDetachFce != null) this.vrAttachDetachFce.call(this.vr, this, true);
    this.state = this.createDefaultState();
    this.initDataRequest();
  }

  get Size(): number {
    return 1;
  }

  get VCX(): VisualContext {
    return this.internalGetVCX();
  }

  get MetaData(): CSNclControlMetadata {
    return this.ncl;
  }

  get Ncl(): T {
    return this.ncl;
  }

  get State(): ControlStateBase {
    return this.state;
  }

  get Listener(): any {
    return this.listener;
  }

  get InEditMode(): boolean {
    return this.internalInEditMode();
  }

  get MarginXFactor(): number {
    return 1;
  }

  get MarginYFactor(): number {
    return 1;
  }

  get ComputedMinHeight(): number {
    return this.computeMinHeight(false);
  }

  get ComputedMinHeightWithMargin(): number {
    return this.computeMinHeight(true);
  }

  get Parent(): NclControlBase {
    return this.parent;
  }

  get IsPreparedDataRequest(): boolean {
    return this.isPreparedDataRequest;
  }

  public isInPreview(): boolean {
    let ctrl: NclControl<any, any> = this;
    while (ctrl) {
      if (ctrl instanceof NclPreviewPanel) return true;
      ctrl = ctrl.Parent as NclControl<any, any>;
    }

    return false;
  }

  public addNestedControl(control: NclControlBase) {
    if (!this.nestedControls) this.nestedControls = [];

    this.nestedControls.push(control);
  }

  public removeNestedControl(control: NclControlBase) {
    if (this.nestedControls) {
      let i = this.nestedControls.indexOf(control);
      if (i >= 0) {
        this.nestedControls.splice(i, 1);
      }
    }
  }

  protected internalInEditMode(): boolean {
    return this.vr.InEditMode;
  }

  protected forEachNestedControls(fce: ForEachNestedControlFce) {
    if (this.nestedControls) this.nestedControls.map(fce);
  }

  protected internalGetVCX(): VisualContext {
    if (this.parent) {
      return this.parent.VCX;
    }
    if (this.vr) {
      return this.vr.VCX;
    }

    return VisualContext.Default;
  }

  protected computeMinHeight(withMargin: boolean): number {
    if (withMargin) {
      return this.internalComputeMinHeight() + 2 * (this.MarginYFactor * this.VCX.Data.MarginY);
    } else {
      return this.internalComputeMinHeight();
    }
  }

  protected internalComputeMinHeight(): number {
    return this.VCX.MinRowHeight;
  }

  protected abstract createDefaultState(): S;

  public appendFunction(fce: Function, callServer: boolean = false) {
    if (callServer && limitedFceList.indexOf(fce.Name) >= 0) {
      let key = `${this.MetaData.ControlUID}_${fce.Name}`;
      if (lastFceKey && key === lastFceKey.key && new Date().getTime() - lastFceKey.timestamp <= 300) {
        return;
      }
      lastFceKey = { key: key, timestamp: new Date().getTime() };
    }
    if (this.request.Functions == null) this.request.Functions = new Array<Function>();

    if (
      this.request.Functions.findIndex((value: Function, index: number, obj: Function[]) => {
        if (value.Name === fce.Name) return true;
      }) === -1
    ) {
      this.request.Functions.push(fce);
    }
    this.isPreparedDataRequest = true;
    if (callServer) {
      this.vr.sendRequest(RealizerOperations.None);
    }
  }

  public resetToState() {
    if (this.listener) {
      let st = this.state.toJS();
      this.state = this.createDefaultState();
      this.updateState(st);
    }
  }

  public updateState(data: CSUpdateControl) {
    let newState: S = this.state.with(data) as S;
    let canUpdate: boolean = true;
    if (
      Object.keys(data).length === 2 &&
      data.Visible === false &&
      this.parent &&
      this.parent.State.Visible === data.Visible &&
      (!(this.parent instanceof NclPage) || !(this.parent.parent instanceof NclVRTabControl))
    ) {
      canUpdate = false; //Can't call update when parent container is invisible.
    }
    this.setState(newState, canUpdate);
  }

  protected setState(newState: S, canUpdate: boolean) {
    if (newState !== this.state) {
      if (canUpdate) canUpdate = this.internalCanUpdate(newState);

      let oldState = this.state;
      this.state = newState;
      this.afterSetState(canUpdate, oldState);
    }
  }

  protected afterSetState(canUpdate: boolean, oldState: S) {
    if (canUpdate) {
      if (this.listener != null) {
        this.listener.updateState(this.state);
        if (this.state.Visible != oldState.Visible && this.parent instanceof NclContainerBase) {
          this.parent.changeVisibleAnyPartsOfContainer(this.MetaData.ControlUID);
        }
      } else {
        if (this.state.Visible === oldState.Visible) {
          Log.warn("Listener not found."); //pouze zmena visible muze narazit na nenastavenneho listenera, v jinem pripade se jedna o zbytecny update a mel by byt zrusen
        }
      }
    }
  }

  public collectData(collector: Array<ControlDataRequest>) {
    this.internalCollectData(collector);
  }

  public setAsActiveControl(isActive: boolean) {
    if (this.listener && this.listener.setAsActive) {
      this.listener.setAsActive(isActive);
    }
  }

  public setActiveControlRequested() {
    this.vr.RequestActiveControlUID = this.ncl.ControlUID;
  }

  public willUnMount(onlyListener: boolean) {
    if (onlyListener) {
      this.listener = null;
    } else {
      this.vrAttachDetachFce.call(this.vr, this);
      this.vrAttachDetachFce = null;
      this.listener = null;
      this.parent = null;
      this.vr = null;
    }
    this.forEachNestedControls((ctrl) => {
      ctrl.willUnMount(onlyListener);
    });
  }

  protected internalCanUpdate(newState: S): boolean {
    return true;
  }

  public getRealizerUID(): string {
    if (this.vr != null) {
      return this.vr.getRealizerUID();
    }

    return "";
  }

  protected internalCollectData(collector: Array<ControlDataRequest>) {
    if (this.isPreparedDataRequest) {
      collector.push(this.request);
      this.initDataRequest();
    }

    this.forEachNestedControls((value) => {
      value.collectData(collector);
    });
  }

  public init(listener: ControlListener): UpdateControl {
    if (this.listener == null) this.listener = listener;
    return this.state;
  }

  protected internalSetVCX(vcx: VisualContext, forceRefresh: boolean) {
    if (this.listener != null && forceRefresh && this.state.Visible) {
      this.listener.updateVCX(this.VCX.getVersion());
    }

    this.forEachNestedControls((value) => {
      (value as NclControl<any, any>).internalSetVCX(vcx, forceRefresh);
    });
  }

  protected internalSetData<U extends ControlDataRequest>(
    data: U,
    callServer: Boolean,
    changeDataValue: boolean = false,
    rootOperation: RealizerOperations = RealizerOperations.None
  ) {
    if (data != null) {
      Object.assign(this.request, data);
      this.isPreparedDataRequest = true;
      if (changeDataValue) {
        this.vr.addToChangeList(this.MetaData.ControlUID);
      }
    }

    if (callServer) {
      this.vr.sendRequest(rootOperation);
    }
  }

  protected initDataRequest() {
    this.request = { ControlUID: this.ncl.ControlUID };
    this.isPreparedDataRequest = false;
    this.dataRequestCleared();
  }

  protected dataRequestCleared() {}
}

abstract class UFNclControl<T extends CSUFNclControlMetadata, S extends UFUpdateControl> extends NclControl<T, S> implements UFNclControlBase {
  private vcx: VisualContext;

  protected internalGetVCX(): VisualContext {
    if (this.vcx) {
      return this.vcx;
    }

    return super.internalGetVCX();
  }

  get MetaData(): CSUFNclControlMetadata {
    return this.ncl;
  }

  get Size(): number {
    let size = this.Ncl.FrgtData.Size;
    if (size && size != NaN) {
      return Math.max(size, 1);
    }

    return 1;
  }

  protected internalComputeMinHeight(): number {
    return super.internalComputeMinHeight() * this.Size;
  }

  public willUnMount(onlyListener: boolean) {
    super.willUnMount(onlyListener);
    if (!onlyListener) this.vcx = null;
  }

  public setVCX(vcx: VisualContext, forceRefresh: boolean) {
    this.internalSetVCX(vcx, forceRefresh);
  }

  protected internalSetVCX(vcx: VisualContext, forceRefresh: boolean) {
    if (vcx.ZoomFactor != this.MetaData.FrgtData.ZoomFactor) {
      this.vcx = vcx.createVCXForZoomFactor(this.MetaData.FrgtData.ZoomFactor);
    } else {
      this.vcx = null;
    }

    super.internalSetVCX(vcx, forceRefresh);
  }
}

export interface NclBaseContainer extends UFNclControlBase {
  Children: List<UFNclControlBase>;
  Parts: Array<Array<UFNclControlBase>>;
  VerticalLines: FrgtGridLinesStyle;
  HorizontalLines: FrgtGridLinesStyle;
  Parent: NclControlBase;
}

export enum ScrollOption {
  None,
  Enabled,
  Disabled,
}

export abstract class NclContainerBase<T extends CSNclContainerMetadata, S extends UFUpdateControl> extends UFNclControl<T, S> implements NclBaseContainer {
  private _Children: List<UFNclControlBase>;
  private _Parts: Array<Array<UFNclControlBase>>;

  constructor(ncl: T, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    this.initChildren(ncl);
  }

  public get VerticalLines(): FrgtGridLinesStyle {
    return this.InternalGetVerticalLines();
  }

  public get HorizontalLines(): FrgtGridLinesStyle {
    return this.InternalGetHorizontalLines();
  }

  get MarginXFactor(): number {
    return 0;
  }

  get MarginYFactor(): number {
    return 0;
  }

  public willUnMount(onlyListener: boolean) {
    super.willUnMount(onlyListener);
    this.Children.forEach((child) => {
      child.willUnMount(onlyListener);
    });
  }

  protected internalSetVCX(vcx: VisualContext, forceRefresh: boolean) {
    super.internalSetVCX(vcx, forceRefresh);
    if (this.Children) {
      if (this.Children) {
        this.Children.forEach((value: UFNclControlBase, key: number, iter: List<UFNclControlBase>) => {
          value.setVCX(this.VCX, forceRefresh);
        });
      }
    }
  }

  public changeVisibleAnyPartsOfContainer(controlUID: string) {
    let listener: ContainerListener = this.Listener as ContainerListener;
    if (listener && listener.reCalculateHeight) {
      listener.reCalculateHeight(controlUID);
    }
  }

  protected InternalGetVerticalLines(): FrgtGridLinesStyle {
    return FrgtGridLinesStyle.glsNone;
  }

  protected InternalGetHorizontalLines(): FrgtGridLinesStyle {
    return FrgtGridLinesStyle.glsNone;
  }

  private initChildren(ncl: CSNclContainerMetadata) {
    if (ncl.Controls) {
      let children = new Array<UFNclControlBase>();
      for (var i = 0; i < ncl.Controls.length; i++) {
        children.push(NclContainerBase.createControl(ncl.Controls[i], this, this.vr, this.vrAttachDetachFce));
      }

      this._Children = List<UFNclControlBase>(children);
      this._Parts = NclContainerBase.splitToParts(this._Children);
    } else {
      this._Children = List<UFNclControlBase>();
      this._Parts = [];
    }
  }

  get Children(): List<UFNclControlBase> {
    return this.getChildren();
  }

  protected getChildren(): List<UFNclControlBase> {
    return this._Children;
  }

  get Parts(): Array<Array<UFNclControlBase>> {
    return this.getParts();
  }

  protected getParts(): Array<Array<UFNclControlBase>> {
    return this._Parts;
  }

  public static createControl(
    ncl: CSUFNclControlMetadata,
    parent: NclControlBase,
    vr: ViewRealizer,
    vrAttachDetachFce: ViewRealizerAttachDetachControlFce
  ): UFNclControlBase {
    switch (ncl.__type) {
      case ControlType.Input:
        return new NclInput(ncl as CSNclInputMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.SimpleDataGrid:
        return new NclSimpleDataGrid(ncl as CSNclDataGridMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.DataGrid:
        return new NclDataGrid(ncl as CSNclDataGridMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.RibbonAction:
      case ControlType.CommandAction:
      case ControlType.ActionTT:
      case ControlType.Action:
        return new NclCommandItem(ncl as CSNclCommandItemMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.MenuButton:
        return new NclCommandItem(ncl as CSNclCommandItemMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.Button:
        return new NclButton(ncl as CSNclButtonMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.DetailTab:
      case ControlType.Tab:
        return new NclTabControl(ncl as CSNclTabControlMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.MultiContent:
        return new NclMultiContent(ncl as CSNclTabControlMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.SplitPanel:
        return new NclSplitterPanel(ncl as CSNclSplitterPanelMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.DataLabel:
        return new NclDataLabel(ncl as CSNclDataLabelMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.PreviewPanel:
        return new NclPreviewPanel(ncl as CSNclPreviewPanelMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.CheckBox:
        return new NclCheckBox(ncl as CSNclCheckBoxMetaData, parent, vr, vrAttachDetachFce);
      case ControlType.GroupBox:
        return new NclGroupBox(ncl as CSNclGroupBoxMetaData, parent, vr, vrAttachDetachFce);
      case ControlType.Expander:
        return new NclExpander(ncl as CSNclExpanderMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.DynamicContent:
        return new NclDynamicContent(ncl as CSNclDynamicContentMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.Panel:
      case ControlType.Container:
        return new NclPanel<CSNclPanelMetadata, UFUpdateControl>(ncl as CSNclPanelMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.ListView:
        return new NclListView(ncl as CSNclListViewMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.LibraryReference:
        return new NclLibraryReference(ncl as CSNclContainerMetadata, parent, vr, vrAttachDetachFce);
      //case 'TNclControl': return new K2Control(ncl as INclControl, vr);
      case ControlType.VRTab:
        return new NclVRTabControl(ncl as CSNclVRTabControlMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.Image:
        return new NclImage(ncl as CSNclImageMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.FilePreview:
        return new NclFilePreview(ncl as CSNclImageMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.Space:
        return new NclSpace(ncl, parent, vr, vrAttachDetachFce);
      case ControlType.FloaterView:
        return new NclFloaterView(ncl as CSNclFloaterViewMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.MenuView:
        return new NclMenuView(ncl as CSNclMenuViewMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.View:
        return new NclView(ncl as CSNclViewMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.InplaceView:
        return new NclInplaceView(ncl as CSNclViewMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.ToolBar:
        return new NclTabToolBar(ncl as CSNclTabToolBarMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.ActionSeparator:
        return new NclActionSeparator(ncl, parent, vr, vrAttachDetachFce);
      case ControlType.StackPanel:
        return new NclStackPanel(ncl as CSNclContainerMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.Page:
        return new NclPage(ncl as CSNclPageMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.FloaterAccessor:
        return new NclFloaterAccessor(ncl as CSNclFloaterAccessorMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.OpenDialog:
        return new NclOpenDialog(ncl as CSNclOpenDialogMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.FormattableInput:
        return new NclFormattableInput(ncl as CSNclFormattableInputMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.LocatorPanel:
        return new NclLocatorPanel(ncl as CSNclLocatorPanelMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.SignInput:
        return new NclSignInput(ncl as CSNclSignInputMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.Ribbon:
        return new NclRibbon(ncl as CSNclRibbonMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.Breaker:
        return new NclBreaker(ncl as CSNclBreakerMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.TreeView:
        return new NclTreeView(ncl as CSNCLTreeViewMetadata, parent, vr, vrAttachDetachFce);
      default:
        //Log.warn('Unknown ncl control: ' + ncl.__type);
        return new NclUndefinedControl(ncl, parent, vr, vrAttachDetachFce);
    }
  }

  protected internalCollectData(collector: Array<ControlDataRequest>) {
    super.internalCollectData(collector);
    this.internalCollectChildrenUpdate(collector);
  }

  protected internalCollectChildrenUpdate(collector: Array<ControlDataRequest>) {
    this.Children.map((child) => {
      child.collectData(collector);
    });
  }

  protected static splitToParts(controls: List<UFNclControlBase>): Array<UFNclControlBase[]> {
    let parts = new Array<UFNclControlBase[]>();

    let band: UFNclControlBase[] = null;
    controls.map((child) => {
      if (parts.length <= child.MetaData.Bounds.PartIndex) {
        band = new Array<UFNclControlBase>();
        parts.push(band);
      } else {
        band = parts[child.MetaData.Bounds.PartIndex];
      }

      band.push(child);
    });

    return parts;
  }

  private calcMinHeight(withMargin: boolean): number {
    let size: number = 0;
    let partSize: number = 0;
    let h: number = 0;
    this.Parts.forEach((band) => {
      partSize = 0;
      h = 0;
      band.forEach((value) => {
        h = withMargin ? value.ComputedMinHeightWithMargin : value.ComputedMinHeight;
        if (h > partSize) {
          partSize = h;
        }
      });
      size += partSize;
    });
    if (
      this instanceof NclSplitterPanel &&
      ((this.MetaData.FrgtData as FrgtSplitterPanelData).Orientation === SplitterPanelOrientation.spoLeft ||
        (this.MetaData.FrgtData as FrgtSplitterPanelData).Orientation === SplitterPanelOrientation.spoRight)
    ) {
      let minHeights: number[] = [];
      this.Parts.map((part) => part.map((p) => minHeights.push(withMargin ? p.ComputedMinHeightWithMargin : p.ComputedMinHeight)));
      size = Math.max(...minHeights);
    }
    if (withMargin) size = size + Math.max(this.MarginYFactor, 1) * (2 * this.VCX.Data.MarginY);
    return size;
  }

  protected computeMinHeight(withMargin: boolean): number {
    return Math.max(this.calcMinHeight(withMargin), this.VCX.MinRowHeight * this.Size);
  }
}

export class NclPanel<T extends CSNclPanelMetadata, S extends UFUpdateControl> extends NclContainerBase<T, S> {
  protected createDefaultState(): S {
    return new UFUpdateControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() }) as S;
  }

  protected internalGetVerticalLines(): FrgtGridLinesStyle {
    return this.Ncl.FrgtData.VerticalLines;
  }

  public internalGetHorizontalLines(): FrgtGridLinesStyle {
    return this.Ncl.FrgtData.HorizontalLines;
  }

  get MarginXFactor(): number {
    return 0;
  }

  get MarginYFactor(): number {
    return 0;
  }

  public static getScrollOption(frgt: FrgtPanelBaseData): ScrollOption {
    if (frgt.Scroll) {
      return ScrollOption.Enabled;
    }
    return ScrollOption.Disabled;
  }

  protected internalComputeMinHeight(): number {
    let result = 0;

    result += Math.max(this.VCX.MinRowHeight * this.Size, super.internalComputeMinHeight());

    return result;
  }
}

export class NclListView extends UFNclControl<CSNclListViewMetadata, UpdateListView> {
  public maxRowCount: number;
  public iconPerRow: number;

  constructor(ncl: CSNclListViewMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    this.maxRowCount = this.Ncl.FrgtData.Size;
  }
  protected createDefaultState(): UpdateListView {
    return new UpdateListView({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  get MarginXFactor(): number {
    return 0;
  }

  get MarginYFactor(): number {
    return 0;
  }

  public isLite(): boolean {
    return this.ncl.FrgtData.LiteHeader ? true : false;
  }

  public setVCX(vcx: VisualContext, forceRefresh: boolean) {
    this.internalSetVCX(vcx, forceRefresh);
  }

  public executeSetPosition(position: number) {
    if (this.state.Position != position) {
      this.internalSetData<ListViewDataRequest>({ Position: position } as ListViewDataRequest, true);
    }
  }

  public executeShortcut(args?: Array<any>) {
    this.appendFunction({ Name: cJSonDefaultAcceptExecute, Args: args }, true);
  }

  public setMaxRowCount(maxRowCount: number) {
    maxRowCount = Math.max(Math.round(maxRowCount), this.Ncl.FrgtData.Size);
    if (this.maxRowCount != maxRowCount) {
      //this.internalSetData({ MaxRowCount: maxRowCount } as DataGridDataRequest, true);
      this.maxRowCount = maxRowCount;
    }
  }
}

export class NclContainer extends NclContainerBase<CSNclContainerMetadata, UFUpdateControl> {
  protected createDefaultState(): UFUpdateControl {
    return new UFUpdateControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }
}

export class NclLibraryReference extends NclContainer {}

export class NclStackPanel extends NclContainer {}

export class NclPage extends UFNclControl<CSNclPageMetadata, UpdatePageControl> {
  private content: UFNclControlBase;

  constructor(ncl: CSNclPageMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.Content) {
      this.content = NclContainer.createControl(ncl.Content, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.content);
    }
  }

  protected createDefaultState(): UpdatePageControl {
    return new UpdatePageControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  static create(
    controlUID: string,
    pageUID: string,
    parent: UFNclControlBase,
    vr: ViewRealizer,
    vrAttachDetachFce: ViewRealizerAttachDetachControlFce
  ): NclPage {
    let page = new NclPage(
      {
        ControlUID: controlUID,
        PageUID: pageUID,
        IsDynamic: 1,
        Bounds: { Align: Align.Client, BandsCount: 0, FromBandIndex: 0, InteriorBandsCount: 0, PartIndex: 0 },
        FrgtData: { Size: 1, HorizontalAlignment: HorizontalAlignment.fhaCenter, VerticalAlignment: VerticalAlignment.fvaCenter },
      } as CSNclPageMetadata,
      parent,
      vr,
      vrAttachDetachFce
    );
    let dockVr: ViewRealizer = ViewRealizerManager.getViewRealizerByDock(pageUID);
    page.content = new NclDockControl(pageUID, page, vr, vrAttachDetachFce, dockVr ? dockVr.getRealizerUID() : "");
    return page;
  }

  public willUnMount(onlyListener: boolean) {
    super.willUnMount(onlyListener);
    if (this.content instanceof NclDockControl) this.content.willUnMount(onlyListener);
  }

  get Content(): UFNclControlBase {
    return this.content;
  }

  get Depth(): number {
    return this.vr.getDepth();
  }

  get MarginXFactor(): number {
    return 0;
  }

  get MarginYFactor(): number {
    return 0;
  }

  public getTabParent(): NclBaseTabControl<CSNclTabControlMetadata, UpdateTabControl> {
    if (this.parent instanceof NclBaseTabControl) {
      return this.parent;
    }

    return null;
  }
}

export class NclDataLabel extends UFNclControl<CSNclDataLabelMetadata, UpdateDataLabel> {
  protected createDefaultState(): UpdateDataLabel {
    return new UpdateDataLabel({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  protected internalComputeMinHeight(): number {
    let size: number = this.Size;
    if (this.state.GlyphId) {
      size = Math.max(this.VCX.InputControl.getInputHeight(1, true, false), this.VCX.LabelControl.getHeight(size));
    } else {
      size = this.VCX.LabelControl.getHeight(size);
    }

    if (this.ncl.FrgtData.Orientation === Orientation.foVertical && this.ncl.FrgtData.TitleDisplayMode !== DisplayMode.fpdmNone) {
      size += this.VCX.LabelControl.getHeight(1);
    }

    // if(this.BottomLine){
    // 	size++;
    // }

    return size;
  }

  public contextMenu() {
    this.appendFunction({ Name: cJSonFunctionContextMenu }, true);
  }
}

class NclUndefinedControl extends UFNclControl<CSUFNclControlMetadata, UFUpdateControl> {
  protected createDefaultState(): UFUpdateControl {
    return new UFUpdateControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }
}

export class NclSplitterPanel extends NclContainerBase<CSNclSplitterPanelMetadata, UpdateSplitterPanel> {
  protected createDefaultState(): UpdateSplitterPanel {
    return new UpdateSplitterPanel({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  public setRatio(ratio: number) {
    if (this.state.Ratio != ratio) {
      this.internalSetData<SplitterDataRequest>({ Ratio: ratio } as SplitterDataRequest, false);
    }
  }

  public toggleCollapsed() {
    this.internalSetData<SplitterDataRequest>({ Collapsed: !this.state.Collapsed } as SplitterDataRequest, true);
  }

  public get MarginXFactor(): number {
    return 0;
  }

  public get MarginYFactor(): number {
    return 0;
  }
}

export abstract class NclBaseTabControl<T extends CSNclTabControlMetadata, S extends UpdateTabControl> extends UFNclControl<T, S> {
  private _Pages: List<NclPage>;
  private btn: NclCommandItem;
  private isMainTabControl: boolean = false;

  constructor(ncl: T, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    this.isMainTabControl = Context.getApplication().registerMainTabControl(this);
    this.initPages(ncl);
    if (ncl.Btn) {
      ncl.Btn.FrgtData.DisplayStyle = TUFActionDisplayStyle.ufadsButtonSmall;
      this.btn = new NclCommandItem(ncl.Btn, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.btn);
    }
  }

  willUnMount(onlyListener: boolean) {
    super.willUnMount(onlyListener);
    Context.getApplication().unRegisterMainTabControl(this);
  }

  get CompanyColor(): string {
    let clr = Context.getApplication().getCompanyColor();

    if (this.isMainTabControl && clr > 0) {
      return this.VCX.ColorMap.getColor(Context.getApplication().getCompanyColor());
    }
    return undefined;
  }

  get MarginXFactor(): number {
    return 1;
  }

  get MarginYFactor(): number {
    return 1;
  }

  private initPages(ncl: T) {
    if (ncl.Pages) {
      let page: NclPage;
      let children = new Array<NclPage>();
      for (var i = 0; i < ncl.Pages.length; i++) {
        page = new NclPage(ncl.Pages[i], this, this.vr, this.vrAttachDetachFce);
        children.push(page);
        this.addNestedControl(page);
      }

      this._Pages = List<NclPage>(children);
    } else {
      this._Pages = List<NclPage>();
    }
  }

  protected getPages(): List<NclPage> {
    return this._Pages;
  }

  get Pages(): List<NclPage> {
    return this.getPages();
  }

  get Btn(): NclCommandItem {
    return this.btn;
  }

  public changeCurrentPage(page: string) {
    if (page !== this.state.CurrentPage) {
      this.internalSetData<TabControlDataRequest>({ CurrentPage: page } as TabControlDataRequest, true);
    }
  }

  protected internalCollectData(collector: Array<ControlDataRequest>) {
    super.internalCollectData(collector);
    this.internalCollectChildrenUpdate(collector);
  }

  protected internalCollectChildrenUpdate(collector: Array<ControlDataRequest>) {
    if (this.state.CurrentPage) {
      let ctrl = this.vr.getControlByUID(this.state.CurrentPage);
      if (ctrl) ctrl.collectData(collector);
    }
  }
}

export class NclTabControl extends NclBaseTabControl<CSNclTabControlMetadata, UpdateTabControl> {
  protected createDefaultState(): UpdateTabControl {
    return new UpdateTabControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }
}

export class NclMultiContent extends NclBaseTabControl<CSNclTabControlMetadata, UpdateTabControl> {
  protected createDefaultState(): UpdateTabControl {
    return new UpdateTabControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  public get MarginXFactor(): number {
    return 0;
  }

  public get MarginYFactor(): number {
    return 0;
  }

  protected computeMinHeight(withMargin: boolean): number {
    let page = this.Pages.find((item) => {
      return item.MetaData.ControlUID == this.state.CurrentPage;
    });
    if (!page) {
      page = this.Pages.first();
    }

    if (page && page.Content) {
      if (withMargin) {
        return page.Content.ComputedMinHeightWithMargin;
      } else {
        return page.Content.ComputedMinHeight;
      }
    }

    return super.computeMinHeight(withMargin);
  }
}

export class NclDockControl extends UFNclControl<CSUFNclControlMetadata, UpdateDockControl> {
  private vrADFce: ViewRealizerAttachDetachControlFce;
  private dockRealizerRUID: string;

  constructor(controlUID: string, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce, dockRealizerUID: string) {
    super(
      {
        ControlUID: controlUID,
        Bounds: { Align: Align.Client, BandsCount: 0, FromBandIndex: 0, InteriorBandsCount: 0, PartIndex: 0 },
        FrgtData: { Size: 1, HorizontalAlignment: HorizontalAlignment.fhaCenter, VerticalAlignment: VerticalAlignment.fvaCenter },
      } as CSUFNclControlMetadata,
      parent,
      vr,
      null
    );
    this.dockRealizerRUID = dockRealizerUID;
    this.vrADFce = vrAttachDetachFce;
    this.vrAttachDetachFce = NclDockControl.dockAttachDettach;
    this.vrAttachDetachFce.call(this.vr, this, true);
  }

  private static dockAttachDettach(control: UFNclControlBase, attach: boolean) {
    let dock: NclDockControl = control as NclDockControl;
    if (dock.vrADFce) dock.vrADFce.call(dock.vr, dock, attach);
    let dockVr: ViewRealizer = ViewRealizerManager.getViewRealizer(dock.dockRealizerRUID);
    if (dockVr) {
      if (attach) {
        dockVr.setDockControl(dock);
      } else {
        dockVr.setDockControl(null);
      }
    }
  }

  public closeRequest() {
    if (this.dockRealizerRUID != "") {
      let vr = ViewRealizerManager.getViewRealizer(this.dockRealizerRUID);
      if (vr) {
        vr.closeRequest();
      }
    }
  }

  public isDocked(): boolean {
    return this.state.DockRealizerUID === this.dockRealizerRUID;
  }

  public dockViewRealizer(realizerUID?: string): Promise<void> {
    if (realizerUID) {
      this.dockRealizerRUID = realizerUID;
    }
    this.state = this.state.merge({ DockRealizerUID: this.dockRealizerRUID }) as UpdateDockControl;
    if (this.Listener && (this.Listener as DockListener).dock) {
      return (this.Listener as DockListener).dock(this.state);
    } else {
      return Promise.resolve();
    }
  }

  public undockViewRealizer(): Promise<void> {
    if (this.Listener && (this.Listener as DockListener).undock) {
      return (this.Listener as DockListener).undock();
    } else {
      return Promise.resolve();
    }
  }

  protected createDefaultState(): UpdateDockControl {
    return new UpdateDockControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }
}

export class NclVRTabControl extends NclBaseTabControl<CSNclVRTabControlMetadata, UpdateVRTabControl> {
  private reloadPages: boolean;
  private pages: List<NclPage>;

  protected createDefaultState(): UpdateVRTabControl {
    return new UpdateVRTabControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  constructor(ncl: CSNclVRTabControlMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    this.reloadPages = true;
  }

  get CompanyColor(): string {
    let clr = Context.getApplication().getCompanyColor();
    if (clr > 0) return this.VCX.ColorMap.getColor(clr);

    return undefined;
  }

  public closePage(page: string) {
    this.Pages.forEach((value) => {
      if (value.Content.MetaData.ControlUID === page && value.Content instanceof NclDockControl) {
        value.Content.closeRequest();
        return;
      }
    });
    //this.vr.closeRequest();
  }

  protected getPages(): List<NclPage> {
    if (this.reloadPages) {
      if (this.pages == null) {
        this.pages = super.getPages();
      }

      if (this.state.RemovedTabs != null && this.state.RemovedTabs.size > 0) {
        this.state.RemovedTabs.map((ctrlId) => {
          let pageNdx = this.pages.findIndex((value) => {
            return value.Ncl.ControlUID === ctrlId;
          });
          if (pageNdx > -1) {
            let page = this.pages.get(pageNdx);
            if (page) {
              page.willUnMount(false);
              this.removeNestedControl(page);
              this.pages = this.pages.remove(pageNdx);
            }
          }
        });
      }

      if (this.state.AddedTabs != null && this.state.AddedTabs.size > 0) {
        this.state.AddedTabs.map(async (tab: List<string>) => {
          let page: NclPage = null;
          let tabIndex = this.pages.findIndex((value: NclPage, key: number) => {
            return value.Ncl.PageUID === tab.get(1);
          });

          if (tabIndex == -1) {
            page = NclPage.create(tab.get(0), tab.get(1), this, this.vr, this.vrAttachDetachFce);
            this.addNestedControl(page);
            this.pages = this.pages.push(page);
          } else {
            throw new Error("Page already exist.");
          }
        });
      }
      this.state = this.state.withMutations((state) => {
        state.set("AddedTabs", null);
        state.set("RemovedTabs", null);
      }) as UpdateVRTabControl;
      this.reloadPages = false;
    }
    return this.pages;
  }

  protected internalCanUpdate(newState: UpdateVRTabControl): boolean {
    let result: boolean = super.internalCanUpdate(newState);

    if (result && ((newState.AddedTabs != null && newState.AddedTabs.size > 0) || (newState.RemovedTabs != null && newState.RemovedTabs.size > 0))) {
      this.reloadPages = true;
    }

    return result;
  }
}

abstract class NclActionBase<T extends CSNclCommandItemMetadata, S extends UpdateCommandItem> extends UFNclControl<T, S> {
  public executeCommand(args?: Array<any>) {
    this.appendFunction({ Name: cJSonFunctionExecute, Args: args }, true);
  }
}

export class NclBreaker extends UFNclControl<CSNclBreakerMetadata, UFUpdateControl> {
  protected createDefaultState(): UpdateCommandItem {
    return new UpdateCommandItem({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID(), Decorate: undefined });
  }

  get MarginXFactor(): number {
    return 0;
  }

  get MarginYFactor(): number {
    return 0;
  }
}

export class NclCommandItem extends NclActionBase<CSNclCommandItemMetadata, UpdateCommandItem> {
  constructor(ncl: CSNclCommandItemMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    ncl.Bounds.Align = Align.Client; ///
    super(ncl, parent, vr, vrAttachDetachFce);
  }

  protected createDefaultState(): UpdateCommandItem {
    return new UpdateCommandItem({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID(), Decorate: undefined });
  }

  protected internalComputeMinHeight(): number {
    return this.VCX.InputControl.getEditHeight(1);
  }

  public get MarginXFactor(): number {
    return 0;
  }

  public get MarginYFactor(): number {
    return 0;
  }
}

export class NclButton extends NclActionBase<CSNclButtonMetadata, UpdateCommandItem> {
  protected createDefaultState(): UpdateCommandItem {
    return new UpdateCommandItem({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID(), Decorate: undefined });
  }

  protected internalComputeMinHeight(): number {
    let result: number;
    if (this.MetaData.Bounds.Align === Align.Client) {
      result = this.Size * this.VCX.MinRowHeight;
    } else {
      result = this.VCX.InputControl.getInputHeight(this.Size, true, false);
    }

    if (this.ncl.FrgtData.IconPosition === IconPosition.ipBottom || this.ncl.FrgtData.IconPosition === IconPosition.ipTop) {
      result += this.VCX.LabelControl.getHeight(1);
    }

    return result;
  }
}

export class NclCheckBox extends UFNclControl<CSNclCheckBoxMetaData, UpdateCheckBox> {
  public check() {
    this.internalSetData<CheckBoxDataRequest>({ Checked: !this.state.Checked } as CheckBoxDataRequest, true, true, RealizerOperations.Accept);
  }

  public contextMenu() {
    this.appendFunction({ Name: cJSonFunctionContextMenu }, true);
  }

  public createDefaultState(): UpdateCheckBox {
    return new UpdateCheckBox({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  protected internalInEditMode(): boolean {
    return !this.state.ReadOnly;
  }
}

export class NclHeader extends NclControl<CSNclHeaderMetaData, UpdateControl> {
  private toolbar: NclToolBar;
  private separator: NclActionSeparator;
  private rQuickButtons: Array<NclCommandItem>;
  private locatorPanel: NclLocatorPanel;
  private lQuickButton: NclCommandItem;

  constructor(ncl: CSNclHeaderMetaData, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (!(parent instanceof NclHeaderedControl)) {
      throw Error("Bad parent");
    }
    if (ncl.ToolBar) {
      this.toolbar = new NclToolBar(ncl.ToolBar, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.toolbar);
    }

    if (ncl.Separator) {
      this.separator = new NclActionSeparator(ncl.Separator, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.separator);
    }

    this.rQuickButtons = new Array<NclCommandItem>();
    if (ncl.RQuickButtons && ncl.RQuickButtons.length > 0) {
      let action: NclCommandItem;
      ncl.RQuickButtons.map((value) => {
        action = new NclCommandItem(value, this, vr, vrAttachDetachFce);
        this.rQuickButtons.push(action);
        this.addNestedControl(action);
      });
    }

    if (ncl.LQuickButton) {
      this.lQuickButton = new NclCommandItem(ncl.LQuickButton, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.lQuickButton);
    }

    if (ncl.LocatorPanel) {
      this.locatorPanel = new NclLocatorPanel(ncl.LocatorPanel, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.locatorPanel);
    }
  }

  protected createDefaultState(): UpdateControl {
    return new UpdateControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  public get Headered(): NclHeaderedControl<CSNclHeaderedMetadata, UpdateHeadered> {
    return this.parent as NclHeaderedControl<CSNclHeaderedMetadata, UpdateHeadered>;
  }

  public get ToolBar(): NclToolBar {
    return this.toolbar;
  }

  public get Separator(): NclActionSeparator {
    return this.separator;
  }

  public get RQuickButtons(): Array<NclCommandItem> {
    return this.rQuickButtons;
  }

  public get LocatorPanel(): NclLocatorPanel {
    return this.locatorPanel;
  }

  public get LQuickButton(): NclCommandItem {
    return this.lQuickButton;
  }

  protected internalComputeMinHeight(): number {
    return this.VCX.ExpanderControl.GetHFHeight();
  }
}

export abstract class NclHeaderedControl<T extends CSNclHeaderedMetadata, S extends UpdateHeadered> extends UFNclControl<T, S> {
  private header: NclHeader;
  constructor(ncl: T, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.Header) {
      this.header = new NclHeader(ncl.Header, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.header);
    }
  }

  protected createDefaultState(): S {
    return new UpdateHeadered({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() }) as S;
  }

  public abstract isLite(): boolean;

  public isShowHeader(): boolean {
    return this.Header != undefined;
  }

  public get Header(): NclHeader {
    return this.header;
  }

  protected internalComputeMinHeight(): number {
    let result: number = super.internalComputeMinHeight();

    if (this.Header) {
      result = this.Header.ComputedMinHeight;
    }

    return result;
  }
}

export class NclTreeView extends NclHeaderedControl<CSNCLTreeViewMetadata, UpdateHeadered> {
  private content: NclInnerTreeView;

  constructor(ncl: CSNCLTreeViewMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.Content) {
      this.content = new NclInnerTreeView(ncl.Content, this, this.vr, this.vrAttachDetachFce);
      this.addNestedControl(this.content);
    }
  }

  protected createDefaultState(): UpdateHeadered {
    return new UpdateHeadered({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  get Content(): NclInnerTreeView {
    return this.content;
  }

  get MarginXFactor(): number {
    return 0;
  }

  get MarginYFactor(): number {
    return 0;
  }

  public isLite(): boolean {
    return false;
  }

  public setVCX(vcx: VisualContext, forceRefresh: boolean) {
    this.internalSetVCX(vcx, forceRefresh);
  }
}

interface ClientData {
  key?: string;
  children?: ClientData[];
}

export class K2TreeViewItem implements ClientData {
  private _parent: K2TreeViewItem;
  private nodes: Array<K2TreeViewItem>;
  private _key: string;
  private data: TreeDataItem;

  constructor(key: string, data: CSTreeDataItem, parent: K2TreeViewItem) {
    this._parent = parent;
    this._key = key;
    this.update(data);
    if (this._parent) this._parent.addAsChild(this);
  }

  public get parent(): K2TreeViewItem {
    return this._parent;
  }

  //property for UI

  public get key(): string {
    return this._key;
  }

  public get index(): number {
    if (this.data) {
      return this.data.get("Index");
    }
    return -1;
  }

  public get children(): ClientData[] {
    return this.nodes;
  }

  public get expanded(): boolean {
    return this.data && this.data.get("Expanded") === true;
  }

  public getExpandedKeys(): string[] {
    let result = new Array<string>();
    if (this.expanded) {
      result.push(this.key);
    }
    if (this.children) {
      this.nodes.forEach((item) => {
        result.push(...item.getExpandedKeys());
      });
    }
    return result;
  }

  //property for UI

  public move(newParent: K2TreeViewItem) {
    if (newParent && newParent !== this.parent) {
      if (this._parent && this.parent.children) {
        let index = this.parent.children.indexOf(this);
        if (index > -1) {
          this.parent.children.splice(index, 1);
        }
      }
      this._parent = newParent;
      this.parent.addAsChild(this);
    }
  }

  private addAsChild(child: K2TreeViewItem) {
    if (!this.nodes) {
      this.nodes = new Array<K2TreeViewItem>();
    }

    this.nodes.push(child);

    this.nodes.sort((a, b) => {
      return a.index - b.index;
    });
  }

  public delete(): boolean {
    if (this._parent) {
      let ndx = this._parent.children.indexOf(this);
      if (ndx >= 0) {
        this._parent.children.splice(ndx, 1);
        return true;
      }
    }
    return false;
  }

  update(data: CSTreeDataItem) {
    if (data) {
      if (!this.data) {
        this.data = new TreeDataItem(data);
      } else {
        let oldIndex = this.data.Index;
        this.data = this.data.with(data) as TreeDataItem;
        if (this.data.Index !== oldIndex && this.parent && this.parent.nodes) {
          this.parent.nodes.sort((a, b) => {
            return a.index - b.index;
          });
        }
      }
      if (!this.nodes || data.HasChildren) {
        if (this.data.HasChildren !== TTreeDataHasChildNodes.chnNo) this.nodes = new Array<K2TreeViewItem>();
        else this.nodes = undefined;
      }

      if (data.Values) {
        Object.assign(this, data.Values);
      }
    }
  }
}

abstract class NclInnerColumnsListBase<
  T extends CSNclInnerDataGridMetadata | CSNclInnerTreeViewMetadata,
  S extends UpdateInnerDataGrid | UpdateInnerTreeView
> extends NclControl<T, S> {
  private columnMoveRequest: InnerColumnsListDataRequest;

  public setColumnsWidth(diffs: Array<number>, columnsProportion: ColumnsProportion, treeview: boolean = false) {
    if (!columnsProportion) return;
    let newDiffs = [...diffs];
    if (treeview) {
      newDiffs.shift();
    }
    let data: Array<Array<number>> = [];
    newDiffs.map((value, i) => {
      if (columnsProportion.length > i) {
        let v = GetColumnWidthEm(columnsProportion[i].Width + value, this.VCX.GridControl);
        data.push([i, v]);
      }
    });

    this.internalSetData({ WidthColumns: data } as InnerColumnsListDataRequest, false, false, RealizerOperations.Update);
  }

  public columnMove(from: number, to: number, columnsProportion: ColumnsProportion): boolean {
    if (!columnsProportion) return false;
    if (from <= columnsProportion.length && to < columnsProportion.length) {
      if (!this.columnMoveRequest) this.columnMoveRequest = { ColumnsMove: [] } as InnerColumnsListDataRequest;

      this.columnMoveRequest.ColumnsMove.push([from, Math.max(to, -1)]);
      this.internalSetData(this.columnMoveRequest, false);
      return true;
    }
    return false;
  }

  protected dataRequestCleared() {
    this.columnMoveRequest = null;
  }
}

export class NclInnerTreeView extends NclInnerColumnsListBase<CSNclInnerTreeViewMetadata, UpdateInnerTreeView> {
  private _tree: NclTreeView;
  private root: K2TreeViewItem;
  private map: Map<string, K2TreeViewItem>;

  constructor(ncl: CSNclInnerTreeViewMetadata, parent: NclTreeView, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    this._tree = parent;
    this.root = new K2TreeViewItem("", null, null);
    this.map = new Map<string, K2TreeViewItem>();
  }

  public get Tree(): NclTreeView {
    return this._tree;
  }

  public get Root(): K2TreeViewItem {
    return this.root;
  }

  protected createDefaultState(): UpdateInnerTreeView {
    return new UpdateInnerTreeView({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  public executeSetCurrentBookmark(value: string, callToServer: boolean = true) {
    if (this.state.CurrentBookmark != value) {
      this.internalSetData<TreeViewDataRequest>({ CurrentBookmark: value } as TreeViewDataRequest, callToServer);
    }
  }

  public executeExpand(value: string) {
    this.executeSetCurrentBookmark(value, false);

    this.appendFunction({ Name: cJSonFunctionToggleNodeExecute, Args: null }, true);
  }

  public executeMove(nodeBmk: string, toNodeBmk: string, mode: TTreeDataMoveNodeMode) {
    this.appendFunction({ Name: cJSonFunctionMoveNodeExecute, Args: [nodeBmk, toNodeBmk, mode] }, true);
  }

  public applyModification(changes: Array<ModifyTreeItem>): boolean {
    if (changes && changes.length > 0) {
      let item: K2TreeViewItem;
      let parent: K2TreeViewItem;
      let data: CSTreeDataItem;
      let unprocessed: ModifyTreeItem[] = [];
      changes.map((value) => {
        if (value.ModifyType !== TDataItemModifyType.tdimNew) {
          if (value.Key && this.map.has(value.Key)) {
            item = this.map.get(value.Key);
          } else {
            Log.warn(`TreeItem with key:${value.Key} not found.`);
          }
        }

        if (value.Data) {
          data = value.Data;
          if (typeof data === "string") {
            data = parseData(data);
          }
        }

        switch (value.ModifyType) {
          case TDataItemModifyType.tdimDelete:
            if (item) {
              this.map.delete(item.key);
              item.delete();
            }
            break;
          case TDataItemModifyType.tdimNew:
            if (data) {
              if (data.ParentKey) {
                if (this.map.has(data.ParentKey)) {
                  parent = this.map.get(data.ParentKey);
                } else {
                  unprocessed.push(value);
                  return;
                }
              } else {
                parent = this.root;
              }

              item = new K2TreeViewItem(value.Key, data, parent);
              this.map.set(item.key, item);
            }
            break;
          case TDataItemModifyType.tdimModify:
            if (item && data) {
              item.update(data);
              if (data.ParentKey !== undefined) {
                //move
                let newParent = null;
                if (this.map.has(data.ParentKey)) {
                  newParent = this.map.get(data.ParentKey);
                } else {
                  newParent = this.root;
                }
                if (newParent) {
                  item.move(newParent);
                }
              }
            }
            break;
          default:
            break;
        }
      });
      if (unprocessed.length > 0) {
        return this.applyModification(unprocessed);
      }
      return true;
    }

    return false;
  }

  public updateState(data: CSUpdateControl) {
    if ((data as CSUpdateInnerTreeView).Data) {
      let upd = data as CSUpdateInnerTreeView;
      if (this.applyModification(upd.Data)) {
        upd.DataVersion = Date.now();
        delete upd.Data;
      }
    }
    super.updateState(data);
  }
}

export class NclAggregationPanel extends NclControl<CSNclAggregationPanel, UpdateAggregationPanel> {
  private container: NclDataGridBase<CSNclDataGridBaseMetadata, UpdateDataGrid>;

  constructor(
    ncl: CSNclAggregationPanel,
    parent: NclControlBase,
    container: NclDataGridBase<CSNclDataGridBaseMetadata, UpdateDataGrid>,
    vr: ViewRealizer,
    vrAttachDetachFce: ViewRealizerAttachDetachControlFce
  ) {
    super(ncl, parent, vr, vrAttachDetachFce);
    this.container = container;
  }

  public updateState(data: CSUpdateControl) {
    super.updateState(data);
  }

  contextMenu(index: number) {
    this.appendFunction({ Name: cJSonFunctionContextMenu, Args: [index] }, true);
  }

  protected createDefaultState(): UpdateAggregationPanel {
    return new UpdateAggregationPanel({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }
}

export class NclDataGridFooter extends NclControl<CSNclDataGridFooter, UpdateControl> {
  private leftToolbar: NclToolBar;
  private rightToolbar: NclToolBar;

  constructor(
    ncl: CSNclDataGridFooter,
    parent: NclDataGridBase<CSNclDataGridBaseMetadata, UpdateDataGrid>,
    vr: ViewRealizer,
    vrAttachDetachFce: ViewRealizerAttachDetachControlFce
  ) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.LeftToolbar) {
      this.leftToolbar = new NclToolBar(ncl.LeftToolbar, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.leftToolbar);
    }

    if (ncl.RightToolbar) {
      this.rightToolbar = new NclToolBar(ncl.RightToolbar, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.rightToolbar);
    }
  }

  get LeftToolbar(): NclToolBar {
    return this.leftToolbar;
  }

  get RightToolbar(): NclToolBar {
    return this.rightToolbar;
  }

  protected createDefaultState(): UpdateControl {
    return new UpdateControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  get MarginYFactor(): number {
    return 0;
  }
}

export class NclDataGridContent extends NclControl<CSNclDataGridContent, UpdateControl> {
  private dataGrid: NclInnerDataGrid;

  constructor(
    ncl: CSNclDataGridContent,
    parent: NclDataGridBase<CSNclDataGridBaseMetadata, UpdateDataGrid>,
    vr: ViewRealizer,
    vrAttachDetachFce: ViewRealizerAttachDetachControlFce
  ) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.DataGrid) {
      this.dataGrid = new NclInnerDataGrid(ncl.DataGrid, this, parent, vr, vrAttachDetachFce);
      this.addNestedControl(this.dataGrid);
    }
  }

  get MarginXFactor(): number {
    return 0;
  }

  get MarginYFactor(): number {
    return 0;
  }

  get DataGrid(): NclInnerDataGrid {
    return this.dataGrid;
  }

  protected createDefaultState(): UpdateControl {
    return new UpdateControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  protected internalComputeMinHeight(): number {
    let result = super.internalComputeMinHeight();

    if (this.DataGrid) {
      result = this.DataGrid.ComputedMinHeightWithMargin;
    }

    return result;
  }
}

export class NclInnerDataGrid extends NclInnerColumnsListBase<CSNclInnerDataGridMetadata, UpdateInnerDataGrid> {
  private container: NclDataGridBase<CSNclDataGridBaseMetadata, UpdateDataGrid>;
  private map: Map<string, CSDataItem>;
  private quickFilter: NclQuickFilter;
  private rowNdx: number = -1;
  private colNdx: number = 0;

  constructor(
    ncl: CSNclInnerDataGridMetadata,
    parent: NclControlBase,
    container: NclDataGridBase<CSNclDataGridBaseMetadata, UpdateDataGrid>,
    vr: ViewRealizer,
    vrAttachDetachFce: ViewRealizerAttachDetachControlFce
  ) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (container.Ncl.QuickFilter) {
      this.quickFilter = new NclQuickFilter(container.Ncl.QuickFilter, this, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.quickFilter);
    }
    this.container = container;
    this.map = new Map<string, CSDataItem>();
  }

  public getRowHeightMultiplier(): number {
    if (this.container) {
      return this.container.Ncl.FrgtData.RowHeightMultiplier;
    }

    return 1;
  }

  public get QuickFilter(): NclQuickFilter {
    return this.quickFilter;
  }

  public get RowNdx(): number {
    return this.rowNdx;
  }

  public get ColNdx(): number {
    return this.colNdx;
  }

  public applyModification(changes: Array<ModifyItem>): boolean {
    if (changes && changes.length > 0) {
      let item: CSDataItem;
      let data: CSDataItem;
      changes.map((value) => {
        if (value.ModifyType !== TDataItemModifyType.tdimNew) {
          if (value.Key && this.map.has(value.Key)) {
            item = this.map.get(value.Key);
          } else {
            Log.warn(`Item with key:${value.Key} not found.`);
          }
        }

        if (value.Changes) {
          data = value.Changes;
          if (typeof data === "string") {
            data = parseData(data);
          }
        }

        switch (value.ModifyType) {
          case TDataItemModifyType.tdimDelete:
            if (item) {
              this.map.delete(value.Key);
            }
            break;
          case TDataItemModifyType.tdimNew:
            if (data) {
              this.map.set(value.Key, data);
            }
            break;
          case TDataItemModifyType.tdimModify:
            if (item && data) {
              if (data.Columns) {
                if (data.Columns.length === item.Columns.length) {
                  data.Columns.forEach((column, index) => {
                    if (column.CondFormatting && item.Columns[index].CondFormatting) {
                      Object.assign(item.Columns[index].CondFormatting, column.CondFormatting);
                      delete column.CondFormatting;
                    }
                    Object.assign(item.Columns[index], column);
                  });
                } else {
                  item.Columns = data.Columns; //when add or remove column server send all new columns
                }
              }
              if (data.Row != undefined) {
                if (data.Row !== item.Row) {
                  item.Row = data.Row;
                }
              }
            }
            break;
          default:
            break;
        }
      });
      return true;
    }

    return false;
  }

  public updateState(data: CSUpdateControl) {
    if ((data as CSUpdateInnerDataGrid).Data) {
      let upd = data as CSUpdateInnerDataGrid;
      if (this.applyModification(upd.Data)) {
        upd.DataVersion = Date.now();
        delete upd.Data;
      }
    }
    super.updateState(data);
  }

  protected afterSetState(canUpdate: boolean, oldState: UpdateInnerDataGrid) {
    let newRow: number;
    let newCol: number;
    if (canUpdate) {
      if (this.state.Position !== oldState.Position) {
        newRow = this.state.Position;
      }
      if (this.state.CurrentColumn !== oldState.CurrentColumn) {
        newCol = this.state.CurrentColumn;
      }
      if (this.state.DataVersion !== oldState.DataVersion) {
        let list = Array<List<CSColumn>>();
        this.map.forEach((value, key, mp) => {
          list[value.Row] = List(value.Columns);
        });
        this.state = this.state.with({ DataSource: List(list) });
      }
    }

    super.afterSetState(canUpdate, oldState);
    this.updatePositions(newRow, newCol);
  }

  private updatePositions(rowIndex: number, colIndex: number) {
    let update: boolean = false;
    let newRowNdx = rowIndex >= 0 ? rowIndex : this.rowNdx;
    let newColNdx = colIndex >= 0 ? colIndex : this.colNdx;

    if (this.colNdx !== newColNdx) {
      update = true;
      this.internalSetData({ CurrentColumn: newColNdx } as InnerDataGridDataRequest, false, false, RealizerOperations.None);
    }

    if (this.rowNdx !== newRowNdx) {
      update = true;
      this.internalSetData({ Position: newRowNdx } as InnerDataGridDataRequest, false, false, RealizerOperations.None);
    }

    if (update) {
      let oldRow = this.rowNdx;
      this.rowNdx = newRowNdx;
      let oldCol = this.colNdx;
      this.colNdx = newColNdx;
      let listener: DataGridListener = this.Listener as DataGridListener;
      if (listener && listener.updatePosition) {
        this.Listener.updatePosition(oldRow, this.rowNdx, oldCol, this.colNdx);
      }
    }
  }

  protected createDefaultState(): UpdateInnerDataGrid {
    return new UpdateInnerDataGrid({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  get MarginXFactor(): number {
    return 0;
  }

  get MarginYFactor(): number {
    return 0;
  }

  public contextMenu(rowIndex: number, colIndex: number) {
    this.updatePositions(rowIndex, colIndex);

    this.appendFunction({ Name: cJSonFunctionContextMenu, Args: [rowIndex, this.colNdx] }, true); //row index can be -1 for heading and i nthis case must be send to server -1 instead of real selected row index
  }

  public click(rowIndex: number, columnIndex: number, clickCount: number = undefined) {
    this.updatePositions(rowIndex, columnIndex);

    if (clickCount === 1) {
      this.vr.sendRequest(RealizerOperations.Update);
    }

    if (clickCount === 2) {
      this.container.doubleClick();
    }
  }

  get Container(): NclDataGridBase<CSNclDataGridBaseMetadata, UpdateDataGrid> {
    return this.container;
  }

  protected internalComputeMinHeight(): number {
    let result = super.internalComputeMinHeight();

    let gridLineWidth = 1;
    result = (this.VCX.GridControl.GetRowHeight(this.getRowHeightMultiplier()) + gridLineWidth) * (this.container.Ncl.FrgtData.Size + 2) /*+ FixedRowCount*/;
    return result;
  }
}

abstract class NclDataGridBase<T extends CSNclDataGridBaseMetadata, S extends UpdateDataGrid> extends NclHeaderedControl<T, S> {
  private content: NclDataGridContent;
  private footer: NclDataGridFooter;
  private aggregationPanel: NclAggregationPanel;
  private maxRowCount: number;
  private timer: NodeJS.Timer;

  constructor(ncl: T, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    this.maxRowCount = -1;

    if (ncl.Content) {
      this.content = new NclDataGridContent(ncl.Content, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.content);
    }

    if (ncl.Footer) {
      this.footer = new NclDataGridFooter(ncl.Footer, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.footer);
    }

    if (ncl.AggregationPanel) {
      this.aggregationPanel = new NclAggregationPanel(ncl.AggregationPanel, this, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.aggregationPanel);
    }
  }

  protected createDefaultState(): S {
    return new UpdateDataGrid({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() }) as S;
  }

  get AggregationPanel(): NclAggregationPanel {
    return this.aggregationPanel;
  }

  get Content(): NclDataGridContent {
    return this.content;
  }

  get Footer(): NclDataGridFooter {
    return this.footer;
  }

  public setAsActiveControl(isActive: boolean) {
    super.setAsActiveControl(isActive);
    if (this.Content && this.Content.DataGrid && this.Content.DataGrid.setAsActiveControl) {
      this.Content.DataGrid.setAsActiveControl(isActive);
    }
  }

  public invokeAction(op: GridOperations) {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
    if (Context.getApplication().isBusy()) return;
    this.appendFunction({ Name: cJSonFunctionGridOperation, Args: [op] }, true);
  }

  public changeSortBy(column: number, append: boolean) {
    this.appendFunction({ Name: cJSonFunctionGridChangeSortBy, Args: [column, append] }, true);
  }

  public doubleClick() {
    this.appendFunction({ Name: cJSonDataGridDblClickExecute }, true);
  }

  public refreshMaxRowCount() {
    if (this.maxRowCount <= 0) return;
    this.setMaxRowCount(this.maxRowCount);
  }

  /**
   * @param maxRowCount maximální počet řádků
   * @param resized určuje, jestli byla metoda volána skrz callback metodu onResize
   */
  public setMaxRowCount(maxRowCount: number, resized: boolean = false) {
    maxRowCount = Math.max(Math.round(maxRowCount), this.Ncl.FrgtData.Size);
    let newValue = this.repairMaxRowCount(maxRowCount, resized);
    if (this.maxRowCount != newValue) {
      this.internalSetData({ MaxRowCount: newValue } as InnerDataGridDataRequest, true);
      this.maxRowCount = newValue;
    }
  }

  private repairMaxRowCount(count: number, resized: boolean = false): number {
    let result = count;

    if (this.content && this.content.DataGrid) {
      if (resized) {
        if (this.content.DataGrid.QuickFilter && this.content.DataGrid.QuickFilter.State && this.content.DataGrid.QuickFilter.State.Visible) {
          result = count - 1;
        }
      } else {
        if (this.content.DataGrid.QuickFilter && this.content.DataGrid.QuickFilter.State && this.content.DataGrid.QuickFilter.State.Visible) {
          result = count - 1;
        } else {
          result = count + 1;
        }
      }
    }

    return result;
  }

  protected internalComputeMinHeight(): number {
    let result = super.internalComputeMinHeight();

    if (this.Content) {
      result += this.content.ComputedMinHeight;
    }

    if (this.Footer && this.showFooter()) {
      result += this.footer.ComputedMinHeight;
    }

    return result;
  }

  abstract showFooter(): boolean;

  public reorderColumns(dragged: number, hovered: number, columns: any[]) {
    if (dragged < hovered) {
      columns.splice(hovered + 1, 0, columns[dragged]);
      columns.splice(dragged, 1);
    } else {
      columns.splice(hovered, 0, columns[dragged]);
      columns.splice(dragged + 1, 1);
    }
  }
}

export class NclDataGrid extends NclDataGridBase<CSNclDataGridMetadata, UpdateDataGrid> {
  isShowHeader(): boolean {
    return super.isShowHeader() && !this.Ncl.FrgtData.HideHeader;
  }

  showFooter(): boolean {
    return !this.Ncl.FrgtData.HideFooter;
  }

  public isLite(): boolean {
    return this.Ncl.FrgtData.LiteHeader ? true : false;
  }
}

export class NclSimpleDataGrid extends NclDataGrid {
  // extends NclDataGridBase<CSNclSimpleDataGridMetadata, UpdateBaseGrid>{

  showFooter(): boolean {
    return false;
  }

  isShowHeader(): boolean {
    return false;
  }
}

export class NclViewBase<T extends CSNclViewMetadata, S extends UpdateHeadered> extends NclHeaderedControl<T, S> {
  private content: NclPanel<CSNclPanelMetadata, UFUpdateControl>;
  private activeControlUID: string;

  constructor(ncl: T, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.Content) {
      this.content = new NclPanel<CSNclPanelMetadata, UFUpdateControl>(ncl.Content, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.content);
    }
  }

  public get Content(): NclControlBase {
    return this.content;
  }

  public isLite(): boolean {
    return false;
  }

  get MarginXFactor(): number {
    return 0;
  }

  get MarginYFactor(): number {
    return 0;
  }

  public closeRequest() {
    this.vr.closeRequest();
  }

  public isForbiddenHotKey(hotKey: string) {
    let hk = hotKey.toUpperCase();
    if (hk === "TAB" || hk === "SHIFT+TAB") {
      return true;
    }
    return false;
  }

  isShowHeader(): boolean {
    let rect: RectInDock = this.getRectInDock();
    return super.isShowHeader() && rect && rect.FrameStyle < FrameStyle.frsNone;
  }

  public getRectInDock(): RectInDock {
    return this.vr.getRectInDock();
  }

  showView(): Promise<void> {
    if (this.Listener && (this.Listener as ViewListener).show) {
      return (this.Listener as ViewListener).show();
    } else {
      return Promise.resolve();
    }
  }

  closeView(): Promise<void> {
    if (this.Listener && (this.Listener as ViewListener).close) {
      return (this.Listener as ViewListener).close();
    } else {
      return Promise.resolve();
    }
  }

  protected computeMinHeight(withMargin: boolean): number {
    let size = super.computeMinHeight(withMargin);

    if (this.content) {
      if (withMargin) {
        size += this.content.ComputedMinHeightWithMargin;
      } else {
        size += this.content.ComputedMinHeight;
      }
    }

    if (this.Header) {
      //
    }
    return size;
  }
}

export class NclView extends NclViewBase<CSNclViewMetadata, UpdateHeadered> {}

export class NclInplaceView extends NclViewBase<CSNclViewMetadata, UpdateHeadered> {
  protected computeMinHeight(withMargin: boolean): number {
    return this.VCX.GridControl.GetRowHeight(1);
  }

  public isForbiddenHotKey(hotKey: string) {
    let hk = hotKey.toUpperCase();
    if (hk === "TAB" || hk === "SHIFT+TAB") {
      return false;
    }
    return false;
  }

  get MarginXFactor(): number {
    return 0;
  }

  get MarginYFactor(): number {
    return 0;
  }
}

export class NclExpander extends NclHeaderedControl<CSNclExpanderMetadata, UpdateExpander> {
  private flowPanel: NclPanel<CSNclPanelMetadata, UFUpdateControl>;

  constructor(ncl: CSNclExpanderMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.Content) {
      this.flowPanel = new NclPanel<CSNclPanelMetadata, UFUpdateControl>(ncl.Content, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.flowPanel);
    }
  }

  public get Content(): NclPanel<CSNclPanelMetadata, UFUpdateControl> {
    return this.flowPanel;
  }

  public isLite(): boolean {
    return this.ncl.FrgtData.LiteHeader ? true : false;
  }

  protected createDefaultState(): UpdateExpander {
    return new UpdateExpander({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  protected computeMinHeight(withMargin: boolean): number {
    let result = withMargin ? this.Header.ComputedMinHeightWithMargin : this.Header.ComputedMinHeight;

    if (!this.state.Collapsed) {
      result += Math.max(
        this.VCX.MinRowHeight * this.Size,
        this.Content ? (!withMargin ? this.Content.ComputedMinHeight : this.Content.ComputedMinHeightWithMargin) : 0
      );
    }

    result += this.MarginYFactor * this.VCX.Data.MarginY * 2;
    return result;
  }

  public isCollapsed(): boolean {
    return this.state.Collapsed;
  }
}

export class NclPreviewPanel extends NclContainerBase<CSNclPreviewPanelMetadata, UFUpdateControl> {
  protected createDefaultState(): UFUpdateControl {
    return new UFUpdateControl({ ControlUID: this.MetaData.ControlUID, RealizerUID: this.getRealizerUID() });
  }
}

export enum EditCheckValueResult {
  default = 0,
  block = 1,
  newValue = 2,
}

export interface CheckResult {
  Value?: string;
  ToPosition?: number;
  Result: EditCheckValueResult;
}

export class NclInput extends UFNclControl<CSNclInputMetadata, UpdateInput> {
  private toolBar: NclToolBar;
  private inPlaceView: boolean;

  constructor(ncl: CSNclInputMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.ToolBar) {
      this.toolBar = new NclToolBar(ncl.ToolBar, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.toolBar);
    }
  }

  public isVisibleTitle() {
    if (this.state.Title && !(this.parent instanceof NclLocatorPanel)) {
      return true;
    }

    return false;
  }

  public get ToolBar(): NclToolBar {
    return this.toolBar;
  }

  public isEmpty(): boolean {
    switch (this.ncl.InputType) {
      case TNclInputType.nitNumeric:
        return this.state.Text === "0";
      case TNclInputType.nitDate:
        return this.state.Text === "0000-00-00";
      case TNclInputType.nitTime:
        return this.state.Text === "00:00:00.000";
      case TNclInputType.nitString:
        return this.state.Text === "";
    }
    return false;
  }

  public change(value: string, accept: boolean = false) {
    if (this.state.ReadOnly) return;

    if (this.state.Text === value) {
      this.initDataRequest();
      return;
    }

    if (this.state.IsUpperCase) {
      value = value.toUpperCase();
    }

    this.internalSetData({ Text: value } as InputDataRequest, accept, true, accept ? RealizerOperations.Accept : RealizerOperations.None);
  }

  protected internalInEditMode(): boolean {
    return !this.state.ReadOnly;
  }

  public nextPriorValue(next: boolean, value: string) {
    this.appendFunction({ Name: cJSonFunctionNextPriorValue, Args: [next, value] }, true);
  }

  public accept() {
    if (this.state.ReadOnly || !this.IsPreparedDataRequest) return;
    this.vr.sendRequest(RealizerOperations.Accept);
  }

  protected createDefaultState(): UpdateInput {
    return new UpdateInput({ ControlUID: this.MetaData.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  protected internalComputeMinHeight(): number {
    let size: number = this.Size;
    size = this.VCX.InputControl.getInputHeight(size, this.ncl.FrgtData.ShowFrame, this.ncl.FrgtData.ShowLabel && this.isVisibleTitle());
    return size;
  }

  public contextMenu() {
    this.appendFunction({ Name: cJSonFunctionContextMenu }, true);
  }

  public checkInput(value: string, puttingValue: string, selection: InputSelectionText, replaceAll?: boolean): CheckResult {
    let result: CheckResult = { Result: EditCheckValueResult.block };
    let reg: RegExp | null = this.getInRegularExpr();
    let separator = Context.getApplication().getLocalSeparator(this.Ncl.InputType);
    let newValue: string | null = null;
    let puttingLength: number = puttingValue.length;
    let clearReg: RegExp = null;

    switch (this.ncl.InputType) {
      case TNclInputType.nitNumeric:
        clearReg = this.getThousandSeparatorByFormat();
        if (puttingValue.length > 1) {
          let match = puttingValue.match(/\.|,/g);
          if (match && match.length > 0 && match[0] !== separator) {
            puttingValue = puttingValue.replace(match[0], separator);
          }
        }
        if ((puttingValue === "." || puttingValue === ",") && puttingValue !== separator) {
          puttingValue = separator;
        }
        if (reg && reg.test(puttingValue)) {
          if (replaceAll === true) {
            newValue = puttingValue;
          } else {
            newValue = this.createNewValue(value, puttingValue, selection, separator, clearReg, false);
          }
        }
        break;
      case TNclInputType.nitString:
        result.Result = EditCheckValueResult.default;
        return result;
      case TNclInputType.nitDate:
      case TNclInputType.nitTime:
        let putValue: string = puttingValue;
        let skipSeparator = false;
        if (puttingValue.length > 2 && puttingValue.indexOf(separator) < 0) {
          let vals = puttingValue.match(/.{1,2}/g);
          if (vals) {
            putValue = vals.join(separator);
          }
        } else {
          let segments: string[] = value.split(separator);
          if (segments.length > 0) {
            let pos = 0;
            for (let i = 0; i < segments.length; i++) {
              pos += 2;
              if (selection.start === pos) {
                skipSeparator = true;
                break;
              }
              pos++; //separator
              if (selection.start < pos) break;
            }
          }
        }

        puttingLength = putValue.length;

        if (replaceAll === true) {
          newValue = putValue;
        } else {
          newValue = this.createNewValue(value, putValue, selection, separator, clearReg, skipSeparator);
        }

        if (putValue !== separator) {
          let parts = newValue.split(separator);
          if (parts && parts.length < 3 && parts[parts.length - 1].length === 2) {
            newValue += separator;
            puttingLength += separator.length;
          }
        }
        break;
      default:
        console.error(`Invalid input type.`);
        break;
    }
    if (!reg || !newValue) return result;
    let checkValue = clearReg !== null ? newValue.replace(clearReg, "") : newValue; //when exist any separator, for check use raw value, but for user use newValue
    if (!checkValue || !reg.test(checkValue)) return result;

    result.Value = newValue;
    result.ToPosition = selection.start + puttingLength;
    result.Result = EditCheckValueResult.newValue;
    this.change(newValue, false); //numeric and date field must set new value to server request, string set in input component onchange event
    return result;
  }

  private createNewValue(
    value: string,
    puttingValue: string,
    selection: InputSelectionText,
    separator: string,
    clearReg: RegExp,
    skipSeparator: boolean = true
  ): string | null {
    let newValue = value;
    if (selection.start < 0) return null;

    if (!value) return puttingValue;

    if (selection.start == selection.end) {
      let replaceChar = newValue.substr(selection.start, 1);
      if (replaceChar === puttingValue) return newValue;

      if ((separator === replaceChar && skipSeparator) || (clearReg && replaceChar.match(clearReg))) {
        selection.start += 1; //when replaced char is separators then inc selection position
        selection.end += 1;
      }
      if (separator !== replaceChar || skipSeparator) selection.end += 1;
    }
    newValue = [newValue.slice(0, selection.start), puttingValue, newValue.slice(selection.end)].join("");

    return newValue;
  }

  private getInRegularExpr(): RegExp | null {
    let separator = Context.getApplication().getLocalSeparator(this.Ncl.InputType);
    switch (this.ncl.InputType) {
      case TNclInputType.nitTime:
        return new RegExp(
          `^((([01]?\\d|2[0-3])(${separator}?([0-5]?\\d)?)${separator}([0-5]?\\d)?)|(${separator}([0-5]?\\d)?)${separator}?([0-5]?\\d)?|([01]?\\d|2[0-3]))$`
        );
      case TNclInputType.nitDate:
        return new RegExp(
          `^(((([0-3]?\\d)(\\${separator}?(0?[1-9]|1\\d)?)\\${separator}(\\d{1,4})?))|(\\${separator}(0?[1-9]|1[0-2])?)\\${separator}?(\\d{1,4})?|([0-3]?\\d))$`
        );
      case TNclInputType.nitNumeric:
        return new RegExp(`^-?[0-9]*\\${separator}?[0-9]${this.getDecimalsRegExpByFormat()}$`);
      default:
        break;
    }

    return null;
  }

  private getDecimalsRegExpByFormat(): string {
    let decimals = this.getDecimalsCountByFormat();
    if (decimals > 0) {
      return `{0,${decimals}}`;
    }

    return "";
  }

  // public getLocaleFormat():string{
  //     switch (this.ncl.InputType) {
  //         case TNclInputType.nitDate:
  //             return this.state.Format.replace(new RegExp('/', 'g'), this.getLocalSeparator()).toUpperCase();
  //         case TNclInputType.nitTime:
  //             return this.state.Format.replace(new RegExp('n', 'g'), 'm').replace(new RegExp(':', 'g'), this.getLocalSeparator());
  //         default:
  //             break;
  //     }
  //     return '';
  // }

  public getThousandSeparatorByFormat(): RegExp {
    let n = 1234.5;
    let str = n.toLocaleString();
    if (str.length === 6) return null;
    let sep = str.substring(1, 2);
    if (sep.charCodeAt(0) === 160 || sep.charCodeAt(0) === 32) {
      return /\s/g;
    } else return new RegExp(sep, "g");
  }

  public getDecimalsCountByFormat(): number {
    if (this.state.Format) {
      let f = this.state.Format.split(".");
      if (f.length === 2) {
        return f[1].length;
      }
    }

    return 0;
  }

  public getTimeByFormat(format: string, value: string) {
    let newValue = format;
    let timeParts = value.split(/[.:]/);
    let formatParts = newValue.split(/[.:]/);

    for (let i = 0; i < formatParts.length; i++) {
      newValue = newValue.replace(formatParts[i], timeParts[i]);
    }

    return newValue;
  }

  protected get InPlaceView(): boolean {
    if (this.inPlaceView === undefined && this.vr.getRoot()) {
      this.inPlaceView = this.vr.getRoot() instanceof NclInplaceView;
    }

    return this.inPlaceView;
  }

  get MarginXFactor(): number {
    return this.InPlaceView ? 0 : 1;
  }

  get MarginYFactor(): number {
    return this.InPlaceView ? 0 : 1;
  }
}

interface ImageItem {
  prefix: string;
  isStatic: boolean;
  extension?: string;
  folder?: string;
  isSvg?: boolean;
  withsize?: boolean;
  language?: boolean;
}

interface ImageAddressResult {
  url: string;
  altUrl?: string;
}

export const InlineSVGGlyphsPattern = "^svg\\*";

const GlyphIdParam = "glyphId=";
const RequiredSizeParam = "requiredSize=";
const sizes: number[] = [16, 24, 32, 48, 64, 256];
export class NclImage extends UFNclControl<CSNclImageMetadata, UpdateImage> {
  private static repository: Map<string, ImageItem>;
  private static dynamicGlyphsPattern: string;
  private static svgGlyphsPattern: string;
  private static allGlpyhpsWithSize: string;
  private static allGlyphsPattern: string;

  public static getRepository(): Map<string, ImageItem> {
    if (!NclImage.repository) {
      let map = new Map<string, ImageItem>();
      map.set("chr", { prefix: "chr", isStatic: true, folder: "chr", extension: ".png", isSvg: false, withsize: true });
      map.set("zoo", { prefix: "zoo", isStatic: true, folder: "zoo", extension: ".png", isSvg: false, withsize: true });
      map.set("mui", { prefix: "mui", isStatic: true, folder: "mui", extension: ".png", isSvg: false, withsize: true });
      map.set("ui", { prefix: "ui", isStatic: true, folder: "ui", extension: ".png", isSvg: false, withsize: true });

      map.set("wicon", { prefix: "wicon", isStatic: true, folder: "wicon", extension: ".svg", isSvg: true, withsize: false });
      map.set("stdbmp", { prefix: "stdbmp", isStatic: true, folder: "Standard.bmp", extension: ".bmp", isSvg: false, withsize: false });
      map.set("wui", { prefix: "wui", isStatic: true, folder: "wui", extension: ".svg", isSvg: true, withsize: false });
      map.set("flags", { prefix: "flags", isStatic: true, folder: "flags", extension: ".svg", isSvg: true, withsize: false });
      map.set("wuil", { prefix: "wuil", isStatic: true, folder: "wuil", extension: ".svg", isSvg: true, withsize: false, language: true });
      //dynamic
      map.set("svgl", { prefix: "svgl", isStatic: false, isSvg: true });
      map.set("k2", { prefix: "k2", isStatic: false });
      NclImage.repository = map;
    }

    return NclImage.repository;
  }

  private static getDynamicGlyphsPattern(): string {
    if (!NclImage.dynamicGlyphsPattern) {
      let str: string = "";
      this.getRepository().forEach((value) => {
        if (!value.isStatic) {
          if (str != "") {
            str += `|`;
          }
          str += `^${value.prefix}\\*`;
        }
      });
      NclImage.dynamicGlyphsPattern = str;
    }
    return NclImage.dynamicGlyphsPattern;
  }

  private static getSVGGlyphsPattern(): string {
    if (!NclImage.svgGlyphsPattern) {
      let str: string = "";
      this.getRepository().forEach((value) => {
        if (value.isSvg) {
          if (str != "") {
            str += `|`;
          }
          str += `^${value.prefix}\\*`;
        }
      });
      NclImage.svgGlyphsPattern = str;
    }
    return NclImage.svgGlyphsPattern;
  }

  private static getAllGlpyhpsWithSize(): string {
    if (!NclImage.allGlpyhpsWithSize) {
      let str: string = "";
      this.getRepository().forEach((value) => {
        if (value.withsize) {
          if (str != "") {
            str += `|`;
          }
          str += `^${value.prefix}\\*`;
        }
      });
      NclImage.allGlpyhpsWithSize = str;
    }
    return NclImage.allGlpyhpsWithSize;
  }

  private static getAllGlyphsPattern(): string {
    if (!NclImage.allGlyphsPattern) {
      let str: string = "";
      this.getRepository().forEach((value) => {
        if (str != "") {
          str += `|`;
        }
        str += `^${value.prefix}\\*`;
      });
      NclImage.allGlyphsPattern = str;
    }
    return NclImage.allGlyphsPattern;
  }

  protected createDefaultState(): UpdateImage {
    return new UpdateImage({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  private static isDynamicImg(value: GlyphId): boolean {
    if (value) {
      let re: RegExp = new RegExp(NclImage.getDynamicGlyphsPattern());

      if (re.exec(value)) {
        return true;
      }
    }

    return false;
  }

  public static isSVG(value: GlyphId): boolean {
    if (value) {
      let re: RegExp = new RegExp(NclImage.getSVGGlyphsPattern() + "|" + InlineSVGGlyphsPattern);

      if (re.exec(value)) {
        return true;
      }
    }

    return false;
  }

  public static isInlineSVG(value: GlyphId): boolean {
    if (value) {
      let re: RegExp = new RegExp(InlineSVGGlyphsPattern);

      if (re.exec(value)) {
        return true;
      }
    }

    return false;
  }

  public static getSourceUrl(value: GlyphId, requiredSize?: number, forceDynamicMethod: boolean = false): ImageAddressResult {
    if (value.includes("\\")) {
      value = value.split("\\").join("/");
    }

    let url: string;
    let altUrl: string;

    if (forceDynamicMethod || NclImage.isDynamicImg(value)) {
      url = `${Context.getApplication().getPictureServerUrl(false)}${GlyphIdParam}${value}`;
      let rSize: number = NclImage.correctRequiredSize(requiredSize, value);
      if (rSize) {
        url += "&" + RequiredSizeParam + rSize;
      }
    } else {
      let rSize: number = NclImage.correctRequiredSize(requiredSize, value);
      let sizeFolder: string = rSize ? `/${rSize}/` : "";
      let strs = value.split("*");
      if (strs.length > 1) {
        let item = NclImage.getRepository().get(strs[0]);
        if (item) {
          let lngFolder = item.language == true ? `${Context.getApplication().getCurrentK2LanguageImgFolder()}/` : "";
          url = `${Context.getApplication().getPictureServerUrl(true)}/${lngFolder}${item.folder}/${sizeFolder}${strs[1] + item.extension}`;
          if (item.language === true) {
            altUrl = `${Context.getApplication().getPictureServerUrl(true)}/${DEFAULT_LANG_IMG}/${item.folder}/${sizeFolder}${strs[1] + item.extension}`;
          }
        } else {
          return NclImage.getSourceUrl(value, requiredSize, true);
        }
      } else {
        return NclImage.getSourceUrl(value, requiredSize, true);
      }
    }

    return { url: encodeURI(url), altUrl: altUrl };
  }

  private static correctRequiredSize(requiredSize: number, value: GlyphId): number {
    let result: number;
    if (requiredSize) {
      if (value.match(NclImage.getAllGlpyhpsWithSize())) {
        for (let index = sizes.length - 1; index >= 0; index--) {
          result = sizes[index];
          if (result < requiredSize) {
            break;
          }
        }
      }
    }

    return result;
  }

  public static IsStaticGlyphId(value: string): boolean {
    return !NclImage.isDynamicImg(value);
  }

  public get MarginXFactor(): number {
    return 0;
  }

  public get MarginYFactor(): number {
    return 0;
  }

  protected internalComputeMinHeight(): number {
    return Math.max(this.VCX.InputControl.getInputHeight(1, true, false), this.VCX.LabelControl.getHeight(this.Size));
  }
}

export class NclGroupBox extends NclContainerBase<CSNclGroupBoxMetaData, UFUpdateControl> {
  protected createDefaultState(): UFUpdateControl {
    return new UFUpdateControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  protected computeMinHeight(withMargin: boolean): number {
    let result = super.computeMinHeight(withMargin);
    result += this.VCX.ExpanderControl.GetHFHeight(); // Výška dle headerfontu
    result -= this.VCX.ExpanderControl.GetHFMargin(); // Výška odsazení headeru
    return result;
  }
}

export class NclSpace extends UFNclControl<CSUFNclControlMetadata, UFUpdateControl> {
  protected createDefaultState(): UFUpdateControl {
    return new UFUpdateControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  protected internalComputeMinHeight(): number {
    return this.VCX.LabelControl.getHeight(this.Size);
  }
}

export class NclTabToolBar extends UFNclControl<CSNclTabToolBarMetadata, UFUpdateControl> {
  private toolBars: Array<NclToolBar>;

  constructor(ncl: CSNclTabToolBarMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.ToolBars) {
      this.toolBars = Array<NclToolBar>();
      ncl.ToolBars.map((bar) => {
        let tb = new NclToolBar(bar, this, this.vr, this.vrAttachDetachFce);
        this.toolBars.push(tb);
        this.addNestedControl(tb);
      });
    }
  }

  public get ToolBars(): Array<NclToolBar> {
    return this.toolBars;
  }

  protected createDefaultState(): UFUpdateControl {
    return new UFUpdateControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  protected internalComputeMinHeight(): number {
    return this.VCX.InputControl.getInputHeight(this.Size * 0.7, false, true) * 1.2;
  }
}

export class NclToolBar extends NclControl<CSNclToolBarMetadata, UpdateControl> {
  private actions: Array<UFNclControlBase>;
  private menuBtn: NclCommandItem;
  private hasMainMenu: boolean;

  constructor(ncl: CSNclToolBarMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.Actions) {
      let parentName: string = "X";
      if ((parent instanceof NclInput || parent instanceof NclTabToolBar) && parent.Ncl.Name) {
        parentName = parent.Ncl.Name;
      }
      this.actions = new Array<UFNclControlBase>();
      ncl.Actions.map((action) => {
        action.Name = parentName + action.Name;
        let act = NclContainer.createControl(action, this, vr, this.vrAttachDetachFce);
        if (act) {
          this.addNestedControl(act);
          this.actions.push(act);
        }
      });
    }
    if (ncl.MenuBtn) {
      this.menuBtn = NclContainer.createControl(ncl.MenuBtn, this, vr, vrAttachDetachFce) as NclCommandItem;
      this.addNestedControl(this.menuBtn);
    }
  }

  removeAction(cmdSuffix: string) {
    let index = this.actions.findIndex((value) => {
      return value.MetaData.Name.endsWith(cmdSuffix);
    });
    if (index >= 0) {
      this.actions.splice(index, 1);
    }
  }

  public get HasMainMenu(): boolean {
    if (this.hasMainMenu === undefined) {
      if (this.menuBtn) {
        if (this.parent instanceof NclTabToolBar) {
          this.hasMainMenu = true;
        } else {
          this.hasMainMenu = false;
        }
      } else {
        this.hasMainMenu = false;
      }
    }

    return this.hasMainMenu;
  }

  public get Actions(): Array<UFNclControlBase> {
    return this.actions;
  }

  public get MenuBtn(): NclCommandItem {
    return this.menuBtn;
  }

  protected createDefaultState(): UpdateControl {
    return new UpdateControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  protected internalComputeMinHeight(): number {
    return this.VCX.InputControl.getEditHeight(1);
  }

  public get MarginXFactor(): number {
    return 0;
  }

  public get MarginYFactor(): number {
    return 0;
  }
}

export class NclActionSeparator extends UFNclControl<CSUFNclControlMetadata, UFUpdateControl> {
  constructor(ncl: CSUFNclControlMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    ncl.Bounds.Align = Align.Client; ///
    super(ncl, parent, vr, vrAttachDetachFce);
  }
  protected createDefaultState(): UFUpdateControl {
    return new UFUpdateControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  protected internalComputeMinHeight(): number {
    // let size = this.VCX.InputControl.getInputHeight(this.Size, true, false);
    // size += this.VCX.LabelControl.getHeight(1);
    return 1;
  }

  public get MarginYFactor(): number {
    return 0;
  }
}

export class NclFloaterAccessor extends NclContainerBase<CSNclFloaterAccessorMetadata, UFUpdateControl> {
  protected createDefaultState(): UFUpdateControl {
    return new UFUpdateControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  public show() {
    this.appendFunction({ Name: cJsonFunctionShowFloater }, true);
  }

  hide() {
    this.appendFunction({ Name: cJsonFunctionHideFloater }, true);
  }

  get MarginYFactor(): number {
    return 1;
  }

  get MarginXFactor(): number {
    return 1;
  }

  protected computeMinHeight(withMargin: boolean): number {
    let size = this.VCX.InputControl.getInputHeight(this.Size, true, false);
    if (withMargin) {
      size += this.MarginYFactor * (2 * this.VCX.Data.MarginY);
    }
    return size;
  }
}

export class NclFloaterView extends NclViewBase<CSNclFloaterViewMetadata, UpdateHeadered> {
  private okCommand: NclCommandItem;

  constructor(ncl: CSNclFloaterViewMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.Header) {
      this.okCommand = new ClientNclCommandItem("", "wui*ok", this, this.okExecute, this, true, { viewRealizer: vr, attachDettachFce: vrAttachDetachFce });
      this.Header.RQuickButtons.push(this.okCommand);
    }
  }
  protected setState(newState: UpdateHeadered, canUpdate: boolean) {
    if (newState !== this.state) {
      if (this.state.Visible !== newState.Visible) {
        if (newState.Visible) {
          ViewRealizer.getNearestListener(this.vr).showModal(this.vr.getRealizerUID(), this.MetaData.ControlUID);
        } else {
          this.Content.willUnMount(true); //odpojeni ncl od komponenty, protoze pokud se pri zavirani dela animace tak behem ni se nastavi celemu conentu visible na false a okno zmizi. cast ncl je stale aktivni a viditelna cast stejne konci.
          this.closeView().then(() => {
            super.setState(newState, canUpdate); // mohu nastavit state az po zavreni, kvuli animaci
            ViewRealizer.getNearestListener(this.vr).closeModal(this.vr.getRealizerUID(), this.MetaData.ControlUID);
          });
          return;
        }
      }
    }
    super.setState(newState, canUpdate);
  }

  public closeRequest() {
    this.hide();
  }

  private okExecute(ctrl: ClientNclCommandItem) {
    this.hide();
  }

  hide() {
    if (this.parent instanceof NclFloaterAccessor) {
      this.parent.hide();
    }
  }

  public getRectInDock(): RectInDock {
    return this.Ncl.RectInDock;
  }
}

export class NclOpenDialog extends NclViewBase<CSNclOpenDialogMetadata, UpdateHeadered> {
  private ok: NclButton;
  private cancel: NclButton;

  constructor(ncl: CSNclOpenDialogMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.OKBtn) {
      this.ok = new NclButton(ncl.OKBtn, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.ok);
    }

    if (ncl.CancelBtn) {
      this.cancel = new NclButton(ncl.CancelBtn, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.cancel);
    }
  }

  public get OK(): NclButton {
    return this.ok;
  }

  public get Cancel(): NclButton {
    return this.cancel;
  }

  public acceptFiles(files: Array<CSFile>) {
    if (files && files.length > 0) {
      this.internalSetData({ Files: files } as OpenDialogDataRequest, false);
    }
  }

  protected createDefaultState(): UpdateHeadered {
    return new UpdateHeadered({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  protected computeMinHeight(withMargin: boolean): number {
    let result = super.computeMinHeight(withMargin);
    result += this.VCX.LabelControl.getHeight(12); // Výška file area
    return result;
  }
}

export class NclFormattableInput extends UFNclControl<CSNclFormattableInputMetadata, UpdateFormattableInput> {
  protected createDefaultState(): UpdateFormattableInput {
    return new UpdateFormattableInput({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  protected internalInEditMode(): boolean {
    return !this.state.ReadOnly;
  }

  public change(value: string, accept: boolean = false) {
    if (this.state.ReadOnly) return;

    if (this.state.Text === value) {
      this.initDataRequest();
      return;
    }

    this.internalSetData({ Text: value } as InputDataRequest, accept, true, accept ? RealizerOperations.Accept : RealizerOperations.None);
  }

  // TODO if defHtml tak projít kontrolou a očistit od HTML kódu
}

export class NclDynamicContent extends NclContainerBase<CSNclDynamicContentMetadata, UpdateDynamicContent> {
  private children: List<UFNclControlBase>;
  private parts: Array<Array<UFNclControlBase>>;

  public updateState(data: CSUpdateControl) {
    let oldContent: UFNclControlBase[] = null;
    let newStructure: CSUFNclControlMetadata = null;
    if ((data as CSUpdateDynamicContent).Structure) {
      oldContent = this.Children.toArray();
      this.children = this.Children.clear();
      newStructure = (data as CSUpdateDynamicContent).Structure;
      (data as CSUpdateDynamicContent).Structure = null;
      (data as CSUpdateDynamicContent).DynamicContentId = newStructure.ControlUID;
    }
    super.updateState(data);

    if (oldContent) {
      oldContent.forEach((control) => {
        control.willUnMount(false);
      });
    }
    if (newStructure) {
      this.children = this.children.push(NclContainer.createControl(newStructure, this, this.vr, this.vrAttachDetachFce));
      this.parts = NclContainerBase.splitToParts(this.children);
      (data as CSUpdateDynamicContent).DynamicContentId = "";
      this.updateState(data);
    }
  }

  protected getChildren(): List<UFNclControlBase> {
    if (this.children == null) {
      this.children = super.getChildren();
    }
    return this.children;
  }

  protected getParts(): Array<Array<UFNclControlBase>> {
    if (this.parts == null) {
      this.parts = super.getParts();
    }

    return this.parts;
  }

  protected createDefaultState(): UpdateDynamicContent {
    return new UpdateDynamicContent({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }
}

export class NclQuickFilter extends NclControl<CSNclQuickFilterMetadata, UpdateQuickFilter> {
  private filters: Array<UFNclControlBase>;
  private container: NclInnerDataGrid;

  constructor(
    ncl: CSNclQuickFilterMetadata,
    parent: NclControlBase,
    container: NclInnerDataGrid,
    vr: ViewRealizer,
    vrAttachDetachFce: ViewRealizerAttachDetachControlFce
  ) {
    super(ncl, parent, vr, vrAttachDetachFce);
    this.container = container;
  }

  public updateState(data: CSUpdateControl) {
    if ((data as CSUpdateQuickFilter).Controls) {
      if (this.filters) {
        this.filters.forEach((control) => {
          this.removeNestedControl(control);
        });

        this.filters = null;
        this.setState(this.state.with({ Controls: null } as CSUpdateQuickFilter), true); // update for recreate all components
      }

      this.filters = [];
      let control: UFNclControlBase;
      (data as CSUpdateQuickFilter).Controls.map((ctrl) => {
        control = NclContainer.createControl(ctrl, this, this.vr, this.vrAttachDetachFce);
        this.filters.push(control);
        this.addNestedControl(control);
      });
    }
    super.updateState(data);
    if (this.container && this.container.Container && data.Visible !== undefined) {
      this.container.Container.refreshMaxRowCount();
    }
  }

  public get Filters(): Array<UFNclControlBase> {
    return this.filters;
  }

  protected createDefaultState(): UpdateQuickFilter {
    return new UpdateQuickFilter({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }
}

/* Client NCL parts */

type ClientExecuteFce = (ctrl: ClientNclCommandItem) => any;

export class ClientNclCommandItem extends NclCommandItem {
  private executeFce: ClientExecuteFce = null;
  private executor: any;

  constructor(caption: string, glyphId: GlyphId, executor: any, executeFce: ClientExecuteFce, parent: NclControlBase, enabled: boolean, context: VRContext) {
    super(
      {
        FrgtData: {
          Icon: "",
          Decorate: DataActionDecorate.dadeBase,
          FillWidth: 1,
          HorizontalAlignment: HorizontalAlignment.fhaLeft,
          IconPosition: IconPosition.ipLeft,
          ShowCaption: 0,
          ShowIcon: 1,
          DisplayStyle: TUFActionDisplayStyle.ufadsButtonTile,
          Size: 1,
          TabStop: 0,
          VerticalAlignment: VerticalAlignment.fvaCenter,
          ZoomFactor: 100,
          __type: ControlType.CommandAction,
        } as FrgtCommandItemData,
        Bounds: { Align: Align.Client, FromBandIndex: 0, PartIndex: 0, BandsCount: 0, InteriorBandsCount: 0 },
        ControlUID: parent.MetaData.ControlUID + "" + Math.random(),
      } as CSNclCommandItemMetadata,
      parent,
      context.viewRealizer,
      context.attachDettachFce
    );
    this.updateState({ Visible: true, Title: caption, Enabled: enabled, GlyphId: glyphId } as CSUpdateCommandItem);
    this.executeFce = executeFce;
    this.executor = executor;
  }

  executeCommand() {
    if (this.executeFce) {
      this.executeFce.call(this.executor, this);
    }
  }
}

export class NclMenuView extends NclViewBase<CSNclMenuViewMetadata, UpdateHeadered> {
  private menu: NclMenu;

  constructor(ncl: CSNclMenuViewMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.Menu) {
      this.menu = new NclMenu(ncl.Menu, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.menu);
    }
  }

  public get Content(): NclControlBase {
    return this.menu;
  }

  internalComputeMinHeight(): number {
    let h = super.internalComputeMinHeight();
    if (this.menu) {
      h += this.menu.internalComputeMinHeight();
    }
    return h;
  }

  isShowHeader(): boolean {
    return super.isShowHeader() && Context.DeviceInfo.StyleOfModalWindowShow === "mobile";
  }

  public isOnlyActionsInFirstLevel(maxCount: number): boolean {
    let result: boolean = true;
    if (this.menu.Actions.count() > maxCount) return false;
    this.menu.Actions.map((item) => {
      if (item instanceof NclMenuContainer) {
        result = false;
        return;
      }
    });

    return result;
  }
}

export abstract class NclMenuItemBase<T extends CSNclMenuItemBaseMetadata, S extends UpdateControl> extends NclControl<T, S> {
  static createByNcl(
    ncl: CSNclMenuItemBaseMetadata,
    parent: NclMenuContainer<CSNclMenuContainerMetadata, UpdateMenuContainer>,
    vr: ViewRealizer,
    vrAttachDetachFce: ViewRealizerAttachDetachControlFce
  ): NclMenuItemBase<CSNclMenuItemBaseMetadata, UpdateControl> {
    switch (ncl.__type) {
      case ControlType.Menu:
        return new NclMenu(ncl as CSNclMenuMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.MenuItem:
        return new NclMenuItem(ncl as CSNclMenuItemMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.MenuDivider:
        return new NclMenuDivider(ncl as CSNclMenuMetadata, parent, vr, vrAttachDetachFce);
      case ControlType.MenuGroup:
        return new NclMenuGroup(ncl as CSNclMenuGroupMetadata, parent, vr, vrAttachDetachFce);
    }
    throw new Error("Invalid type of menu control: " + ncl.__type);
  }

  internalComputeMinHeight(): number {
    return this.VCX.LabelControl.getHeight(2);
  }

  public abstract execute(): void;

  get MarginXFactor(): number {
    return 1 / 3;
  }

  get MarginYFactor(): number {
    return 1 / 3;
  }
}

export abstract class NclMenuContainer<T extends CSNclMenuContainerMetadata, S extends UpdateMenuContainer> extends NclMenuItemBase<T, S> {
  private actions: List<NclMenuItemBase<CSNclMenuItemBaseMetadata, UpdateControl>>;

  constructor(ncl: T, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.Actions) {
      let list = new Array<NclMenuItemBase<CSNclMenuItemBaseMetadata, UpdateControl>>();
      let act: NclMenuItemBase<CSNclMenuItemBaseMetadata, UpdateControl>;
      ncl.Actions.forEach((item) => {
        act = NclMenuItemBase.createByNcl(item, this, vr, vrAttachDetachFce);
        list.push(act);
        this.addNestedControl(act);
      });

      this.actions = List<NclMenuItemBase<CSNclMenuItemBaseMetadata, UpdateControl>>(list);
    }
  }

  public get Actions(): List<NclMenuItemBase<CSNclMenuItemBaseMetadata, UpdateControl>> {
    return this.actions;
  }

  public execute(forceClose?: boolean, forceOpen?: boolean, closeOthers?: boolean) {
    if (this.Parent instanceof NclMenuContainer) {
      if (closeOthers) {
        this.Parent.Actions.forEach((element) => {
          if (element instanceof NclMenuContainer && element !== this) {
            if ((element.State as UpdateMenuContainer).isOpen) {
              element.updateState({ isOpen: false } as UpdateMenuContainer);
              this.closeAllSubMenuContainer(element);
            }
          } else if (element instanceof NclMenuContainer && element === this && (element.State as UpdateMenuContainer).isOpen) {
            this.closeAllSubMenuContainer(element);
          }
        });
      }
      if (forceClose) {
        this.updateState({ isOpen: false } as UpdateMenuContainer);
      } else if (forceOpen) {
        this.updateState({ isOpen: true } as UpdateMenuContainer);
      } else {
        this.updateState({ isOpen: !(this.State as UpdateMenuContainer).isOpen } as UpdateMenuContainer);
      }
    }
  }

  private closeAllSubMenuContainer(element: NclMenuItemBase<CSNclMenuItemBaseMetadata, UpdateControl>) {
    if (element instanceof NclMenuContainer) {
      element.actions.forEach((subElement) => {
        if ((subElement.State as UpdateMenuContainer).isOpen) {
          subElement.updateState({ isOpen: false } as UpdateMenuContainer);
          this.closeAllSubMenuContainer(subElement);
        }
      });
    }
  }

  public getMenuWidth(): number {
    const widestMenuItem = this.getMaxCharCaption();
    const caption = this.VCX.GridControl.Font.computeTextWidth(widestMenuItem?.caption);
    const iconSpace: number = this.VCX.GridControl.Font._MWidth * 2;
    const hotkey = this.VCX.GridControl.Font.computeTextWidth(widestMenuItem?.hotkey) + 10; // zohledneni marginu

    return caption + iconSpace + (widestMenuItem?.hasHotkey ? hotkey : 0) + (widestMenuItem?.expand ? 20 : 0) + 20;
  }

  public getMenuHeight(): number {
    let result: number = 0;
    this.Actions.forEach((action) => {
      if (action instanceof NclMenuDivider) {
        result += 2 * action.VCX.sizeMap(3);
      } else {
        result += action.ComputedMinHeightWithMargin;
      }
    });

    return result;
  }

  private getMaxCharCaption() {
    if (this.Ncl.Actions.length < 1) return null;

    let menuItem: { caption: string; hasHotkey: boolean; charCount: number; hotkey: string; expand: boolean }[] = [];

    this.Ncl.Actions.map((item) => {
      const itemWithHotkey = (item as CSNclMenuItemMetadata).HotKey;

      if (itemWithHotkey && itemWithHotkey !== "") {
        menuItem.push({
          caption: item.Caption,
          hasHotkey: true,
          charCount: item.Caption.length + itemWithHotkey.length,
          hotkey: itemWithHotkey,
          expand: false,
        });
      } else {
        if ((item as CSNclMenuContainerMetadata).Actions && (item as CSNclMenuContainerMetadata).Actions.length > 0) {
          menuItem.push({ caption: item.Caption, hasHotkey: false, charCount: item.Caption.length, hotkey: "", expand: true });
        } else {
          menuItem.push({ caption: item.Caption, hasHotkey: false, charCount: item.Caption.length, hotkey: "", expand: false });
        }
      }
    });

    menuItem.sort((a, b) => {
      return b.charCount - a.charCount;
    });

    return menuItem[0];
  }

  get MarginXFactor(): number {
    return 0;
  }

  get MarginYFactor(): number {
    return 0;
  }
}

export class NclMenu extends NclMenuContainer<CSNclMenuMetadata, UpdateMenuContainer> {
  protected createDefaultState(): UpdateMenuContainer {
    return new UpdateMenuContainer({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID(), isOpen: true } as CSUpdateMenuContainer);
  }

  internalComputeMinHeight(): number {
    let h: number = 0;
    h = this.getMenuHeight();
    return h;
  }
}

export class NclMenuGroup extends NclMenuContainer<CSNclMenuGroupMetadata, UpdateMenuContainer> {
  protected createDefaultState(): UpdateMenuContainer {
    return new UpdateMenuContainer({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }
}

export class NclMenuItem extends NclMenuItemBase<CSNclMenuItemMetadata, UpdateControl> {
  protected createDefaultState(): UpdateControl {
    return new UpdateControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  public execute() {
    this.appendFunction({ Name: cJSonFunctionExecute }, true);
  }
}

export class NclMenuDivider extends NclMenuItemBase<CSNclMenuDividerMetadata, UpdateControl> {
  protected createDefaultState(): UpdateControl {
    return new UpdateControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  internalComputeMinHeight(): number {
    return this.VCX.InputControl.getInputHeight(1, true, false) / 6;
  }

  public execute() {}
}

export class NclLocatorPanel extends NclContainerBase<CSNclLocatorPanelMetadata, UFUpdateControl> {
  private switchBtn: NclCommandItem;

  constructor(ncl: CSNclLocatorPanelMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.SwitchBtn) {
      this.switchBtn = new NclCommandItem(ncl.SwitchBtn, this, vr, vrAttachDetachFce);
      this.addNestedControl(this.switchBtn);
    }
  }

  public get SwitchBtn(): NclCommandItem {
    return this.switchBtn;
  }

  public getLastLocatrInput(): NclInput {
    if (this.Children && this.Children.count() > 0) {
      let child: UFNclControlBase;
      for (let index = 0; index < this.Children.count(); index++) {
        child = this.Children.get(index);
        if (child.State.Visible && child.State.Enabled !== false && child instanceof NclInput) {
          return child;
        }
      }
    }
    return null;
  }

  protected createDefaultState(): UFUpdateControl {
    return new UFUpdateControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }
}

export class NclFilePreview extends NclImage {}

export class NclSignInput extends UFNclControl<CSNclSignInputMetadata, UpdateSignInput> {
  protected createDefaultState(): UpdateSignInput {
    return new UpdateSignInput({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  get ComputedMinHeight(): number {
    return this.VCX.InputControl.getInputHeight(this.ncl.FrgtData.Size, true, true);
  }

  public change(value: SignData, accept: boolean = false) {
    if (this.state.SignData === value) {
      this.initDataRequest();
      return;
    }

    this.internalSetData({ SignData: value } as SignInputDataRequest, accept, true, accept ? RealizerOperations.Accept : RealizerOperations.None);
  }
}

export class SectionItems {
  private size: number;
  private rest: number;
  private items: Array<UFNclControlBase>;

  constructor(size: number) {
    this.size = size;
    this.rest = size;
    this.items = new Array<UFNclControlBase>();
  }

  public get Rest(): number {
    return this.rest;
  }

  private addOrRemoveItem(item: NclCommandItem, add: boolean) {
    switch (item.Ncl.FrgtData.DisplayStyle) {
      case TUFActionDisplayStyle.ufadsButtonTile:
        if (add) {
          this.rest -= this.size;
        } else {
          this.rest += this.size;
        }
        break;
      case TUFActionDisplayStyle.ufadsButtonSmall:
        if (add) {
          this.rest -= 1;
        } else {
          this.rest += 1;
        }
        break;
      case TUFActionDisplayStyle.ufadsButtonLine:
        if (add) {
          this.rest -= 1;
        } else {
          this.rest += 1;
        }
        break;
    }
  }

  public pushItem(item: NclCommandItem) {
    this.items.push(item);
    if (item instanceof NclCommandItem) {
      this.addOrRemoveItem(item, true);
    }
  }

  public popItem(): NclCommandItem {
    let item: NclCommandItem = this.items.pop() as NclCommandItem;
    if (item instanceof NclCommandItem) {
      this.addOrRemoveItem(item, false);
    }
    return item;
  }

  public get Items(): Array<UFNclControlBase> {
    return this.items;
  }
}

export class NclInnerSection extends NclControl<CSNclInnerSectionMetaData, UpdateControl> {
  private contentItems: Array<UFNclControlBase>;
  private ribbon: NclRibbon;

  constructor(
    ncl: CSNclInnerSectionMetaData,
    parent: NclControlBase,
    vr: ViewRealizer,
    vrAttachDetachFce: ViewRealizerAttachDetachControlFce,
    ribbon: NclRibbon
  ) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.ContentItems) {
      this.contentItems = new Array<UFNclControlBase>();
      let item: UFNclControlBase;
      let actGroup: SectionItems = new SectionItems(ribbon.Ncl.FrgtData.Size);
      this.ribbon = ribbon;
      ncl.ContentItems.map((value, index) => {
        item = this.createControl(value, this, this.vr, vrAttachDetachFce);
        if (item) {
          this.addNestedControl(item);
          this.contentItems.push(item);
        }
      });
    }
  }

  public get Ribbon(): NclRibbon {
    return this.ribbon;
  }

  public get ContentItems(): Array<UFNclControlBase> {
    return this.contentItems;
  }

  protected createDefaultState(): UpdateControl {
    return new UpdateControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  private createControl(ncl: CSUFNclControlMetadata, parent: this, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce): UFNclControlBase {
    return NclContainer.createControl(ncl, parent, vr, vrAttachDetachFce);
  }
}

export class NclInnerToolBar extends NclControl<CSNclInnerToolBarMetadata, UpdateInnerToolbar> {
  private sections: Array<NclInnerSection>;
  private ribbon: NclRibbon;
  private accessor: NclAccessor;

  constructor(
    ncl: CSNclInnerToolBarMetadata,
    parent: NclRibbon,
    vr: ViewRealizer,
    vrAttachDetachFce: ViewRealizerAttachDetachControlFce,
    accessor: NclAccessor
  ) {
    super(ncl, parent, vr, vrAttachDetachFce);
    this.ribbon = parent;
    this.accessor = accessor;
    if (ncl.Sections) {
      this.sections = new Array<NclInnerSection>();
      let section: NclInnerSection;
      ncl.Sections.map((value) => {
        section = new NclInnerSection(value, this, this.vr, vrAttachDetachFce, parent);
        this.addNestedControl(section);
        this.sections.push(section);
      });
    }
  }

  public get Sections(): Array<NclInnerSection> {
    return this.sections;
  }

  public get Ribbon(): NclRibbon {
    return this.ribbon;
  }

  protected createDefaultState(): UpdateInnerToolbar {
    return new UpdateInnerToolbar({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  protected internalComputeMinHeight(): number {
    return -1; //(2 * (this.VCX.LabelControl.getHeight(this.ribbon.Size)));
  }

  protected afterSetState(canUpdate: boolean, oldState: UpdateInnerToolbar) {
    super.afterSetState(canUpdate, oldState);
    if (canUpdate && oldState.VisibleAccessor !== this.state.VisibleAccessor) {
      if (this.accessor) {
        this.accessor.updateState({ Visible: this.state.VisibleAccessor } as CSUpdateControl);
      }
    }
  }
}

export class NclAccessor extends NclControl<CSNclInnerToolBarMetadata, UpdateControl> {
  private ribbon: NclRibbon;

  constructor(ncl: CSNclInnerToolBarMetadata, parent: NclRibbon, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    let old = ncl.ControlUID;
    ncl.ControlUID += "_accesor_";
    super(ncl, parent, vr, vrAttachDetachFce);
    ncl.ControlUID = old;
    this.ribbon = parent;
  }

  protected createDefaultState(): UpdateControl {
    return new UpdateControl({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  get MarginXFactor(): number {
    return 4;
  }

  get MarginYFactor(): number {
    return 0;
  }
}

export interface RibbonItem {
  Accessor: NclAccessor;
  ToolBar: NclInnerToolBar;
}

export class NclRibbon extends UFNclControl<CSNclRibbonMetadata, UpdateRibbon> {
  private toolbars: Array<RibbonItem>;
  private othersBtn: NclCommandItem;
  private tabHiddeBtn: NclCommandItem;

  constructor(ncl: CSNclRibbonMetadata, parent: NclControlBase, vr: ViewRealizer, vrAttachDetachFce: ViewRealizerAttachDetachControlFce) {
    super(ncl, parent, vr, vrAttachDetachFce);
    if (ncl.ContextToolBarsCount > 0) {
      this.vr.registerContextDependency(this.ncl.ControlUID);
    }
    if (ncl.OthersBtn) {
      this.othersBtn = new NclCommandItem(ncl.OthersBtn, this, this.vr, vrAttachDetachFce);
      this.addNestedControl(this.othersBtn);
    }

    if (ncl.TabHideBtn) {
      this.tabHiddeBtn = new NclCommandItem(ncl.TabHideBtn, this, this.vr, vrAttachDetachFce);
      this.tabHiddeBtn.Ncl.FrgtData.DisplayStyle = TUFActionDisplayStyle.ufadsButtonSmall;
      this.addNestedControl(this.tabHiddeBtn);
    }
    this.toolbars = [];
  }

  public updateState(data: CSUpdateControl) {
    if ((data as CSUpdateRibbon).ToolBars) {
      if (this.toolbars && this.toolbars.length > 0) {
        this.toolbars.forEach((control) => {
          this.removeNestedControl(control.Accessor);
          this.removeNestedControl(control.ToolBar);
        });

        this.toolbars = [];
        this.setState(this.state.with({ ToolBars: null } as CSUpdateRibbon), true); // update for recreate all components
      }

      let toolBar: NclInnerToolBar;
      let accessor: NclAccessor;
      (data as CSUpdateRibbon).ToolBars.map((value) => {
        accessor = new NclAccessor(value, this, this.vr, this.vrAttachDetachFce);
        this.addNestedControl(accessor);
        toolBar = new NclInnerToolBar(value, this, this.vr, this.vrAttachDetachFce, accessor);
        this.addNestedControl(toolBar);
        this.toolbars.push({ Accessor: accessor, ToolBar: toolBar });
      });

      if (this.toolbars.length > 1) {
        this.othersBtn.Ncl.FrgtData.DisplayStyle = TUFActionDisplayStyle.ufadsButtonSmall;
      } else {
        this.othersBtn.Ncl.FrgtData.DisplayStyle = TUFActionDisplayStyle.ufadsButtonTile;
      }
    }
    super.updateState(data);
  }

  public contextMenu() {
    this.appendFunction({ Name: cJSonFunctionContextMenu }, true);
  }

  public get ToolBars(): Array<RibbonItem> {
    return this.toolbars;
  }

  public get OthersBtn(): NclCommandItem {
    return this.othersBtn;
  }

  public get TabHideBtn(): NclCommandItem {
    return this.tabHiddeBtn;
  }

  public changeCurrentToolBar(index: number) {
    if (this.state.CurrentToolBarIndex != index) {
      this.internalSetData<RibbonDataRequest>({ CurrentToolBarIndex: index } as RibbonDataRequest, true);
    }
  }

  protected createDefaultState(): UpdateRibbon {
    return new UpdateRibbon({ ControlUID: this.ncl.ControlUID, RealizerUID: this.getRealizerUID() });
  }

  protected internalComputeMinHeight(): number {
    return -1;
  }

  public willUnMount(onlyListener: boolean) {
    if (!onlyListener && this.ncl.ContextToolBarsCount > 0) {
      this.vr.unRegisterContextDependency(this.ncl.ControlUID);
    }
    super.willUnMount(onlyListener);
  }
}

export enum ControlType {
  Expander = "TNclExpander",
  Image = "TNclImage",
  VRTab = "TNclVRTabControl",
  LibraryReference = "TNclLibraryReference",
  Container = "TNclContainer",
  Panel = "TNclPanel",
  ListView = "TNclListView",
  PanelBase = "TNclPanelBase",
  PreviewPanel = "TNclPreviewPanel",
  DataLabel = "TNclDataLabel",
  SplitPanel = "TNclSplitPanel",
  Tab = "TNclTabControl",
  DetailTab = "TNclDetailTabControl",
  Button = "TNclButton",
  DataGrid = "TNclDataGrid",
  SimpleDataGrid = "TNclSimpleGrid",
  Input = "TNclInput",
  FormattableInputData = "TNclFormattableInputData",
  CheckBox = "TNclCheckBox",
  GroupBox = "TNclGroupBox",
  Space = "TNclSpace",
  View = "TNclView",
  ToolBar = "TNclTabToolBar",
  Action = "TNclNoFrgtButton",
  ActionTT = "TNclToolBarNoFrgtButton",
  MenuButton = "TNclToolBarMenuButton",
  CommandAction = "TNclCommandAction",
  ActionSeparator = "TNclNoFrgtControlSeparator",
  StackPanel = "TNclStackPanel",
  DynamicContent = "TNclDynamicContent",
  Page = "TNclPageInfo",
  FloaterAccessor = "TNclFloaterAccessor",
  FloaterView = "TNclFloaterView",
  MultiContent = "TNclMultiContent",
  OpenDialog = "TNclOpenDialog",
  FormattableInput = "TNclInputFormattable",
  MenuView = "TNclMenuView",
  Menu = "TNclMenu",
  MenuItem = "TNclMenuItem",
  MenuDivider = "TNclMenuDivider",
  MenuGroup = "TNclGroup",
  LocatorPanel = "TNclLocatorPanel",
  FilePreview = "TNclFilePreview",
  SignInput = "TNclSignInput",
  ListViewGroup = "TNclListViewGroup",
  ListViewItem = "TNclListViewItem",
  Ribbon = "TNclRibbon",
  RibbonAction = "TNclRibbonButton",
  Breaker = "TNclNoFrgtControlBreaker",
  TreeView = "TNclTreeView",
  QuickFilter = "TNclQuickFilter",
  InplaceView = "TNclInPlaceView",
}
