import * as React from "react";

import {
  UpdateControl,
  UpdateHeadered,
  UpdateInnerTreeView,
  ModifyTreeItem,
  ColumnsProportion,
  ColumnProportionEm,
  reCalculateColumnWidths,
  TTreeDataMoveNodeMode,
} from "../common/communication.base";
import { withContext, K2ComponentState, WithContextPlacementProps, StyleHelper, AcquireControl } from "./k2hoc";
import { NclTreeView, NclInnerTreeView, NclImage, K2TreeViewItem } from "../common/components.ncl";
import { ElementHtmlAttributes, getAttributes } from "../common/common";
import Table, { ColumnType } from "antd/es/table";
import { K2Img } from "./K2Image";
import { Resizable, ResizeCallbackData } from "react-resizable";
import { VisualContext } from "../common/visualContext";
import ReactResizeDetector from "react-resize-detector";
import { K2Header } from "./K2Expander";
import { K2RuleWithVCXProps } from "./k2StyleRenderer";
import ResizeObserver from "resize-observer-polyfill";
import { HeaderColumnProps, K2HeaderColumn } from "./K2DataGrid";

interface InnerTreeViewState extends K2ComponentState<UpdateInnerTreeView> {
  columns: ColumnType<K2TreeViewItem>[];
  expandedKeys: string[];
  columnIndex: number;
  arrow: string;
  drag: boolean;
  width: number;
  height: number;
  selectedRowKey: string;
  clientWidthDiffs?: number[];
  dragging: boolean;
  xDragPos: number;
}

class _InnerTreeView extends React.PureComponent<WithContextPlacementProps, InnerTreeViewState> {
  static displayName = `K2InnerTreeView`;
  private control: NclInnerTreeView;
  private rect: DOMRect;
  private nodes: DOMRect[] = [];
  private expandedNodes = new Map<EventTarget, number>();
  private disabledColReorder: boolean = true;
  private draggedNode: K2TreeViewItem;
  private innerTreeViewDiv = React.createRef<HTMLDivElement>();
  private ro: ResizeObserver;
  private columns: DOMRect[];
  private draggedColumnIndex: number;
  private hoveredColumnIndex: number;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclInnerTreeView;
    }) as NclInnerTreeView;
    let expandedKeys: string[] = [];
    if (this.control.Root) {
      expandedKeys = this.control.Root.getExpandedKeys();
    }
    this.state = {
      data: this.control.init(this) as UpdateInnerTreeView,
      vcxVersion: -1,
      columns: undefined,
      expandedKeys: expandedKeys,
      columnIndex: undefined,
      arrow: undefined,
      drag: false,
      width: undefined,
      selectedRowKey: undefined,
      height: 0,
      dragging: false,
      xDragPos: undefined,
    };
    this.props.renderer.renderStatic(treeViewStyles(this.control.VCX));
  }

  updateState(state: UpdateInnerTreeView) {
    this.setState((prevState: InnerTreeViewState) => {
      let diffs: number[];
      diffs = this.state.data.ColumnsProportion.map((col) => 0);
      if (diffs.length == 0) {
        let newArray = new Array(30);
        diffs = newArray.fill(0, 0, newArray.length);
      }
      if (state instanceof UpdateInnerTreeView) {
        if (state.AutoAdjustWidth != prevState.data.AutoAdjustWidth || !state.IsEqualsColumnsProportion(prevState.data)) {
          state = reCalculateColumnWidths<UpdateInnerTreeView>(state, prevState.width, state.AutoAdjustWidth, this.control.VCX);
        }
      }
      return { data: state as UpdateInnerTreeView, clientWidthDiffs: diffs };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.ro.unobserve(this.innerTreeViewDiv.current);
    this.control.willUnMount(true);
    this.control = null;
  }

  componentDidMount() {
    this.ro = new ResizeObserver((entries, observer) => {
      this.setState({ height: entries[0].contentRect.height - this.control.VCX.sizeMap(20), width: entries[0].contentRect.width });
    });

    this.ro.observe(this.innerTreeViewDiv.current);
  }

  componentDidUpdate(prevProps: WithContextPlacementProps, prevState: InnerTreeViewState) {
    let expandedKeys: string[] = undefined;
    if (prevState.data.DataVersion !== this.state.data.DataVersion) {
      expandedKeys = this.control.Root.getExpandedKeys();
    }

    let newState: InnerTreeViewState = null;
    if (prevState.data.ColumnsProportion !== this.state.data.ColumnsProportion) {
      newState = { columns: this.convertColumns(this.state.data.ColumnsProportion, false, true) } as InnerTreeViewState;
    }

    if (expandedKeys) {
      newState = Object.assign({}, newState, { expandedKeys: expandedKeys }) as InnerTreeViewState;
    }

    if (prevState.data.CurrentBookmark !== this.state.data.CurrentBookmark) {
      newState = Object.assign({}, newState, { selectedRowKey: this.state.data.CurrentBookmark });
    }

    this.state.clientWidthDiffs?.map((width, index) => {
      let prev: number[];

      if (prevState.clientWidthDiffs === undefined) {
        prev = [];
      } else {
        prev = prevState.clientWidthDiffs;
      }
      if (width !== prev[index] && expandedKeys === undefined) {
        newState = { ...newState, columns: this.convertColumns(this.state.data.ColumnsProportion, true) };
      }
    });

    if (newState) {
      this.setState(newState);
    }
  }

  handleResize = (index: number) => (e: React.SyntheticEvent, data: ResizeCallbackData) => {
    this.setState(({ columns }) => {
      const newColumns = [...columns];
      newColumns[index] = {
        ...newColumns[index],
        width: data.size.width,
      };

      return { columns: newColumns };
    });
  };

  deleteColumn = (colIndex: number) => {
    let newColumns = [...this.state.columns];
    newColumns = newColumns.filter((column, index) => index !== colIndex);
    this.setState({ columns: newColumns });
  };

  setNodes = (nodes: DOMRect[], colReorder: boolean) => {
    this.nodes = [...nodes];
    this.disabledColReorder = colReorder;
  };

  setColumns = (options: { sizes: DOMRect[]; draggedColumnIndex: number }) => {
    return;
    this.columns = options.sizes;
    this.draggedColumnIndex = options.draggedColumnIndex;
    this.setState({ dragging: true });
  };

  private convertColumns(ColumnsProportion: ColumnsProportion, resized: boolean = false, expanded: boolean = false): ColumnType<K2TreeViewItem>[] {
    if (ColumnsProportion) {
      let columns: Array<ColumnType<K2TreeViewItem>> = new Array<ColumnType<K2TreeViewItem>>();
      if (ColumnsProportion.length > 0) {
        columns.push(
          this.createColumn({ Adaptable: false, Alignment: 0, Caption: "", DisplayStyleRT: 0, MultiplierKind: 1, Size: 1, Width: 200 }, 0, 0, resized)
        );
        ColumnsProportion.map((value, index) => {
          columns.push(this.createColumn(value, index + 1, ColumnsProportion.length, resized));
        });
        columns.push({ width: 0 }); // posledni sloupec by mel mit ve fixed layout modu sirku 0
      } else {
        columns.push(this.createColumn(null, 0, 0, resized));
      }

      return columns;
    }

    return null;
  }

  private createColumn(value: ColumnProportionEm, index: number, columns: number, resized: boolean): ColumnType<K2TreeViewItem> {
    let diff = 0;

    if (resized && this.state.clientWidthDiffs) {
      diff = this.state.clientWidthDiffs[index];
      if (diff == undefined) {
        //diff = 0;
      }
    }

    return {
      // title: (value) ? value.Caption : '',
      dataIndex: index,
      render: (text: string, record: K2TreeViewItem, index: number) => {
        return this.renderNode(text, record);
      },
      width: value?.Width ? value.Width + diff : 100,
      onHeaderCell: (column: ColumnType<K2TreeViewItem>): /*ResizableTitleProps*/ HeaderColumnProps => {
        return {
          columnProportion: value,
          onContextMenu: () => {},
          onColumnClick: () => {},
          fixed: false,
          vcx: this.control.VCX,
          onColumnResize: this.handleColumnResize(index),
          // setColumns: this.setColumns,
          // width: column.width,
          // onResize: this.handleResize(index),
          // vcx: this.control.VCX,
          // columnReorder: { columnIndex: this.state.columnIndex, arrow: this.state.arrow, currentIndex: index },
          // setNodes: this.setNodes,
          // deleteColumn: this.deleteColumn,
          // columns: columns,
        };
      },
    };
  }

  private handleColumnResize = (colIndex: number) => (diffX: number, isCompleted: boolean) => {
    let diffs: Array<number>;
    if (isCompleted) {
      let columns = [...this.state.data.ColumnsProportion];
      columns.splice(0, 0, { Adaptable: false, Alignment: 0, Caption: "", DisplayStyleRT: 0, MultiplierKind: 1, Size: 1, Width: 200 });
      if (columns && colIndex < columns.length) {
        if (this.state.clientWidthDiffs) {
          diffs = [...this.state.clientWidthDiffs];
        } else {
          diffs = [];
          columns.map((value, i) => {
            diffs[i] = 0;
          });
        }
      }
      if (diffs) {
        diffs[colIndex] += diffX;
        if (columns[colIndex].Width + diffs[colIndex] <= 10) return; //check minimum
        columns.shift();
        this.control.setColumnsWidth(diffs, columns, true);
        this.setState({ clientWidthDiffs: diffs });
      }
    }
  };

  private renderNode(text: string, node: K2TreeViewItem): JSX.Element | string {
    if (NclImage.isSVG(text)) {
      return <K2Img glyphId={text} vcx={this.control.VCX} height={20} />;
    } else {
      return (
        <div
          className="border-transparent"
          draggable={this.state.data.IsDraggable}
          onDragEnter={(e) => this.handleDragEnter(e, node)}
          onDragStart={(e) => this.handleDragStart(e, node)}
          onDragOver={(e) => this.handleDragOver(e, node)}
          onDragLeave={this.handleDragLeave}
          onDrop={(e) => this.handleDrop(e, node)}
        >
          {text}
        </div>
      );
    }
  }

  handleDragStart = (e: React.DragEvent, node: K2TreeViewItem) => {
    e.dataTransfer.setData("text/plain", node.key);
    this.draggedNode = node;
  };

  handleDragEnter = (e: React.DragEvent, node: K2TreeViewItem) => {
    if (!this.disabledColReorder) return;
    this.rect = (e.target as HTMLDivElement).getBoundingClientRect();
    let delayID: number = window.setTimeout(() => {
      if (this.state.expandedKeys.indexOf(node.key) === -1) {
        this.control.executeExpand(node.key);
      }
    }, 1000);
    this.expandedNodes.set(e.target, delayID);
  };

  handleDrop = (e: React.DragEvent, node: K2TreeViewItem) => {
    e.preventDefault();
    if (!this.disabledColReorder) return;
    (e.target as HTMLDivElement).className = "border-transparent";

    if ((this.draggedNode && node.key === this.draggedNode.key) || node.parent.key === this.draggedNode.key) return;
    if (this.expandedNodes.has(e.target)) {
      window.clearTimeout(this.expandedNodes.get(e.target));
    }

    this.control.executeMove(e.dataTransfer.getData("text/plain"), node.key, this.calcDropPosition(e));
    this.expandedNodes.clear();
    this.setState({ selectedRowKey: e.dataTransfer.getData("text/plain") });
  };

  draggingParentIntoChildren = (node: K2TreeViewItem): boolean => {
    if (this.draggedNode.key === node.key) {
      return true;
    } else if (this.draggedNode.children && this.draggedNode.children.length > 0) {
      const found = node.parent && node.parent.children.findIndex((childNode: K2TreeViewItem) => childNode.parent.key === this.draggedNode.key);
      if (found === null) {
        return false;
      } else if (found > -1) {
        return true;
      } else {
        return this.draggingParentIntoChildren(node.parent);
      }
    } else {
      return false;
    }
  };

  handleDragOver = (e: React.DragEvent, node: K2TreeViewItem) => {
    if (!this.disabledColReorder) return;
    if (this.draggingParentIntoChildren(node)) {
      this.setState({ selectedRowKey: node.key });
      return;
    }

    e.preventDefault();

    const target = e.target as HTMLDivElement;
    let position = this.calcDropPosition(e);

    switch (position) {
      case TTreeDataMoveNodeMode.tdmnOnNode:
        target.className = "border-transparent";
        this.setState({ selectedRowKey: node.key });
        break;
      case TTreeDataMoveNodeMode.tdmnBeforeNode:
        target.className = "border-top";
        this.setState({ selectedRowKey: undefined });
        break;
      case TTreeDataMoveNodeMode.tdmnAfterNode:
        target.className = "border-bottom";
        this.setState({ selectedRowKey: undefined });
        break;
      default:
        break;
    }
  };

  private calcDropPosition(e: React.DragEvent): TTreeDataMoveNodeMode {
    if (e.clientY >= Math.floor(this.rect.top) - 1 && e.clientY < this.rect.top + 5) {
      return TTreeDataMoveNodeMode.tdmnBeforeNode;
    } else if (e.clientY > this.rect.bottom - 5 && e.clientY < this.rect.bottom) {
      return TTreeDataMoveNodeMode.tdmnAfterNode;
    } else {
      return TTreeDataMoveNodeMode.tdmnOnNode;
    }
  }

  handleDragLeave = (e: React.DragEvent) => {
    (e.target as HTMLDivElement).className = "border-transparent";
    if (this.expandedNodes.has(e.target)) window.clearTimeout(this.expandedNodes.get(e.target));
  };

  onExpand = (expanded: boolean, record: K2TreeViewItem) => {
    this.control.executeExpand(record.key);
  };

  handleColumnDragOver = (e: React.DragEvent) => {
    if (this.disabledColReorder) return;
    if (this.nodes.length === 0) return;
    e.preventDefault();

    this.nodes.map((column, index) => {
      if (e.clientX > column.left && e.clientX < column.right) {
        if (e.clientX < (column.left + column.right) / 2) {
          this.setState({ columnIndex: index, arrow: "left" });
        } else if (e.clientX > (column.left + column.right) / 2) {
          this.setState({ columnIndex: index, arrow: "right" });
        }
      }
    });
  };

  handleDragEnterColumns = (e: React.DragEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    let xPos = 0;

    if (e.nativeEvent instanceof DragEvent) {
      xPos = (e as React.DragEvent).clientX;
    } else {
      xPos = (e as React.TouchEvent).touches[0].clientX;
    }

    let offsetLeft = e.currentTarget.getBoundingClientRect().left;
    this.columns.map((columns, index) => {
      if (xPos > columns.left && xPos < columns.right) {
        if (this.draggedColumnIndex >= index) {
          this.setState({ xDragPos: columns.left - offsetLeft });
          this.hoveredColumnIndex = index;
        } else {
          this.setState({ xDragPos: columns.right - offsetLeft });
          this.hoveredColumnIndex = index;
        }
      }
    });
  };

  render() {
    const deniedText = [{ key: "denied", 0: this.state.data.DeniedText }];

    return (
      <div
        ref={this.innerTreeViewDiv}
        style={StyleHelper(this.control, { flex: "1 1 auto", position: "relative" })}
        className={this.props.renderer.renderFontRule(this.control.VCX.LabelControl.Font, this.control.VCX)}
        onDragOver={this.handleColumnDragOver}
        onDragEnd={() => this.setState({ arrow: undefined })}
        onDragEnter={this.handleDragEnterColumns}
      >
        {this.state.dragging && this.hoveredColumnIndex !== undefined && (
          <div style={{ border: "1px solid", position: "absolute", left: this.state.xDragPos, height: "calc(100% - 24px)", zIndex: 1 }}></div>
        )}
        {this.state.columns && this.state.columns.length !== 0 && (
          <Table
            showHeader={this.state.columns.length === 1 ? false : true}
            scroll={{ y: this.state.height }}
            tableLayout="fixed"
            locale={{ emptyText: this.control.Tree.Ncl.NoDataText }}
            expandable={{
              childrenColumnName: "children",
              expandIconColumnIndex: 0,
              onExpand: this.onExpand,
              expandedRowKeys: this.state.expandedKeys,
            }}
            size="small"
            dataSource={this.state.data.DeniedText ? deniedText : this.control.Root.children}
            columns={this.state.columns}
            pagination={{ hideOnSinglePage: true, pageSize: 100 }}
            rowClassName={(record: K2TreeViewItem) => {
              if (this.state.selectedRowKey === record.key) {
                return "antd-selected";
              }
              if (this.state.data.DeniedText) {
                return "antd-denied-text";
              }
            }}
            onRow={(record: K2TreeViewItem) => {
              return {
                onClick: (e) => {
                  this.control.executeSetCurrentBookmark(record.key, true);
                },
              };
            }}
            components={{ header: { cell: /*ResizableTitle*/ K2HeaderColumn } }}
          />
        )}
      </div>
    );
  }
}

const InnerTreeView = withContext(_InnerTreeView);

const baseRule = (props: K2RuleWithVCXProps) => ({
  flex: "1 1 auto",
  flexDirection: "column" as "column",
});

class _TreeView extends React.PureComponent<WithContextPlacementProps, K2ComponentState<UpdateHeadered>> {
  static displayName = `K2TreeView`;
  private control: NclTreeView;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclTreeView;
    }) as NclTreeView;
    this.state = { data: this.control.init(this) as UpdateHeadered, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateHeadered>) => {
      return { data: state as UpdateHeadered };
    });

    this.forceUpdate();
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    let addAttributes: ElementHtmlAttributes = getAttributes(this.state.data);
    let title = this.state.data.Title ? this.state.data.Title : "";
    if (this.state.data.TitleSuffix) {
      title += " " + this.state.data.TitleSuffix;
    }
    return (
      <div style={StyleHelper(this.control, this.props.style)} className={this.props.renderer.renderRule(baseRule, { vcx: this.control.VCX })}>
        {this.control.isShowHeader() && <K2Header controlUID={this.control.Header.MetaData.ControlUID} vrUID={this.props.vrUID} title={title} />}
        {this.control.Content && (
          <InnerTreeView controlUID={this.control.Content.Ncl.ControlUID} vrUID={this.control.getRealizerUID()} style={{ flexDirection: "column" }} />
        )}
      </div>
    );
  }
}

export const K2TreeView = withContext(_TreeView);

interface ResizableTitleProps extends React.HTMLAttributes<HTMLElement> {
  onResize?: (e: React.SyntheticEvent, data: ResizeCallbackData) => any;
  width: number | string;
  vcx: VisualContext;
  columnReorder: { columnIndex: number; arrow: string; currentIndex: number };
  setNodes: (nodes: DOMRect[], colReorder: boolean) => void;
  deleteColumn?: (index: number) => void;
  columns?: number;
}

class ResizableTitle extends React.PureComponent<ResizableTitleProps> {
  nodes: DOMRect[] = [];

  constructor(props: ResizableTitleProps) {
    super(props);
  }

  render() {
    if (this.props.columnReorder === undefined) {
      return <th {...this.props}></th>;
    }

    let tableHeader = (
      <th className={this.props.className}>
        <ColumnReorder
          vcx={this.props.vcx}
          width={this.props.width}
          columns={this.props.columns}
          columnReorder={this.props.columnReorder}
          setNodes={this.props.setNodes}
          deleteColumn={this.props.deleteColumn}
        >
          {this.props.children}
        </ColumnReorder>
      </th>
    );

    if (!this.props.width) {
      return tableHeader;
    }

    return (
      <Resizable
        width={this.props.width as number}
        height={0}
        onResize={this.props.onResize}
        handle={
          <span
            className="react-resizable-handle"
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        }
        draggableOpts={{ enableUserSelectHack: false }}
      >
        {tableHeader}
      </Resizable>
    );
  }
}

class ColumnReorder extends React.PureComponent<ResizableTitleProps> {
  nodes: DOMRect[] = [];

  constructor(props: ResizableTitleProps) {
    super(props);
  }

  handleDragStart = (e: React.DragEvent<HTMLElement>) => {
    if ((e.target as HTMLElement).parentElement.tagName === "TH") {
      this.nodes = [];
      const nodes = Array.from((e.target as HTMLElement).parentElement.parentElement.children);
      nodes.map((node) => this.nodes.push(node.getBoundingClientRect()));
      this.props.setNodes(this.nodes, false);
    }
  };

  handleDragEnd = (e: React.DragEvent<HTMLElement>) => {
    if (e.clientY < (e.target as HTMLElement).getBoundingClientRect().top) {
      this.props.deleteColumn(((e.target as HTMLElement).parentElement as HTMLTableHeaderCellElement).cellIndex);
    }

    this.props.setNodes([], true);
  };

  render() {
    return (
      <div draggable onDragStart={this.handleDragStart} onDragEnd={this.handleDragEnd}>
        {this.props.columnReorder.currentIndex === this.props.columnReorder.columnIndex && this.props.columnReorder.arrow === "left" && (
          <span style={{ pointerEvents: "none", position: "absolute" }}>
            <K2Img glyphId="wui*roofleftfill" vcx={this.props.vcx} height={15} />
          </span>
        )}
        {this.props.children}
        {this.props.columnReorder.currentIndex === this.props.columnReorder.columnIndex && this.props.columnReorder.arrow === "right" && (
          <span style={{ pointerEvents: "none", position: "absolute", right: 0, top: 3 }}>
            <K2Img glyphId="wui*roofrightfill" vcx={this.props.vcx} height={15} />
          </span>
        )}
      </div>
    );
  }
}

const treeViewStyles = (vcx: VisualContext) => `
	.ant-table-container {
		border: 1px solid ${vcx.getColor(vcx.Data.ColorMap.ContentFrame1)};
	}

	.ant-table-header {
		border-bottom: 1px solid ${vcx.getColor(vcx.Data.ColorMap.ContentFrame1)};
	}

	.ant-table-small .ant-table-thead > tr > th {
		color: ${vcx.getColor(vcx.Data.ColorMap.ContentDecorateColorFrg)};
		background-color: ${vcx.getColor(vcx.Data.ColorMap.ContentColorBck1)};
	}

	.antd-selected {
		background-color: ${vcx.getColor(vcx.Data.ColorMap.ContentChangeColorBck)};
	}

	.antd-denied-text {
		background-color: ${vcx.getColor(vcx.Data.ColorMap.ErrorColorBck)};
		color: ${vcx.getColor(vcx.Data.ColorMap.ErrorColorFrg)};
	}

	.antd-denied-text div {
		width: min-content;
		margin: auto;
	}

	.ant-table-thead > tr > th:not(:last-child), .ant-table-tbody >tr > td:not(:last-child) {
		border-right: 1px solid ${vcx.getColor(vcx.Data.ColorMap.ContentFrame1)};
	}

	.border-top {
		border-top: 2px solid ${vcx.getColor(vcx.Data.ColorMap.BaseColorBck1)};
		border-bottom: 2px solid transparent;
		border-radius: 4px;
	}

	.border-bottom {
		border-bottom: 2px solid ${vcx.getColor(vcx.Data.ColorMap.BaseColorBck1)};
		border-top: 2px solid transparent;
		border-radius: 4px;
	}

	.border-transparent {
		border-top: 2px solid transparent;
		border-bottom: 2px solid transparent;
	}

	.react-resizable {
		position: relative;
		background-clip: padding-box;
	}

	.react-resizable-handle {
		position: absolute;
		width: 10px;
		height: 100%;
		bottom: 0;
		right: -5px;
		cursor: col-resize;
		z-index: 1;
	}

	.ant-table.ant-table-small .ant-table-tbody > tr > td {
		overflow: hidden;
	}

	.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
		color: ${vcx.getColor(vcx.Data.ColorMap.ContentNormalColorFrg)};
		background-color: ${vcx.getColor(vcx.Data.ColorMap.ContentChangeColorBck)};
	}			
`;
