import React = require("react");
import { WithContextPlacementProps, K2ComponentState, withContext, StyleHelper, AcquireControl } from "./k2hoc";
import { K2Img } from "./K2Image";
import { K2RuleWithVCXProps } from "./k2StyleRenderer";
import { NclFloaterAccessor, UFOverAlign, UFOverAlignListener } from "../common/components.ncl";
import { UpdateControl, UFUpdateControl } from "../common/communication.base";
import { K2TruncateText } from "./K2TruncateText";
import { ElementHtmlAttributes, getAttributes } from "../common/common";

const styleRules = {
  buttonRule: (props: K2RuleWithVCXProps) => ({
    display: "flex",
    flexDirection: "row" as "row",
    flexWrap: "nowrap" as "nowrap",
    width: "100%",
    alignItems: "center",
    backgroundColor: props.vcx.ColorMap.getColor(props.vcx.Data.ColorMap.GridRulerColorBck),
    borderColor: props.vcx.ColorMap.getColor(props.vcx.Data.ColorMap.AccentBaseColorBck),
    border: props.vcx.InputControl.getInputFrameWidth() + "px " + props.vcx.ColorMap.getColor(props.vcx.Data.ColorMap.ContentFrame2) + " solid",
  }),
  contentRule: (props: K2RuleWithVCXProps) => ({
    width: "100%",
  }),
};

class _FloaterAccessor extends React.PureComponent<WithContextPlacementProps, K2ComponentState<UFUpdateControl>> implements UFOverAlign {
  static displayName = `K2FloaterAccessor`;
  private title: K2TruncateText;
  private control: NclFloaterAccessor;
  private element: HTMLElement;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclFloaterAccessor;
    }) as NclFloaterAccessor;
    this.state = { data: this.control.init(this) as UFUpdateControl, vcxVersion: -1 };
  }
  getOverRect(): DOMRect {
    if (this.element) return this.element.getBoundingClientRect();
    return null;
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UFUpdateControl>) => {
      return { data: state as UFUpdateControl };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  setAsActive(isActive: boolean) {}

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  private handleClick = () => {
    this.control.show();
  };

  private getContent(): JSX.Element {
    let addAttributes: ElementHtmlAttributes = getAttributes(this.state.data);

    return (
      <button
        onClick={this.handleClick}
        data-k2-test-id={this.control.MetaData.Name}
        accessKey={this.title ? this.title.accessKey : null}
        className={this.props.renderer.renderRule(styleRules.buttonRule, { vcx: this.control.VCX })}
        title={this.state.data.Title}
        {...addAttributes}
      >
        {this.imgShow()}
        {this.labelShow()}
      </button>
    );
  }

  private imgShow(): JSX.Element {
    if (this.state.data.GlyphId && this.state.data.GlyphId !== "") {
      return (
        <div>
          <K2Img
            glyphId={this.state.data.GlyphId}
            height={this.control.ComputedMinHeight}
            vcx={this.control.VCX}
            style={{ color: this.control.VCX.ColorMap.getColor(this.control.VCX.Data.ColorMap.ContentDecorateColorFrg) }}
          />
        </div>
      );
    } else {
      return null;
    }
  }

  private labelShow(): JSX.Element {
    return (
      <K2TruncateText
        className={
          this.props.renderer.renderRule(styleRules.contentRule, { vcx: this.control.VCX }) +
          " " +
          this.props.renderer.renderFontRule(this.control.VCX.BookTabControl.AccessorFont, this.control.VCX)
        }
      >
        {this.state.data.Title}
      </K2TruncateText>
    );
  }

  render() {
    return (
      <div
        style={StyleHelper(this.control, { display: "flex", ...this.props.style })}
        ref={(ref) => {
          this.element = ref;
        }}
      >
        {this.getContent()}
      </div>
    );
  }
}
export const K2FloaterAccessor = withContext(_FloaterAccessor);
