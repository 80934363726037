import { ApplicationBase } from "./app";
import { DeviceInfo } from "./common/common";

export class Context {
  private static instance: Context;
  private app: ApplicationBase;
  private deviceInfo: DeviceInfo;

  private constructor() {}

  public static getInstance(): Context {
    if (!Context.instance) {
      Context.instance = new Context();
    }

    return Context.instance;
  }

  public static registerApplication(app: ApplicationBase) {
    let instance = Context.getInstance();
    if (instance.app) throw new Error("Application mischmash.");

    return (instance.app = app);
  }

  public static getApplication(): ApplicationBase {
    let instance = Context.getInstance();
    return instance.app;
  }

  public static showMessage(message: string) {
    alert(message);
  }

  public static get DeviceInfo(): DeviceInfo {
    if (!this.getInstance().deviceInfo) {
      this.getInstance().deviceInfo = new DeviceInfo();
    }

    return this.getInstance().deviceInfo;
  }
}
