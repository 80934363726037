import React = require("react");
import { Align, UpdateControl, UpdateImage } from "../common/communication.base";
import { K2ComponentState, WithContextPlacementProps, withContext, AcquireControl } from "./k2hoc";
import { NclFilePreview } from "../common/components.ncl";
import { isUrl, CancelablePromise, makeCancelable, Log, isPDF } from "../common/common";
import { MediaManager, MediaData } from "../common/mediaManager";
import { K2TruncateText } from "./K2TruncateText";
import { K2RuleWithVCXProps } from "./k2StyleRenderer";
import { getDenyImage, K2Img } from "./K2Image";

class _FilePreview extends React.PureComponent<WithContextPlacementProps, K2ComponentState<UpdateImage>> {
  static displayName = `K2FilePreview`;
  private control: NclFilePreview;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclFilePreview;
    }) as NclFilePreview;
    this.state = { data: this.control.init(this) as UpdateImage, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateImage>) => {
      return { data: state as UpdateImage };
    });

    this.forceUpdate();
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    let content = null;
    if (this.state.data.ImageDeny === true) {
      content = getDenyImage(this.control.VCX, this.state.data.Stretch, this.control.MetaData.Bounds.Align, this.control.ComputedMinHeight);
    } else {
      content = <FileViewer control={this.control} url={this.state.data.ObjectId} />;
    }
    return (
      <div
        style={{ width: "100%" }}
        className={this.props.renderer.renderRule(
          (props: K2RuleWithVCXProps) => ({ backgroundColor: props.vcx.getColor(props.vcx.Data.ColorMap.DataBrowseColorBck) }),
          { vcx: this.control.VCX }
        )}
      >
        {content}
      </div>
    );
  }
}
export const K2FilePreview = withContext(_FilePreview);

interface FileViewerProps {
  url: string;
  control: NclFilePreview;
}
interface FileViewerState {
  data: string;
  errorMessage?: string;
  url: string;
}

const urlPostfix: string = "?page=1&pagemode=none&toolbar=0&statusbar=0&navpanes=0";

class FileViewer extends React.PureComponent<FileViewerProps, FileViewerState> {
  private promise: CancelablePromise<MediaData>;

  constructor(props: FileViewerProps) {
    super(props);
    this.state = { data: null, url: null };
  }

  render() {
    let content: JSX.Element = null;
    if (this.state.url != this.props.url) {
      this.initData();
    } else {
      if (this.state.errorMessage) {
        content = (
          <K2TruncateText line={10} style={{ alignSelf: "center" }}>
            {this.state.errorMessage}
          </K2TruncateText>
        );
      } else if (this.state.data) {
        if (this.state.data.includes("image")) {
          content = <K2Img glyphId={this.state.url} vcx={this.props.control.VCX} />;
        } else {
          content = (
            <div style={{ width: "100%", height: "100%" }}>
              <object
                data={this.state.data}
                //type={"application/"+ this.state.data.co}
                width={"100%"}
                height={"100%"}
              ></object>
            </div>
          );
        }
      }
    }
    return content;
  }

  private initData() {
    if (isUrl(this.props.url)) {
      this.setState({ data: this.props.url, url: this.props.url + (isPDF(this.props.url) ? urlPostfix : "") });
    } else {
      this.promise = makeCancelable<MediaData>(MediaManager.tryGetData(this.props.url));

      this.promise.promise
        .then((data) => {
          if (data.errorMessage) {
            this.setState({ errorMessage: data.errorMessage, url: this.props.url });
          } else {
            this.setState({ data: data.base64Data, url: this.props.url });
          }
        })
        .catch((reason) => {
          if (reason && !reason.isCanceled) {
            Log.error("error: " + reason, null);
          }
        });
    }
  }
}
