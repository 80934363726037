import * as React from "react";
import { WithContextPlacementProps, K2ComponentState, withContext, AcquireControl, StyleHelper } from "./k2hoc";
import { UFUpdateControl, UpdateControl, UpdateRibbon, UpdateCommandItem, TUFActionDisplayStyle, UpdateInnerToolbar } from "../common/communication.base";
import { NclRibbon, NclInnerToolBar, NclInnerSection, UFNclControlBase, NclCommandItem, SectionItems, NclBreaker, NclAccessor } from "../common/components.ncl";
import { GenerateControl } from "./K2GenerateControl";
import { K2RuleWithVCXProps } from "./k2StyleRenderer";
import { K2RibbonAction, K2ControlBreaker } from "./K2RibbonAction";
import { ViewRealizer, ViewRealizerManager } from "../viewrealizer";
import { Context } from "../appcontext";

type K2ActionRuleProps = K2RuleWithVCXProps & {
  usePadding: boolean;
};
const sectionRules = {
  baseStyle: (props: K2RuleWithVCXProps) => ({
    borderRight: props.vcx.GridControl.LineWidth + "px solid " + props.vcx.ColorMap.getColor(props.vcx.Data.ColorMap.ContentFrame1),
    flexDirection: "column" as "column",
    padding: "0px " + props.vcx.Data.MarginX * 2 + "px",
  }),
  actionsStyle: (props: K2RuleWithVCXProps) => ({
    display: "grid",
  }),
  nameStyle: (props: K2RuleWithVCXProps) => ({
    width: "100%",
    justifyContent: "center" as "center",
    borderTop: props.vcx.GridControl.LineWidth + "px solid " + props.vcx.ColorMap.getColor(props.vcx.Data.ColorMap.ContentFrame1),
    small: {
      display: "none",
    },
  }),
  baseSubSectionStyle: (props: K2RuleWithVCXProps) => ({
    height: 4.2 * props.vcx.LabelControl.getHeight(1) + "px",
    flexDirection: "row" as "row",
    flexWrap: "wrap" as "wrap",
    writingMode: "vertical-lr" as "vertical-lr", // wrap column zpusoboval overflow pres div, viz https://github.com/philipwalton/flexbugs#flexbug-14; pouzit workaround pomoci writing-mode
    small: {
      height: 1.5 * props.vcx.LabelControl.getHeight(1) + "px",
      writingMode: "horizontal-tb" as "horizontal-tb", // writing-mode na puvodni hodnotu
    },
  }),
  actionStyle: (props: K2ActionRuleProps) => ({
    padding: props.usePadding ? "0px " + props.vcx.Data.MarginX + "px" : "0px",
  }),
};

class _InnerSection extends React.PureComponent<WithContextPlacementProps, K2ComponentState<UpdateControl>> {
  private control: NclInnerSection;
  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclInnerSection;
    }) as NclInnerSection;
    this.state = { data: this.control.init(this) as UpdateControl, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateControl>) => {
      return { data: state as UpdateControl };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  private renderItem(item: UFNclControlBase): JSX.Element {
    let content: JSX.Element = null;
    if (item instanceof NclCommandItem) {
      content = (
        <K2RibbonAction
          key={"item_" + item.MetaData.ControlUID}
          className={this.props.renderer.renderRule(sectionRules.actionStyle, {
            vcx: this.control.VCX,
            usePadding: item.Ncl.FrgtData.DisplayStyle !== TUFActionDisplayStyle.ufadsButtonSmall,
          })}
          captionLinesCount={this.control.Ribbon.Ncl.FrgtData.TileCaptionLineCount}
          vrUID={item.getRealizerUID()}
          controlUID={item.MetaData.ControlUID}
          color={this.control.VCX.getColor(this.control.VCX.Data.ColorMap.ContentDecorateColorFrg)}
        />
      );
    } else if (item instanceof NclBreaker) {
      content = <K2ControlBreaker key={"item_" + item.MetaData.ControlUID} vrUID={item.getRealizerUID()} controlUID={item.MetaData.ControlUID} />;
    } else {
      content = GenerateControl(item);
    }
    return content;
  }

  render() {
    return (
      <div style={StyleHelper(this.control, this.props.style)} className={this.props.renderer.renderRule(sectionRules.baseStyle, { vcx: this.control.VCX })}>
        <div className={this.props.renderer.renderRule(sectionRules.actionsStyle, { vcx: this.control.VCX })}>
          <div className={this.props.renderer.renderRule(sectionRules.baseSubSectionStyle, { vcx: this.control.VCX })}>
            {this.control.ContentItems.map((item) => this.renderItem(item))}
          </div>
        </div>
        <div
          className={
            this.props.renderer.renderRule(sectionRules.nameStyle, { vcx: this.control.VCX }) +
            " " +
            this.props.renderer.renderFontRule(this.control.VCX.LabelControl.Font, this.control.VCX)
          }
        >
          {this.control.Ncl.Caption}
        </div>
      </div>
    );
  }
}

const K2InneSection = withContext(_InnerSection);

const toolBarRules = {
  baseStyle: (props: K2RuleWithVCXProps) => ({
    width: "100%",
    justifyContent: "space-between",
    background: props.vcx.getColor(props.vcx.Data.ColorMap.DataBrowseColorBck),
  }),
  menu: (props: K2RuleWithVCXProps) => ({
    color: props.vcx.getColor(props.vcx.Data.ColorMap.ContentDecorateColorFrg),
    alignSelf: "center",
    paddingLeft: props.vcx.Data.MarginX * 2 + "px",
    paddingRight: props.vcx.Data.MarginX * 2 + "px",
    small: {
      display: "initial",
    },
    ">div": {
      justifyContent: "flex-end",
    },
  }),
  hideBtn: (props: K2RuleWithVCXProps) => ({
    color: props.vcx.getColor(props.vcx.Data.ColorMap.ContentDecorateColorFrg),
    alignSelf: "flex-end",
    paddingLeft: props.vcx.Data.MarginX * 2 + "px",
    paddingRight: props.vcx.Data.MarginX * 2 + "px",
    small: {
      display: "none",
    },
  }),
  content: (props: K2RuleWithVCXProps) => ({
    overflowX: "auto" as "auto",
    flex: "1 1 auto",
  }),
  buttons: (props: K2RuleWithVCXProps) => ({
    flexShrink: 0,
    paddingRight: props.vcx.Data.MarginX + "px",
  }),
};

interface InnerToolBarProps extends WithContextPlacementProps {
  showMenu: boolean;
}

class _InnerToolBar extends React.PureComponent<InnerToolBarProps, K2ComponentState<UpdateInnerToolbar>> {
  private control: NclInnerToolBar;
  constructor(props: InnerToolBarProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclInnerToolBar;
    }) as NclInnerToolBar;
    this.state = { data: this.control.init(this) as UpdateInnerToolbar, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateInnerToolbar>) => {
      return { data: state as UpdateInnerToolbar };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    return (
      <div style={StyleHelper(this.control, this.props.style)} className={this.props.renderer.renderRule(toolBarRules.baseStyle, { vcx: this.control.VCX })}>
        <div className={this.props.renderer.renderRule(toolBarRules.content, { vcx: this.control.VCX })}>
          <div className={this.props.renderer.renderRule(toolBarRules.buttons, { vcx: this.control.VCX })}>
            {this.control.Sections.map((value) => {
              return <K2InneSection controlUID={value.MetaData.ControlUID} vrUID={this.control.getRealizerUID()} key={value.MetaData.ControlUID} />;
            })}
          </div>
        </div>
        {this.props.showMenu && (
          <div className={this.props.renderer.renderRule(toolBarRules.menu, { vcx: this.control.VCX })}>
            <K2RibbonAction controlUID={this.control.Ribbon.OthersBtn.MetaData.ControlUID} vrUID={this.control.getRealizerUID()} />
          </div>
        )}
        {
          <div className={this.props.renderer.renderRule(toolBarRules.hideBtn, { vcx: this.control.VCX })}>
            {(this.control.Parent as NclRibbon).ToolBars.length > 1 && (
              <K2RibbonAction controlUID={this.control.Ribbon.TabHideBtn.MetaData.ControlUID} vrUID={this.control.getRealizerUID()} heightIcon={16} />
            )}
          </div>
        }
      </div>
    );
  }
}

const K2InnerToolBar = withContext(_InnerToolBar);

type RibbonPageProps = K2RuleWithVCXProps & {
  active: boolean;
};

type RibbonProps = K2RuleWithVCXProps & {
  depth?: number;
};

type ToolBarProps = RibbonProps & {
  width: string;
  top: string;
};

const ribbonRules = {
  baseStyle: (props: K2RuleWithVCXProps) => ({
    flexDirection: "column" as "column",
    //height:(props.hiddenToolBars)?+ props.vcx.LabelControl.getHeight(1) + props.vcx.ExpanderControl.GetHFHeight() + 'unset': (props.size * props.vcx.LabelControl.getHeight(2)) + props.vcx.LabelControl.getHeight(1) + props.vcx.ExpanderControl.GetHFHeight() + 'px',
    height: "100%",
    small: {
      //height:2.7*props.vcx.LabelControl.getHeight(1),
      flexDirection: "row-reverse",
    },
  }),
  header: (props: K2RuleWithVCXProps) => ({
    background: props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorBck1),
    height: props.vcx.LabelControl.getHeight(1.8) + "px",
    padding: props.vcx.Data.MarginY + "px " + props.vcx.Data.MarginX + "px 0px " + props.vcx.Data.MarginX + "px",
    small: {
      height: "100%",
      background: props.vcx.getColor(props.vcx.Data.ColorMap.DataBrowseColorBck),
      border: props.vcx.GridControl.LineWidth + "px solid " + props.vcx.getColor(props.vcx.Data.ColorMap.ContentFrame1),
      borderLeft: "none",
      //width:3.5*props.vcx.LabelControl.getHeight(1),
      padding: props.vcx.Data.MarginY * 2 + "px " + props.vcx.Data.MarginX * 2 + "px",
    },
  }),
  pages: (props: RibbonProps) => ({
    zIndex: props.depth ? props.depth : 0,
    flex: "1 1 auto",
    overflowX: "hidden" as "hidden",
    paddingRight: props.vcx.Data.MarginX + "px",
    small: {
      display: "none",
    },
  }),
  page: (props: RibbonPageProps) => ({
    backgroundColor: props.active ? props.vcx.getColor(props.vcx.Data.ColorMap.DataBrowseColorBck) : "unset",
    color: props.active ? "unset" : props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorFrg1),
    alignSelf: "center",
    height: "100%",
    alignItems: "center",
  }),
  pageText: (props: K2RuleWithVCXProps) => ({
    textOverflow: "ellipsis",
    overflow: "hidden",
    minWidth: props.vcx.sizeMap(10) + "px",
  }),
  menu: (props: K2RuleWithVCXProps) => ({
    flex: "none",
    //alignSelf:'flex-end',
    paddingLeft: props.vcx.Data.MarginX * 2 + "px",
    paddingRight: props.vcx.Data.MarginX * 2 + "px",
    color: props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorFrg1),
    ">div": {
      justifyContent: "flex-end",
      small: {
        justifyContent: "center",
        color: props.vcx.getColor(props.vcx.Data.ColorMap.ContentDecorateColorFrg),
      },
    },
    small: {
      flex: "1 0 100%",
      padding: "0px",
      justifyContent: "flex-end",
      alignItems: "center",
      //height: "100%"
    },
  }),
  toolbar: (props: K2RuleWithVCXProps & { accessorsCount: number }) => ({
    border: props.vcx.GridControl.LineWidth + "px solid " + props.vcx.getColor(props.vcx.Data.ColorMap.ContentFrame1),
    borderTop: props.accessorsCount > 1 ? "none" : null,
    small: {
      border: props.vcx.GridControl.LineWidth + "px solid " + props.vcx.getColor(props.vcx.Data.ColorMap.ContentFrame1),
      borderRight: "none",
      flex: "1 1 auto",
    },
  }),
  flowToolbar: (props: ToolBarProps) => ({
    position: "absolute" as "absolute",
    width: props.width,
    top: props.top,
    zIndex: props.depth ? props.depth : 0,
    small: {
      position: "initial",
    },
  }),
  overlay: (props: K2RuleWithVCXProps) => ({
    backgroundColor: "transparent",
    height: "100%",
    width: "100%",
    position: "absolute" as "absolute",
    top: 0,
    left: 0,
  }),
};

interface K2AccessorProps extends WithContextPlacementProps {
  handleClick: () => void;
  isActive: boolean;
}

class _Accessor extends React.PureComponent<K2AccessorProps, K2ComponentState<UpdateControl>> {
  private control: NclAccessor;

  constructor(props: K2AccessorProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclAccessor;
    }) as NclAccessor;
    this.state = { data: this.control.init(this), vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateControl>) => {
      return { data: state as UpdateControl };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render(): JSX.Element {
    return (
      <div
        style={StyleHelper(this.control, this.props.style)}
        data-k2-test-id={this.control.MetaData.Name + `_accesor_`}
        onClick={() => {
          this.props.handleClick();
        }}
        className={
          this.props.renderer.renderRule(ribbonRules.page, { vcx: this.control.VCX, active: this.props.isActive }) +
          " " +
          this.props.renderer.renderFontRule(this.control.VCX.LabelControl.Font, this.control.VCX)
        }
      >
        <span className={this.props.renderer.renderRule(ribbonRules.pageText, { vcx: this.control.VCX })}>{this.control.Ncl.Caption}</span>
      </div>
    );
  }
}

const K2Accessor = withContext(_Accessor);

interface RibbonState extends K2ComponentState<UpdateRibbon> {
  flowVisible: boolean;
  width: number;
}

class _Ribbon extends React.PureComponent<WithContextPlacementProps, RibbonState> {
  private control: NclRibbon;
  private element: HTMLDivElement;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclRibbon;
    }) as NclRibbon;
    this.state = { data: this.control.init(this) as UpdateRibbon, vcxVersion: -1, flowVisible: false, width: window.innerWidth };
    window.addEventListener("resize", this.setWidth);
  }

  updateState(state: UpdateRibbon) {
    this.setState((prevState: K2ComponentState<UpdateRibbon>) => {
      return { data: state as UpdateRibbon, flowVisible: state.HideToolBars && prevState.data.CurrentToolBarIndex != state.CurrentToolBarIndex ? true : false };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  setWidth = () => {
    this.setState({ width: window.innerWidth });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.setWidth);
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    let vr: ViewRealizer = ViewRealizerManager.getViewRealizer(this.control.getRealizerUID());
    let cssFlow: string = "";
    if (this.state.flowVisible && this.element) {
      cssFlow =
        " " +
        this.props.renderer.renderRule(ribbonRules.flowToolbar, {
          vcx: this.control.VCX,
          width: this.element.offsetWidth - 2 * this.control.VCX.Data.MarginX - 1 + "px",
          top: this.element.offsetTop + this.element.offsetHeight - this.control.VCX.Data.MarginY + "px",
          depth: this.state.flowVisible ? vr.getDepth() + 1 : undefined,
        });
    }
    return (
      <div
        ref={(ref) => {
          this.element = ref;
        }}
        style={StyleHelper(this.control, this.props.style)}
        className={this.props.renderer.renderRule(ribbonRules.baseStyle, { vcx: this.control.VCX })}
        onContextMenu={this.handleContextMenu}
      >
        {this.control.ToolBars.length > 1 && (
          <div className={this.props.renderer.renderRule(ribbonRules.header, { vcx: this.control.VCX })}>
            <div
              className={this.props.renderer.renderRule(ribbonRules.pages, {
                vcx: this.control.VCX,
                depth: this.state.flowVisible ? vr.getDepth() + 1 : undefined,
              })}
            >
              {this.control.ToolBars.map((value, index) => {
                return (
                  <K2Accessor
                    key={this.control.MetaData.ControlUID + "_accessor_" + index}
                    controlUID={value.Accessor.MetaData.ControlUID}
                    vrUID={value.Accessor.getRealizerUID()}
                    handleClick={() => {
                      this.handleClick(index);
                    }}
                    isActive={(!this.state.data.HideToolBars || this.state.flowVisible) && index == this.state.data.CurrentToolBarIndex}
                  />
                );
              })}
            </div>
            <div className={this.props.renderer.renderRule(ribbonRules.menu, { vcx: this.control.VCX })}>
              <K2RibbonAction controlUID={this.control.OthersBtn.MetaData.ControlUID} vrUID={this.control.getRealizerUID()} />
            </div>
          </div>
        )}
        {this.state.flowVisible && (
          <div onClick={this.handleOverlayClick} className={this.props.renderer.renderRule(ribbonRules.overlay, { vcx: this.control.VCX })} />
        )}
        {(!this.state.data.HideToolBars || this.state.flowVisible || this.state.width < Context.DeviceInfo.ResponsiveBreakpoints[0]) && (
          <div
            className={this.props.renderer.renderRule(ribbonRules.toolbar, { vcx: this.control.VCX, accessorsCount: this.control.ToolBars.length }) + cssFlow}
          >
            {this.control.ToolBars.map((value, index) => {
              if (this.state.data.CurrentToolBarIndex == index) {
                return (
                  <K2InnerToolBar
                    key={this.control.MetaData.ControlUID + "_tab_" + value.ToolBar.MetaData.ControlUID}
                    showMenu={this.control.ToolBars.length == 1}
                    controlUID={value.ToolBar.MetaData.ControlUID}
                    vrUID={this.control.getRealizerUID()}
                  />
                );
              }
            })}
          </div>
        )}
      </div>
    );
  }

  private handleContextMenu = (e: React.MouseEvent) => {
    this.control.contextMenu();
    e.preventDefault();
  };

  private handleOverlayClick = () => {
    if (this.state.flowVisible) {
      this.setState({ flowVisible: false });
    }
  };

  private handleClick(index: number) {
    if (index === this.state.data.CurrentToolBarIndex && this.state.data.HideToolBars) {
      this.setState({ flowVisible: !this.state.flowVisible });
    } else {
      this.control.changeCurrentToolBar(index);
    }
  }
}

export const K2Ribbon = withContext(_Ribbon);
