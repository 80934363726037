import * as React from "react";
import { K2RuleWithVCXProps } from "./k2StyleRenderer";
import { withContext, WithContextPlacementProps, K2ComponentState, WithVCXProps, StyleHelper, AcquireControl } from "./k2hoc";
import { NclPreviewPanel } from "../common/components.ncl";
import { UpdateControl, UFUpdateControl } from "../common/communication.base";
import { K2TruncateText } from "./K2TruncateText";
import { GenerateControl, GenerateChildrenOfControl } from "./K2GenerateControl";

interface HeaderProps extends WithVCXProps {
  title: string;
}

const headerRule = (props: K2RuleWithVCXProps) => ({
  width: "100%",
  color: props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorFrg1),
});

const previewRule = (props: K2RuleWithVCXProps) => ({
  backgroundColor: props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorBck1),
  display: "flex",
  flexDirection: "column" as "column",
  overflow: "auto",
});

const titleRule = (props: K2RuleWithVCXProps) => ({
  color: props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorFrg1),
  fontSize: props.vcx.Data.TitleControl.Font.FontSize + "px",
  minWidth: 0,
});

class _Header extends React.PureComponent<HeaderProps, {}> {
  static displayName = `K2Header`;

  private getStyle(): React.CSSProperties {
    let style: React.CSSProperties = {
      padding: "8px 3px",
      flex: "none",
    };
    return style;
  }

  private getTitleClassName(): string {
    let className: string = this.props.renderer.renderRule(titleRule, { vcx: this.props.vcx });
    return className;
  }

  render() {
    return (
      <div className={this.props.renderer.renderRule(headerRule, { vcx: this.props.vcx })} style={this.getStyle()}>
        <span className={this.getTitleClassName()}>
          <K2TruncateText>{this.props.title}</K2TruncateText>
        </span>
      </div>
    );
  }
}

const K2Header = withContext(_Header);

class _PreviewPanel extends React.PureComponent<WithContextPlacementProps, K2ComponentState<UFUpdateControl>> {
  static displayName = `K2PreviewPanel`;
  private control: NclPreviewPanel;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclPreviewPanel;
    }) as NclPreviewPanel;
    this.state = { data: this.control.init(this) as UFUpdateControl, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UFUpdateControl>) => {
      return { data: state as UFUpdateControl };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    //<K2Header key={this.control.getNcl().ControlUID + '_header'} title={this.control.getNcl().Title} />
    return (
      <div style={StyleHelper(this.control, this.props.style)} className={this.props.renderer.renderRule(previewRule, { vcx: this.control.VCX })}>
        <K2Header key={this.control.MetaData.ControlUID + "_header"} title={this.state.data.Title} vcx={this.control.VCX} />
        {GenerateChildrenOfControl(this.control)}
      </div>
    );
  }
}

export const K2PreviewPanel = withContext(_PreviewPanel);
