import React = require("react");

import { Align, UpdateControl, UFUpdateControl, CSUFNclControlMetadata, CSNclControlMetadata, Bounds } from "../common/communication.base";
import { NclContainerBase, NclControlBase, NclFloaterAccessor, NclHeaderedControl, NclTabControl, ScrollOption } from "../common/components.ncl";
import { VisualContext } from "../common/visualContext";
import { VCXContext } from "./app";
import { K2StyleRenderer } from "./k2StyleRenderer";
import { ViewRealizerManager, ViewRealizer } from "../viewrealizer";
import { Context } from "../appcontext";

export interface WithContextProps {
  renderer?: K2StyleRenderer;
  children?: React.ReactNode;
}

export interface WithVCXProps extends WithContextProps {
  vcx: VisualContext;
}

/**
 * HOC přidává do props objekty z kontextu
 * @param WrappedComponent
 */
export const withContext = <T extends React.Component<P>, P extends WithContextProps>(WrappedComponent: React.ComponentClass<P>) => {
  type PrivateProps = { forwardedRef: React.RefObject<T> };
  type Props = P & PrivateProps;

  class WithContext extends React.Component<Props> {
    static displayName = `WithContext(${WrappedComponent.displayName || WrappedComponent.name})`;
    render() {
      const { forwardedRef, ...restProps } = this.props as PrivateProps;
      const rest = restProps as P;

      return <VCXContext.Consumer>{(context) => <WrappedComponent {...rest} renderer={context.renderer} ref={forwardedRef} />}</VCXContext.Consumer>;
    }
  }

  const RefForwardingFactory = (props: Props, ref: React.RefObject<T>) => <WithContext {...props} forwardedRef={ref} />;

  return React.forwardRef<T, P>(RefForwardingFactory as any);
};

export interface K2ComponentState<S extends UpdateControl> {
  data: S;
  vcxVersion: number;
}

export interface WithContextPlacementProps extends WithContextProps {
  vrUID?: string;
  controlUID?: string;
  style?: React.CSSProperties;
  className?: string;
}

function getVerticalAlign(align: Align): "flex-start" | "flex-end" | "stretch" | "auto" {
  if (align != null) {
    switch (align) {
      case Align.Bottom:
        return "flex-end";
      case Align.Top:
        return "flex-start";
      default:
        return "auto";
    }
  }

  return "auto";
}

type CheckType = (ctrl: any) => boolean;
/**
 * Methods try fond control in their VR elements, when not found or found bad type of control than throw error
 * @param controlUID Identification of control.
 * @param viewRealizerUID  Identification of VR.
 * @param checkType Method for check type.
 * @param notFoundError When set to true method throw error when element not found.
 */
export const AcquireControl = (controlUID: string, viewRealizerUID: string, checkType: CheckType, notFoundError: Boolean = true): NclControlBase => {
  let vr: ViewRealizer = null;

  if (viewRealizerUID && viewRealizerUID !== "") {
    vr = ViewRealizerManager.getViewRealizer(viewRealizerUID);
  }

  if (!vr) {
    vr = Context.getApplication().getActiveRealizer();
  }

  if (!vr) return null;

  let ctrl = vr.getControlByUID(controlUID);

  if (!ctrl) {
    if (!notFoundError) return null;
    throw Error(`Control ${controlUID} not found.`);
  }

  if (!checkType(ctrl)) {
    throw Error(`Control ${controlUID} has invalid type: ${ctrl.MetaData.__type}.`);
  }

  return ctrl;
};

export const StyleHelper = (ctrl: NclControlBase, style?: React.CSSProperties): React.CSSProperties => {
  let result: React.CSSProperties = style ? Object.assign({}, style) : {};
  if (!ctrl) {
    return result;
  }

  let metadata: CSUFNclControlMetadata = ctrl.MetaData as CSUFNclControlMetadata;
  if (metadata) {
    if (metadata.Bounds) {
      if (!result.width) result.width = metadata.Bounds.Align === Align.Left || metadata.Bounds.Align === Align.Right ? "auto" : "100%";
      if (metadata.Bounds.BandsCount > 0 && !result.flex) {
        result.flex = metadata.Bounds.BandsCount;
      }
      if (!result.alignSelf) {
        result.alignSelf = getVerticalAlign(metadata.Bounds.Align);
      }
    }
  }

  if (!result.minHeight && ctrl.ComputedMinHeightWithMargin >= 1) {
    if (ctrl.Size >= 0 && ((!(ctrl instanceof NclContainerBase) && !(ctrl instanceof NclHeaderedControl)) || ctrl instanceof NclFloaterAccessor)) {
      result.minHeight = ctrl.ComputedMinHeightWithMargin + "px";
    }
  }

  if (!result.minWidth && ctrl instanceof NclContainerBase) {
    result.minWidth = ctrl.VCX.LabelControl.Font._MWidth * ctrl.MetaData.Bounds.BandsCount;
  }

  if (ctrl.VCX.Data.MarginX && ctrl.MarginXFactor > 0 && result.padding == undefined) {
    result.paddingRight = ctrl.VCX.Data.MarginX * ctrl.MarginXFactor + "px";
    result.paddingLeft = ctrl.VCX.Data.MarginX * ctrl.MarginXFactor + "px";
  }
  if (ctrl.VCX.Data.MarginY && ctrl.MarginYFactor > 0 && result.padding == undefined) {
    result.paddingTop = ctrl.VCX.Data.MarginY * ctrl.MarginYFactor + "px";
    result.paddingBottom = ctrl.VCX.Data.MarginY * ctrl.MarginYFactor + "px";
  }

  if (!result.whiteSpace) result.whiteSpace = "nowrap";

  if (!result.display && ctrl.State.Visible === false) {
    result.display = "none";
  }

  return result;
};
