import { Iterable, List, Record } from "immutable";
import { VCXGrid, VisualContext } from "./visualContext";
import { NclCommandItem } from "./components.ncl";
import { Log } from "./common";

export const cJSonFunctionGridOperation = "GridOperation";
export const cJSonFunctionUpdateData = "UpdateData";
export const cJSonFunctionAcceptData = "AcceptData";
export const cJSonFunctionExecute = "Execute";
export const cJSonCommandExecuteLookup = "ExecuteLookup";
export const cJSonFunctionCloseView = "CloseView";
export const cJSonFunctionExecuteShortcut = "ExecuteShortcut";
export const cJSonDefaultAcceptExecute = "DefaultAccept";
export const cJsonFunctionShowFloater = "ShowFloater";
export const cJsonFunctionHideFloater = "HideFloater";
export const cJSonFunctionContextMenu = "ContextMenu";
export const cJSonFunctionGridChangeSortBy = "GridChangeSortBy";
export const cJSonFunctionNextPriorValue = "NextPriorValue";
export const cJSonFunctionToggleNodeExecute = "ToggleNode";
export const cJSonFunctionMoveNodeExecute = "MoveNode";
export const cJSonDataGridDblClickExecute = "DataGridDblClick";

export const cClientFileType = "TClientFile";
export const cClientUrlType = "TClientUrl";
export const cClienSoundType = "TClientSound";

export const DEFAULT_LANG_IMG = "Lang/Lang0";

export const clNone = 536870911;
//#region "enums"

export enum TUFDisplayValueAs {
  dvaNone,
  dvaText,
  dvaImage,
}

export enum GridOperations {
  next = 1,
  prior = 2,
  firstPage = 3,
  lastPage = 4,
  nextPage = 5,
  priorPage = 6,
}
export enum Align {
  Left = 0,
  Right = 1,
  Top = 2,
  Bottom = 3,
  Client = 4,
}

export enum FontStyle {
  fsBold = 1,
  fsItalic = 2,
  fsUnderline = 4,
  fsStrikeOut = 8,
}

export enum TCondFormattingOption {
  cfoFontStyleBold = 1,
  cfoFontStyleItalic = 2,
  cfoFontStyleUnderline = 4,
  cfoFontStyleStrike = 8,
  cfoBackColor = 16,
  cfoForegroundColor = 32,
}

export enum DisplayMode {
  ///	<summary>
  ///	  Nezobrazit
  ///	</summary>
  fpdmNone,

  ///	<summary>
  ///	  Zobrazit standardne
  ///	</summary>
  fpdmNormal,

  ///	<summary>
  ///	  Zobrazit standardne tucne
  ///	</summary>
  fpdmNormalBold,

  ///	<summary>
  ///	  Zobrazit dekoracni barvou
  ///	</summary>
  fpdmDecorated,

  ///	<summary>
  ///	  Zobrazit dekoracni barvou tucne
  ///	</summary>
  fpdmDecoratedBold,
}

export enum Orientation {
  foHorizontal,
  foVertical,
}

export enum SplitterPanelOrientation {
  spoLeft,
  spoRight,
  spoBottom,
  spoTop,
}

export enum DecorateMode {
  ufdemNone,
  ufdemDesiner,
}

export enum DataActionDecorate {
  dadeDecorate,
  dadeBase,
  dadeError,
  dateHighLight,
  dadeContent,
  dadeContentSuppress,
  dadeTransparent,
  dadeTransparentBold,
}

export enum FrgtGridLinesStyle {
  glsNone,
  glsFirst,
  glsLast,
  glsAll,
}

export enum TAlignment {
  taLeftJustify,
  taRightJustify,
  taCenter,
}

export enum TUFCharCountMultiplierKind {
  ccmkZero,
  ccmk2Em3,
  ccmkRowHeight,
  ccmkEm,
}

export enum TUFContextDisplayStyle {
  cdsDefault,
  cdsText,
  cdsImage,
  cdsTextAboveImage,
}

/// <summary>
///   Umisteni ikony ve vizualnim fragmentu
/// </summary>
export enum IconPosition {
  ipLeft,
  ipTop,
  ipRight,
  ipBottom,
  ipCenter,
}

export enum HorizontalAlignment {
  fhaLeft,
  fhaCenter,
  fhaRight,
}
export enum VerticalAlignment {
  fvaTop,
  fvaCenter,
  fvaBottom,
}

export enum RectInDockSizeMode {
  ridsmOriginal, //OriginSize
  ridsmPercent, //Procento z InRect
  ridsmAnchorWidth, //Sirka anchored jako anchor (pokud neni anchored pak jde o sirku zarovnavaneho), vyska OriginSize
  ridsmAnchorWidthMinOriginal, //Sirka anchored jako maximum z anchor a OriginSize (pokud neni anchored pak jde o sirku zarovnavaneho), vyska OriginSize
}

export enum RectInDockPositionMode {
  ridpmCenter, //Na stred v InRect
  ridpmAchorCenter, //Zarovnany na stred anchoru
  ridpmAnchorOutside, //Zarovnany X: na pravou/stred/levou hranu anchoru (podle umisteni anchoru), Y: na dolni hranu anchoru
  ridpmAnchorOverAtLeft, //Zarovnany X: anchored.left  na levou hranu anchoru , Y: top anchoru na top anchored (pokud neni anchored pak jde o zarovnavany control)
  ridpmAnchorOverAtRight, //Zarovnany X: anchored.right na pravou hranu anchoru, Y: top anchoru na top anchored (pokud neni anchored pak jde o zarovnavany control)
}

export enum FrameStyle {
  frsTitle,
  frsTitleResize,
  frsNone,
  frsFrame,
  frsFrameResize,
}

export enum TOpenOption {
  ofReadOnly,
  ofOverwritePrompt,
  ofHideReadOnly,
  ofNoChangeDir,
  ofShowHelp,
  ofNoValidate,
  ofAllowMultiSelect,
  ofExtensionDifferent,
  ofPathMustExist,
  ofFileMustExist,
  ofCreatePrompt,
  ofShareAware,
  ofNoReadOnlyReturn,
  ofNoTestFileCreate,
  ofNoNetworkButton,
  ofNoLongNames,
  ofOldStyleDialog,
  ofNoDereferenceLinks,
  ofEnableIncludeNotify,
  ofEnableSizing,
  ofDontAddToRecent,
  ofForceShowHidden,
}

export enum TFormattableNoSpecifiedFormat {
  fnsfAutoDetect,
  fnsfText,
  fnsfHtml,
}

export enum TDataTextFormat {
  dtfText,
  dtfHTML,
}

/// <summary>
///   Rezim viceradkoveho editu
/// </summary>
export enum TInputMultiLineMode {
  /// <summary>
  ///   Standardni, jednoradkovy
  /// </summary>
  imlmSingleLine,

  /// <summary>
  ///   Viceradkovy text, zjednoduseny = wordwrap, bez posuvniku, Enter skace na dalsi komponentu
  /// </summary>
  imlmMultiLineSimple,

  /// <summary>
  ///   Viceradkovy text plny - posuvnik, Enter dela zalomeni radku
  /// </summary>
  imlmMultiLineFull,
}

export enum TNclInputType {
  nitUndefined,
  nitString,
  nitNumeric,
  nitDate,
  nitTime,
}

/// <summary>
///   Rezim zapisu hodnoty z komponenty do presenteru
/// </summary>
export enum TAcceptMode {
  /// <summary>
  ///   Explicitni - vynucene (ztrata focusu, enter, atd...)
  /// </summary>
  amExplicit,

  /// <summary>
  ///   Okamzite - po kazde zmene hodnoty v komponente
  /// </summary>
  amImmediate,

  /// <summary>
  ///   Opozdene - automaticky zapis po zmene hodnoty, ale se zpozdenim
  /// </summary>
  amDelayed,
}

export enum TClientFileActionType {
  catUnknown,
  catOpen,
  catPrint,
  catDownload,
}

export enum TUFActionDisplayStyle {
  ufadsButtonTile,
  ufadsButtonLine,
  ufadsButtonSmall,
}

export enum TTreeDataHasChildNodes {
  chnYes,
  chnNo,
  chnOnDemand,
}

export enum TDataItemModifyType {
  tdimModify = 0,
  tdimNew = 1,
  tdimDelete = 2,
}

/// <summary>
///   typ presunu uzlu stromu
/// </summary>
export enum TTreeDataMoveNodeMode {
  tdmnOnNode,
  tdmnBeforeNode,
  tdmnAfterNode,
}

//#endregion "enums"

//#region "other types and interfaces"
export type GlyphId = string;

export type SignData = string;

export interface LoginInfo {
  Delay: number;
  TwoFactorAuthentification: boolean;
}

export interface CSClientData extends K2JSONObject {}

export interface CSClientUrl extends CSClientData {
  Url: string;
}

export interface CSClientFile extends CSClientData {
  FileName: string;
  Action: TClientFileActionType;
  UseCache: boolean;
  ErrorMessage: string;
  Url: string; // change data to url on IIS
}

export interface CSFile extends CSClientData {
  FileName: string;
  Data: string;
}

export interface CSClientSound extends CSClientFile {}

export interface OutData {
  OutData: Array<CSClientData>;
}

export interface Function {
  Name: string;
  Args?: any;
}

export interface Bounds {
  Align: Align;
  FromBandIndex: number;
  PartIndex: number;
  BandsCount: number;
  InteriorBandsCount: number;
}

export interface RectInDock extends K2JSONObject {
  SizeMode: RectInDockSizeMode;
  PositionMode: RectInDockPositionMode;
  FrameStyle: FrameStyle;
  Width: number;
  Height: number;
  AnchorControlUID: string;
  AnchoredControlName: string;
  MouseClickClose: boolean;
}

export class DataRequest {
  public RealizerUID: string;
  public Data: Array<ControlDataRequest>;
  public ActiveControl: string;
  public ScreenSize: number;
  public TransformColumnsCount: number;

  constructor(realizerUID: string) {
    this.RealizerUID = realizerUID;
    this.Data = new Array<ControlDataRequest>();
  }
}

export interface MultiMessage {
  Operation: number;
  RealizerUID: string;
  RealizeCounter: number;
  JSon: string;
}

export interface UserInfo {
  Lng: number;
  NoopTimeout: number;
}

export interface CSAggrData {
  Text: String;
  Align: TAlignment;
  GlyphId: GlyphId;
}

export interface CondFormattingStyle {
  Options: TCondFormattingOption;
  BackColor: number;
  ForegroundColor: number;
}

//#endregion "other types and interfaces"

//interface objektu ktere klient posila na server jako update

//#region "Control Request"

export interface ControlDataRequest {
  ControlUID: string;
  Functions?: Array<Function>;
  Commands?: Array<Function>;
}

export interface InputDataRequest extends ControlDataRequest {
  Text: string;
}

export interface CheckBoxDataRequest extends ControlDataRequest {
  Checked: boolean;
}
export interface InnerColumnsListDataRequest extends ControlDataRequest {
  WidthColumns?: Array<Array<number>>;
  ColumnsMove?: Array<Array<number>>;
}
export interface InnerDataGridDataRequest extends InnerColumnsListDataRequest {
  MaxRowCount: number;
  Position: number;
  CurrentColumn: number;
}

export interface DataGridDataRequest extends ControlDataRequest {
  MaxRowCount: number;
}

export interface TabControlDataRequest extends ControlDataRequest {
  CurrentPage: string;
}

export interface ExpanderDataRequest extends ControlDataRequest {
  Collapsed: boolean;
}

export interface SplitterDataRequest extends ControlDataRequest {
  Ratio: number;
  Collapsed: boolean;
}

export interface ListViewDataRequest extends ControlDataRequest {
  Position: number;
}

export interface RibbonDataRequest extends ControlDataRequest {
  CurrentToolBarIndex: number;
}

export interface OpenDialogDataRequest extends ControlDataRequest {
  Files: Array<CSFile>;
}

export interface SignInputDataRequest extends ControlDataRequest {
  SignData: SignData;
}

export interface TreeViewDataRequest extends ControlDataRequest {
  CurrentBookmark: string;
}

//#endregion

//#region "Interface objektu ktere klient ziskava ze serveru pri update"

export interface CSUpdateControl {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  RealizerUID: string;
}

export const UpdateControlRecord = (defaultValues: any) =>
  class extends Record({
    ControlUID: "",
    Visible: false,
    Enabled: undefined,
    TabStop: false,
    RealizerUID: "",
    ...defaultValues,
  }) {};

export class UpdateControl extends UpdateControlRecord({}) implements CSUpdateControl {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  RealizerUID: string;

  constructor(props: Partial<CSUpdateControl>) {
    super(props);
  }

  public with(value: Partial<CSUpdateControl>): UpdateControl {
    return this.merge(value) as this;
  }
}

export interface CSUFUpdateControl extends CSUpdateControl {
  Title: string;
  GlyphId: GlyphId;
}

export const UFUpdateControlRecord = (defaultValues: any) =>
  class extends UpdateControlRecord({
    Title: "",
    GlyphId: "",
    ...defaultValues,
  }) {};

export class UFUpdateControl extends UFUpdateControlRecord({}) implements CSUFUpdateControl {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;

  constructor(props: Partial<CSUFUpdateControl>) {
    super(props);
  }

  public with(value: Partial<CSUFUpdateControl>): UFUpdateControl {
    return this.merge(value) as this;
  }
}

interface CSUpdatePrefixSufix extends CSUFUpdateControl {
  PrefixDeny: boolean;
  PrefixGlyphId: GlyphId;
  PrefixText: string;
  SuffixDeny: boolean;
  SuffixGlyphId: GlyphId;
  SuffixText: string;
}

const UpdatePrefixSufixRecord = (defaultValues: any) =>
  UFUpdateControlRecord({
    PrefixDeny: false,
    PrefixGlyphId: "",
    PrefixText: "",
    SuffixDeny: false,
    SuffixGlyphId: "",
    SuffixText: "",
    ...defaultValues,
  });

export interface CSUpdateInput extends CSUpdatePrefixSufix {
  Text: string;
  ReadOnly: boolean;
  Watermark: string;
  TextDeny: boolean;
  Modified: boolean;
  AcceptMode: TAcceptMode;
  AutoSelect: boolean;
  Hint: string;
  IsUpperCase: boolean;
  MultiLineMode: TInputMultiLineMode;
  MaxLength: number;
  Format: string;
  ErrorsCount: number;
  WarningsCount: number;
  Formatting: CondFormattingStyle;
}

const UpdateInputRecord = UpdatePrefixSufixRecord({
  Text: "",
  ReadOnly: false,
  Watermark: "",
  TextDeny: false,
  Modified: false,
  AcceptMode: TAcceptMode.amExplicit,
  AutoSelect: false,
  Hint: "",
  IsUpperCase: false,
  MultiLineMode: TInputMultiLineMode.imlmSingleLine,
  MaxLength: 0,
  Format: "",
  ErrorsCount: 0,
  WarningsCount: 0,
  Formatting: undefined,
});

export class UpdateInput extends UpdateInputRecord implements CSUpdateInput {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Modified: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  Text: string;
  ReadOnly: boolean;
  Watermark: string;
  TextDeny: boolean;
  PrefixDeny: boolean;
  PrefixGlyphId: GlyphId;
  PrefixText: string;
  SuffixDeny: boolean;
  SuffixGlyphId: GlyphId;
  SuffixText: string;
  AcceptMode: TAcceptMode;
  AutoSelect: boolean;
  Hint: string;
  IsUpperCase: boolean;
  MultiLineMode: TInputMultiLineMode;
  MaxLength: number;
  Format: string;
  ErrorsCount: number;
  WarningsCount: number;
  Formatting: CondFormattingStyle;

  get FormattingStyle(): CondFormattingStyle {
    let f = this.get("Formatting");
    if (f && f.toJS) {
      return f.toJS() as CondFormattingStyle;
    }

    return undefined;
  }

  constructor(props: Partial<CSUpdateInput>) {
    super(props);
  }

  public with(value: Partial<CSUpdateInput>): UpdateInput {
    return this.merge(value) as this;
  }
}

export interface CSUpdateInputFramed extends CSUFUpdateControl {
  Text: string;
  ReadOnly: boolean;
}

const UpdateInputFramedRecord = (defaultValues: any) =>
  class extends UFUpdateControlRecord({
    Text: "",
    ReadOnly: false,
    ...defaultValues,
  }) {};

export class UpdateInputFramed extends UpdateInputFramedRecord({}) implements CSUpdateInputFramed {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Modified: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  Text: string;
  ReadOnly: boolean;

  constructor(props: Partial<CSUpdateInputFramed>) {
    super(props);
  }

  public with(value: Partial<CSUpdateInputFramed>): UpdateInputFramed {
    return this.merge(value) as this;
  }
}

export interface CSUpdateFormattableInput extends CSUpdateInputFramed {
  Language: number;
  TextFormat: TDataTextFormat;
}

const UpdateFormattableInputRecord = UpdateInputFramedRecord({
  Language: 0,
  TextFormat: TDataTextFormat.dtfText,
});

export class UpdateFormattableInput extends UpdateFormattableInputRecord implements CSUpdateFormattableInput {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Modified: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  Text: string;
  ReadOnly: boolean;
  Language: number;
  TextFormat: TDataTextFormat;

  constructor(props: Partial<CSUpdateFormattableInput>) {
    super(props);
  }

  public with(value: Partial<CSUpdateFormattableInput>): UpdateFormattableInput {
    return this.merge(value) as this;
  }
}

export interface CSUpdateCommandItem extends CSUFUpdateControl {
  Decorate: DataActionDecorate;
  Checked: boolean;
  SubMenuIndicatorVisible: boolean;
}

const UpdateCommandItemRecord = UFUpdateControlRecord({
  Decorate: DataActionDecorate.dadeBase,
  Checked: false,
  SubMenuIndicatorVisible: false,
});

export class UpdateCommandItem extends UpdateCommandItemRecord implements CSUpdateCommandItem {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  Checked: boolean;
  SubMenuIndicatorVisible: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  Decorate: DataActionDecorate;

  constructor(props: Partial<CSUpdateCommandItem>) {
    super(props);
  }

  public with(value: Partial<CSUpdateCommandItem>): UpdateCommandItem {
    return this.merge(value) as this;
  }
}

export interface CSUpdateCheckBox extends CSUFUpdateControl {
  Checked: boolean;
  ReadOnly: boolean;
  ValueDeny: boolean;
  ErrorsCount: number;
  WarningsCount: number;
}

const UpdateCheckBoxRecord = UFUpdateControlRecord({
  Checked: false,
  ReadOnly: false,
  ValueDeny: false,
  ErrorsCount: 0,
  WarningsCount: 0,
});

export class UpdateCheckBox extends UpdateCheckBoxRecord implements CSUpdateCheckBox {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  Checked: boolean;
  ReadOnly: boolean;
  ValueDeny: boolean;
  ErrorsCount: number;
  WarningsCount: number;

  constructor(props: Partial<CSUpdateCheckBox>) {
    super(props);
  }

  public with(value: Partial<CSUpdateCheckBox>): UpdateCheckBox {
    return this.merge(value) as this;
  }
}

export interface CSUpdateDataLabel extends CSUpdatePrefixSufix {
  Text: string;
  Hint: string;
  TextDeny: boolean;
  Watermark: string;
}

const UpdateDataLabelRecord = UpdatePrefixSufixRecord({
  Text: "",
  Hint: "",
  TextDeny: false,
  Watermark: "",
});

export class UpdateDataLabel extends UpdateDataLabelRecord implements CSUpdateDataLabel {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  PrefixDeny: boolean;
  PrefixGlyphId: GlyphId;
  PrefixText: string;
  SuffixDeny: boolean;
  SuffixGlyphId: GlyphId;
  SuffixText: string;
  Text: string;
  Hint: string;
  TextDeny: boolean;
  Watermark: string;

  constructor(props: Partial<CSUpdateDataLabel>) {
    super(props);
  }

  public with(value: Partial<CSUpdateDataLabel>): UpdateDataLabel {
    return this.merge(value) as this;
  }
}

export interface ColumnProportionEm {
  Caption: string;
  Adaptable: boolean;
  Size: number;
  Alignment: TAlignment;
  MultiplierKind: TUFCharCountMultiplierKind;
  DisplayStyleRT: TUFContextDisplayStyle;
  Width?: number;
}

export interface ColumnsProportion extends Array<ColumnProportionEm> {}

export interface OrderByItem {
  ColNdx: number;
  OrderByNdx: number;
}

export interface CSUpdateInnerColumnsList extends CSUpdateControl {
  AutoAdjustWidth: boolean;
  FixedColumnsCount: number;
  ColumnsProportion: ColumnsProportion;
}

export const UpdateInnerColumnsListRecord = (defaultValues: any) =>
  class extends UpdateControlRecord({
    AutoAdjustWidth: false,
    FixedColumnsCount: 0,
    ColumnsProportion: List<Iterable.Keyed<string, any>>(),
    ...defaultValues,
  }) {
    public IsEqualsColumnsProportion(value: UpdateInnerDataGrid | UpdateInnerTreeView): boolean {
      return value.get("ColumnsProportion") == this.get("ColumnsProportion"); // protoze se plain JS objekt vztvari pro kazdou instanci tak se porovnavaji instance List.
    }

    private columnsProportion: ColumnsProportion;

    public get ColumnsProportion(): ColumnsProportion {
      //Nechci pristupovat navenek k vlastnostem pres get(name) tak z List delam plain JS objekt
      if (!this.columnsProportion) this.columnsProportion = this.get("ColumnsProportion").toJS();

      return this.columnsProportion;
    }
  };

export interface CSColumnBase {
  IsModified: boolean;
  HasError: boolean;
  IsCancelled: boolean;
  IsValidValue: boolean;
  IsSelectedText: boolean;
  CondFormatting: CondFormattingStyle;
  CanEdit: boolean;
}

export interface CSColumn extends CSColumnBase {
  Text: string;
  GlyphId: string;
}

export interface CSDataItem {
  Row: number;
  Columns: Array<CSColumn>;
}

export interface ModifyItem {
  Key: string;
  ModifyType: TDataItemModifyType;
  Changes: CSDataItem;
}

export interface CSUpdateInnerDataGrid extends CSUpdateInnerColumnsList {
  Position: number;
  Data?: Array<ModifyItem>;
  OrderBy: List<OrderByItem>;
  CurrentColumn: number;
  CanEditItems: boolean;
  DataVersion: number;
  DataSource: List<List<CSColumn>>;
}

export const UpdateInnerDataGridRecord = class extends UpdateInnerColumnsListRecord({
  OrderBy: List<OrderByItem>(),
  CurrentColumn: -1,
  Position: -1,
  CanEditItems: false,
  DataSource: List<List<CSColumn>>(),
  DataVersion: -1,
}) {};

export class UpdateInnerDataGrid extends UpdateInnerDataGridRecord implements CSUpdateInnerDataGrid {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  AutoAdjustWidth: boolean;
  FixedColumnsCount: number;
  OrderBy: List<OrderByItem>;
  CanEditItems: boolean;
  DataSource: List<List<CSColumn>>;
  CurrentColumn: number;
  Position: number;
  DataVersion: number;

  constructor(props: Partial<CSUpdateInnerDataGrid>) {
    super(props);
  }

  public with(value: Partial<CSUpdateInnerDataGrid>): UpdateInnerDataGrid {
    return this.merge(value) as this;
  }
}

export interface CSUpdateTabControl extends CSUFUpdateControl {
  CurrentPage: string;
}

const UpdateTabControlRecord = (defaultValues: any) =>
  class extends UFUpdateControlRecord({
    CurrentPage: "",
    ...defaultValues,
  }) {};

export function reCalculateColumnWidths<T extends UpdateInnerDataGrid | UpdateInnerTreeView>(
  __this: T,
  forWidth: number,
  autoAdjustWidth: boolean,
  vcx: VisualContext
): T {
  let adaptableWidth: number = 0;
  let occupyWidth: number = 0;
  let lW: number = 0;
  let columns: ColumnsProportion = [];

  if (__this.ColumnsProportion && __this.ColumnsProportion.length > 0) {
    __this.ColumnsProportion.map((value: ColumnProportionEm) => {
      lW = GetColumnWidth(value, vcx.GridControl);
      occupyWidth += lW;
      if (value.Adaptable) {
        adaptableWidth += lW;
      }
      columns.push(Object.assign({}, value, { Width: lW }));
    });
  }

  if (autoAdjustWidth && forWidth > 0) {
    let fWidth: number = forWidth - __this.ColumnsProportion.length * vcx.GridControl.LineWidth;
    let adaptPix: number = 0;
    let lastNdx: number = 0;
    let maxAdaptW: number = 0;
    if (fWidth != occupyWidth && adaptableWidth > 0) {
      adaptPix = 1 + (fWidth - occupyWidth) / adaptableWidth;
      occupyWidth = 0;
      lastNdx = -1;
      maxAdaptW = 0;

      columns.map((value: ColumnProportionEm, index: number) => {
        if (value.Adaptable) {
          value.Width = Math.max(Math.round(value.Width * adaptPix), vcx.GridControl.GetExteriorCellWidth(vcx.GridControl.GetRowHeight(1)));
          if (value.Width > maxAdaptW) {
            lastNdx = index;
            maxAdaptW = value.Width;
          }
        }
        occupyWidth += value.Width;
      });

      occupyWidth += 2;
      if (lastNdx > 0) {
        columns[lastNdx].Width = Math.max(
          columns[lastNdx].Width + (forWidth - occupyWidth),
          vcx.GridControl.GetExteriorCellWidth(vcx.GridControl.GetRowHeight(1))
        );
      }
    }
  }

  return __this.with({ ColumnsProportion: columns }) as T;
}

function GetColumnWidth(value: ColumnProportionEm, vcx: VCXGrid): number {
  let result: number = 0;
  switch (value.MultiplierKind) {
    case TUFCharCountMultiplierKind.ccmk2Em3:
      result = vcx.GetExteriorCellWidth(Math.round(value.Size * vcx.Font._MWidth * (2 / 3)));
      break;
    case TUFCharCountMultiplierKind.ccmkRowHeight:
      result = Math.round(value.Size * vcx.GetRowHeight(value.Size));
      break;
    case TUFCharCountMultiplierKind.ccmkZero:
      result = vcx.GetExteriorCellWidth(Math.round(value.Size * vcx.Font._0Width));
      break;
    case TUFCharCountMultiplierKind.ccmkEm:
    default:
      result = vcx.GetExteriorCellWidth(Math.round(value.Size * vcx.Font._MWidth));
  }

  result = Math.max(result, vcx.Font.computeTextWidth(value.Caption));

  if (result <= 0) {
    result = Math.max(Math.floor(vcx.GetRowHeight(value.Size) / 2), 4);
  }

  return result;
}

export function GetColumnWidthEm(valuePx: number, vcx: VCXGrid): number {
  return (valuePx - vcx.GetExteriorCellWidth(0)) / vcx.Font._MWidth;
}

export class UpdateTabControl extends UpdateTabControlRecord({}) implements CSUpdateTabControl {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  CurrentPage: string;

  constructor(props: Partial<CSUpdateTabControl>) {
    super(props);
  }

  public with(value: Partial<CSUpdateTabControl>): UpdateTabControl {
    return this.merge(value) as this;
  }
}

export interface CSUpdateVRTabControl extends CSUpdateTabControl {
  AddedTabs: List<List<string>>;
  RemovedTabs: List<string>;
}

const UpdateVRTabControlRecord = UpdateTabControlRecord({
  AddedTabs: List<List<string>>(),
  RemovedTabs: List<string>(),
});

export class UpdateVRTabControl extends UpdateVRTabControlRecord implements CSUpdateVRTabControl {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  CurrentPage: string;
  AddedTabs: List<List<string>>;
  RemovedTabs: List<string>;

  constructor(props: Partial<CSUpdateVRTabControl>) {
    super(props);
  }

  public with(value: Partial<CSUpdateVRTabControl>): UpdateVRTabControl {
    return this.merge(value) as this;
  }
}

export interface CSUpdatePageControl extends CSUFUpdateControl {
  Hide: boolean;
}

const UpdatePageControlRecord = (defaultValues: any) =>
  class extends UFUpdateControlRecord({
    Hide: false,
    ...defaultValues,
  }) {};

export class UpdatePageControl extends UpdatePageControlRecord({}) implements CSUpdatePageControl {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  Hide: boolean;

  constructor(props: Partial<CSUpdateTabControl>) {
    super(props);
  }

  public with(value: Partial<CSUpdatePageControl>): UpdatePageControl {
    return this.merge(value) as this;
  }
}

export interface CSUpdateHeadered extends CSUFUpdateControl {
  CollapsedTitle: string;
  TitleSuffix: string;
  TitleSuffixCommandEnabled: boolean;
}

const UpdateHeaderedRecord = (defaultValues: any) =>
  class extends UFUpdateControlRecord({
    CollapsedTitle: "",
    TitleSuffix: "",
    TitleSuffixCommandEnabled: false,
    ...defaultValues,
  }) {};

export class UpdateHeadered extends UpdateHeaderedRecord({}) implements CSUpdateHeadered {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  TitleSuffix: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  CollapsedTitle: string;
  TitleSuffixCommandEnabled: boolean;

  constructor(props: Partial<CSUpdateHeadered>) {
    super(props);
  }

  public with(value: Partial<CSUpdateHeadered>): UpdateHeadered {
    return this.merge(value) as this;
  }
}

export interface CSUpdateDataGrid extends CSUpdateHeadered {
  MarkedCount: number;
  RecordsCount: number;
  InLineEditActive: boolean;
}

export const UpdateDataGridRecord = class extends UpdateHeaderedRecord({
  MarkedCount: 0,
  RecordsCount: 0,
  InLineEditActive: false,
}) {};

export class UpdateDataGrid extends UpdateDataGridRecord implements CSUpdateDataGrid {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  RecordsCount: number;
  MarkedCount: number;
  CollapsedTitle: string;
  TitleSuffix: string;
  InLineEditActive: boolean;
  TitleSuffixCommandEnabled: boolean;

  constructor(props: Partial<CSUpdateDataGrid>) {
    super(props);
  }

  public with(value: Partial<CSUpdateDataGrid>): UpdateDataGrid {
    return this.merge(value) as this;
  }
}

export interface CSUpdateExpander extends CSUpdateHeadered {
  Collapsed: boolean;
}

const UpdateExpanderRecord = UpdateHeaderedRecord({
  Collapsed: false,
});

export class UpdateExpander extends UpdateExpanderRecord implements CSUpdateExpander {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  TitleSuffix: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  Collapsed: boolean;
  CollapsedTitle: string;
  TitleSuffixCommandEnabled: boolean;

  constructor(props: Partial<CSUpdateExpander>) {
    super(props);
  }

  public with(value: Partial<CSUpdateExpander>): UpdateExpander {
    return this.merge(value) as this;
  }
}

export interface CSUpdateImage extends CSUFUpdateControl {
  Stretch: boolean;
  ObjectId: string;
  ImageDeny: boolean;
}

const UpdateImageRecord = UFUpdateControlRecord({
  Stretch: false,
  ObjectId: "",
  ImageDeny: false,
});

export class UpdateImage extends UpdateImageRecord implements CSUpdateImage {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  Stretch: boolean;
  ObjectId: string;
  ImageDeny: boolean;

  constructor(props: Partial<CSUpdateImage>) {
    super(props);
  }

  public with(value: Partial<CSUpdateImage>): UpdateImage {
    return this.merge(value) as this;
  }
}

export interface CSUpdateSplitterPanel extends CSUFUpdateControl {
  Ratio: number;
  Collapsed: boolean;
}

const UpdateSplitPanelRecord = UFUpdateControlRecord({
  Ratio: 0,
  Collapsed: false,
});

export class UpdateSplitterPanel extends UpdateSplitPanelRecord implements CSUpdateSplitterPanel {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  Ratio: number;
  Collapsed: boolean;

  constructor(props: Partial<CSUpdateSplitterPanel>) {
    super(props);
  }

  public with(value: Partial<CSUpdateSplitterPanel>): UpdateSplitterPanel {
    return this.merge(value) as this;
  }
}

export interface CSUpdatePageHolder extends CSUFUpdateControl {
  DockRealizerUID: string;
}

const UpdatePageHolderRecord = UFUpdateControlRecord({
  DockRealizerUID: "",
});

export class UpdateDockControl extends UpdatePageHolderRecord implements CSUpdatePageHolder {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  DockRealizerUID: string;

  constructor(props: Partial<UpdateDockControl>) {
    super(props);
  }

  public with(value: Partial<UpdateDockControl>): UpdateDockControl {
    return this.merge(value) as this;
  }
}

export interface CSUpdateDynamicContent extends CSUFUpdateControl {
  Structure: CSUFNclControlMetadata;
  DynamicContentId: string;
}

const UpdateDynamicContentRecord = UFUpdateControlRecord({
  Structure: null,
  DynamicContentId: null,
});

export class UpdateDynamicContent extends UpdateDynamicContentRecord implements CSUpdateDynamicContent {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  Structure: CSUFNclControlMetadata;
  DynamicContentId: string;

  constructor(props: Partial<CSUpdateDynamicContent>) {
    super(props);
  }

  public with(value: Partial<CSUpdateDynamicContent>): UpdateDynamicContent {
    return this.merge(value) as this;
  }
}

export interface CSUpdateMenuContainer extends CSUFUpdateControl {
  isOpen: boolean;
}

const UpdateMenuContainerRecord = (defaultValues: any) =>
  class extends UpdateControlRecord({
    isOpen: false,
    ...defaultValues,
  }) {};

export class UpdateMenuContainer extends UpdateMenuContainerRecord({}) implements CSUpdateMenuContainer {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  isOpen: boolean;

  constructor(props: Partial<CSUpdateMenuContainer>) {
    super(props);
  }

  public with(value: Partial<CSUpdateMenuContainer>): UpdateMenuContainer {
    return this.merge(value) as this;
  }
}

export interface CSUpdateSignInput extends CSUFUpdateControl {
  SignData: SignData;
}

const UpdateSignInputRecord = (defaultValues: any) =>
  class extends UpdateControlRecord({
    SignData: "",
    ...defaultValues,
  }) {};

export class UpdateSignInput extends UpdateSignInputRecord({}) implements CSUpdateSignInput {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  SignData: "";

  constructor(props: Partial<CSUpdateSignInput>) {
    super(props);
  }

  public with(value: Partial<CSUpdateSignInput>): UpdateSignInput {
    return this.merge(value) as this;
  }
}

export interface Updatable {
  collectData(collector: Array<ControlDataRequest>): void;
  updateState(update: CSUFUpdateControl): void;
  resetToState(): void;
}

export interface UpdateBase {
  RealizerUID: string;
}

export interface CSUpdateData extends UpdateBase {
  Data: Array<CSUFUpdateControl>;
  ActiveControl: string;
  VCX: VisualContextMetadata;
  VCXSetToDef: boolean;
  HotKeys: string[];
  IsVCXAsInActive: boolean;
  CompanyColor: number;
}

export interface CSUpdateListView extends CSUFUpdateControl {
  Position: number;
  GroupView: boolean;
  ViewStyleAsInt: number;
  ListViewGroups: Array<CSUFNclListViewGroupMetadata>;
}

const UpdateListViewRecord = UFUpdateControlRecord({
  Position: 0,
  GroupView: true,
  ViewStyleAsInt: 0,
  ListViewGroups: List<CSUFNclListViewGroupMetadata>(),
});

export class UpdateListView extends UpdateListViewRecord implements CSUpdateListView {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;

  Position: number;
  GroupView: boolean;
  ViewStyleAsInt: number;
  ListViewGroups: Array<CSUFNclListViewGroupMetadata>;

  constructor(props: Partial<CSUpdateListView>) {
    super(props);
  }

  public with(value: Partial<CSUpdateListView>): UpdateListView {
    return this.merge(value) as this;
  }
}

export interface ModifyTreeItem {
  Key: string;
  ModifyType: TDataItemModifyType;
  Data: CSTreeDataItem;
}

export interface CSTreeDataItem {
  ParentKey: string;
  Values: Array<string>;
  HasChildren: TTreeDataHasChildNodes;
  Expanded: boolean;
  Index: number;
}

const TreeDataItemRecord = class extends Record({
  ParentKey: undefined,
  Values: undefined,
  HasChildren: undefined,
  Expanded: undefined,
  Index: undefined,
}) {};

export class TreeDataItem extends TreeDataItemRecord implements CSTreeDataItem {
  ParentKey: string;
  Values: string[];
  HasChildren: TTreeDataHasChildNodes;
  Expanded: boolean;
  Index: number;

  constructor(props: Partial<CSTreeDataItem>) {
    super(props);
  }

  public with(value: Partial<CSTreeDataItem>): CSTreeDataItem {
    return this.merge(value) as this;
  }
}

export interface CSUpdateInnerTreeView extends CSUpdateInnerColumnsList {
  Data: Array<ModifyTreeItem>;
  CurrentBookmark: string;
  IsDraggable: boolean;
  DeniedText: string;
  DataVersion: number;
}

const UpdateInnerTreeViewRecord = class extends UpdateInnerColumnsListRecord({
  Data: List<ModifyTreeItem>(),
  CurrentBookmark: undefined,
  IsDraggable: false,
  DeniedText: undefined,
  DataVersion: -1,
}) {};

export class UpdateInnerTreeView extends UpdateInnerTreeViewRecord implements CSUpdateInnerColumnsList {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  AutoAdjustWidth: boolean;
  FixedColumnsCount: number;
  Data: List<ModifyTreeItem>;
  CurrentBookmark: string;
  IsDraggable: boolean;
  DeniedText: string;
  DataVersion: number;

  constructor(props: Partial<CSUpdateInnerDataGrid>) {
    super(props);
  }

  public with(value: Partial<CSUpdateInnerTreeView>): UpdateInnerTreeView {
    return this.merge(value) as this;
  }
}

interface TreeViewColumn {
  Caption: string;
  Width: number;
}

export interface CSUpdateRibbon extends CSUFUpdateControl {
  CurrentToolBarIndex: number;
  HideToolBars: boolean;
  ToolBars: List<CSNclInnerToolBarMetadata>;
}

const UpdateRibbonRecord = UFUpdateControlRecord({
  CurrentToolBarIndex: 0,
  HideToolBars: false,
  ToolBars: undefined,
});

export class UpdateRibbon extends UpdateRibbonRecord implements CSUpdateRibbon {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;

  CurrentToolBarIndex: number;
  HideToolBars: boolean;
  ToolBars: List<CSNclInnerToolBarMetadata>;

  constructor(props: Partial<CSUpdateRibbon>) {
    super(props);
  }

  public with(value: Partial<CSUpdateRibbon>): UpdateRibbon {
    return this.merge(value) as this;
  }
}

export interface CSUpdateInnerToolbar extends CSUpdateControl {
  VisibleAccessor: boolean;
}

const UpdateInnerToolbarRecord = UpdateControlRecord({
  VisibleAccessor: false,
});

export class UpdateInnerToolbar extends UpdateInnerToolbarRecord implements CSUpdateInnerToolbar {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;

  VisibleAccessor: boolean;

  constructor(props: Partial<CSUpdateInnerToolbar>) {
    super(props);
  }

  public with(value: Partial<CSUpdateInnerToolbar>): UpdateInnerToolbar {
    return this.merge(value) as this;
  }
}

export interface CSUpdateQuickFilter extends CSUpdateControl {
  Controls: List<CSUFNclControlMetadata>;
}

const UpdateQuickFilterRecord = UpdateControlRecord({
  Controls: null,
});

export class UpdateQuickFilter extends UpdateQuickFilterRecord implements CSUpdateQuickFilter {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  Controls: List<CSUFNclControlMetadata>;

  constructor(props: Partial<CSUpdateQuickFilter>) {
    super(props);
  }

  public with(value: Partial<CSUpdateQuickFilter>): UpdateQuickFilter {
    return this.merge(value) as this;
  }
}

export interface CSUpdateAggregationPanel extends CSUpdateControl {
  Data: List<CSAggrData>;
  InvalidData: Boolean;
}

const UpdateAggregationPanelRecord = UpdateControlRecord({
  Data: null,
  InvalidData: false,
});

export class UpdateAggregationPanel extends UpdateAggregationPanelRecord implements CSUpdateAggregationPanel {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  Data: List<CSAggrData>;
  InvalidData: Boolean;

  constructor(props: Partial<CSUpdateQuickFilter>) {
    super(props);
  }

  public with(value: Partial<CSUpdateAggregationPanel>): UpdateAggregationPanel {
    return this.merge(value) as this;
  }
}

//#endregion

//#region "Interface objektu TFrgtData"
export type K2Boolean = 0 | 1;

export interface FrgtBaseData extends K2JSONObject {
  Icon: GlyphId;
  Size: number;
  HorizontalAlignment: HorizontalAlignment;
  VerticalAlignment: VerticalAlignment;
  ZoomFactor: number;
}

export interface FrgtPanelBaseData extends FrgtBaseData {
  VerticalLines: FrgtGridLinesStyle;
  HorizontalLines: FrgtGridLinesStyle;
  Scroll: K2Boolean;
}

export interface FrgtListViewData extends FrgtBaseData {
  LiteHeader: K2Boolean;
  //HideHeader     : boolean;
  //TabStop        : boolean;
}

export interface FrgtTreeViewData extends FrgtBaseData {}

export interface FrgtExpanderData extends FrgtPanelBaseData {
  LiteHeader: K2Boolean;
}

export interface FrgtFormBaseData extends FrgtPanelBaseData {
  WHRatio: number;
}

export interface FrgtBaseGridData extends FrgtBaseData {
  RowHeightMultiplier: number;
}

export interface FrgtGridData extends FrgtBaseGridData {
  LiteHeader: K2Boolean;
  HideHeader: K2Boolean;
  HideFooter: K2Boolean;
  DefaultCellLink: K2Boolean;
  CellLinkCommand: string;
}

export interface FrgtSimpleGridData extends FrgtBaseGridData {}

export interface FrgtInputData extends FrgtBaseData {
  Watermark: String;
  ShowLabel: K2Boolean;
  ShowFrame: K2Boolean;
  DisableLabelHighlight: K2Boolean;
  TabStop: K2Boolean;
  IsPassword: number;
}

export interface FrgtFormattableInputData extends FrgtInputData {
  ShowToolBar: K2Boolean;
  NoSpecifiedFormat: TFormattableNoSpecifiedFormat;
}

export interface FrgtLabelData extends FrgtBaseData {
  TitleDisplayMode: DisplayMode;
  ValueDisplayMode: DisplayMode;
  Orientation: Orientation;
  BottomLine: K2Boolean;
  ShowColon: K2Boolean;
  StretchImage: K2Boolean;
}

export interface FrgtTabControlData extends FrgtBaseData {
  TabIconPosition: IconPosition;
  AccesorsStretch: K2Boolean;
}

export interface FrgtSplitterPanelData extends FrgtBaseData {
  Orientation: SplitterPanelOrientation;
  InitialRatio: number;
}

export interface FrgtCommandItemData extends FrgtBaseData {
  ShowIcon: K2Boolean;
  ShowCaption: K2Boolean;
  IconPosition: IconPosition;
  TabStop: K2Boolean;
  DisplayStyle: TUFActionDisplayStyle;
}

export interface FrgtButtonData extends FrgtCommandItemData {
  FillWidth: K2Boolean;
  Decorate: DataActionDecorate;
}

export interface FrgtCheckBoxData extends FrgtBaseData {
  TabStop: K2Boolean;
}

export interface FrgtOpenDialogData extends FrgtFormBaseData {
  DefaultExt: string;
  Filter: string;
  InitialDir: string;
  Options: TOpenOption;
}

export interface FrgtRibbonData extends FrgtBaseData {
  TileCaptionLineCount: number;
  ShowMenuButton: K2Boolean;
  ShowF9Button: K2Boolean;
  ShowSectionCaption: K2Boolean;
}

//#endregion

//#region "Interface objektu ktere klient ziskava ze serveru pri get structure"

export interface K2JSONObject {
  readonly __type: string;
}

export interface CSNclControlMetadata extends K2JSONObject {
  ControlUID: string;
  Name: string;
}

export interface CSUFNclControlMetadata extends CSNclControlMetadata {
  Bounds: Bounds;
  FrgtData: FrgtBaseData;
}

export interface CSNclContainerMetadata extends CSUFNclControlMetadata {
  Controls: Array<CSUFNclControlMetadata>;
}

export interface CSNclPanelMetadata extends CSNclContainerMetadata {
  FrgtData: FrgtPanelBaseData;
}

export interface CSUFNclListViewItemMetadata extends CSNclControlMetadata {
  Caption: string;
  GlyphId: string;
  Position: number;
  OverLayIcon: string;
}

export interface CSUFNclListViewGroupMetadata extends CSNclControlMetadata {
  Caption: string;
  ListViewItems: Array<CSUFNclListViewItemMetadata>;
}

export interface CSNclListViewMetadata extends CSUFNclControlMetadata {
  FrgtData: FrgtListViewData;
  //	ListViewGroups:Array<CSUFNclListViewGroupMetadata>;
}

export interface CSNCLTreeViewMetadata extends CSNclHeaderedMetadata {
  FrgtData: FrgtTreeViewData;
  Content: CSNclInnerDataGridMetadata;
  NoDataText: string;
}

export interface CSNclInnerTreeViewMetadata extends CSNclControlMetadata {
  //
}

export interface CSNclDynamicContentMetadata extends CSNclContainerMetadata {}

export interface CSNclHeaderedMetadata extends CSUFNclControlMetadata {
  Header: CSNclHeaderMetaData;
}

export interface CSNclViewMetadata extends CSNclHeaderedMetadata {
  FrgtData: FrgtFormBaseData;
  Content: CSNclPanelMetadata;
}

export interface CSNclFloaterViewMetadata extends CSNclViewMetadata {
  RectInDock: RectInDock;
}

export interface CSNclMenuViewMetadata extends CSNclViewMetadata {
  Menu: CSNclMenuMetadata;
}

export interface CSNclMenuItemBaseMetadata extends CSNclControlMetadata {
  Caption: string;
  GlyphId: GlyphId;
  ExplicitCheck: K2Boolean;
}

export interface CSNclMenuContainerMetadata extends CSNclMenuItemBaseMetadata {
  Actions: Array<CSNclMenuItemBaseMetadata>;
}

export interface CSNclMenuMetadata extends CSNclMenuContainerMetadata {}

export interface CSNclMenuGroupMetadata extends CSNclMenuContainerMetadata {}

export interface CSNclMenuItemMetadata extends CSNclMenuItemBaseMetadata {
  HotKey: string;
}

export interface CSNclMenuDividerMetadata extends CSNclMenuItemBaseMetadata {}

export interface CSNclLibraryReferenceMetadata extends CSNclContainerMetadata {}

export interface CSNclHeaderMetaData extends CSNclControlMetadata {
  ToolBar: CSNclToolBarMetadata;
  RQuickButtons: Array<CSNclCommandItemMetadata>;
  Separator: CSUFNclControlMetadata;
  LocatorPanel: CSNclLocatorPanelMetadata;
  LQuickButton: CSNclCommandItemMetadata;
}

export interface CSNclExpanderMetadata extends CSNclHeaderedMetadata {
  FrgtData: FrgtExpanderData;
  Content: CSNclPanelMetadata;
}

export interface CSNclInnerColumnsListMetadata extends CSNclControlMetadata {
  CanColumnDrag: K2Boolean;
}

export interface CSNclInnerDataGridMetadata extends CSNclInnerColumnsListMetadata {}

export interface CSNclInnerGanttMetadata extends CSNclInnerColumnsListMetadata {
  //
}

export interface CSNclDataGridContent extends CSUFNclControlMetadata {
  DataGrid: CSNclInnerDataGridMetadata;
}

export interface CSNclDataGridFooter extends CSNclControlMetadata {
  LeftToolbar: CSNclToolBarMetadata;
  RightToolbar: CSNclToolBarMetadata;
}

export interface CSNclDataGridBaseMetadata extends CSNclHeaderedMetadata {
  FrgtData: FrgtBaseGridData;
  Content: CSNclDataGridContent;
  Footer: CSNclDataGridFooter;
  QuickFilter: CSNclQuickFilterMetadata;
  AggregationPanel: CSNclAggregationPanel;
}

export interface CSNclDataGridMetadata extends CSNclDataGridBaseMetadata {
  FrgtData: FrgtGridData;
}

export interface CSNclSimpleDataGridMetadata extends CSNclDataGridBaseMetadata {
  FrgtData: FrgtSimpleGridData;
}

export interface CSNclInputMetadata extends CSUFNclControlMetadata {
  FrgtData: FrgtInputData;
  ToolBar: CSNclToolBarMetadata;
  InputType: TNclInputType;
  PrefixDisplayAs: TUFDisplayValueAs;
  SuffixDisplayAs: TUFDisplayValueAs;
}

export interface CSNclInputFramedMetadata extends CSUFNclControlMetadata {
  FrgtData: FrgtInputData;
}

export interface CSNclFormattableInputMetadata extends CSNclInputFramedMetadata {
  FrgtData: FrgtFormattableInputData;
  ToolBar: CSNclToolBarMetadata;
}

export interface CSNclDataLabelMetadata extends CSUFNclControlMetadata {
  FrgtData: FrgtLabelData;
  DisplayAs: TUFDisplayValueAs;
}

export interface CSNclTabControlMetadata extends CSUFNclControlMetadata {
  ListDetailTabControl: K2Boolean;
  FrgtData: FrgtTabControlData;
  Pages: Array<CSNclPageMetadata>;
  Btn: CSNclCommandItemMetadata;
}

export interface CSNclPageMetadata extends CSUFNclControlMetadata {
  PageUID: string;
  IsDynamic: K2Boolean;
  Content: CSUFNclControlMetadata;
}

export interface CSNclVRTabControlMetadata extends CSNclTabControlMetadata {}

export interface CSNclSplitterPanelMetadata extends CSNclContainerMetadata {
  FrgtData: FrgtSplitterPanelData;
}

export interface CSNclButtonMetadata extends CSNclCommandItemMetadata {
  FrgtData: FrgtButtonData;
  BackgroundColorCalc: number;
  ForegroundColorCalc: number;
}

export interface CSNclCommandItemMetadata extends CSUFNclControlMetadata {
  FrgtData: FrgtCommandItemData;
}

export interface CSNclCheckBoxMetaData extends CSUFNclControlMetadata {
  FrgtData: FrgtCheckBoxData;
}

export interface CSNclTabToolBarMetadata extends CSUFNclControlMetadata {
  ToolBars: Array<CSNclToolBarMetadata>;
}

export interface CSNclToolBarMetadata extends CSNclControlMetadata {
  Actions: Array<CSUFNclControlMetadata>;
  MenuBtn: CSNclCommandItemMetadata;
}

export interface CSNclGroupBoxMetaData extends CSNclContainerMetadata {}

export interface CSNclPreviewPanelMetadata extends CSNclContainerMetadata {
  FrgtData: FrgtPanelBaseData;
}

export interface CSNclFloaterAccessorMetadata extends CSNclContainerMetadata {}

export interface CSNclImageMetadata extends CSUFNclControlMetadata {}

export interface CSNclOpenDialogMetadata extends CSNclViewMetadata {
  FrgtData: FrgtOpenDialogData;
  OKBtn: CSNclButtonMetadata;
  CancelBtn: CSNclButtonMetadata;
}

export interface CSNclLocatorPanelMetadata extends CSNclContainerMetadata {
  SwitchBtn: CSNclCommandItemMetadata;
}

export interface CSNclSignInputMetadata extends CSUFNclControlMetadata {}

export interface CSViewRealizerStructure extends UpdateBase {
  Modal: boolean;
  Structure: CSUFNclControlMetadata;
  PriorRealizerUID: string;
  Dock: string;
  Caption: string;
  HotKeys: string[];
  VCX: VisualContextMetadata;
  RectInDock: RectInDock;
  InEditMode: boolean;
}

export interface CSNclInnerSectionMetaData extends CSNclControlMetadata {
  ContentItems: Array<CSUFNclControlMetadata>;
  Caption: string;
  Hint: string;
  GlyphId: GlyphId;
}

export interface CSNclInnerToolBarMetadata extends CSNclControlMetadata {
  Sections: Array<CSNclInnerSectionMetaData>;
  Caption: string;
  Hint: string;
  GlyphId: GlyphId;
}

export interface CSNclRibbonMetadata extends CSUFNclControlMetadata {
  FrgtData: FrgtRibbonData;
  OthersBtn: CSNclCommandItemMetadata;
  TabHideBtn: CSNclCommandItemMetadata;
  ContextToolBarsCount: number;
}

export interface CSNclBreakerMetadata extends CSUFNclControlMetadata {}

export interface CSNclQuickFilterMetadata extends CSNclControlMetadata {}

export interface CSNclAggregationPanel extends CSNclControlMetadata {}

//#endregion

//#region "VCX"

export interface VCXColorMapMetaData extends VCXMetaData {
  Alpha: number;
  InactiveAlphaValue: number;
  InactiveAlphaColor: number;
  AlphaColor: number;
  LumaShift: number;
  Gray: number;

  BaseColorBck1: number;
  BaseColorFrg1: number;

  AccentBaseColorBck: number;
  AccentBaseColorFrg: number;

  ContentColorBck1: number;
  ContentChangeColorBck: number;
  ContentNormalColorFrg: number;
  ContentDecorateColorFrg: number;
  ContentChangeDecorateColorFrg: number;

  DataBrowseColorBck: number;
  DataBrowseColorFrg: number;

  DataChangeColorBck: number;
  DataChangeColorFrg: number;

  DataChangeROColorBck: number;
  DataChangeROColorFrg: number;

  ErrorColorBck: number;
  ErrorColorFrg: number;

  WarningColorBck: number;
  WarningColorFrg: number;

  HighLightColorBck: number;
  HighLightColorFrg: number;

  GridRulerColorBck: number;

  ContentFrameMin: number;
  ContentFrame1: number;
  ContentFrame2: number;
  ContentFrame3: number;

  ContentFrameMinForChange: number;
  ContentFrame1ForChange: number;
  ContentFrame2ForChange: number;
  ContentFrame3ForChange: number;
}

export interface VisualContextMetadata extends K2JSONObject {
  UI: string;
  Caption: string;
  Description: string;

  MarginX: number;
  MarginY: number;
  Zoom: number;

  DecorateMode: DecorateMode;

  SimpleChartControl: VCXSimpleChartControlMetaData;
  ColorMap: VCXColorMapMetaData;

  ExpanderControl: VCXExpanderControlMetaData;
  SplitterControl: VCXSplitterControlMetaData;

  BookTabControl: VCXTabControlMetaData;
  GridControl: VCXGridControlMetaData;
  Placement: VCXPlacementMetaData;
  TreeViewControl: VCXTreeViewControlMetaData;
  DashboardControl: VCXDashboardControlMetaData;
  ViewControl: VCXViewControlMetaData;

  DataInputControl: VCXDataInputControlMetaData;
  ListViewControl: VCXListViewControlMetaData;

  LabelControl: VCXLabelBaseMetaData;
  TitleControl: VCXLabelBaseMetaData;
}
export interface VCXMetaData extends K2JSONObject {}

export interface VCXListViewControlMetaData extends VCXMetaData {
  SmallIconSize: number;
  LargeIconSize: number;
  Font: VCXFontNoColorMetaData;
}

export interface VCXLabelBaseMetaData extends VCXMetaData {
  Font: VCXFontNoColorMetaData;
}

export interface VCXInputEditBaseMetaData extends VCXMetaData {
  InteriorBorder: number;
  Font: VCXFontNoColorMetaData;
}

export interface VCXDataInputControlMetaData extends VCXMetaData {
  FrameWidth: number;
  InputLabel: VCXLabelBaseMetaData;
  InputEdit: VCXInputEditBaseMetaData;
}

export interface VCXViewControlMetaData extends VCXMetaData {
  OutLineWidth: number;
  FrameWidth: number;
  Margin: number;
}

export interface VCXDashboardControlMetaData extends VCXMetaData {
  TileFont: VCXFontNoColorMetaData;
  TileSmallFont: VCXFontNoColorMetaData;
  GraphFont: VCXFontNoColorMetaData;
  SimpleTableFont: VCXFontNoColorMetaData;
  FilterInnerFont: VCXFontNoColorMetaData;
}

export interface VCXTreeViewControlMetaData extends VCXMetaData {
  HeadingFont: VCXFontNoColorMetaData;
  Font: VCXFontNoColorMetaData;
}

export interface VCXPlacementMetaData extends K2JSONObject {
  MaximizeAll: number;
}

export interface VCXGridControlMetaData extends VCXMetaData {
  HeadingFont: VCXFontNoColorMetaData;
  Font: VCXFontNoColorMetaData;
}

export interface VCXTabControlMetaData extends VCXMetaData {
  CurrentAccessorFont: VCXFontNoColorMetaData;
  AccessorFont: VCXFontNoColorMetaData;
}

export interface VCXSplitterControlMetaData extends VCXMetaData {
  Size: number;
}

export interface VCXExpanderControlMetaData extends VCXMetaData {
  HeaderFont: VCXFontNoColorMetaData;
  LiteHeaderFont: VCXFontNoColorMetaData;
}

export interface VCXSimpleChartControlMetaData extends VCXMetaData {
  ChartCaptionFont: VCXFontNoColorMetaData;
  Font: VCXFontNoColorMetaData;
}

export interface VCXFontNoColorMetaData extends VCXMetaData {
  FontStyle: FontStyle;
  FontSize: number;
  FontName: string;
}

//#endregion "VCX"
