import React = require("react");

import { Log, makeCancelable, CancelablePromise } from "../common/common";
import { Align, UpdateControl, UpdateImage } from "../common/communication.base";
import { NclImage } from "../common/components.ncl";
import { MediaManager, MediaData } from "../common/mediaManager";
import { VCXColorMap, VisualContext } from "../common/visualContext";
import { K2ComponentState, StyleHelper, withContext, WithContextPlacementProps, WithVCXProps, AcquireControl } from "./k2hoc";
import { K2RuleWithVCXProps } from "./k2StyleRenderer";

interface ImgProps extends WithVCXProps {
  glyphId: string;
  height?: number;
  width?: number;
  strokeColor?: string;
  backgroundColor?: string;
  style?: React.CSSProperties;
  stretch?: boolean;
  className?: string;
}

interface ImgSource {
  src: string;
}

interface ImgState {
  height: number;
  width: number;
  svg: string;
}

type ImgRuleProps = {
  height?: number;
  width?: number;
  backgroundColor?: string;
};

type SvgRuleProps = K2RuleWithVCXProps & {
  strokeColor: string;
  backgroundColor?: string;
};

const divRule = (props: ImgRuleProps) => ({
  minWidth: "inherit",
  flex: "0 0 auto",
  height: props.height ? props.height + "px" : "100%",
  width: props.width ? props.width + "px" : "100%",
  margin: "auto",
  marginTop: "initial",
  marginBottom: "initial",
  padding: "1px",
  backgroundColor: props.backgroundColor,
});

const imgStretchRule = (props: ImgSource) => ({
  display: "block",
  width: "100%",
  backgroundImage: "url(" + props.src + ")",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
});

const svgWrapper = (props: SvgRuleProps) => ({
  minWidth: "inherit",
  height: "100%",
  width: "100%", // odebráno původní -webkit-fill-available protože mozilla tuto hodnotu nepodporuje
  justifyContent: "center",
  backgroundColor: props.backgroundColor,
  "> svg": {
    fill: props.strokeColor ? props.strokeColor : "currentColor",
    height: "100%",
    width: "auto",
    minWidth: "inherit",
  },
});

const notFoundSvg =
  '<?xml version="1.0" encoding="utf-8"?><!-- Generator: Adobe Illustrator 20.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  --><svg version="1.1" id="error.invert" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 32 32" enable-background="new 0 0 32 32" xml:space="preserve" xmlns:k2svg="http://www.k2.cz/svg">' +
  '<path id="error.invert_1_" d="M16,2c7.7,0,14,6.3,14,14s-6.3,14-14,14S2,23.7,2,16S8.3,2,16,2 M16,0C7.2,0,0,7.2,0,16s7.2,16,16,16&#xA;	s16-7.2,16-16S24.8,0,16,0L16,0z M24.5,8.9l-1.4-1.4L16,14.6L8.9,7.5L7.5,8.9l7.1,7.1l-7.1,7.1l1.4,1.4l7.1-7.1l7.1,7.1l1.4-1.4&#xA;	L17.4,16L24.5,8.9z"/></svg>';

class _Img extends React.Component<ImgProps, ImgState> {
  static displayName = `K2Img`;
  private promise: CancelablePromise<MediaData>;
  constructor(props: ImgProps) {
    super(props);
    this.state = { height: 0, width: 0, svg: null };
  }

  private isSVG(): boolean {
    if (this.state.svg) return true;
    return NclImage.isSVG(this.props.glyphId);
  }

  componentWillUnmount() {
    if (this.promise) {
      this.promise.cancel();
    }
  }

  loadImage = () => {
    this.promise = makeCancelable<MediaData>(MediaManager.getSVG(this.props.glyphId));

    this.promise.promise
      .then((svg) => this.setState({ svg: svg.content }))
      .catch((reason) => {
        if (reason && !reason.isCanceled) {
          Log.error("Svg error: " + reason, null);
          this.setState({ svg: notFoundSvg });
        }
      });
  };

  componentDidUpdate(prevProps: ImgProps, prevState: ImgState) {
    if (this.isSVG() && prevProps.glyphId !== this.props.glyphId) {
      this.loadImage();
    }
  }

  componentDidMount() {
    if (this.isSVG()) {
      this.loadImage();
    }
  }

  render() {
    let img: JSX.Element = null;
    let clsName = "";

    if (!this.props.glyphId || this.props.glyphId === "") return null;

    if (this.isSVG()) {
      if (this.state.svg) {
        img = (
          <div
            dangerouslySetInnerHTML={{ __html: this.state.svg }}
            className={this.props.renderer.renderRule<SvgRuleProps>(svgWrapper, {
              vcx: this.props.vcx,
              backgroundColor: this.props.backgroundColor,
              strokeColor: this.props.strokeColor,
            })}
          />
        );
      }
    } else {
      let src = NclImage.getSourceUrl(this.props.glyphId, this.props.height > this.state.height ? this.props.height : this.state.height);
      let clsName: string = this.props.renderer.renderRule<ImgSource>(imgStretchRule, { src: src && src.url ? src.url : "" });
      img = <div className={clsName}></div>;
    }

    if (this.props.className) {
      clsName = this.props.className + " ";
    }

    return (
      <div
        className={
          clsName +
          this.props.renderer.renderRule<ImgRuleProps>(divRule, {
            height: this.props.height,
            width: this.props.width,
            backgroundColor: this.props.backgroundColor,
          })
        }
        style={this.props.style}
      >
        {img}
      </div>
    );
  }
}

export const K2Img = withContext(_Img);

class _Image extends React.PureComponent<WithContextPlacementProps, K2ComponentState<UpdateImage>> {
  static displayName = `K2Image`;
  private control: NclImage;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclImage;
    }) as NclImage;
    this.state = { data: this.control.init(this) as UpdateImage, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateImage>) => {
      return { data: state as UpdateImage };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    let img = null;

    if (this.state.data.ImageDeny === true) {
      img = getDenyImage(this.control.VCX, this.state.data.Stretch, this.control.MetaData.Bounds.Align, this.control.ComputedMinHeight);
    } else {
      img = (
        <K2Img
          glyphId={this.state.data.ObjectId && this.state.data.ObjectId !== "" ? this.state.data.ObjectId : this.state.data.GlyphId}
          vcx={this.control.VCX}
          height={this.control.MetaData.Bounds.Align != Align.Client ? this.control.ComputedMinHeight : null}
          stretch={this.state.data.Stretch}
          backgroundColor={this.control.VCX.ColorMap.getColor(this.control.VCX.Data.ColorMap.DataBrowseColorBck)}
        />
    );
  }
    return <div style={StyleHelper(this.control, this.props.style)}>{img}</div>;
  }
}

export function getDenyImage(vcx: VisualContext, stretch: boolean = false, align: Align = Align.Client, minHeight: number = 0): JSX.Element {
  return (
    <K2Img
      glyphId="wui*disagree"
      vcx={vcx}
      height={align != Align.Client ? minHeight : null}
      stretch={stretch}
      strokeColor={vcx.ColorMap.getColorRGB(vcx.Data.ColorMap.ContentNormalColorFrg).toHTML(0.2)}
      backgroundColor={vcx.ColorMap.getColor(vcx.Data.ColorMap.DataBrowseColorBck)}
    />
  );
}

export const K2Image = withContext(_Image);
