import * as React from "react";

import { Context } from "../appcontext";
import { ElementHtmlAttributes, getAttributes } from "../common/common";
import {
  CondFormattingStyle,
  CSColumnBase,
  TAcceptMode,
  TCondFormattingOption,
  TInputMultiLineMode,
  TNclInputType,
  TUFDisplayValueAs,
  UpdateControl,
  UpdateInput,
} from "../common/communication.base";
import { NclInput, EditCheckValueResult, NclLocatorPanel, InputSelectionText, UFOverAlign } from "../common/components.ncl";
import { AcquireControl, K2ComponentState, StyleHelper, withContext, WithContextPlacementProps } from "./k2hoc";
import { K2Img } from "./K2Image";
import { getTextDecoration, K2RuleWithVCXProps } from "./k2StyleRenderer";
import { K2ToolBar } from "./K2ToolBar";
import { K2TruncateText } from "./K2TruncateText";
import { setRequestedActiveControl } from "./app";
import { VCXColorMap, VisualContext } from "../common/visualContext";

interface InputHtmlAttributes extends ElementHtmlAttributes {
  type: string;
  id: string;

  maxLength?: number;
  autoComplete?: string;
  placeholder?: string;
  pattern?: string;
  title?: string;

  min?: number;
  max?: number;
  step?: number;
}

type WLProps = K2RuleWithVCXProps & {
  lines: number;
};

/* CSS */
const lblRule = (props: K2RuleWithVCXProps) => ({
  flex: "none",
  color: props.vcx.getColor(props.vcx.Data.ColorMap.ContentDecorateColorFrg),
  padding: props.vcx.sizeMap(1) + "px " + props.vcx.sizeMap(7) + "px",
  // width: 'max-content'
});

const lblModifiedRule = (props: K2RuleWithVCXProps) => ({
  background: props.vcx.getColor(props.vcx.Data.ColorMap.ContentChangeDecorateColorFrg),
  color: props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorFrg1),
});

const lblErrorRule = (props: K2RuleWithVCXProps) => ({
  background: props.vcx.getColor(props.vcx.Data.ColorMap.ErrorColorBck),
  color: props.vcx.getColor(props.vcx.Data.ColorMap.ErrorColorFrg),
});

const lblWarnRule = (props: K2RuleWithVCXProps) => ({
  background: props.vcx.getColor(props.vcx.Data.ColorMap.WarningColorBck),
  color: props.vcx.getColor(props.vcx.Data.ColorMap.WarningColorFrg),
});

const inputRule = (props: K2RuleWithVCXProps) => ({
  display: "flex",
  flexDirection: "column" as "column",
  color: props.vcx.getColor(props.vcx.Data.ColorMap.ContentNormalColorFrg),
});

export const WrapLabel = (props: WLProps) => ({
  background: props.inEditMode
    ? props.vcx.getColor(props.vcx.Data.ColorMap.DataChangeColorBck)
    : props.vcx.getColor(props.vcx.Data.ColorMap.DataBrowseColorBck),
  outlineWidth: props.vcx.InputControl.getInputFrameWidth(),
  outlineStyle: "solid" as "solid",
  outlineColor: props.inEditMode ? props.vcx.getColor(props.vcx.Data.ColorMap.AccentBaseColorBck) : props.vcx.getColor(props.vcx.Data.ColorMap.ContentFrame1),
  width: "auto",
  display: "flex",
  flex: "1 1 auto",
  justifyContent: "space-between",
  alignItems: "center",
  overflow: "hidden" as "hidden",
  padding: props.vcx.InputControl.getInputInteriorBorder(),
  //height: props.vcx.InputControl.getEditHeight(props.lines),
  border: props.inEditMode ? props.vcx.InputControl.getInputFrameWidth() + "px solid transparent" : null,
  ":hover": {
    border: props.inEditMode
      ? props.vcx.InputControl.getInputFrameWidth() + "px solid " + props.vcx.getColor(props.vcx.Data.ColorMap.AccentBaseColorBck)
      : null,
  },
});

export const inputTag = (props: K2RuleWithVCXProps) => ({
  background: "transparent",
  border: "none",
  flex: 1,
  width: "auto",
  height: "100%",
  alignSelf: "center",
  paddingLeft: ".2em",
  minWidth: props.vcx.sizeMap(20) + "px",
  userSelect: "auto" as "auto",
});

export const inputFocusedLabel = (props: K2RuleWithVCXProps) => ({
  borderStyle: "solid" as "solid",
  borderWidth: props.vcx.InputControl.getInputFrameWidth(),
  borderColor: props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorBck1) + " !important",
  outlineColor: props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorBck1) + " !important",
});

const prefixAndSufix = (props: K2RuleWithVCXProps) => ({
  padding: "0px " + props.vcx.sizeMap(3) + "px",
  color: props.vcx.getColor(props.vcx.Data.ColorMap.ContentDecorateColorFrg),
  width: "auto",
  overflow: "hidden",
  flex: "0 0 auto",
});

const toolbarRule = (props: K2RuleWithVCXProps) => ({
  flex: "1 0 auto",
  alignSelf: "center",
  color: props.vcx.getColor(props.vcx.Data.ColorMap.BaseColorBck1),
});

const alignLeft = (props: K2RuleWithVCXProps) => ({
  textAlign: "left" as "left",
});

const alignRight = (props: K2RuleWithVCXProps) => ({
  textAlign: "right" as "right",
});

export const inputContainerRule = (props: K2RuleWithVCXProps) => ({
  flex: "1 1 auto",
  width: "100%",
  height: "100%",
  overflow: "hidden",
});

export const innerInputRule = (props: K2RuleWithVCXProps & { formatting: CondFormattingStyle }) => ({
  backgroundColor: ColumnBaseHelper.getBackgroundColor(props.formatting, false, false, props.vcx),
  color: ColumnBaseHelper.getForegroundColor(props.formatting, false, false, props.vcx),
  fontWeight: ColumnBaseHelper.IsBold(props.formatting) ? ("bold" as "bold") : ("normal" as "normal"),
  fontStyle: ColumnBaseHelper.IsItalic(props.formatting) ? ("italic" as "italic") : ("normal" as "normal"),
  textDecoration: getTextDecoration(ColumnBaseHelper.IsStrike(props.formatting), ColumnBaseHelper.IsUnderline(props.formatting)),
});

interface InputState extends K2ComponentState<UpdateInput> {
  Focused: boolean;
  revealPassword: boolean;
}

enum TextPosition {
  beforeCursor,
  afterCursor,
  selected,
}

export class _Input extends React.PureComponent<WithContextPlacementProps, InputState> implements UFOverAlign {
  static displayName = `K2Input`;
  private element: InnerInput | InnerSimpleInput;
  private timer: any;
  private rootEl: HTMLElement;

  private control: NclInput;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclInput;
    }) as NclInput;
    this.state = { data: this.control.init(this) as UpdateInput, Focused: false, vcxVersion: -1, revealPassword: false };
  }

  getOverRect(): DOMRect {
    if (this.rootEl) {
      return this.rootEl.getBoundingClientRect();
    }

    return null;
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateInput>) => {
      return { data: state as UpdateInput };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  setAsActive(isActive: boolean) {
    if (this.element && isActive) {
      this.element.focus();
    }
  }

  componentDidUpdate(prevProps: WithContextPlacementProps, prevState: InputState) {
    if (this.state.data != prevState.data) {
      this.element.updateState(this.state.data, { Value: this.state.data.Text });
    }
  }

  render() {
    return (
      <div
        data-k2-errors-count={this.state.data.ErrorsCount > 0 ? this.state.data.ErrorsCount : undefined}
        style={StyleHelper(this.control, this.props.style)}
        ref={(ref) => {
          this.rootEl = ref;
        }}
        className={this.props.renderer.renderRule(inputRule, { vcx: this.control.VCX })}
      >
        {this.control.isVisibleTitle() && <K2TruncateText className={this.getLabelClassName()}>{this.state.data.Title}</K2TruncateText>}
        {this.getInputHtml()}
      </div>
    );
  }

  private getLabelClassName(): string {
    let className: string;
    if (this.state.data.ErrorsCount > 0) {
      className = this.props.renderer.renderRule(this.props.renderer.combineRule(lblRule, lblErrorRule), { vcx: this.control.VCX });
    } else if (this.state.data.WarningsCount > 0) {
      className = this.props.renderer.renderRule(this.props.renderer.combineRule(lblRule, lblWarnRule), { vcx: this.control.VCX });
    } else if (this.state.data.Modified && this.control.InEditMode !== false) {
      className = this.props.renderer.renderRule(this.props.renderer.combineRule(lblRule, lblModifiedRule), { vcx: this.control.VCX });
    } else {
      className = this.props.renderer.renderRule(lblRule, { vcx: this.control.VCX });
    }
    className += " ";
    className += this.props.renderer.renderFontRule(this.control.VCX.InputControl.LabelFont, this.control.VCX);
    return className;
  }

  private getHorizontalAlignByTyp(InputType: number): string {
    if (InputType === TNclInputType.nitString) {
      return this.props.renderer.renderRule(alignLeft, { vcx: this.control.VCX });
    }
    return this.props.renderer.renderRule(alignRight, { vcx: this.control.VCX });
  }

  private getPrefixSufix(inputClassFont: string, isPrefix: boolean = true): JSX.Element {
    let glyphId: string = "";
    let text: string = "";
    let valAs: TUFDisplayValueAs;
    if (isPrefix) {
      glyphId = this.state.data.PrefixGlyphId;
      text = this.state.data.PrefixText;
      valAs = this.control.Ncl.PrefixDisplayAs;
    } else {
      glyphId = this.state.data.SuffixGlyphId;
      text = this.state.data.SuffixText;
      valAs = this.control.Ncl.SuffixDisplayAs;
    }
    switch (valAs) {
      case TUFDisplayValueAs.dvaImage:
        return (
          <K2Img
            glyphId={glyphId}
            vcx={this.control.VCX}
            style={{ width: this.control.VCX.InputControl.getEditHeight(1), height: this.control.VCX.InputControl.getEditHeight(1) }}
          />
        );
      case TUFDisplayValueAs.dvaText:
        return <span className={inputClassFont + this.props.renderer.renderRule(prefixAndSufix, { vcx: this.control.VCX })}>{text}</span>;
      default:
        return null;
    }
  }

  private getTextFromPosition(option: TextPosition): string {
    if (
      this.element &&
      this.element.Value &&
      this.element.selectionStart &&
      this.element.selectionStart > 0 &&
      this.element.selectionStart < this.element.Value.length
    ) {
      switch (option) {
        case TextPosition.beforeCursor:
          return this.element.Value[this.element.selectionStart - 1];
        case TextPosition.afterCursor:
          return this.element.Value[this.element.selectionStart];
        case TextPosition.selected:
          if (this.element.selectionEnd && this.element.selectionStart < this.element.selectionEnd) {
            return this.element.Value.slice(this.element.selectionStart, this.element.selectionEnd);
          }
        default:
          break;
      }
    }

    return "";
  }

  private getInputHtml(): JSX.Element {
    let inputCss: string =
      this.props.renderer.renderFontRule(this.control.VCX.InputControl.InputFont, this.control.VCX) +
      " " +
      this.props.renderer.renderRule(innerInputRule, { vcx: this.control.VCX, formatting: this.state.data.FormattingStyle });

    let labelClass: string = "";
    if (this.state.Focused && !this.state.data.ReadOnly) {
      labelClass = this.props.renderer.renderRule(this.props.renderer.combineRules(WrapLabel, inputFocusedLabel), {
        vcx: this.control.VCX,
        lines: this.control.Size,
        inEditMode: this.control.InEditMode,
      });
    } else {
      labelClass = this.props.renderer.renderRule(WrapLabel, { vcx: this.control.VCX, lines: this.control.Size, inEditMode: this.control.InEditMode });
    }

    return (
      <label className={labelClass} onContextMenu={Context.DeviceInfo.IndependentFormatMode ? null : this.handleContextMenu}>
        <div className={this.props.renderer.renderRule(inputContainerRule, { vcx: this.control.VCX })}>
          {this.getPrefixSufix(inputCss, true)}
          {this.getInputTag(inputCss)}
          {this.getPrefixSufix(inputCss, false)}
        </div>
        {this.control.ToolBar && (
          <K2ToolBar
            controlUID={this.control.ToolBar.MetaData.ControlUID}
            vrUID={this.props.vrUID}
            className={this.props.renderer.renderRule(toolbarRule, { vcx: this.control.VCX })}
            style={{ width: "auto" }}
          />
        )}
        {this.control.Ncl.FrgtData.IsPassword === 1 && (
          <div style={{ flex: "0 0 auto" }}>
            <button type="button" style={{ border: "none", backgroundColor: "transparent" }} onClick={this.revealPassword}>
              <K2Img
                width={this.control.VCX.MinRowHeight}
                height={this.control.VCX.MinRowHeight}
                glyphId={this.state.revealPassword ? "wui*eye.no" : "wui*eye"}
                vcx={this.control.VCX}
                strokeColor={this.control.VCX.getColor(this.control.VCX.Data.ColorMap.BaseColorBck1)}
              />
            </button>
          </div>
        )}
      </label>
    );
  }

  private revealPassword = () => {
    this.setState({ revealPassword: !this.state.revealPassword });
  };

  private refCallBack = (element: InnerInput | InnerSimpleInput) => {
    if (element) {
      this.element = element;
    }
  };

  private getInputTag(inputCss: string): JSX.Element {
    if (Context.DeviceInfo.IndependentFormatMode) {
      return (
        <K2InnerSimpleInput
          controlUID={this.control.MetaData.ControlUID}
          vrUID={this.control.getRealizerUID()}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onKeyPress={this.handleKeyPress}
          change={this.change}
          onDrop={this.handleDrop}
          onPaste={this.handlePaste}
          showContextMenu={() => {
            this.showContextMenu();
          }}
          ref={this.refCallBack}
          InputTagPlaceholder={this.state.data.Watermark}
          className={this.getHorizontalAlignByTyp(this.control.Ncl.InputType) + " " + inputCss}
          revealPassword={this.state.revealPassword}
        />
      );
    } else {
      return (
        <K2InnerInput
          controlUID={this.control.MetaData.ControlUID}
          vrUID={this.control.getRealizerUID()}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          change={this.change}
          onDrop={this.handleDrop}
          onKeyDown={this.handleKeyDown}
          onKeyPress={this.handleKeyPress}
          onPaste={this.handlePaste}
          ref={this.refCallBack}
          InputTagPlaceholder={this.state.data.Watermark}
          className={this.getHorizontalAlignByTyp(this.control.Ncl.InputType) + " " + inputCss}
          revealPassword={this.state.revealPassword}
        />
      );
    }
  }

  private change = (value: string) => {
    this.control.change(value, false);
    switch (this.state.data.AcceptMode) {
      case TAcceptMode.amImmediate:
        this.control.accept();
        break;
      case TAcceptMode.amDelayed:
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(() => {
          this.control.accept();
        }, 1000);
        break;
    }
  };

  private handleFocus = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.control.setActiveControlRequested();
    this.setState({ Focused: true });
    e.stopPropagation();
  };

  private handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({ Focused: false });
  };

  private handleContextMenu = (e: React.MouseEvent<HTMLLabelElement>) => {
    e.preventDefault();
    this.showContextMenu();
    return false;
  };

  private showContextMenu() {
    this.control.contextMenu();
  }

  public handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement | HTMLDivElement>) => {
    if (e.keyCode === 8 || e.keyCode === 46) {
      if (this.control.Ncl.InputType === TNclInputType.nitTime || this.control.Ncl.InputType === TNclInputType.nitDate) {
        let value: string = this.getTextFromPosition(TextPosition.selected);
        if (!value) {
          value = this.getTextFromPosition(e.keyCode === 8 ? TextPosition.beforeCursor : TextPosition.afterCursor);
        }
        if (value && value.indexOf(Context.getApplication().getLocalSeparator(this.control.Ncl.InputType)) >= 0) {
          e.preventDefault();
          e.stopPropagation();
          return;
        }
      }
    }
  };

  public handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement | HTMLDivElement>) => {
    if (this.checkInput(e.key) > EditCheckValueResult.default) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
  };

  private handlePaste = (e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (this.checkInput(e.clipboardData.getData("text").trim()) > EditCheckValueResult.default) {
      e.preventDefault();
      return;
    }
  };

  private handleDrop = (e: React.DragEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let data = e.dataTransfer.getData("text").trim();
    let value = this.checkInput(data, true);
    switch (value) {
      case EditCheckValueResult.block:
        e.preventDefault();
        break;
      case EditCheckValueResult.default:
        this.element.updateState(null, { Value: data });
        e.preventDefault();
      default:
        this.element.focus();
        break;
    }
  };

  private checkInput(key: string, replaceAll?: boolean): EditCheckValueResult {
    if (!this.control.InEditMode) return;
    if (!this.element) return EditCheckValueResult.default;
    if (this.element.selectionStart && this.element.selectionStart < 0 && !replaceAll) return EditCheckValueResult.default;
    let selection: InputSelectionText = {
      start: this.element.selectionStart !== null && this.element.selectionStart >= 0 ? this.element.selectionStart : this.element.Value.length,
      end: this.element.selectionEnd !== null && this.element.selectionEnd >= 0 ? this.element.selectionEnd : this.element.Value.length,
    };
    let result = this.control.checkInput(this.element.Value, key, selection, replaceAll);
    switch (result.Result) {
      case EditCheckValueResult.block:
        //beep???
        break;
      case EditCheckValueResult.newValue:
        this.element.updateState(null, { Value: result.Value as string, Position: result.ToPosition });
        break;
      default:
        break;
    }

    return result.Result;
  }
}

interface InnerInputProps extends WithContextPlacementProps {
  onFocus: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onDrop?: (e: React.DragEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  change: (value: string) => void;
  InputTagPlaceholder: string;
  revealPassword: boolean;
}

interface InnerInputBaseState {
  Value?: string;
  Position?: number;
}

interface InnerInputStateBase extends K2ComponentState<UpdateInput>, InnerInputBaseState {}

interface InnerInputState extends InnerInputStateBase {}

class InnerInput extends React.PureComponent<InnerInputProps, InnerInputState> {
  static displayName = `InnerInput`;
  private element: HTMLInputElement | HTMLTextAreaElement;
  private control: NclInput;

  constructor(props: InnerInputProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclInput;
    }) as NclInput;

    this.state = { data: this.control.State as UpdateInput, Value: "", vcxVersion: -1 };
  }

  public get selectionStart(): number {
    return this.element ? this.element.selectionStart : -1;
  }

  public set selectionStart(value: number) {
    if (this.element) {
      this.element.selectionStart = value;
    }
  }

  public get selectionEnd(): number {
    return this.element ? this.element.selectionEnd : -1;
  }

  public set selectionEnd(value: number) {
    if (this.element) {
      this.element.selectionEnd = value;
    }
  }

  public get Value(): string {
    return this.state.Value;
  }

  updateState(state?: UpdateInput, valueState?: InnerInputBaseState) {
    this.setState((prevstate: InnerInputState) => {
      let newState = {};
      if (state) {
        newState = { data: state };
      }

      if (valueState) {
        newState = Object.assign({}, newState, valueState);
      }
      return newState;
    });
  }

  focus() {
    if (this.element) {
      this.element.focus();
    }
  }

  private needKeyStopPropagation = (e: React.KeyboardEvent<HTMLElement>) => {
    // zachytávám použitou(již aplikovanou) klávesu, aby dále neprobublávala DOM stromem
    if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.keyCode === 37 && !e.altKey) {
      //ctrl+shift+left
      e.stopPropagation();
    }

    if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.keyCode === 39 && !e.altKey) {
      //ctrl+shift+right
      e.stopPropagation();
    }

    if ((e.ctrlKey || e.metaKey) && e.keyCode === 67 && !(e.altKey || e.shiftKey)) {
      //ctrl+c
      e.stopPropagation();
    }
    if ((e.ctrlKey || e.metaKey) && e.keyCode === 45 && !(e.altKey || e.shiftKey)) {
      //ctrl+insert
      e.stopPropagation();
    }

    if ((e.ctrlKey || e.metaKey) && e.keyCode === 86 && !(e.altKey || e.shiftKey)) {
      //ctrl+v
      e.stopPropagation();
    }
    if (e.shiftKey && e.keyCode === 45 && !(e.ctrlKey || e.shiftKey)) {
      //shift+insert
      e.stopPropagation();
    }

    if ((e.ctrlKey || e.metaKey) && e.keyCode === 88 && !(e.altKey || e.shiftKey)) {
      //ctrl+x
      e.stopPropagation();
    }

    if ((e.ctrlKey || e.metaKey) && e.keyCode === 65 && !(e.altKey || e.shiftKey)) {
      //ctrl+a
      e.stopPropagation();
    }

    if ((e.ctrlKey || e.metaKey) && e.keyCode === 90 && !(e.altKey || e.shiftKey)) {
      //ctrl+z
      e.stopPropagation();
    }

    if ((e.ctrlKey || e.metaKey) && e.keyCode === 89 && !(e.altKey || e.shiftKey)) {
      //ctrl+y
      e.stopPropagation();
    }

    if (e.keyCode === 8 && !(e.altKey || e.shiftKey || e.ctrlKey)) {
      //Del
      e.stopPropagation();
    }

    if (e.keyCode === 46 && !(e.altKey || e.shiftKey || e.ctrlKey)) {
      //Del
      e.stopPropagation();
    }
  };

  private refCallBack = (element: HTMLInputElement | HTMLTextAreaElement) => {
    if (element) {
      this.element = element;
    }
  };

  componentDidUpdate(prevProps: WithContextPlacementProps, prevState: InnerInputStateBase) {
    if (this.element && this.state.Position != prevState.Position && this.state.Position >= 0) {
      this.element.selectionEnd = this.element.selectionStart = this.state.Position;
    }
  }

  render(): JSX.Element {
    let value: string = this.state.Value;
    let inputAttributes: InputHtmlAttributes = {
      type: "text",
      id: this.control.Ncl.Name,
      title: this.state.data.Hint + ": " + value,
      ...getAttributes(this.state.data),
    };
    let inputClass: string = this.props.renderer.renderRule(inputTag, { vcx: this.control.VCX }) + " " + this.props.className;

    inputAttributes.autoComplete = "off";
    inputAttributes.placeholder = this.props.InputTagPlaceholder;
    if (this.state.data.MaxLength) {
      inputAttributes.maxLength = this.state.data.MaxLength;
    }

    if (this.control.Ncl.FrgtData.IsPassword) {
      inputAttributes.type = this.props.revealPassword ? "text" : "password";
    }

    if (this.control.Ncl.InputType === TNclInputType.nitString && this.state.data.MultiLineMode) {
      return (
        <textarea
          className={inputClass}
          onKeyDown={this.handleKeyDown}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          ref={this.refCallBack}
          {...inputAttributes}
          value={value}
          spellCheck={false}
          rows={this.state.data.MultiLineMode == TInputMultiLineMode.imlmMultiLineSimple ? 1 : 0}
        />
      );
    } else {
      return (
        <input
          className={inputClass}
          value={value}
          ref={this.refCallBack}
          spellCheck={false}
          type="text"
          onKeyPress={this.props.onKeyPress}
          onKeyDown={this.handleKeyDown}
          onPaste={this.props.onPaste}
          onDrop={this.props.onDrop}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          {...inputAttributes}
        />
      );
    }
  }

  private handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (this.props.change) {
      let value = e.target.value;
      if (this.state.Value != value) {
        this.setState({ Value: value });
        this.props.change(value);
      }
    }
  };

  private handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (this.props.onKeyDown) {
      this.props.onKeyDown(e);
    }

    this.needKeyStopPropagation(e); // catched used key in input, that i can't use in other parts of application

    if (!e.isPropagationStopped()) {
      if (((e.ctrlKey || e.metaKey) && e.keyCode === 38) || ((e.ctrlKey || e.metaKey) && e.keyCode === 40)) {
        //ctrl+UpArrow//ctrl+DownArrow
        this.control.nextPriorValue(e.keyCode === 40 ? true : false, this.state.Value);
        e.preventDefault();
      }

      if (e.keyCode === 13) {
        //ENTER
        this.control.change(this.state.Value, false); //set value for send, but send key Enter
      }
    }
  };

  private handleFocus = (e: React.FocusEvent<any>) => {
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
    if (!(this.control.Parent instanceof NclLocatorPanel)) {
      this.element.select();
    }
  };

  private handleBlur = (e: React.FocusEvent<any>) => {
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
    setRequestedActiveControl(e, this.control.getRealizerUID());
    this.control.change(this.state.Value, true);
  };
}

const K2InnerInput = withContext(InnerInput);

interface InnerSimpleInputProps extends InnerInputProps {
  showContextMenu?: () => void;
}

interface InnerSimpleInputState extends InnerInputStateBase {}

class InnerSimpleInput extends React.PureComponent<InnerSimpleInputProps, InnerSimpleInputState> {
  static displayName = `InnerSimpleInput`;
  private element: HTMLInputElement | HTMLTextAreaElement;
  private control: NclInput;
  private contextMenuTimer: any;
  private possibleShowContextMenu: boolean = false;

  constructor(props: InnerSimpleInputProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclInput;
    }) as NclInput;

    this.state = { data: this.control.State as UpdateInput, Value: "", vcxVersion: -1 };

    if (this.control.Ncl.InputType == TNclInputType.nitDate && this.control.ToolBar) {
      this.control.ToolBar.removeAction("_ExecuteLookupCOMMAND");
    }
  }

  updateState(state?: UpdateInput, valueState?: InnerInputBaseState) {
    this.setState((prevstate: InnerInputState) => {
      let newState = {};
      if (state) {
        newState = { data: state };
      }

      if (valueState) {
        newState = Object.assign({}, newState, valueState);
      }
      return newState;
    });
  }

  public get selectionStart(): number {
    return this.element ? this.element.selectionStart : -1;
  }

  public set selectionStart(value: number) {
    if (this.element) {
      this.element.selectionStart = value;
    }
  }

  public get selectionEnd(): number {
    return this.element ? this.element.selectionEnd : -1;
  }

  public set selectionEnd(value: number) {
    if (this.element) {
      this.element.selectionEnd = value;
    }
  }

  public get Value(): string {
    return this.state.Value;
  }

  focus() {
    if (this.element) {
      this.element.readOnly = true;
      this.element.focus();

      if (Context.DeviceInfo.BrowserInfo === "Firefox") {
        this.element.inputMode = "none";
      }

      this.element.readOnly = false;
    }
  }

  private getInputType(): string {
    switch (this.control.Ncl.InputType) {
      case TNclInputType.nitDate:
        // datum obsahuje cas, napr. dd/mm/yyyy hh:mm:ss
        if (this.state.data.Format.includes("h")) {
          return "datetime-local"; // datetime je deprecated
        }
        return "date";
      case TNclInputType.nitTime:
        return "time";
      case TNclInputType.nitNumeric:
        return "number";
      default:
        break;
    }

    return "text";
  }

  private getInputMode(): "numeric" | "decimal" | "text" {
    if (this.control.Ncl.InputType === TNclInputType.nitNumeric) {
      if (this.control.getDecimalsCountByFormat() > 0) {
        return "numeric";
      } else {
        return "decimal";
      }
    }

    return "text";
  }

  private getValue(value: string) {
    if (value == undefined) return "";

    if (this.control.Ncl.InputType === TNclInputType.nitTime) {
      return this.control.getTimeByFormat(this.state.data.Format, value);
    } else {
      return value;
    }
  }

  private getStep(): string {
    if (this.control.Ncl.InputType == TNclInputType.nitNumeric) {
      return (1 / Math.pow(10, 4)).toString();
    }

    return "1";
  }

  componentDidUpdate(prevProps: WithContextPlacementProps, prevState: InnerInputStateBase) {
    if (this.element && this.state.Position != prevState.Position && this.state.Position >= 0) {
      this.element.selectionEnd = this.element.selectionStart = this.state.Position;
    }
  }

  private refCallBack = (ref: HTMLInputElement | HTMLTextAreaElement) => {
    if (ref) {
      this.element = ref;
      if (Context.DeviceInfo.IsIOS && Context.DeviceInfo.IsTouchDevice && this.element instanceof HTMLInputElement) {
        this.element.defaultValue = "";
      }
    }
  };

  render(): JSX.Element {
    if (
      Context.DeviceInfo.IsIOS &&
      Context.DeviceInfo.IsTouchDevice &&
      this.element instanceof HTMLInputElement &&
      (this.control.Ncl.InputType === TNclInputType.nitDate || this.control.Ncl.InputType === TNclInputType.nitTime)
    ) {
      const target = this.element;
      setTimeout(() => {
        target.defaultValue = "";
      }, 100);
    }
    let value = this.getValue(this.state.Value);
    let inputAttributes: InputHtmlAttributes = {
      type: this.getInputType(),
      id: this.control.Ncl.Name,
      title: this.state.data.Hint + ": " + value,
      ...getAttributes(this.state.data),
    };
    let inputClass: string = this.props.renderer.renderRule(inputTag, { vcx: this.control.VCX }) + " " + this.props.className;

    inputAttributes.autoComplete = "off";
    inputAttributes.placeholder = this.props.InputTagPlaceholder;
    if (this.state.data.MaxLength) {
      inputAttributes.maxLength = this.state.data.MaxLength;
    }

    if (this.control.Ncl.FrgtData.IsPassword) {
      inputAttributes.type = this.props.revealPassword ? "text" : "password";
    }
    if (this.control.Ncl.InputType === TNclInputType.nitString && this.state.data.MultiLineMode) {
      return (
        <textarea
          className={inputClass}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onContextMenu={this.handleContextMenu}
          ref={this.refCallBack}
          {...inputAttributes}
          value={value}
          spellCheck={false}
          rows={this.state.data.MultiLineMode == TInputMultiLineMode.imlmMultiLineSimple ? 1 : 0}
        />
      );
    } else {
      return (
        <div style={{ width: "100%", minWidth: this.control.VCX.sizeMap(20) + "px" }}>
          <input
            style={{ flex: 1 }}
            className={inputClass}
            ref={this.refCallBack}
            inputMode={this.getInputMode()}
            step={this.getStep()}
            value={value}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            onPaste={this.props.onPaste}
            onTouchEnd={this.handleTouchEnd}
            onTouchStart={this.handleTouchStart}
            onTouchMove={this.handleTouchMove}
            //onTouchCancel={this.handleTouchCancel}
            onContextMenu={this.handleContextMenu}
            {...inputAttributes}
          />
          {(this.control.Ncl.InputType === TNclInputType.nitDate || this.control.Ncl.InputType === TNclInputType.nitTime) && (
            <K2Img
              style={{ alignSelf: "center", width: "auto" }}
              strokeColor={this.control.VCX.getColor(this.control.VCX.Data.ColorMap.BaseColorBck1)}
              glyphId="wui*calendar"
              vcx={this.control.VCX}
              height={this.control.VCX.InputControl.getEditHeight(1)}
            />
          )}
        </div>
      );
    }
  }

  private handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (this.props.change) {
      let value = e.target.value;
      switch (this.control.Ncl.InputType) {
        case TNclInputType.nitNumeric:
          if (this.state.Value === "0") {
            if (value.startsWith(this.state.Value)) {
              value = value.substr(1);
            } else if (value.endsWith(this.state.Value)) {
              value = value.substr(0, 1);
            }
          }
          let accept = this.control.checkInput(this.state.Value, value, { start: 0, end: 0 }, true);
          if (!value || (accept && accept.Result === EditCheckValueResult.newValue)) {
            if (this.state.Value != value) {
              this.props.change(value);
              this.setState({ Value: value });
            }
          }

          break;
        case TNclInputType.nitDate:
        case TNclInputType.nitTime:
          if (this.state.Value != value && (value !== "" || (value === "" && !this.control.isEmpty()))) {
            this.props.change(value);
            this.control.accept();
          }
          break;
        case TNclInputType.nitString:
          if (this.state.Value != value) {
            this.props.change(value);
            this.setState({ Value: value });
          }
          break;
      }
    }
  };

  private handleTouchStart = (e: React.TouchEvent) => {
    if (Context.DeviceInfo.BrowserInfo === "Firefox") {
      this.element.readOnly = true;
      setTimeout(() => {
        this.element.inputMode = this.getInputMode();
        this.element.readOnly = false;
      }, 0);
    }

    this.possibleShowContextMenu = true;
    this.contextMenuTimer = setTimeout(() => {
      if (this.possibleShowContextMenu) {
        this.possibleShowContextMenu = false;
        if (this.props.showContextMenu) {
          this.props.showContextMenu();
        }
      }
    }, 610);
  };

  private handleTouchEnd = (e: React.TouchEvent) => {
    if (this.possibleShowContextMenu === false) {
      e.preventDefault();
    }
    this.possibleShowContextMenu = false;
    clearTimeout(this.contextMenuTimer);
  };

  private handleTouchMove = (e: React.TouchEvent) => {
    this.possibleShowContextMenu = false;
    clearTimeout(this.contextMenuTimer);
  };

  private handleTouchCancel = (e: React.TouchEvent) => {
    this.possibleShowContextMenu = false;
    clearTimeout(this.contextMenuTimer);
  };

  private handleContextMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation(); //on android show context menu with copy/paste/...
  };

  private handleFocus = (e: React.FocusEvent<any>) => {
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  };

  private handleBlur = (e: React.FocusEvent<any>) => {
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
    setRequestedActiveControl(e, this.control.getRealizerUID());
    this.control.accept();
  };
}

const K2InnerSimpleInput = withContext(InnerSimpleInput);

export const K2Input = withContext(_Input);

export class ColumnBaseHelper {
  static getForegroundColor(formatting: CondFormattingStyle, isSelected: boolean, fixed: boolean, vcx: VisualContext): string {
    if (this.UseForegroundColor(formatting)) {
      return vcx.getColor(formatting.ForegroundColor);
    }
    return "inherit";
  }

  static getBackgroundColor(formatting: CondFormattingStyle, isSelectedText: boolean, fixed: boolean, vcx: VisualContext): string {
    let result: string = "inherit";
    if (isSelectedText) {
      result = vcx.getColor(vcx.Data.ColorMap.GridRulerColorBck);
    }

    if (this.UseBackgroundColor(formatting)) {
      if (isSelectedText) {
        result = VCXColorMap.rgbToHTMLColor(
          VCXColorMap.combineAlpha(vcx.getRGBColor(formatting.BackColor), vcx.getRGBColor(vcx.Data.ColorMap.GridRulerColorBck), 180)
        );
      } else {
        result = vcx.getColor(formatting.BackColor);
      }
    } else {
      if (fixed) {
        if (isSelectedText) {
          result = VCXColorMap.rgbToHTMLColor(
            VCXColorMap.combineAlpha(vcx.getRGBColor(vcx.Data.ColorMap.DataChangeROColorBck), vcx.getRGBColor(vcx.Data.ColorMap.GridRulerColorBck), 180)
          );
        } else {
          result = vcx.getColor(vcx.Data.ColorMap.DataChangeROColorBck);
        }
      }
    }

    return result;
  }
  static IsBold(formatting: CondFormattingStyle): boolean {
    return formatting && (formatting.Options & TCondFormattingOption.cfoFontStyleBold) === TCondFormattingOption.cfoFontStyleBold;
  }

  static IsItalic(formatting: CondFormattingStyle): boolean {
    return formatting && (formatting.Options & TCondFormattingOption.cfoFontStyleItalic) === TCondFormattingOption.cfoFontStyleItalic;
  }

  static IsStrike(formatting: CondFormattingStyle): boolean {
    return formatting && (formatting.Options & TCondFormattingOption.cfoFontStyleStrike) === TCondFormattingOption.cfoFontStyleStrike;
  }

  static IsUnderline(formatting: CondFormattingStyle): boolean {
    return formatting && (formatting.Options & TCondFormattingOption.cfoFontStyleUnderline) === TCondFormattingOption.cfoFontStyleUnderline;
  }

  static UseBackgroundColor(formatting: CondFormattingStyle): boolean {
    return formatting && formatting.BackColor > 0 && (formatting.Options & TCondFormattingOption.cfoBackColor) === TCondFormattingOption.cfoBackColor;
  }

  static UseForegroundColor(formatting: CondFormattingStyle): boolean {
    return (
      formatting &&
      formatting.ForegroundColor > 0 &&
      (formatting.Options & TCondFormattingOption.cfoForegroundColor) === TCondFormattingOption.cfoForegroundColor
    );
  }
}
