import * as React from "react";
import { withContext, WithVCXProps } from "./k2hoc";
import { DisplayMode } from "../common/communication.base";
import { K2RuleWithVCXProps } from "./k2StyleRenderer";

interface K2TruncateTextProps {
  className?: string;
  children: string;
  style?: React.CSSProperties;
  line?: number;
  keyboardAccelerator?: boolean;
  textColor?: string;
  textBackColor?: string;
  onClick?: () => void;
}

const UnderLinePattern = "&\\w";

export class K2TruncateText extends React.PureComponent<K2TruncateTextProps> {
  static displayName = `K2TruncateText`;
  private _accessKey: string;

  private convertText(): string | JSX.Element {
    let input = this.props.children;

    if (!input || this.props.keyboardAccelerator === false) return input;

    let matches = input.match(UnderLinePattern);
    if (matches && matches.length === 1) {
      let elements: JSX.Element[] = new Array<JSX.Element>();
      matches.forEach((value: string, index: number, array: string[]) => {
        if (!this._accessKey) {
          this._accessKey = value.charAt(1);
        }
        let i = input.indexOf(value);
        if (i === 0) {
          elements.push(<u key={this._accessKey + "_u"}>{this._accessKey}</u>);
          elements.push(<span key={this._accessKey + "_s"}>{input.substring(i + 2)}</span>);
        } else if (i > 0) {
          elements.push(<span key={this._accessKey + "_s0"}>{input.substring(0, i)}</span>);
          elements.push(<u key={this._accessKey + "_u"}>{this._accessKey}</u>);
          elements.push(<span key={this._accessKey + "_s"}>{input.substring(i + 2)}</span>);
        } else {
          return input;
        }
      });

      return <span>{elements}</span>;
    }

    return input;
  }

  public get accessKey(): string {
    return this._accessKey;
  }

  render() {
    let multilineStyle: React.CSSProperties = {
      display: "-webkit-box",
      WebkitBoxOrient: "vertical" as "vertical",
      WebkitLineClamp: this.props.line,
      whiteSpace: "normal" as "normal",
      lineHeight: "1em",
      maxHeight: this.props.line + "em",
    };

    let style: React.CSSProperties = this.props.style;
    if (this.props.line && this.props.line > 1) {
      style = Object.assign({}, multilineStyle, style);
    }

    if (this.props.onClick) {
      let linkStyle: React.CSSProperties = {};
      style = Object.assign({}, style, linkStyle);
    }

    let clsName: string = "truncate-text";
    if (this.props.className && this.props.className != undefined) {
      clsName += " " + this.props.className;
    }

    let st: React.CSSProperties;
    if (this.props.textBackColor) {
      st = {};
      st.backgroundColor = this.props.textBackColor;
    }
    if (this.props.textColor) {
      if (!st) st = {};
      st.color = this.props.textColor;
    }
    let content: string | JSX.Element;
    if (st) {
      content = <span style={st}>{this.convertText()}</span>;
    } else {
      content = this.convertText();
    }
    return (
      <p className={clsName} style={style} onClick={this.handleClick}>
        {content}
      </p>
    );
  }

  private handleClick = (e: React.MouseEvent<HTMLParagraphElement>) => {
    if (this.props.onClick) {
      this.props.onClick.call(this);
    }
  };
}

/**
 * Nastavení komponenty K2Text
 */
interface TextProps extends WithVCXProps {
  displayMode?: DisplayMode;
  children: string;
  className?: string;
  rawRender?: boolean;
}

type K2RuleDMProps = K2RuleWithVCXProps & {
  displayMode: DisplayMode;
};

const txtRule = (props: K2RuleDMProps) => ({
  display: props.displayMode === DisplayMode.fpdmNone ? "none" : null,
  fontWeight:
    props.displayMode === DisplayMode.fpdmDecoratedBold || props.displayMode === DisplayMode.fpdmNormalBold ? ("bold" as "bold") : ("normal" as "normal"),
  color:
    props.displayMode === DisplayMode.fpdmDecoratedBold || props.displayMode === DisplayMode.fpdmDecorated
      ? props.vcx.getColor(props.vcx.Data.ColorMap.ContentDecorateColorFrg)
      : props.vcx.getColor(props.vcx.Data.ColorMap.ContentNormalColorFrg),
  flex: "1 1 auto",
});

class _Text extends React.PureComponent<TextProps> {
  static displayName = `K2Text`;

  render() {
    if (!this.props.rawRender) {
      return (
        <div className={this.props.className}>
          <K2TruncateText className={this.props.renderer.renderRule<K2RuleDMProps>(txtRule, { vcx: this.props.vcx, displayMode: this.props.displayMode })}>
            {this.props.children}
          </K2TruncateText>
        </div>
      );
    } else {
      return <div className={this.props.className}>{this.props.children}</div>;
    }
  }
}

export const K2Text = withContext(_Text);
