import React = require("react");

import { ControlType, NclBaseContainer, NclDockControl, UFNclControlBase } from "../common/components.ncl";
import { K2Action, K2ActionSeparator } from "./K2Action";
import { K2Button } from "./K2Button";
import { K2CheckBox } from "./K2CheckBox";
import { K2DataGrid } from "./K2DataGrid";
import { K2DataLabel } from "./K2DataLabel";
import { K2Expander } from "./K2Expander";
import { K2FloaterAccessor } from "./K2FloaterAccessor";
import { K2FlowPanel, K2SimplyFlowPanel } from "./K2FlowPanel";
import { K2FormattableInput } from "./K2FormattableInput";
import { K2GroupBox } from "./K2GroupBox";
import { K2Image } from "./K2Image";
import { K2LibraryReference } from "./K2LibraryReference";
import { K2PreviewPanel } from "./K2PreviewPanel";
import { K2SpaceControl } from "./K2SpaceControl";
import { K2SplitterPanel } from "./K2SplitterPanel";
import { K2StackPanel } from "./K2StackPanel";
import { K2MultiContent, K2PageHolder, K2TabControl } from "./K2TabControl";
import { K2TabToolBar } from "./K2TabToolBar";
import { K2UndefinedControl } from "./K2UndefinedControl";
import { K2ListView } from "./K2ListView";
import { K2OpenDialog } from "./K2OpenDialog";
import { K2DynamicContent } from "./K2DynamicContent";
import { K2LocatorPanel } from "./K2LocatorPanel";
import { K2FilePreview } from "./K2FilePreview";
import { K2SignInput } from "./K2Signature";
import { K2Ribbon } from "./K2Ribbon";
import { K2Input } from "./K2Input";
import { K2TreeView } from "./K2TreeView";

type ReferenceCallBack = (element: any) => any;

export const GenerateControl = (control: UFNclControlBase, style?: React.CSSProperties, ref?: ReferenceCallBack): JSX.Element => {
  if (!control) return null;
  let result = null;

  switch (control.MetaData.__type) {
    case ControlType.DataLabel:
      result = (
        <K2DataLabel key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} ref={ref} style={style} />
      );
      break;
    case ControlType.Input:
      result = <K2Input key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} ref={ref} style={style} />;

      break;
    case ControlType.FormattableInput:
      result = (
        <K2FormattableInput
          key={control.MetaData.ControlUID}
          controlUID={control.MetaData.ControlUID}
          vrUID={control.getRealizerUID()}
          ref={ref}
          style={style}
        />
      );
      break;

    case ControlType.SplitPanel:
      result = (
        <K2SplitterPanel key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} ref={ref} style={style} />
      );
      break;
    case ControlType.SimpleDataGrid:
      result = (
        <K2DataGrid key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} ref={ref} style={style} />
      );
      break;
    case ControlType.DataGrid:
      result = (
        <K2DataGrid key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} ref={ref} style={style} />
      );
      break;
    case ControlType.Expander:
      result = (
        <K2Expander key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} ref={ref} style={style} />
      );
      break;
    case ControlType.Image:
      result = <K2Image key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} ref={ref} style={style} />;
      break;
    case ControlType.FilePreview:
      result = (
        <K2FilePreview key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} ref={ref} style={style} />
      );
      break;
    case ControlType.DynamicContent:
      result = (
        <K2DynamicContent key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} ref={ref} style={style} />
      );
      break;
    case ControlType.Container:
    case ControlType.Panel:
    case ControlType.PanelBase:
      result = (
        <K2FlowPanel key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} ref={ref} style={style} />
      );
      break;
    case ControlType.ToolBar:
      result = (
        <K2TabToolBar key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} ref={ref} style={style} />
      );
      break;
    case ControlType.LibraryReference:
      result = (
        <K2LibraryReference
          key={control.MetaData.ControlUID}
          controlUID={control.MetaData.ControlUID}
          vrUID={control.getRealizerUID()}
          ref={ref}
          style={style}
        />
      );
      break;
    case ControlType.PreviewPanel:
      return (
        <K2PreviewPanel key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} ref={ref} style={style} />
      );
    case ControlType.DetailTab:
    case ControlType.Tab:
      result = (
        <K2TabControl key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} ref={ref} style={style} />
      );
      break;
    case ControlType.VRTab: {
      result = (
        <K2TabControl key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} ref={ref} style={style} />
      );
      break;
    }
    case ControlType.MultiContent:
      result = (
        <K2MultiContent key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} ref={ref} style={style} />
      );
      break;
    case ControlType.ActionTT:
    case ControlType.CommandAction:
    case ControlType.Action:
      result = <K2Action key={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} controlUID={control.MetaData.ControlUID} ref={ref} style={style} />;
      break;
    case ControlType.Button:
      result = <K2Button key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} ref={ref} style={style} />;
      break;
    case ControlType.CheckBox:
      result = (
        <K2CheckBox key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} ref={ref} style={style} />
      );
      break;
    case ControlType.GroupBox:
      result = (
        <K2GroupBox key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} ref={ref} style={style} />
      );
      break;
    case ControlType.Space:
      result = (
        <K2SpaceControl key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} ref={ref} style={style} />
      );
      break;
    case ControlType.ActionSeparator:
      result = (
        <K2ActionSeparator
          key={control.MetaData.ControlUID}
          controlUID={control.MetaData.ControlUID}
          vrUID={control.getRealizerUID()}
          ref={ref}
          style={style}
        />
      );
      break;
    case ControlType.StackPanel:
      result = (
        <K2StackPanel key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} ref={ref} style={style} />
      );
      break;
    case ControlType.FloaterAccessor:
      result = (
        <K2FloaterAccessor
          key={control.MetaData.ControlUID}
          controlUID={control.MetaData.ControlUID}
          vrUID={control.getRealizerUID()}
          ref={ref}
          style={style}
        />
      );
      break;
    case ControlType.LocatorPanel:
      result = (
        <K2LocatorPanel key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} ref={ref} style={style} />
      );
      break;
    case ControlType.SignInput:
      result = (
        <K2SignInput key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} ref={ref} style={style} />
      );
      break;
    case ControlType.ListView:
      result = (
        <K2ListView key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} ref={ref} style={style} />
      );
      break;
    case ControlType.Ribbon:
      result = <K2Ribbon key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} ref={ref} style={style} />;
      break;
    case ControlType.TreeView:
      result = (
        <K2TreeView key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} ref={ref} style={style} />
      );
      break;
    default:
      if (control instanceof NclDockControl) {
        result = (
          <K2PageHolder
            key={control.MetaData.ControlUID + "_placeHolder"}
            controlUID={control.MetaData.ControlUID}
            vrUID={control.getRealizerUID()}
            ref={ref}
            style={style}
          />
        );
      } else {
        result = (
          <K2UndefinedControl
            key={control.MetaData.ControlUID}
            controlUID={control.MetaData.ControlUID}
            vrUID={control.getRealizerUID()}
            ref={ref}
            style={style}
          />
        );
      }
  }

  return result;
};

export const GenerateChildrenOfControl = (control: NclBaseContainer): JSX.Element => {
  if (control && control.Children && control.Children.size > 0) {
    return <K2SimplyFlowPanel key={control.MetaData.ControlUID + "_content"} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} />;
  }

  return null;
};
