import * as React from "react";

import { UpdateControl, UFUpdateControl } from "../common/communication.base";
import { UFNclControlBase, NclSpace } from "../common/components.ncl";
import { withContext, WithContextProps, K2ComponentState, WithContextPlacementProps, StyleHelper, AcquireControl } from "./k2hoc";

class _SpaceControl extends React.PureComponent<WithContextPlacementProps, K2ComponentState<UFUpdateControl>> {
  static displayName = `K2SpaceControl`;
  private control: NclSpace;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclSpace;
    }) as NclSpace;
    this.state = { data: this.control.init(this) as UFUpdateControl, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UFUpdateControl>) => {
      return { data: state as UFUpdateControl };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    return (
      <div style={StyleHelper(this.control, this.props.style)}>
        <span />
      </div>
    );
  }
}

export const K2SpaceControl = withContext(_SpaceControl);
